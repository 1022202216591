import React, { useMemo, useState } from 'react';
import { OrderType } from './constants';
import { CallInOrderContext } from './context';

function NewOrderProvider({ children }) {
  const [order, setOrder] = useState({
    selectedMerchant: {},
    orderType: OrderType.Pickup,
    tip: 0,
    tipPercentage: 15,
    voucherCode: '',
    pickupTime: '',
    items: [],
    paymentMethod: null,
    cardId: '',
    address: '',
    deliveryFee: 0,
    adjustmentAmount: 0,
    deliveryMessage: '',
    scheduledFor: '',
    userId: '',
  });

  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState();
  const [menuItems, setMenuItems] = useState();

  const setOrderDetails = (details) => {
    setOrder({ ...order, ...details });
  };
  const contextValue = useMemo(() => ({
    order,
    menus,
    setMenus,
    selectedMenu,
    setSelectedMenu,
    menuItems,
    setMenuItems,
    setOrderDetails,
  }), [order, menus, setMenus, selectedMenu, setSelectedMenu, menuItems, setMenuItems, setOrderDetails]);

  return (
    <CallInOrderContext.Provider value={contextValue}>
      {children}
    </CallInOrderContext.Provider>
  );
}

export default NewOrderProvider;
