import { useCallback, useMemo, useState } from 'react';

export function useInputState(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(defaultValue);

  const handleSet = useCallback((v) => {
    setValue(v);
    setIsLoading(false);
  }, []);

  return useMemo(() => ({
    value,
    setValue: handleSet,
    isLoading,
    startLoading: () => setIsLoading(true),
    stopLoading: () => setIsLoading(false),
  }), [value, isLoading, handleSet]);
}
