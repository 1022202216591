import {
  Col, Divider, Form, Typography,
} from 'antd';
import { UserManagementContext } from 'containers/AccountSettings/UserManagement/constants';
import { checkIsSubordinateRole } from 'containers/AccountSettings/UserManagement/utils';
import { find } from 'lodash-es';
import React, { useContext, useMemo } from 'react';

const { Title, Text } = Typography;

function RoleDescription() {
  const { roles } = useContext(UserManagementContext);
  const userRoles = Form.useWatch('userRoles');
  const getRoleItem = (role) => find(roles, { name: role });
  const rolesItems = useMemo(() => userRoles?.filter((current) => !checkIsSubordinateRole({
    current,
    roles,
    selectedRoles: userRoles,
  })).map((role) => {
    const res = getRoleItem(role);
    return (
      <div className="flex flex-col" key={role.name}>
        <Text strong>{role}</Text>
        <Text className="mt-1 mb-2">{res?.description}</Text>
        <ul className="pl-4">
          {res?.permissions?.map((p, index) => <li key={`permission-${index}`}>{p}</li>)}
        </ul>
      </div>
    );
  }), [userRoles]);

  const hint = () => {
    if (!rolesItems || !rolesItems?.length) return 'Choose roles to see their descriptions…';
    if (rolesItems.length > 1) return 'User will receive the combined set of permissions provided by these roles.';
  };

  return (
    <Col xs={24} sm={12} className="modal-section-container">
      <Title level={5}>Role description</Title>
      {Boolean(hint()) && <Text type="secondary" style={{ fontSize: 13 }}>{hint()}</Text>}

      {Boolean(userRoles?.length) && (
        <>
          <Divider className="my-2" />
          <div>{rolesItems}</div>
        </>
      )}
    </Col>
  );
}

export default RoleDescription;
