import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .delete {
    color: red !important;
    cursor: pointer;

    span {
      transform: none !important;
    }
  }
`;
