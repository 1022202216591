import {
  Button, Col, Modal, Row,
} from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import MenuItems from 'containers/CreateOrder/Form/OrderItems/MerchantItemsModal/MenuItems';
import useIsDesktop from 'hooks/useIsDesktop';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext, useState } from 'react';
import Cart from './Cart';
import { StyledWrapper } from './styles';

function MerchantItemsModal({ open, onClose }) {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { order, selectedMenu, setMenuItems } = useContext(CallInOrderContext);
  const [rerenderKey, setRerenderKey] = useState(0);
  const handleClose = () => {
    onClose();
    setRerenderKey(rerenderKey + 1);
    setMenuItems([...JSON.parse(JSON.stringify(selectedMenu?.categories.sort((a, b) => a.id - b.id)))]);
  };
  return (
    <StyledWrapper>
      <Modal
        bodyStyle={{ backgroundColor: '#F5F8F7', padding: isMobile ? '0 16px' : '0 24px' }}
        width={isDesktop ? '60%' : '100%'}
        open={open}
        title={order.selectedMerchant?.label}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button
            key="back"
            onClick={handleClose}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleClose}
          >
            Confirm
          </Button>,
        ]}
      >

        <Row gutter={10}>
          <Col
            xs={24}
            md={14}
            style={{
              borderRight: isMobile ? 0 : '1px solid #DDE1E4',
              borderBottom: isMobile ? '1px solid #DDE1E4' : 0,
              padding: '16px 0',
            }}
          >
            <MenuItems rerenderKey={rerenderKey} />
          </Col>
          <Col xs={24} md={10}>
            <Cart />
          </Col>
        </Row>
      </Modal>
    </StyledWrapper>
  );
}

export default MerchantItemsModal;
