import { fetchOrganizationConfig } from 'api/organizations';
import { useContext, useEffect, useState } from 'react';
import { prepareConfig } from 'utils/config';
import { captureException } from 'utils/errors';
import { AdminContext } from 'constants/contexts';

function useFetchedConfig() {
  const { isLoggedIn, organizationId } = useContext(AdminContext);
  const [config, setConfig] = useState();

  useEffect( () => {
    if (organizationId && isLoggedIn) {
      fetchOrganizationConfig().then( res => {
        setConfig( prepareConfig( res.data ) );
      } ).catch( e => {
        captureException(e);
      });
    }
  }, [organizationId, isLoggedIn] );

  return config;
}

export default useFetchedConfig;
