export const getSumOfArrays = (...arrays) => {
  const sumOfArrays = [];
  arrays[0].forEach((sub) => {
    sub?.forEach((num, index) => {
      if (sumOfArrays[index]) {
        sumOfArrays[index] += num;
      } else {
        sumOfArrays[index] = num;
      }
    });
  });
  return sumOfArrays;
};

export const transformReportLabel = (label) => {
  const capitalizeLabel = (text) => text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizeLabel(label.split('_').join(' '));
};
