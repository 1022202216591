import React from 'react';
import MenuProvider from './MenuProvider';
import OverviewContainer from './OverviewContainer';

function MenuOverview() {
  return (
    <MenuProvider>
      <OverviewContainer />
    </MenuProvider>
  );
}

export default MenuOverview;
