import http from 'utils/http';

export const fetchOrderDetail = (orderNumber) => http
  .get(`v1/orders/${orderNumber}/anonymous/`, { headers: { Authorization: {} } })
  .then((res) => res.data);

export const acceptCateringOrder =
  (orderNumber) => http.post(`v1/orders/${orderNumber}/anonymous/accept/`, {
    state: 1,
  }, { headers: { Authorization: {} } }).then((res) => res.data);

export const fetchOrganizationConfig = (organizationId) => http.get(`v1/organization/${organizationId}/configs/`, { headers: { Authorization: {} } }).then((res) => res.data);
