import { Divider, Typography } from 'antd';
import React from 'react';
import OrderTypes from './OrderTypes';
import CustomerInfo from './CustomerInfo';

function GeneralInfo() {
  return (
    <>
      <div className="box">
        <div className="box-header">
          <Typography.Text className="box-title">
            General info
            {' '}
            <span className="box-title-condition">(Required)</span>
          </Typography.Text>
        </div>
        <CustomerInfo />
        <OrderTypes />
      </div>
      <Divider style={{ margin: 0 }} />
    </>
  );
}

export default GeneralInfo;
