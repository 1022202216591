import { AdminContext } from 'constants/contexts';
import { useContext, useEffect, useState } from 'react';

export const usePermission = (permissionName) => {
  const { permissions } = useContext(AdminContext);
  const [hasPermission, setHasPermission] = useState(false);

  const checkPermissions = (p) => !p || (Array.isArray(permissions) && permissions.includes(p));

  useEffect(() => {
    if (permissions.length) {
      setHasPermission(checkPermissions(permissionName));
    }
  }, [permissions]);

  return hasPermission;
};
