import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SUBSCRIPTION_STRIPE_KEY } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { captureException } from 'utils/errors';
import { createStripeIntent } from './requests';

function StripeContainer({ children, needRefresh }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const p = loadStripe(SUBSCRIPTION_STRIPE_KEY, {
      apiVersion: '2020-08-27',
    });
    setStripePromise(p);
    setLoading(true);
    createStripeIntent()
      .then((res) => {
        setOptions({ clientSecret: res.data?.client_secret });
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
      });
  }, [needRefresh]);

  return (
    <>
      {!loading && options ? (
        <Elements stripe={stripePromise} options={options}>
          {children}
        </Elements>
      ) : (
        <Space style={{ display: 'flex', justifyContent: 'center', m: 7 }}>
          <LoadingOutlined />
        </Space>
      )}
    </>
  );
}
export default StripeContainer;
