import React from 'react';
import {
  Radio,
  Space,
  Typography,
} from 'antd';
import AmountInput from 'components/AmountInput';
import { EMPTY_OBJECT } from 'constants';

function TipInput({ value = EMPTY_OBJECT, onChange }) {
  const TIP_OPTIONS = [
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    {
      value: -1,
      label: (
        <Space>
          <Typography.Text>Custom:</Typography.Text>
          <AmountInput value={value.amount} onChange={(value) => onChange({ amount: value })} />
        </Space>
      ),
    },
  ];
  return (
    <Radio.Group options={TIP_OPTIONS} value={value.amount ? -1 : value.percentage} onChange={({ target: { value } }) => onChange({ percentage: value })} />
  );
}

export default TipInput;
