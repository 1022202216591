import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledDropdownWrapper = styled.div`
  position: relative;
  width: fit-content;

  .list {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background: #ffffff;
    border: 0.5px solid #d0d1d2;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    width: fit-content;
    display: inline;
    border-radius: 4px;
    z-index: 2;

    &-item {
      cursor: pointer;
      padding: 8px 12px 10px;

      &:hover {
        background: #f3f4f4;
      }
    }
  }

  .arrow {
    width: 10px;
    height: 10px;

    &-up {
      transform: rotate(180deg);
    }

    &-smooth {
      transition: 0.3s ease;
    }
  }

  .btn {
    color: ${Colors.textPrimary1};
    width: fit-content;
    padding: 6px !important;

    &-open {
      background: #f3f4f4;
      color: ${Colors.textPrimary1};
      padding: 6px !important;
    }
  }
`;
