import { Form, Input, Select } from 'antd';
import AddressInput from 'components/AddressInput';
import FormItem from 'components/FormItem';
import React from 'react';

function GeneralForm() {
  return (
    <>
      <FormItem
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter the restaurant name' }]}
      >
        <Input placeholder="Restaurant's name" />
      </FormItem>
      <FormItem
        name="address"
        label="Address"
        rules={[{ required: true, message: 'Please enter the address' }]}
      >
        <AddressInput />
      </FormItem>
      <FormItem
        name="email"
        label="Email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter the email' },
        ]}
      >
        <Input placeholder="e.g. example@example.com" />
      </FormItem>
    </>
  );
}

export default GeneralForm;
