import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  position: relative;
  
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title-row {
    margin-bottom: 40px;
    display: block;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1} !important;
      margin-right: 27px;
      margin-bottom: 0;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -48px;
    margin-right: 0;
  }

  .sort {
    padding: 0 !important;
    width: 140px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    justify-content: flex-start !important;
    position: relative;
    top: unset !important;

    .icon--lead {
      color: #898d8f;
      width: 43px;
    }

    &-list {
      width: 185px !important;

      &-item {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color:${Colors.textPrimary1};
      }
    }

    &-btn {
      width: 185px !important;
      padding: 6px;
      line-height: 18px;
    }
  }
  .empty-box-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.textPrimary1};
    margin-bottom: 12px;
  }
  .empty-box-sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #858796;
    margin-bottom: 12px;
  }
`;
