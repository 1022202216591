import { Flex, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import Row from './Row';

const { Text } = Typography;
function ChangeLog({ order }) {
  function renderLogs() {
    return (
      <Flex vertical gap={12}>
        {
          order.state_records.map((record, index) => {
            if (index === 0) return;
            return (
              <Space wrap size={6}>
                <Text type="secondary">Order status changed</Text>
                <Text type="secondary">from</Text>
                <Text>{order.state_records[index - 1]?.state || '-'}</Text>
                <Text type="secondary">to</Text>
                <Text>{record.state}</Text>
                {
                  record.user && (
                    <>
                      <Text type="secondary">by</Text>
                      {
                        (record.user?.first_name || record.user?.last_name) ? (
                          <Text>
                            {record.user.first_name}
                            {' '}
                            {record.user.last_name}
                          </Text>
                        ) : (
                          <Text type="secondary">{record.user.email}</Text>
                        )
                      }
                    </>
                  )
                }
                <Text type="secondary">at</Text>
                <Text>{dayjs(record.created_at).format('MMM DD, hh:mm A')}</Text>
              </Space>
            );
          })
        }
      </Flex>
    );
  }

  if (!order.state_records || order.state_records?.length < 2) {
    return null;
  }
  return (
    <Row className="flex-col">
      <Typography.Title level={5}>
        Change log
      </Typography.Title>
      {renderLogs()}
    </Row>
  );
}

export default ChangeLog;
