import { CalendarOutlined, CheckOutlined } from '@ant-design/icons';
import { Animation } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis, BarSeries, Chart, ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Tag } from 'antd';
import { Colors } from 'assets/colors';
import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import Button from 'components/Button';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router'
import { captureException } from 'utils/errors';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import { getNotificationById } from '../../api/marketing';
import { ModalContext } from '../../context/modalContext';
import { getData } from '../../utils';
import DeleteModal from '../Marketing/DeleteModal';
import { StyledChartWrapper, StyledWrapper } from './styles';

function MarketingDetail() {
  const [notificationDataLoading, setNotificationDataLoading] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    modal, handleModal, modalContent, hideCloseBtn, classes,
  } =
    useContext(ModalContext);

  useEffect(() => {
    if (id) {
      // Get notifications
      setNotificationDataLoading(true);
      getNotificationById(id).then((res) => {
        setCampaignData(getData(res));
        setNotificationDataLoading(false);
      })
        .catch((e) => {
          captureException(e);
          setNotificationDataLoading(false);
        });
    }
  }, [id]);

  const isCampaignCompleted = (row) => row?.status === 'completed';

  const handleStatus = (row) => {
    const statusTitle = isCampaignCompleted(row) ? 'Completed' : 'Scheduled';
    return (
      <Tag
        color={isCampaignCompleted(row) ? 'green' : 'volcano'}
        icon={isCampaignCompleted(row) ? <CheckOutlined /> : <CalendarOutlined />}
      >
        {statusTitle}
      </Tag>
    );
  };

  const handleGoBack = () => {
    navigate('/console/marketing');
  };

  const handleDelete = () => {
    handleModal(<DeleteModal row={campaignData} reload={handleGoBack} />);
  };

  const makeAxisLabel = (data) => function (props) {
    return (
      <>
        <ArgumentAxis.Label {...props} text="Hi world!" />
        <ArgumentAxis.Label {...props} text="Hi world!" />
      </>
    );
  };

  const ChartLabelC = makeAxisLabel();

  return (
    <Layout header={(
      <Layout.Header
        title={campaignData?.title}
        tag={handleStatus(campaignData)}
        loading={notificationDataLoading}
        backTitle="Marketing"
        backPath="/console/marketing"
      />
    )}
    >
      <StyledWrapper>
        {/* Form */}
        {id && notificationDataLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <div className="table-box">
            <StyledChartWrapper>
              <div className="box box--top">
                <div className="coming-soon">
                  <ClockIcon />
                  <p className='m-0'>Coming soon... </p>
                </div>
                <div className="not-yet">
                  <Chart
                    data={[
                      {
                        year: 'Sends',
                        population: campaignData?.total_sent_customer,
                      },
                      { year: 'Opens', population: campaignData?.total_opened },
                      // { year: 'Conversions', population: 0 },
                    ]}
                  >
                    <ArgumentAxis />
                    <ValueAxis max={20} />

                    <BarSeries
                      valueField="population"
                      argumentField="year"
                      color={Colors.primary1}
                      barWidth={0.55}
                    />
                    <Animation />
                  </Chart>
                </div>
                {/* <p className="info-note">
                  <InfoIcon />
                  <span>52 user turned off their notifications</span>
                </p> */}
              </div>
              {/* Buttons */}
              {!isCampaignCompleted(campaignData) ? (
                <div className="box--bottom">
                  <Button
                    onClick={handleDelete}
                    label="Delete"
                    type="neutral"
                    classes="btn btn-delete"
                  />
                </div>
              ) : null}
            </StyledChartWrapper>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(MarketingDetail, [PERMISSIONS.MARKETING], { redirect: true });
