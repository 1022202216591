import { Spin } from 'antd';
import Layout from 'components/Layout';
import useConfig from 'hooks/useConfig';
import React from 'react';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import CreateOrderForm from './Form';
import NewOrderProvider from './NewOrderProvider';
import { StyledWrapper } from './styles';

function CreateOrder() {
  const generalConfig = useConfig('general');
  return (
    <Layout header={(
      <Layout.Header
        title="New Order"
        backTitle="Orders"
        backPath="/console/orders"
      />
    )}
    >
      <StyledWrapper>
        <NewOrderProvider>
          {!generalConfig && <Spin size="large" style={{ marginLeft: '50%', marginTop: '10%' }} />}
          {generalConfig && <CreateOrderForm />}
        </NewOrderProvider>
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(CreateOrder, [PERMISSIONS.ORDERS], { redirect: true });
