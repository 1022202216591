export const TABS = [
  {
    label: 'All',
    id: 1,
    key: 1,
  },
  {
    label: 'New',
    id: 2,
    key: 2,
  },
  {
    label: 'Active',
    id: 3 ,
    key: 3,
  },
  {
    label: 'Inactive',
    id: 4,
    key: 4,
  },
  {
    label: 'Lost',
    id: 5,
    key: 5,
  },
];
