import { Typography } from 'antd';
import React from 'react';
import ArrowUpOutlined from '@ant-design/icons/ArrowUpOutlined';

function JumpLink({
  text,
  children,
  ellipsis = true,
  maxWidth = 120,
  ...rest
}) {
  return (
    <Typography.Link
      target="_blank"
      onClick={(e) => { e.isLink = true; }}
      className="whitespace-nowrap"
      {...rest}
    >
      <Typography.Text
        type="primary"
        className="mr-1"
        ellipsis={ellipsis ? { tooltip: text } : undefined}
        style={{ maxWidth, color: 'inherit' }}
      >
        {text || children}
      </Typography.Text>
      <ArrowUpOutlined className="rotate-45" />
    </Typography.Link>
  );
}

export default JumpLink;
