import { TABLE_RENDER_TYPES } from 'utils/table';

export const columns = [
  {
    title: 'Payment Date',
    key: 'created_at',
    render: TABLE_RENDER_TYPES.DATE,
  },
  {
    title: 'amount',
    render: TABLE_RENDER_TYPES.CENT,
  },
  {
    title: 'type',
    render: TABLE_RENDER_TYPES.TAG,
  },
  'description',
];
