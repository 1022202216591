import { OrderType } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext } from 'react';
import {
  Space, Modal, Button, Typography, Row, Col,
} from 'antd';
import { priceTransformer } from 'utils';
import { StyledWrapper } from './styles';

function ConfirmOrderModal({
  open,
  onClose,
  submitOrder,
  estimateData,
}) {
  const { order } = useContext(CallInOrderContext);

  const priceRow = ({ title, price, style }) => (
    <Row>
      <Col span={20} style={{ color: '#5A5F62', ...style }}>
        {title}
      </Col>
      <Col span={4} style={{ color: '#5A5F62', textAlign: 'end', ...style }}>
        {priceTransformer(price)}
      </Col>
    </Row>
  );

  return (
    <StyledWrapper>
      <Modal
        open={open}
        title="Confirming order"
        onCancel={() => onClose(false)}
        footer={[
          <Button key="back" onClick={() => onClose(false)}>
            Cancel
          </Button>,
          <Button type="primary" onClick={submitOrder} htmlType="submit">
            Confirm
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Typography.Title style={{ fontSize: '20px' }}>
              Order summary
            </Typography.Title>
          </Row>
          {priceRow({ title: 'Subtotal', price: estimateData?.food_value })}
          {priceRow({ title: 'Delivery fee', price: estimateData?.delivery_fare })}
          {priceRow({ title: 'Service fee', price: estimateData?.service_fee })}
          {order.orderType === OrderType.Delivery && priceRow({ title: 'Tip', price: estimateData?.tip })}
          {priceRow({ title: 'Taxes', price: estimateData?.tax })}
          {priceRow({ title: 'Adjustment amount', price: estimateData?.adjustment_amount })}
          {priceRow({
            title: 'Total',
            price: estimateData?.total_value,
            style: { color: 'black', fontSize: '16px', fontWeight: '700' },
          })}

          <Row style={{ marginTop: 16 }}>
            {' '}
            <Typography.Title style={{ fontSize: '20px' }}>
              Payment summary
            </Typography.Title>
          </Row>
          <Row>
            <Col span={14} style={{ color: '#5A5F62' }}>
              Method
            </Col>
            <Col span={10} style={{ color: '#5A5F62', textAlign: 'end' }}>
              {order.paymentMethod?.title}
            </Col>
          </Row>
        </Space>
      </Modal>
    </StyledWrapper>
  );
}

export default ConfirmOrderModal;
