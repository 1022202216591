import {
  Button, Flex, Tag, Typography,
} from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import React, { useState } from 'react';
import OrderOverlay from 'containers/OrderOverlay';
import { OrderStatusLabel, OrderStatusTagColor } from 'constants/status';
import { priceTransformer } from 'utils';

const { Text } = Typography;
function OrderEvent({ event: { data: order, occurred_at: date }, refresh }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [detailOpen, setDetailOpen] = useState(false);

  return (
    <>
      <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-full">
        <Flex justify="space-between" vertical={isMobile} className="w-full">
          <Flex gap={12} vertical={isMobile} align="flex-start">
            <Text className="font-bold text-[16px]">
              Order #
              {order.order_number}
            </Text>
            <Tag color={order.state === 0 ? 'green' : OrderStatusTagColor[order.state]}>
              {order.state === 0 ? 'Created' : OrderStatusLabel[order.state]}
            </Tag>
          </Flex>
          <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
        </Flex>

        <Flex vertical={isMobile} justify="space-between" gap={4} align={isMobile ? 'flex-start' : 'center'}>
          <Flex gap={12} vertical={isMobile}>
            <Flex gap={4}>
              <Text className="font-weight-light">Customer:</Text>
              <Text className="font-bold">{order.customer_name}</Text>
            </Flex>
            <Flex gap={4}>
              <Text className="font-weight-light">Total:</Text>
              <Text className="font-bold">
                {priceTransformer(order.total)}
              </Text>
            </Flex>
          </Flex>
          <Button onClick={() => setDetailOpen(true)}>
            Order details
          </Button>
        </Flex>
      </Flex>
      <OrderOverlay
        orderId={detailOpen && order.order_id}
        onClose={() => setDetailOpen(false)}
        handleOrderUpdate={refresh}
      />
    </>

  );
}

export default OrderEvent;
