import { Tag } from 'antd';
import { DeliveryIcon, PickupIcon } from 'assets/icons';
import BadgeLabel from 'components/BadgeLabel';
import { OrderStatusLabel } from 'constants/status';
import ColumnWithTooltip from 'containers/Reports/components/ColumnWithTooltip';
import { renderBoldPriceColumn } from 'containers/Reports/utils';
import dayjs from 'dayjs';

const columns = [
  {
    title: 'ORDER NUMBER',
    dataIndex: 'number',
    key: 'number',
    width: '135px',
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    key: 'customer',
    ellipsis: true,
    width: '150px',
  },
  {
    title: 'PAYMENT DATE',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '130px',
    render: (value) => dayjs(value).utc(true).format('MMM D, hh:mm A'),
  },
  {
    title: 'TYPE',
    dataIndex: 'has_delivery',
    key: 'has_delivery',
    width: '110px',
    render: (value) => (
      <BadgeLabel
        label={value}
        leadingIcon={value ? <DeliveryIcon /> : <PickupIcon />}
        classes="type-badge"
      />
    ),
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: '160px',
    render: (value) => OrderStatusLabel[value],
  },
  {
    title: 'ORDER SOURCE',
    dataIndex: 'source',
    key: 'source',
    width: '140px',
    render: (value) => (value ? <Tag>{value?.toUpperCase()}</Tag> : null),
  },
  {
    title: 'SUBTOTAL',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: renderBoldPriceColumn,
    width: '100px',
  },
  {
    title: () => <ColumnWithTooltip title="M.TAX" description="Merchant tax" />,
    dataIndex: 'tax',
    key: 'tax',
    render: renderBoldPriceColumn,
    width: '90px',
  },
  {
    title: 'COMMISSION',
    dataIndex: 'commission',
    key: 'commission',
    render: renderBoldPriceColumn,
    width: '120px',
  },
  {
    title: 'M. PAYMENT FEES',
    dataIndex: 'payment_fee',
    key: 'payment_fee',
    render: renderBoldPriceColumn,
    width: '110px',
  },
  {
    title: () => <ColumnWithTooltip title="M.NET" description="Payment processing fee has been deducted from this amount. This is the final value that merchants should expect to receive." placement="left" />,
    dataIndex: 'net',
    key: 'net',
    render: renderBoldPriceColumn,
    width: '100px',
  },
];
export default columns;
