import {
  Col, Descriptions, Divider, Drawer, Flex, Row, Space, Typography,
} from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { BillingStatus } from './BillingStatus';

function InvoiceDetail({ invoice, open, onClose }) {
  if (!invoice) return null;

  const renderHeader = () => (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} lg={8}>
        <Space direction="vertical">
          <Typography.Text className="font-weight-bold">Issued date:</Typography.Text>
          <Typography.Text>{dayjs(invoice.created_at).format('MMM DD, HH:MM')}</Typography.Text>
        </Space>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Space direction="vertical">
          <Typography.Text className="font-weight-bold">Due date:</Typography.Text>
          <Typography.Text
            className="font-weight-bold"
          >
            {dayjs(invoice.due_date).format('MMM DD, HH:MM')}
          </Typography.Text>
        </Space>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Space direction="vertical">
          <Typography.Text className="font-weight-bold">Total Amount:</Typography.Text>
          <Typography.Text className="font-weight-bold">
            $
            {invoice.invoice_total}
          </Typography.Text>
        </Space>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Space direction="vertical">
          <Typography.Text className="font-weight-bold">Start date:</Typography.Text>
          <Typography.Text>{dayjs(invoice.start_date).format('MMM DD, HH:MM')}</Typography.Text>
        </Space>
      </Col>
      <Col xs={24} sm={12} lg={8}>
        <Space direction="vertical">
          <Typography.Text className="font-weight-bold">End date:</Typography.Text>
          <Typography.Text>{dayjs(invoice.end_date).format('MMM DD, HH:MM')}</Typography.Text>
        </Space>
      </Col>
    </Row>
  );

  return (
    <Drawer
      title={(
        <Space>
          {`#${invoice.id}`}
          {BillingStatus(invoice.status)}
        </Space>
      )}
      placement="right"
      width={780}
      onClose={onClose}
      open={open}
    >
      <Flex vertical>
        {renderHeader()}
        <Divider />
        <Descriptions
          bordered
          size="small"
          column={1}
          contentStyle={{ whiteSpace: 'nowrap' }}
          className="overflow-auto"
        >
          <Descriptions.Item label="Title">
            <Row gutter={16}>
              <Detail value="Flat Fee/Commission" />
              <Detail value="Quantity/Subtotal" />
              <Detail value="Amount" />
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Monthly subscription">
            <Row gutter={16}>
              <Detail value={`$${invoice.plan_base_price}`} />
              <Detail value={1} />
              <Detail value={`$${invoice.plan_base_price}`} />
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="Extra orders">
            <Row gutter={16}>
              <Detail value={`$${invoice.plan_price_per_extra_order}`} />
              <Detail value={invoice.total_orders > invoice.plan_base_number_of_orders ?
                (invoice.total_orders - invoice.plan_base_number_of_orders) : 0}
              />
              <Detail value={`$${invoice.extra_orders_fee}`} />
            </Row>
          </Descriptions.Item>
          {(invoice.plan_gfo_commission?.percentage && invoice.gfo_orders_total && invoice.gfo_fee) ? (
            <Descriptions.Item label="GFO orders">
              <Row gutter={16}>
                <Detail value={`${invoice.plan_gfo_commission?.percentage}%`} />
                <Detail value={`$${invoice.gfo_orders_total}`} />
                <Detail value={`$${invoice.gfo_fee}`} />
              </Row>
            </Descriptions.Item>
          ): null}
          {(invoice.plan_omnimerchant_commission?.percentage && invoice.omnimerchant_orders_total && invoice.omnimerchant_fee) ? (
            <Descriptions.Item label="Omni merchant orders">
              <Row gutter={16}>
                <Detail value={`${invoice.plan_omnimerchant_commission?.percentage}%`} />
                <Detail value={`$${invoice.omnimerchant_orders_total}`} />
                <Detail value={`$${invoice.omnimerchant_fee}`} />
              </Row>
            </Descriptions.Item>
          ) : null}
          {(invoice.plan_self_delivery_commission?.fixed_cents && invoice.self_delivery_orders_count && invoice.self_delivery_fee) ? (
            <Descriptions.Item label="Self delivery orders">
              <Row gutter={16}>
                <Detail value={`$${invoice.plan_self_delivery_commission?.fixed_cents / 100}`} />
                <Detail value={invoice.self_delivery_orders_count} />
                <Detail value={`$${invoice.self_delivery_fee}`} />
              </Row>
            </Descriptions.Item>
          ) : null}
          {invoice.plan_catering_price && <Descriptions.Item label="Catering monthly subscription">
            <Row gutter={16}>
              <Detail value={`$${invoice.plan_catering_price}`} />
              <Detail value={1} />
              <Detail value={`$${invoice.plan_catering_price}`} />
            </Row>
          </Descriptions.Item>}
          {(invoice.plan_catering_commission?.fixed_cents && invoice.catering_orders_count && invoice.catering_fee) ? (
            <Descriptions.Item label="Catering orders">
              <Row gutter={16}>
                <Detail value={`$${invoice.plan_catering_commission?.fixed_cents / 100}`} />
                <Detail value={invoice.catering_orders_count} />
                <Detail value={`$${invoice.catering_fee}`} />
              </Row>
            </Descriptions.Item>
          ) : null}
          {(invoice.plan_dispatch_commission?.fixed_cents && invoice.delivery_orders_count && invoice.dispatch_fee) ? (
          <Descriptions.Item label="Dispatch fee">
            <Row gutter={16}>
              <Detail value={`$${invoice.plan_dispatch_commission?.fixed_cents / 100}`} />
              <Detail value={invoice.delivery_orders_count} />
              <Detail value={`$${invoice.dispatch_fee}`} />
            </Row>
          </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Total" style={{ backgroundColor: '#DCDDDD' }}>
            <Row gutter={16}>
              <Detail value="-" />
              <Detail value="-" />
              <Detail value={`$${invoice.invoice_total}`} />
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Flex>
    </Drawer>
  );
}
function Detail({ value }) {
  return <Col span={8} style={{ textWrap: 'wrap', fontWeight: 'bold' }}>{value}</Col>;
}

export default InvoiceDetail;
