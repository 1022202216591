import {
  App, Button, Drawer, Flex,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { ModalMode } from '../../../constants';
import { MenuContext } from '../../context';
import {
  createMenu, duplicateMenu, editMenu, removeMenu,
} from '../requests';
import MenuForm from './MenuForm';
import { formatPostWorkingHours, formatWorkingHours } from './utils';

const initialValue = {
  name: '',
  isActive: true,
  workingDays: [],
};

function MenuDetailModal({ setMode, mode = ModalMode.CLOSED }) {
  const { id: merchantId } = useParams();
  const { modal } = App.useApp();
  const {
    menuDetail, fetchMenus, showMessage, fetchMenuDetail,
  } = useContext(MenuContext);
  const [detail, setDetail] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const workingDays = formatWorkingHours({ menuDetail, mode });
    if (mode === ModalMode.NEW) setDetail({ ...initialValue, workingDays });
    if (mode === ModalMode.EDIT && menuDetail) {
      setDetail({
        name: menuDetail.name,
        isActive: menuDetail.is_active,
        workingDays,
      });
    }
  }, [menuDetail, mode]);
  const onClose = () => {
    setMode(ModalMode.CLOSED);
  };

  const onSubmit = () => {
    const data = {
      restaurant: Number(merchantId),
      name: detail.name,
      is_active: detail.isActive,
      active_hours_: formatPostWorkingHours(detail.workingDays),
    };
    setLoading(true);
    (mode === ModalMode.NEW ? createMenu(data) : editMenu({ menuId: menuDetail.id, menuDetail: data })).then((res) => {
      onClose();
      fetchMenus();
      if (mode === ModalMode.EDIT) fetchMenuDetail();
      setLoading(false);
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
      setLoading(false);
    });
  };

  const onDuplicate = () => {
    setLoading(true);
    duplicateMenu(menuDetail.id).then((res) => {
      fetchMenus();
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
    }).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  function handleRemove() {
    modal.confirm({
      title: `Delete ${detail.name}`,
      content: `By confirming this modal the menu (${detail.name}) will be deleted permanently.`,
      okText: 'Delete permanently',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => removeMenu(menuDetail.id).then((res) => {
        fetchMenus();
        onClose();
      }).catch((e) => {
        captureException(e);
        showMessage({ type: 'error' });
      }),
    });
  }
  return (
    <Drawer
      title={`${mode === ModalMode.NEW ? 'Add' : 'Edit'} Menu`}
      placement="right"
      width={575}
      onClose={onClose}
      open={mode !== ModalMode.CLOSED}
      footer={(
        <Flex justify="space-between">
          <Button danger disabled={loading} onClick={handleRemove}>Remove</Button>
          <Flex justify="end" gap={8}>
            {/* <Button loading={loading} onClick={onDuplicate}>Duplicate</Button> */}
            <Button loading={loading} type="primary" onClick={onSubmit}>Save</Button>
          </Flex>
        </Flex>

      )}
    >
      <MenuForm detail={detail} setDetail={setDetail} />
    </Drawer>
  );
}

export default MenuDetailModal;
