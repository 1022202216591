import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import { debounce } from 'lodash-es';
import React, { useContext, useMemo } from 'react';

function SearchItemInput({ disabled }) {
  const { selectedMenu, setMenuItems } = useContext(CallInOrderContext);
  const onChange = (e) => {
    const searchTerms = e.target.value?.toLowerCase().split(' ').filter((s) => s);
    if (!searchTerms.length) {
      setMenuItems(selectedMenu?.categories);
      return;
    }
    const categories = JSON.parse(JSON.stringify(selectedMenu?.categories));
    const filteredItems = categories.map((cat) => ({
      ...cat,
      items: cat.items
        .filter(({ name }) => searchTerms.some((term) => name.toLowerCase().includes(term))),
    })).filter((cat) => cat.items.length > 0);

    setMenuItems([...filteredItems]);
  };
  const debouncedChangeHandler = useMemo(
    () => debounce(onChange, 400),
    [selectedMenu],
  );
  return <Input prefix={<SearchOutlined />} placeholder="Lovely burger" onChange={debouncedChangeHandler} disabled={disabled} />;
}

export default SearchItemInput;
