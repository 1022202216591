import {
  Button, Card, Form, Input, message, Typography,
} from 'antd';
import { signUp } from 'api/auth';
import { OCLogoIcon } from 'assets/icons';
import Layout from 'components/Layout';
import React, { useContext, useState } from 'react';
import { AdminContext } from 'constants/contexts';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router'
import { getQueryStringValue } from 'utils';
import { captureException } from 'utils/errors';

function SignUp() {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useContext(AdminContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inviter = getQueryStringValue('inviter');
  const email = getQueryStringValue('email');
  const password = getQueryStringValue('password');

  const handleSubmit = (data) => {
    setLoading(true);
    const userInfo = {
      email: data.email,
      old_password: password,
      new_password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
    };
    signUp(userInfo)
      .then((res) => {
        message.success('Successfully registered');
        navigate('/');
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
        message.error(e.response?.data?.detail || 'Something went wrong');
      });
  };

  const compareToFirstPassword = async (_, value) => {
    const firstPassword = form.getFieldValue('password');
    if (firstPassword !== value) throw new Error('Something wrong!');
  };

  if (isLoggedIn) {
    navigate('/console');
  }

  return (
    <Layout hasSidebar={false}>
      <div className="w-screen h-screen flex items-center justify-center">
        <Card style={{ width: 460 }}>
          <OCLogoIcon />
          <Typography.Title level={1} className="mt-3" style={{ fontSize: 24 }}>
            Let’s create your account.
          </Typography.Title>
          <Typography.Text style={{ color: '#666B6D' }}>
            You've been invited by
            <Typography.Text strong style={{ color: '#02862F', margin: '0 2px' }}>{inviter}</Typography.Text>
            to join their business on Omnicart. Create an account to log in to your admin panel.
          </Typography.Text>
          <Form
            form={form}
            onFinish={handleSubmit}
            className="mt-6"
            validateTrigger="onSubmit"
          >
            <Form.Item
              name="email"
              initialValue={email}
            >
              <Input disabled size="large" />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: 'Please input your first name!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input placeholder="First name" size="large" />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: 'Please input your last name!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: 16 }}
              >
                <Input placeholder="Last name" size="large" />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please confirm your password!' },
                { validator: compareToFirstPassword, message: 'Please enter correct password!' }]}
            >
              <Input.Password placeholder="Confirm your password" size="large" />
            </Form.Item>

            <Form.Item>
              <Button
                block
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="mt-5"
              >
                Create account
              </Button>
            </Form.Item>

            <Typography.Text>
              Already have an account?
              <Link to="/login">Sign in with Email</Link>
            </Typography.Text>

          </Form>
        </Card>
      </div>
    </Layout>
  );
}

export default SignUp;
