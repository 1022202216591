import { DownOutlined } from '@ant-design/icons';
import { AutoComplete, Flex, Form, Input, Select } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import { COUNTRIES, STATES_OF_COUNTRY } from 'containers/Companies/constants';
import React, { useEffect } from 'react';

function AddressInfo({}) {
  const form = Form.useFormInstance();
  const country = Form.useWatch('country');

  useEffect(() => {
    form.setFieldValue('stateOfCountry', null);
  }, [country?.value]);

  return (
    <FormLayout.Section title="Address info">
      <FormItem name="address" label="Address" fullWidth>
        <Input/>
      </FormItem>
      <Flex gap={16}>
        <FormItem name="country" label="Country" fullWidth>
          <Select labelInValue options={COUNTRIES}/>
        </FormItem>
        <FormItem name="stateOfCountry" label="State" fullWidth>
          <AutoComplete
            options={STATES_OF_COUNTRY[country?.value]}
            suffixIcon={<DownOutlined/>}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </FormItem>
      </Flex>

      <Flex gap={16}>
        <FormItem name="city" label="City" fullWidth>
          <Input/>
        </FormItem>
        <FormItem name="zip_code" label="Zip code" fullWidth>
          <Input/>
        </FormItem>
      </Flex>

    </FormLayout.Section>
  );
}

export default AddressInfo;
