import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ModifierContext } from '../../../context';
import { ModalMode } from '../../../constants';
import ModifierModal from '..';
import { OptionMode } from '../constants';
import OptionComponent from './OptionComponent';
import OptionsTable from './OptionsTable';

function Options({ options, onChange, modifierModalMode }) {
  const { merchant } = useContext(ModifierContext);
  const [addNew, setAddNew] = useState(false);
  const [mode, setMode] = useState(ModalMode.CLOSED);

  useEffect(() => {
    if (modifierModalMode === ModalMode.CLOSED) setAddNew(false);
  }, [modifierModalMode]);
  const onSave = (newOption) => {
    onChange([...options, {
      ...newOption?.option,
      title: newOption?.option?.value,
      dietary_tags: newOption?.dietary_tags,
      real_price: newOption?.real_price,
      type: OptionMode.NEW,
      id: null,
      localId: newOption?.option?.id ?? options?.length + 1,
    }]);
    setAddNew(false);
  };
  const onAddNested = () => {
    setMode(ModalMode.NEW);
  };

  const onNestedCreated = (record) => {
    onChange([...options, {
      ...record,
      type: OptionMode.NEW,
      id: null,
      localId: record?.id ?? options?.length + 1,
      modifiers_children: record.options,
    }]);
    setAddNew(false);
  };
  return (
    <>
      <Typography.Title level={5}>Options</Typography.Title>
      <Typography.Text>
        The modifier’s option price calculated with
        {Number(merchant?.markup) ? ` ${merchant?.markup}% ` : Number(merchant?.markup_fixed) ? ` $${merchant?.markup_fixed} ` : 'out '}
        markup.
      </Typography.Text>

      {options?.some((o) => o.type !== OptionMode.DELETED) &&
        <OptionsTable options={options} setOptions={onChange} />}

      {addNew && <OptionComponent onSave={onSave} onAddNested={onAddNested} selectedOptions={options} />}

      <Space style={{ marginTop: 8 }}>
        <Button icon={<PlusOutlined />} disabled={addNew} onClick={() => setAddNew(true)}>
          Add option
        </Button>
      </Space>

      <ModifierModal mode={mode} setMode={setMode} onNestedCreated={onNestedCreated} />
    </>
  );
}

export default Options;
