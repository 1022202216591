import React, {
  useCallback, createContext, useState, useMemo,
  useEffect,
  useRef,
} from 'react';

import { getModifierMinSelections, hasOptionChildren, isMultiSelect } from './utils';

export const modifierContext = createContext();

export default function ModifierProvider({ modifiers, children }) {
  const [stack, setStack] = useState([]);
  const [selectedModifiers, setSelectedModifiers] = useState({});
  const parent = useMemo(() => stack.slice(-1)[0], [stack]);
  const preSelectIterations = useRef({ counter: 0, max: 0 });

  const getKey = useCallback(
    ({ modifier, option = {} }) => {
      let path = [...stack, { modifier, option }];
      // do not repeat parent ids
      if (parent && parent.modifier.id === modifier.id) {
        path = stack;
      }
      return path
        .map(({ modifier, option }) => `${modifier.id}-${option.id || ''}`)
        .join('/');
    },
    [stack, parent],
  );

  useEffect(() => {
    setSelectedModifiers({});
    preSelectIterations.current = {
      counter: 0,
      max: (modifiers?.length ?? 0) * 15, // It's a fair amount for max iteration based on the length of modifiers
    };
  }, [modifiers]);

  const visibleModifiers = useMemo(() => {
    if (!parent) {
      // root modifiers
      return (modifiers || []).filter((m) => m.options_parents.length === 0);
    }
    return (modifiers || []).filter((m) => m.options_parents?.includes(parent.option.id));
  }, [parent, modifiers]);

  const doesKeyMatchModifier = useCallback(
    ({ key, modifier }) => new RegExp(`${getKey({ modifier })}[\\d]+$`).test(key),
    [getKey],
  );

  const getTotalQty = useCallback(
    (modifier) => Object.entries(selectedModifiers)
      .filter(
        ([key, value]) => value && doesKeyMatchModifier({ key, modifier }),
        // ([key, value]) => value && key.getKey({modifier})
      )
      .reduce((total, [_, { qty }]) => total + qty, 0),
    [doesKeyMatchModifier, selectedModifiers],
  );

  const getSelectedOption = useCallback(
    ({ modifier, option }) => selectedModifiers[getKey({ modifier, option })],
    [selectedModifiers, getKey],
  );

  const hasSelectionsSatisfied = useCallback(
    (modifier) => {
      const minSelections = getModifierMinSelections(modifier);
      const totalQty = getTotalQty(modifier);
      return totalQty >= minSelections;
    },
    [getTotalQty],
  );

  const unSatisfiedModifiers = useMemo(
    () => visibleModifiers.filter((m) => !hasSelectionsSatisfied(m)),
    [visibleModifiers, hasSelectionsSatisfied],
  );

  const handleChange = useCallback(
    ({ modifier, option, value }) => {
      const key = getKey({ option, modifier });

      if (value && hasOptionChildren(option)) {
        setStack((current) => [...current, { modifier, option }]);
      }

      let revalidateRequired = true;
      let selectedModifiersEntries = Object.entries({
        ...selectedModifiers,
        [key]: value,
      });

      while (revalidateRequired) {
        revalidateRequired = false;
        selectedModifiersEntries = selectedModifiersEntries.filter(
          ([currentOptionKey, currentOptionValue]) => {
            if (!currentOptionValue) {
              revalidateRequired = true;
              return false;
            }
            if (currentOptionKey === key) {
              return true;
            }

            // nested modifier
            if (currentOptionKey.split('/').length > 1) {
              const parentKey = currentOptionKey
                .split('/')
                .slice(0, -1)
                .join('/');
              // if parent has been removed, remove this as well
              if (!selectedModifiersEntries.some(([mk]) => mk === parentKey)) {
                revalidateRequired = true;
                return false;
              }
            }

            // remove other options for single-select option types
            if (
              !isMultiSelect(modifier) &&
              doesKeyMatchModifier({ key: currentOptionKey, modifier })
            ) {
              revalidateRequired = true;
              return false;
            }

            return true;
          },
        );
      }

      setSelectedModifiers(Object.fromEntries(selectedModifiersEntries));
    },
    [selectedModifiers, getKey, doesKeyMatchModifier],
  );

  const isSelected = useCallback(
    ({ modifier, option }) => {
      const key = getKey({ modifier, option });
      if (option) {
        Boolean(selectedModifiers[key]);
      }
      return Object.keys(selectedModifiers).some((k) => k.startsWith(key));
    },
    [getKey, selectedModifiers],
  );

  useEffect(() => {
    if (
      !modifiers ||
      preSelectIterations.current.counter >= preSelectIterations.current.max
    ) {
      return;
    }
    let changed = false;
    preSelectIterations.current.counter++;
    // eslint-disable-next-line no-restricted-syntax
    for (const modifier of modifiers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const option of modifier.options) {
        if (
          option.default_qty > 0 &&
          (isMultiSelect(modifier)
            ? !isSelected({ modifier, option })
            : !isSelected({ modifier }))
        ) {
          handleChange({
            modifier,
            option,
            value: { qty: option.default_qty, ...option },
          });
          changed = true;
          break;
        }
      }
    }

    // We're good, all default amounts have been set
    if (!changed) {
      preSelectIterations.current.counter = preSelectIterations.current.max;
    }
  }, [modifiers, isSelected, handleChange]);

  const handleBack = useCallback(() => {
    setStack((current) => current.slice(0, -1));
    if (unSatisfiedModifiers.length !== 0) {
      handleChange({ ...parent, value: undefined });
    }
  }, [parent, unSatisfiedModifiers, handleChange]);

  const getNormalizedSelectedModifiers = useCallback(() => {
    const keys = Object.keys(selectedModifiers);
    return Object.entries(selectedModifiers)
      .map(([_, value]) => value);
  }, [selectedModifiers]);

  const getSelectedModifiersTree = useCallback(() => {
    const keys = Object.keys(selectedModifiers);
    return Object.entries(selectedModifiers)
      .filter(
        ([key]) => !keys.some((k) => k.length > key.length && k.includes(key)),
      )
      .reduce(
        (acc, [key, { qty: quantity }]) => ({ ...acc, [key]: { quantity } }),
        {},
      );
  }, [selectedModifiers]);

  const value = useMemo(
    () => ({
      parent,
      isSelected,
      handleBack,
      getTotalQty,
      handleChange,
      visibleModifiers,
      selectedModifiers,
      getSelectedOption,
      unSatisfiedModifiers,
      hasSelectionsSatisfied,
      loading: modifiers == null,
      getSelectedModifiersTree,
      getNormalizedSelectedModifiers,
    }),
    [
      parent,
      modifiers,
      isSelected,
      handleBack,
      getTotalQty,
      handleChange,
      visibleModifiers,
      selectedModifiers,
      getSelectedOption,
      unSatisfiedModifiers,
      hasSelectionsSatisfied,
      getSelectedModifiersTree,
      getNormalizedSelectedModifiers,
    ],
  );

  return (
    <modifierContext.Provider value={value}>
      {children}
    </modifierContext.Provider>
  );
}
