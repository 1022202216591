import { Divider, Flex, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ModifierContext } from '../../../../context';
import { getWorkingHoursByDay } from '../utils';
import WorkingDay from './WorkingDay';

function ActiveHours({ detail, onChangeDetail }) {
  const { merchant } = useContext(ModifierContext);
  const [parentActiveHours, setParentActive] = useState([]);
  useEffect(() => {
    setParentActive(merchant?.parent?.active_hours);
  }, [merchant]);

  const changeTimeSlot = ({ weekday, index, value }) => {
    const changedData = [...detail.workingDays];
    const currentDayIndex = changedData.findIndex((d) => d.key === weekday);
    changedData[currentDayIndex].activeTimes[index] = { from: value[0], to: value[1] };
    onChangeDetail('workingDays', changedData);
  };

  const addTimeSlot = ({ weekday }) => {
    const changedData = [...detail.workingDays];
    const currentDayIndex = changedData.findIndex((d) => d.key === weekday);
    changedData[currentDayIndex].activeTimes = [...changedData[currentDayIndex].activeTimes, { from: '', to: '' }];
    onChangeDetail('workingDays', changedData);
  };

  const removeTimeSlot = ({ weekday, index }) => {
    const changedData = [...detail.workingDays];
    const currentDayIndex = changedData.findIndex((d) => d.key === weekday);
    changedData[currentDayIndex].activeTimes.splice(index, 1);
    onChangeDetail('workingDays', changedData);
  };

  const onChangeDayAvailability = ({ value, weekday }) => {
    onChangeDetail('workingDays', detail.workingDays.map((d) => (d.key === weekday ? {
      ...d,
      isClosed: value,
    } : d)));
  };

  const getParentHours = (weekday) => {
    const hours = getWorkingHoursByDay({ weekday, details: parentActiveHours });
    return hours.map((h) => `${hours[0].from.format('HH:mm A')} - ${hours[0].to.format('HH:mm A')}`);
  };

  return (
    <>
      <Typography.Title level={5}>Active hours</Typography.Title>
      <Flex vertical gap="middle">
        {detail.workingDays.map((weekDay, index) => (
          <>
            <WorkingDay
              key={weekDay.key}
              weekday={weekDay}
              changeTimeSlot={changeTimeSlot}
              addTimeSlot={addTimeSlot}
              removeTimeSlot={removeTimeSlot}
              onChangeAvailability={onChangeDayAvailability}
              parentHours={getParentHours(weekDay.key)}
            />
            {index < detail.workingDays.length - 1 && <Divider style={{ margin: 0 }} />}
          </>
        ))}
      </Flex>
    </>
  );
}

export default ActiveHours;
