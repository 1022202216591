import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Row, Spin,
} from 'antd';
import EmptyComponent from 'components/Empty';
import React, { useContext, useState } from 'react';
import ModifierModal from '../ModifierModal';
import SearchItemInput from '../../components/SearchItemInput';
import { ModalMode } from '../../constants';
import { ModifierContext } from '../../context';

function ModifierActions() {
  const { loading, modifiers, setSearchTerm } = useContext(ModifierContext);
  const [mode, setMode] = useState(ModalMode.CLOSED);

  if (!modifiers.length) {
    return loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 36, marginTop: 24 }} spin />} />
      : (
        <>
          <EmptyComponent
            title="This merchant does not have any modifier!"
            actionButtonText="Add modifier"
            onClick={() => setMode(ModalMode.NEW)}
          />
          <ModifierModal mode={mode} setMode={setMode} />
        </>
      );
  }
  return (
    <>
      <Row style={{ alignItems: 'center', gap: 8, justifyContent: 'space-between' }}>
        <Col xs={24} md={14} lg={12} xl={8}>
          <SearchItemInput placeholder="Search for a modifier" setSearchTerm={setSearchTerm} />
        </Col>
        <Col xs={24} md={4} lg={4} xl={3}>
          <Button icon={<PlusOutlined />} onClick={() => setMode(ModalMode.NEW)}>
            Add modifier
          </Button>
        </Col>
      </Row>

      <ModifierModal mode={mode} setMode={setMode} />
    </>
  );
}

export default ModifierActions;
