import { ArrowRightOutlined } from '@ant-design/icons';
import {
  Button, Flex,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import { truncate } from 'lodash-es';
import React, { useState } from 'react';

const { Text } = Typography;

function EmailEvent({ event: { data: email, occurred_at: date } }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [open, setOpen] = useState(false);
  const text = email.body_html.replace(/<[^>]+>/g, '');
  return (
    <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-full" onClick={() => text.length >= 70 && setOpen(!open)}>
      <Flex justify="space-between" vertical={isMobile} className="w-full">
        <Flex gap={12}>
          <ArrowRightOutlined style={{ transform: email.direction === 'outgoing' ? 'rotate(-45deg)' : 'rotate(45deg)' }} />
          <Text className="font-bold text-[16px]">
            {email.subject || 'Email'}
          </Text>
        </Flex>
        <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
      </Flex>

      <Flex
        vertical={isMobile}
        align="flex-start"
        gap={12}
        wrap={open ? 'wrap' : 'nowrap'}
        style={{ overflow: 'hidden' }}
      >
        <Text className="whitespace-nowrap" style={{ fontSize: 12 }}>
          to:
          {' '}
          {email.to.join(', ')}
        </Text>
        {
          email.cc.length > 0 && (
            <Text className="whitespace-nowrap" style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
              cc:
              {' '}
              {email.cc.join(', ')}
            </Text>
          )
        }
        {
          email.bcc.length > 0 && (
            <Text className="whitespace-nowrap" style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
              bcc:
              {' '}
              {email.bcc.join(', ')}
            </Text>
          )
        }
      </Flex>
      <Flex
        vertical={isMobile}
        justify="space-between"
        align="flex-start"
        gap={4}
      >
        <Text style={{ height: open ? 'auto' : '20px', overflow: 'hidden' }}>
          <Text className="mr-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
            {(email.from || email.to[0]).split('@')[0]}
          </Text>
          {open ? text : truncate(text, {
            length: 70,
            omission: '...',
          })}
        </Text>
        {
          !open && text.length >= 70 && (
            <Button type="link" className="pr-0">
              Show more
            </Button>
          )
        }
      </Flex>

    </Flex>
  );
}

export default EmailEvent;
