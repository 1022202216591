export const PERCENT_COUPON_TYPE = 0;
export const FIXEDAMOUNT_COUPON_TYPE = 1;

export const AFFECTED_FEE_TOTAL_PRICE = 0;
export const AFFECTED_FEE_DELIVERY_FEE = 1;
export const AFFECTED_FEE_SERVICE_FEE = 2;
export const AFFECTED_FEE_FOOD_AMOUNT = 3;

export const COUPON_HOLER_ADMIN = 1;
export const COUPON_HOLER_MERCHANT = 2;

export const COUPON_LIST_ALL = 1;
export const COUPON_LIST_ACTIVE = 2;
export const COUPON_LIST_INACTIVE = 3;
