import { DeliveryIcon, PickupIcon } from 'assets/icons';
import { renderPercentageColumn } from 'containers/Reports/utils';

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
    render: (value) => (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {value.toLowerCase() === 'delivery' ? <DeliveryIcon /> : <PickupIcon />}
        <span style={{ marginLeft: 8 }}>{value}</span>
      </span>
    ),
  },
  {
    title: '',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
  },
  {
    title: '',
    dataIndex: 'percentage',
    key: 'percentage',
    width: '45%',
    render: renderPercentageColumn,
  },
];
export default columns;
