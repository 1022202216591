import {
  Form, Input, Typography, Flex,
} from 'antd';
import { capitalize } from 'lodash-es';
import React from 'react';

function CustomPaymentForm({ paymentMethod }) {
  function getPaymentMetaDataForm() {
    const fields = Object.entries(paymentMethod?.meta?.fields);
    return fields.map(([name, attrs]) => (
      <Form.Item
        key={name}
        name={name}
        rules={[
          {
            required: attrs?.required,
            message: 'This field is required',
          },
        ]}
        wrapperCol={{ xs: 24, lg: 6 }}
      >
        <Input
          placeholder={capitalize(name)}
          style={{ width: '100%' }}
          type={attrs?.type || 'string'}
          {...attrs}
        />
      </Form.Item>
    ));
  }

  return (
    <Flex vertical gap={8} style={{ marginTop: 16 }}>
      <Typography.Text type="secondary">
        To complete your payment, please fill out the following form.
      </Typography.Text>
      {getPaymentMetaDataForm()}
    </Flex>
  );
}

export default CustomPaymentForm;
