import PropTypes from 'prop-types';
import React from 'react';
import { StyledWrapper } from './styles';

/** A Radio group selection */

function RadioButtonGroup({
  options,
  selectedOption,
  setSelectedOption,
  optionSameText,
  classes,
  labelClasses,
  checkmarkClasses,
  disabled,
  ...props
}) {
  return (
    <StyledWrapper disabled={disabled}>
      <div className={`wrapper ${classes}`}>
        {options?.map((option) => (
          <div>
            <label
              onClick={() => {
                if (!disabled) {
                  setSelectedOption(option);
                }
              }}
              className={`container ${labelClasses}`}
            >
              {option.title}
              {' '}
              {optionSameText}
              <input
                type="radio"
                onChange={() => {}}
                checked={option.id === selectedOption?.id ? 'checked' : ''}
              />
              <span className={`checkmark ${checkmarkClasses}`} />
            </label>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
}

RadioButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  optionSameText: PropTypes.func,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  checkmarkClasses: PropTypes.string,
};

export default RadioButtonGroup;
