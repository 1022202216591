import useFetchedData from 'hooks/useFetchedData';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import { App } from 'antd';
import http from 'utils/http';
import DrawerForm from './DrawerForm';

function Edit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { modal } = App.useApp();
  const { data: location, loading, mutate } = useFetchedData(`/v1/organization/{{organizationId}}/parents/${id}/`);
  function handleRemove() {
    modal.confirm({
      title: `Delete ${location.title}`,
      content: `Are you sure you want to delete the "${location.title}" This action can’t be undone.`,
      okText: 'Delete location',
      onOk: async () => {
        await http.delete(`/v1/organization/{{organizationId}}/parents/${id}/`);
        handleBack();
      },
      onCancel: () => { },
      okButtonProps: {
        danger: true,
      },
    });
  }
  async function handleUpdate(data) {
    const res = await http.put(`/v1/organization/{{organizationId}}/parents/${id}/`, data).then((res) => {
      mutate(res.data);
      return res;
    });
    handleBack();
    return res;
  }
  function handleBack() {
    navigate('/console/locations', { replace: true });
  }
  return (
    <DrawerForm
      open
      data={location}
      loading={loading}
      onClose={handleBack}
      onRemove={handleRemove}
      onSubmit={handleUpdate}
    />
  );
}

export default Edit;
