import Layout from 'components/Layout';
import React from 'react';
import './index.css';

function ReportLayout({
  title = 'Reports',
  description,
  backTitle = 'Reports',
  children,
  defaultRoute,
  style,
  loading,
  tag,
}) {
  return (
    <Layout header={(
      <Layout.Header
        title={title}
        tag={tag}
        description={description}
        backTitle={backTitle}
        backPath={defaultRoute}
        loading={loading}
      />
    )}
    >
      <div style={style}>
        {children}
      </div>
    </Layout>
  );
}

export default ReportLayout;
