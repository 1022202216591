import {
  Col, Form, Input, Radio, Row,
} from 'antd';
import SwitchInput from 'components/SwitchInput';
import React, { useEffect, useMemo } from 'react';
import OrderFormItem from '../OrderFormItem';

function DeliveryTip() {
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');
  const hasTip = Form.useWatch('has_tip');
  const tipPercentage = Form.useWatch('tip_percentage');
  const has_delivery = form.getFieldValue('has_delivery');

  const tipOptions = useMemo(() => {
    if (!merchant) return [5, 10, 15, 20];
    return merchant?.parent?.tip_data?.tip_options || [5, 10, 15, 20];
  }, [merchant]);

  useEffect(() => {
    if (tipPercentage !== -1) {
      form.setFieldValue('tip');
    }
  }, [tipPercentage]);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8} lg={5} className="d-flex align-items-center">
        <OrderFormItem name="has_tip" noStyle boolean fullWidth>
          <SwitchInput label="Delivery tip" direction="horizontal" disabled={!has_delivery} />
        </OrderFormItem>
      </Col>
      {hasTip && (
        <Col xs={24} md={24} lg={19}>
          <OrderFormItem name="tip_percentage" noStyle>
            <Radio.Group className="flex flex-col md:flex-row gap-6 md:items-center">
              {tipOptions.map((tip) => (
                <Radio value={tip} key={tip}>
                  {tip}
                  %
                </Radio>
              ))}
              <Radio value={-1}>
                <OrderFormItem name="tip" noStyle>
                  <Input
                    disabled={tipPercentage !== -1}
                    type="number"
                    suffix="$"
                  />
                </OrderFormItem>
              </Radio>
            </Radio.Group>
          </OrderFormItem>
        </Col>
      )}
    </Row>
  );
}

export default DeliveryTip;
