import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Row, TimePicker, Typography,
} from 'antd';
import SwitchInput from 'components/SwitchInput';
import React from 'react';

const { RangePicker } = TimePicker;

function WorkingDayInput({
  value = { },
  onChange,
  title,
}) {
  const { hours = [[]], isClosed } = value;
  function handleChange(payload) {
    onChange({ ...value, ...payload });
  }
  return (
    <Row gutter={16} align={hours?.length > 1 ? 'baseline' : 'middle'}>
      <Col xs={24} md={5}>
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col xs={18} md={14}>
        <Flex vertical gap={4}>
          {hours.map((activeTime, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={index} gap={4}>
              <RangePicker
                style={{ width: '100%' }}
                value={activeTime}
                disabled={isClosed}
                onChange={(value) => {
                  const newHours = [...hours];
                  newHours[index] = value;

                  handleChange({ hours: newHours });
                }}
                changeOnBlur
              />
              {index === 0 && (
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  disabled={isClosed}
                  onClick={() => {
                    onChange({ hours: [...hours, []] });
                  }}
                />
              )}
              {index > 0 && (
                <Button
                  type="text"
                  icon={<MinusOutlined />}
                  disabled={isClosed}
                  onClick={() => handleChange({
                    hours: hours.filter((_, i) => i !== index),
                  })}
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Col>
      <Col xs={6} md={5}>
        <SwitchInput
          label="Closed"
          checked={isClosed}
          onChange={(isClosed) => handleChange({ isClosed })}
          checkedChildren={<CloseOutlined />}
          unCheckedChildren={null}
        />
      </Col>

    </Row>
  );
}

export default WorkingDayInput;
