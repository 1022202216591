import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import MerchantItemsModal from './MerchantItemsModal';

function AddOrderItems({ disable }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        className="mt-2"
        disabled={disable}
        key="add-order"
        icon={<PlusOutlined />}
        onClick={() => {
          setShowModal(true);
        }}
        type="default"
        size="large"
      >
        Add order
      </Button>
      <MerchantItemsModal
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

export default AddOrderItems;
