import React from 'react';
import { ConfigContext } from 'constants/contexts';

import useFetchedConfig from './use-fetched-config';

const emptyValue = {};
function OrganizationConfigProvider({ children }) {
  const contextValue = useFetchedConfig() || emptyValue;

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
}

export default OrganizationConfigProvider;
