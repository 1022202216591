import React, { useState, useEffect, useContext } from 'react';
import Checkbox from 'components/Checkbox';
import SkeletonLoading from 'components/SkeletonLoading';
import { StyledWrapper } from './styles';

function AvailableFeaturesBox({
  merchantData,
  merchantDataLoading,
  ...props
}) {
  // const [isGift, setIsGift] = useState(false);
  // const [scheduling, setScheduling] = useState(true);

  return (
    <StyledWrapper>
      <div className="box">
        <div className="box-title">Available features</div>
        <div className="details">
          {merchantDataLoading ? (
            <SkeletonLoading
              borderRadius="2px"
              width="238px"
              height="38px"
              count={1}
            />
          ) : (
            !merchantData?.is_catering && ( <Checkbox
              classes=""
              name="gift"
              checked={merchantData?.has_send_gift}
              onChange={() => {}}
              label="Send as a gift"
              id="gift"
              disabled
            />)
          )}

          {merchantDataLoading ? (
            <SkeletonLoading
              borderRadius="2px"
              width="238px"
              height="38px"
              count={1}
            />
          ) : (
            <Checkbox
              classes=""
              name="scheduling"
              checked={merchantData?.has_scheduling_order}
              onChange={() => {}}
              label="Scheduling ahead"
              id="scheduling"
              disabled
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  );
}

export default AvailableFeaturesBox;
