import { Link } from 'react-router-dom';
import { ReactComponent as LinkIcon } from 'assets/image/link.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledWrapper } from './styles';

function NewTabLink({
  label,
  link,

  ...props
}) {
  return (
    <StyledWrapper>
      <Link target="_blank" to={link} onClick={(e) => e.stopPropagation()}>
        <span>{label}</span>
        <span className="icon icon--trail">
          <LinkIcon />
        </span>
      </Link>
    </StyledWrapper>
  );
}

NewTabLink.propTypes = {
  label: PropTypes.element,
  link: PropTypes.string,
};

export default NewTabLink;
