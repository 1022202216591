export const getFilteredItems = ({ searchTerm, categories }) => {
  const searchTerms = searchTerm?.split(' ').filter((s) => s);
  if (!searchTerm || !searchTerms.length) {
    return categories;
  }
  const filteredItems = JSON.parse(JSON.stringify(categories)).map((cat) => ({
    ...cat,
    items: cat.items
      .filter(({ name }) => searchTerms.some((term) => name.toLowerCase().includes(term))),
  })).filter((cat) => cat.items.length > 0);

  return filteredItems;
};
