import { App, Table } from 'antd';
import { getFilterQuery, tableLocale } from 'containers/Reports/utils';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect, useMemo, useState } from 'react';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import useColumns from './use-columns';
import './index.css';
import { fetchMerchantsReport } from './requests';

function TotalMerchantsReport({ filterData }) {
  const isMobile = useIsMobile();
  const [reportData, setReportData] = useState();
  const [pagination, setPagination] = useState({ current: 1, total: 0, needAPICall: false });
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  useEffect(() => {
    if (filterData && pagination.needAPICall) {
      getReportData(pagination.current);
    }
  }, [pagination.current]);

  useEffect(() => {
    if (filterData && filterData.options?.locations) {
      getReportData(pagination.current);
    }
  }, [filterData]);

  const getReportData = (page) => {
    setLoading(true);

    fetchMerchantsReport({ query: getFilterQuery(filterData), page })
      .then((res) => {
        if (res.status === 'success') {
          setReportData(getData(res)?.results);
          setPagination({ current: page, total: getData(res)?.count, needAPICall: false });
        } else message.error(getData(res)?.title || 'Something went wrong');
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };

  const handlePagination = ({ current, total }) => {
    setPagination({ current, total, needAPICall: true });
  };

  const tableColumns = useColumns({ dateFilter: filterData?.selected?.date, isMobile });
  return (
    <Table
      columns={tableColumns}
      dataSource={reportData}
      locale={tableLocale}
      scroll={{ x: 2100 }}
      pagination={{
        current: pagination.current,
        total: pagination.total,
        showTotal: (total) => `${total} results`,
        size: 'small',
        showSizeChanger: false,
        defaultPageSize: 100,
      }}
      loading={loading}
      onChange={handlePagination}
      sticky
    />
  );
}

export default TotalMerchantsReport;
