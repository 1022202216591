import useConfig from 'hooks/useConfig';
import useDebouncedEffect from 'use-debounced-effect';
import {
  useState, useRef,
} from 'react';

export default function useAutoComplete(query) {
  const [results, setResults] = useState([]);
  const mapRestriction = useConfig('general.mapRestriction');

  const serviceRef = useRef();
  if (!serviceRef.current && window.google?.maps) {
    window.google.maps.importLibrary('places').then((places) => {
      serviceRef.current = new places.AutocompleteService();
    });
  }
  useDebouncedEffect(() => {
    if (!serviceRef.current || !query || query.length < 3) {
      return;
    }
    const request = { input: query };
    if (mapRestriction?.boundary) {
      request.locationRestriction = mapRestriction.boundary;
    } else if (mapRestriction?.countries) {
      request.componentRestrictions = mapRestriction.countries;
    }
    serviceRef.current.getPlacePredictions(request, (predictions, status) => {
      if (status !== 'OK' || !predictions) {
        return;
      }
      setResults(predictions.map((p) => ({ ...p, value: p.description })));
    });
  }, 500, [query]);

  return results;
}
