import CheckSvg from 'assets/image/checkWhite.svg';
import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  width: 100%;
  position: relative;
  margin: 16px 0;

  label {
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    padding-left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #7b7b7b;
  }

  &:hover input ~ .checkmark-icon {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);

    &--disabled {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #b8bbbc,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }
  }

  &:hover input:checked ~ .checkmark-icon {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2),
      0px 0px 0px 1px ${Colors.primary1}, 0px 1px 1px rgba(0, 0, 0, 0.12);

    &--disabled {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #b8bbbc,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark-icon {
      background-color: ${Colors.primary1};
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px ${Colors.primary1}, 0px 1px 1px rgba(0, 0, 0, 0.12);

      &--disabled {
        background: #b8bbbc;
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #b8bbbc,
          0px 1px 1px rgba(0, 0, 0, 0.12);
      }

      &:after {
        display: block;
        background-image: url(${CheckSvg});
        background-size: 10px 10px;
      }
    }
  }

  .checkmark-icon {
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    padding: 3px 2px;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    &--disabled {
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #b8bbbc,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &--loading {
      position: absolute;
      left: -10px;
      top: 2px;
      margin-top: -2px;
    }

    &:after {
      content: '';
      height: 10px;
      width: 10px;
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
