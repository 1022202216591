/*
 * Migration drivers:
 */
export const OMNI = 'MealMe';
export const FOODHUB = 'FH';

export const MIGRATION_DRIVER = {
  MEAL_ME: 'MealMe',
  FOOD_HUB: 'FH',
  TOAST: 'Toast',
  GFOE: 'GFOE',
  OLO: 'Olo',
  SQUARE: 'Square',
};

export const OMNI_MIGRATION_DRIVER = [MIGRATION_DRIVER.MEAL_ME, MIGRATION_DRIVER.TOAST, MIGRATION_DRIVER.OLO, MIGRATION_DRIVER.SQUARE];
