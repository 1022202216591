import {
  Col, Flex, Input, Radio, Row, Space, Typography,
} from 'antd';
import { PaymentTypes } from 'constants';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OrderFormItem from '../OrderFormItem';

function QuotePaymentForm() {
  const options = [
    { label: 'Online', value: PaymentTypes.CARD },
    { label: 'In-store', value: PaymentTypes.IN_STORE },
    { label: 'Cheque', value: PaymentTypes.CHEQUE },
  ];

  return (
    <Flex vertical gap={12} className="bg-[#DAEADE66] py-2 px-4 rounded">
      <Space align="baseline">
        <Typography.Title level={5}>
          Quote’s payment options
        </Typography.Title>
      </Space>
      <OrderFormItem name={['quotedPaymentMeta', 'type']} className="mb-0">
        <Radio.Group
          block
          options={options}
          defaultValue={PaymentTypes.CARD}
          optionType="button"
          className="d-flex gap-2 quote-payment"
        />
      </OrderFormItem>
      <Flex vertical gap={8}>
        <Typography.Text>
          Customers can complete the payment online via credit card. The quote will be confirmed once payment is made.
        </Typography.Text>
        <Typography>
          This phone number will be used as the customer&apos;s login credential.
        </Typography>
        <Row gutter={8} className="mt-2">
          <Col key="phone" xs={24} md={12} lg={9}>
            <OrderFormItem
              name={['quotedPaymentMeta', 'phone']}
              fullWidth
              className="mb-0"
            >
              <PhoneInput
                country="us"
                buttonClass="phone-country"
                inputClass="input"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{ width: '100%', height: 40 }}
                placeholder="Enter phone number"
                autofocus
                preferredCountries={['us', 'ca']}
                enableSearch
                regions={['america', 'europe']}
                countryCodeEditable={false}
                masks={{
                  pr: '(...) ...-....',
                }}
              />
            </OrderFormItem>
          </Col>
          <Col key="email" xs={24} md={12} lg={9}>
            <OrderFormItem
              name={['quotedPaymentMeta', 'email']}
              fullWidth
            >
              <Input
                placeholder="Email"
                type="email"
                size="large"
              />
            </OrderFormItem>
          </Col>
        </Row>
      </Flex>
    </Flex>
  );
}

export default QuotePaymentForm;
