import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router';
import List from './List';
import Edit from './Edit';
import Create from './Create';

function Location() {
  return (
    <Routes>
        <Route path="" element={<List />} >
          <Route path=":id" element={<Edit />} />
          <Route path="create" element={<Create />} />
        </Route>
    </Routes>
  );
}

export default Location;
