import { Form, Typography } from 'antd';
import React from 'react';

function FormItem({
  fullWidth = false, style, boolean = false, label, rules, ...props
}) {
  const isRequired = rules?.some(rule => rule.required);
  return (
    <Form.Item
      valuePropName={boolean ? 'checked' : 'value'}
      style={{
        width: fullWidth ? '100%' : 300,
        maxWidth: '100%',
        ...style,
      }}
      required={false}
      label={label && <Typography.Text>
        {label}
        {isRequired && <Typography.Text type="secondary" className="ml-2">Required</Typography.Text>}
      </Typography.Text>}
      rules={rules}
      {...props}
    />
  );
}

export default FormItem;
