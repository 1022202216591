import { DollarCircleOutlined, PercentageOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, DatePicker, Form, Input, Radio, Select, Space, Tooltip,
} from 'antd';
import { fetchMerchantsList } from 'api/merchants';
import { fetchAccessibleParents } from 'api/parents';
import { ReactComponent as InfoSquareIcon } from 'assets/image/info.svg';
import DebounceSelect from 'components/Select/DebounceSelect';
import { AdminContext } from 'constants/contexts';
import {
  AFFECTED_FEE_DELIVERY_FEE,
  AFFECTED_FEE_FOOD_AMOUNT,
  AFFECTED_FEE_SERVICE_FEE,
  AFFECTED_FEE_TOTAL_PRICE,
  COUPON_HOLER_ADMIN,
  COUPON_HOLER_MERCHANT,
  FIXEDAMOUNT_COUPON_TYPE,
  PERCENT_COUPON_TYPE,
} from 'containers/Coupons/constants';
import { createCoupon, editCoupon } from 'containers/Coupons/coupons';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';
import { ModalContext } from '../../../context/modalContext';
import DeleteModal from '../../Coupons/DeleteModal';
import { StyledWrapper } from './styles';

function CouponForm({ data, ...props }) {
  const [activationDate, setActivationDate] = useState(
    data && (data.start_date || data.expiry_date)
      ? [dayjs(data.start_date), dayjs(data.expiry_date)]
      : [],
  );
  const [title, setTitle] = useState(data && data.name ? data.name : undefined);
  const [code, setCode] = useState(data && data.code ? data.code : undefined);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const navigate = useNavigate();

  const { handleModal } = useContext(ModalContext);

  const [couponType, setCouponType] = useState(data && data.type);
  const [feeCalculateType, setFeeCalculateType] = useState(
    !data ? 0 : data && data.fee_calculate_type,
  );
  const [amount, setAmount] = useState(data && data.amount);
  const [maxAmount, setMaxAmount] = useState(data && data.max_amount);
  const [minAmount, setMinAmount] = useState(data && data.min_amount);
  const [holderMerchant, setHolderMerchant] = useState(
    data && data.holder_merchant,
  );
  const [selectedMerchants, setSelectedMerchants] = useState(
    data?.merchants ?? [],
  );
  const [selectedParents, setSelectedParents] = useState(data && data.parents);
  const [allParents, setAllParents] = useState([]);

  const [maxUsage, setMaxUsage] = useState(data && data.max_usage);
  const [maxCustomerUsage, setMaxCustomerUsage] = useState(data && data.max_customer_usage);
  const [onlyFirsttimeUser, setOnlyFirsttimeUser] = useState(
    data && data.is_first_user ? data.is_first_user : false,
  );
  const [onlyForApplication, setOnlyForApplication] = useState(
    data && data.is_only_app ? data.is_only_app : false,
  );

  const [holder, setHolder] = useState(
    // !data ? 1 :( data && data.holder_admin ? 1 : data && 2)
    COUPON_HOLER_ADMIN,
  );
  const [merchants, setMerchants] = useState([]);

  const { RangePicker } = DatePicker;
  const { organizationId } = useContext(AdminContext);

  const getMerchants = (searchKey = '') => fetchMerchantsList({ search: searchKey })
    .then((res) => {
      setMerchants(
        res.data.data.results.map((m) => ({ label: m.name, value: m.id })),
      );
      return res.data.data.results.map((m) => ({ label: m.name, value: m.id }));
    })
    .catch((e) => {
      captureException(e);
      setSaveBtnLoading(false);
    });
  const getAccessibleParents = () => fetchAccessibleParents()
    .then((res) => {
      if (res.status === 'success') {
        const parents = res?.data?.parents.map((m) => ({ label: m.title, value: m.id }));

        setAllParents(parents);

        return parents;
      }
    })
    .catch((err) => {
      captureException(err);
      setSaveBtnLoading(false);
    });
  useEffect(() => {
    if (organizationId) {
      getMerchants();
      getAccessibleParents();
    }
  }, [organizationId]);
  const isEdit = () => {
    if (data?.id) return true;
    return false;
  };
  const setAffectedVAlues = () => {
    const affectedValue = {};
    if (maxAmount) affectedValue.max_amount = maxAmount;
    if (minAmount) affectedValue.min_amount = minAmount;
    if (maxUsage) affectedValue.max_usage = maxUsage;
    if (maxCustomerUsage) affectedValue.max_customer_usage = maxCustomerUsage;
    return affectedValue;
  };
  const handleSave = (values) => {
    let couponData = {
      id: data?.id,
      name: title,
      code,
      start_date:
        activationDate && activationDate.length
          ? new Date(activationDate[0])
          : undefined,
      expiry_date:
        activationDate && activationDate.length
          ? new Date(activationDate[1])
          : undefined,
      type: couponType,
      is_disabled: false,
      amount,
      fee_calculate_type: feeCalculateType,
      holder_merchant: holder === 2 ? holderMerchant?.value : undefined,
      holder_admin: holder === 1 ? organizationId : undefined,
      parents: selectedParents?.map((m) => m.value),
      merchants: selectedMerchants?.map((m) => m.value),
      is_first_user: onlyFirsttimeUser,
      is_only_app: onlyForApplication,
    };
    couponData = { ...couponData, ...setAffectedVAlues() };
    if (isEdit()) {
      setSaveBtnLoading(true);
      editCoupon(couponData)
        .then((res) => {
          setSaveBtnLoading(false);
          handleGoToListPage();
        })
        .catch((err) => {
          captureException(err);
          setSaveBtnLoading(false);
        });
    } else {
      setSaveBtnLoading(true);
      createCoupon(couponData)
        .then((res) => {
          setSaveBtnLoading(false);
          handleGoToListPage();
        })
        .catch((err) => {
          captureException(err);
          errorHandling(err);
          setSaveBtnLoading(false);
        });
    }
  };

  const handleGoToListPage = () => {
    navigate('/console/coupons');
  };

  const handleCancel = () => {
    handleGoToListPage();
  };

  const handleDelete = () => {
    handleModal(<DeleteModal row={data} reload={handleGoToListPage} />);
  };
  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Please select active range!',
      },
    ],
  };
  const disabledDate = (current) =>
    // Can not select days before today
    current && current < dayjs().add(-1, 'days');
  return (
    <Form
      name="coupon"
      layout="vertical"
      wrapperCol={{ span: 8 }}
      onFinish={handleSave}
      onFinishFailed={(fail) => {
        console.log(fail);
      }}
    >
      <StyledWrapper>
        {/* Title */}
        <div className="box box--top">
          <Form.Item
            tooltip="This will appear on customers' receipts and invoices."
            label="Title"
            name="coupon-name"
            rules={[
              {
                required: true,
                message: 'Please input coupon name!',
              },
            ]}
            initialValue={title}
            shouldUpdate
            wrapperCol={{ xs: 24, lg: 8 }}
          >
            <Input
              type="text"
              defaultValue={title}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="e.g. Black Friday"
              inputClasses="name-input"
            />
          </Form.Item>

          {/* Code */}
          <Form.Item
            tooltip="Customers must enter this code at checkout."
            label="Coupon Code"
            name="coupon-code"
            initialValue={code}
            wrapperCol={{ xs: 24, lg: 8 }}
            rules={[
              {
                required: true,
                message: 'Please input coupon code!',
              },
            ]}
          >
            <div className="">
              <Input
                type="text"
                id="coupon-code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="9c9f28b8-27"
                inputClasses="name-input"
              />
            </div>
          </Form.Item>

          {/* Type */}
          <Form.Item name="radio-group" label="Type" initialValue={couponType} wrapperCol={{ xs: 24, lg: 8 }}>
            <div className="">
              <Radio.Group
                onChange={(e) => setCouponType(e.target.value)}
                value={couponType}
              >
                <Space direction="vertical">
                  <Radio value={PERCENT_COUPON_TYPE}>Percentage coupon</Radio>
                  <Radio value={FIXEDAMOUNT_COUPON_TYPE}>
                    Fixed amount coupon
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Form.Item>

          {couponType != undefined ? (
            <Form.Item
              label={
                couponType === FIXEDAMOUNT_COUPON_TYPE
                  ? 'Fixed amount'
                  : 'Percentage'
              }
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input coupon amount!',
                },
              ]}
              initialValue={amount}
            >
              <div className="">
                <Input
                  type="number"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  max={couponType === PERCENT_COUPON_TYPE ? 100 : undefined}
                  min={0}
                  suffix={(
                    <span
                      className="input-icon"
                      style={{
                        color: 'rgba(0,0,0,.45)',
                      }}
                    >
                      {couponType === FIXEDAMOUNT_COUPON_TYPE ? (
                        <DollarCircleOutlined />
                      ) : (
                        <PercentageOutlined />
                      )}
                    </span>
                  )}
                />
              </div>
            </Form.Item>
          ) : undefined}
        </div>
        <div className="box">
          <div className="flex-row-end">
            <h6 className="box-title">Affected fee </h6>
            <span className="box-title-light">
              <Tooltip placement="topLeft">
                <InfoSquareIcon />
              </Tooltip>
            </span>
          </div>
          <Radio.Group
            onChange={(e) => setFeeCalculateType(e.target.value)}
            value={feeCalculateType}
            defaultValue={feeCalculateType}
          >
            <Space direction="vertical">
              <Radio value={AFFECTED_FEE_TOTAL_PRICE}>Total price</Radio>
              <Radio value={AFFECTED_FEE_DELIVERY_FEE}>Delivery fee</Radio>
              <Radio value={AFFECTED_FEE_SERVICE_FEE}>Service fee</Radio>
              <Radio value={AFFECTED_FEE_FOOD_AMOUNT}>Subtotal</Radio>
            </Space>
          </Radio.Group>
        </div>

        <div className="box">
          <div className="flex-row-end">
            <h6 className="box-title">Conditions</h6>
            {/* <span className="box-title-light"> (optional)</span> */}
          </div>
          <Form.Item
            tooltip="Minimum from X$ price"
            label="Minimum discount value per use"
            name="minAmount"
            initialValue={minAmount}
            rules={[
              {
                required: true,
                message: 'Please fill minimum discount value!',
              },
            ]}
          >
            <div className="">
              <Input
                type="text"
                id="minAmount"
                value={minAmount}
                onChange={(e) => setMinAmount(e.target.value)}
                inputClasses="price-input"
                suffix="$"
              />
            </div>
          </Form.Item>
          <div>
            <Form.Item
              tooltip="Maximum from X$ price"
              label="Maximum discount value per use"
              name="maxAmount"
              initialValue={maxAmount}
              rules={[
                {
                  required: true,
                  message: 'Please fill miaximum discount value!',
                },
              ]}
            >
              <div className="">
                <Input
                  type="text"
                  id="maxAmount"
                  value={maxAmount}
                  onChange={(e) => setMaxAmount(e.target.value)}
                  inputClasses="price-input"
                  suffix="$"
                />
              </div>
            </Form.Item>
            <Form.Item
              tooltip="Limit number of times this code can be used in total"
              label="Maximum number of redeems"
              name="maxUsage"
              initialValue={maxUsage}
              rules={[
                {
                  required: true,
                  message: 'Please fill miaximum usage value!',
                },
              ]}
            >
              <div className="">
                <Input
                  type="text"
                  id="maxUsage"
                  value={maxUsage}
                  onChange={(e) => setMaxUsage(e.target.value)}
                  inputClasses="price-input"
                  suffix="Times"
                />
              </div>
            </Form.Item>
            <Form.Item
              tooltip="Limit number of times this code can be used by the same customer"
              label="Maximum redeems by a customer"
              name="maxCustomerUsage"
              initialValue={maxCustomerUsage}
              rules={[
                {
                  required: true,
                  message: 'Please fill miaximum  customer usage value!',
                },
              ]}
            >
              <div className="">
                <Input
                  type="text"
                  id="maxCustomerUsage"
                  value={maxCustomerUsage}
                  onChange={(e) => setMaxCustomerUsage(e.target.value)}
                  inputClasses="price-input"
                  suffix="Times"
                />
              </div>
            </Form.Item>

            {/* <Form.Item
              tooltip="Maximum from X$ price"
              label="Maximum order value"
              name="maxAmount"
            >
              <div className="">
                <Input
                  type="text"
                  id="maxAmount"
                  value={maxAmount}
                  onChange={(e) => setMaxAmount(e.target.value)}
                  inputClasses="price-input"
                  suffix="$"
                />
              </div>
            </Form.Item> */}
            <Form.Item
              style={{ 'margin-bottom': '0px' }}
              tooltip="Only first time user"
              name="onlyFirsttimeUser"
              wrapperCol={{ xs: 24, lg: 8 }}
            >
              <div>
                <span className="tool-tip">
                  <Checkbox
                    ke={1}
                    // name="onlyFirsttimeUser"
                    checked={onlyFirsttimeUser}
                    onChange={(e) => setOnlyFirsttimeUser(e.target.checked)}
                  >
                    Only first time user
                  </Checkbox>
                </span>
              </div>
            </Form.Item>

            <Form.Item
              style={{ 'margin-bottom': '0px' }}
              tooltip="Only for mobile application"
              name="onlyForApplication"
              wrapperCol={{ xs: 24, lg: 8 }}
            >
              <div>
                <span className="tool-tip">
                  <Checkbox
                    ke={1}
                    checked={onlyForApplication}
                    onChange={(e) => setOnlyForApplication(e.target.checked)}
                  >
                    Only for mobile application
                  </Checkbox>
                </span>
              </div>
            </Form.Item>
          </div>
        </div>
        {/* Active dates */}

        <div className="box">
          <Form.Item
            name="range-time-picker"
            label="Active dates"
            {...rangeConfig}
            initialValue={activationDate}
            wrapperCol={{ xs: 24, lg: 8 }}
          >
            <RangePicker
              disabledDate={disabledDate}
              onChange={(date) => {
                setActivationDate(date);
              }}
              defaultValue={activationDate}
              value={activationDate}
              showTime
            />
          </Form.Item>
        </div>
        <div className="box">
          <Form.Item label="Payment holder" name="couponHolder" wrapperCol={{ xs: 24, lg: 8 }}>
            <Select
              className="row-right-margin"
              defaultValue={holder}
              onChange={(selected) => setHolder(selected)}
              disabled
              options={[
                {
                  value: COUPON_HOLER_ADMIN,
                  label: 'Admin',
                },
                {
                  value: COUPON_HOLER_MERCHANT,
                  label: 'Merchant',
                },
              ]}
            />
          </Form.Item>
          {holder !== COUPON_HOLER_MERCHANT ? undefined : (
            <Form.Item
              label="Merchant"
              name="merchantHolder"
              className="section"
              required
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (holderMerchant) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please choose  merchant!'),
                    );
                  },
                }),
              ]}
              initialValue={holderMerchant}
            >
              <DebounceSelect
                id="merchnats"
                allowClear
                placeholder="Select merchants"
                defaultValue={data && data.holder_merchant}
                fetchOptions={getMerchants}
                onChange={(value) => setHolderMerchant(value)}
                options={merchants}
                showSearch
              />
              {' '}
            </Form.Item>
          )}
        </div>
        <div className="box">
          <h6 className="box-title">Affected Merchants or Parents</h6>
          <Form.Item
            wrapperCol={{ xs: 24, lg: 8 }}
            label="Parents"
            name="parents"
            required
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (selectedParents?.length) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please choose  parent!'));
                },
              }),
            ]}
            initialValue={selectedParents}
          >
            <div>
              <Select
                labelInValue
                id="parents"
                mode="multiple"
                allowClear
                placeholder="Select parents"
                options={allParents}
                defaultValue={selectedParents || undefined}
                filterOption={(input, option) => (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())}
                onChange={(selected) => setSelectedParents(selected)}
              />
            </div>
          </Form.Item>
          <Form.Item
            label="Merchants"
            name="merchants"
            initialValue={(data && data.merchants) || []}
            wrapperCol={{ xs: 24, lg: 8 }}
          >
            <div className="">
              <DebounceSelect
                id="merchnats"
                mode="multiple"
                allowClear
                placeholder="Select merchants"
                value={selectedMerchants}
                // defaultValue={(data && data.merchants) || []}
                fetchOptions={getMerchants}
                onChange={(selected) => setSelectedMerchants(selected)}
              />
              {' '}
            </div>
          </Form.Item>
        </div>

        {/* Buttons */}
        <div className="box--bottom flex-col md:flex-row gap-2">
          {isEdit() ? (
            <Button
              onClick={handleDelete}
              type="default"
              classes="btn-delete"
              danger
            >
              Delete
            </Button>
          ) : null}
          <div className="btns-right flex-col md:flex-row gap-2 md:justify-end w-full">
            <Button
              type="default"
              size="middle"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                htmlType="submit"
                className="w-full"
                // onClick={handleSave}
                type="primary"
                loading={saveBtnLoading}
              >
                {isEdit() ? 'Save coupon' : 'Create coupon'}
              </Button>
            </Form.Item>
          </div>
        </div>
      </StyledWrapper>
    </Form>
  );
}

export default CouponForm;
