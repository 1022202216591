import http from 'utils/http';

export const addNewMerchant = ({ data, parent, ...rest }) => {
  const payload = {
    ...data,
    ...rest,
    name: data.name,
    ...(data.email && { email: data.email }),
    is_partner: data.type === 'Partner',
    phone: data.phone,
    address: data.address,
    location: data.location,
    application_delivery_fee: data.deliveryCommission / 100 || 0,
    application_pickup_fee: data.pickupCommission / 100 || 0,
    markup: data.markup || 0,
    has_send_gift: data.gift,
    has_scheduling_order: data.schedule,
    parent,
  };
  return http
    .post('v1/organizations/{{organizationId}}/merchants/', payload)
    .then((res) => res.data);
};

export const addNewOmniMerchant = (payload) => http
  .post('v1/integrations/add/', payload)
  .then((res) => res.data);
