import { generateQuery } from 'utils';
import http from 'utils/http';

export const getParentByLocation = ({ lat, lng }) => {
  const query = generateQuery([
    lat ? `lat=${lat}` : '',
    lng ? `lng=${lng}` : '',
  ]);
  return http
    .get(`v1/organization/{{organizationId}}/parents/location/${query}`)
    .then((res) => res.data);
};

export const fetchAccessibleParents = () => http.get('v1/organizations/{{organizationId}}/accessible-parents/').then((res) => res.data);
