import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, Typography, Upload } from 'antd';
import axios from 'axios';
import { API_URL } from 'constants/index';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { MenuContext } from '../../../context';

function UploadItemImage({ image, onChange }) {
  const { id: merchantId } = useParams();
  const { showMessage } = useContext(MenuContext);
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      showMessage({ type: 'error', content: 'You can only upload JPG/PNG file!' });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showMessage({ type: 'error', content: 'Image must smaller than 2MB!' });
    }
    if (isJpgOrPng && isLt2M) {
      const formData = new FormData();
      Object.entries({ image: file, restaurants: [merchantId] }).map(([key, val]) => {
        if (Array.isArray(val)) {
          val.map((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, val);
        }
      });
      setLoading(true);
      axios
        .post(`${API_URL}v1/restaurant-images/`, formData)
        .then((res) => {
          onChange('image', res.data);
          setLoading(false);
        })
        .catch((e) => {
          captureException(e);
          setLoading(false);
        });
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <Flex vertical align="center">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <Typography.Text type="secondary">Upload item picture</Typography.Text>
    </Flex>
  );
  return (
    <Upload
      style={{ marginTop: 8 }}
      name="image"
      listType="picture-card"
      maxCount={1}
      showUploadList={false}
      onRemove={(file) => {
        console.log('onRemove', file);
      }}
      beforeUpload={beforeUpload}
    >
      {image ? <img src={image} alt="avatar" style={{ width: '100%', maxHeight: '100px' }} /> : uploadButton}
    </Upload>
  );
}

export default UploadItemImage;
