import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledInputWrapper } from './styles';

const Textarea = forwardRef(
  (
    {
      label,
      onChange,
      type,
      leadingIcon,
      trailingIcon,
      classes,
      id,
      value,
      placeholder,
      labelClasses,
      inputClasses,
      message,
      onKeyDown,
      autofocus,
      ...props
    },
    ref,
  ) => (
    <StyledInputWrapper>
      <label className={`label ${labelClasses}`} htmlFor={id}>
        {label}
      </label>
      {leadingIcon || null}
      <textarea
        ref={ref}
        id={id}
        type={type}
        className={`input ${inputClasses}`}
        value={value}
        onKeyDown={onKeyDown}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        autoComplete="off"
        autoFocus={autofocus}
      />
      {message ? <div>{message}</div> : null}
      {trailingIcon || null}
    </StyledInputWrapper>
  ),
);

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  type: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.element,
  onKeyDown: PropTypes.func,
  autofocus: PropTypes.bool,
};

export default Textarea;
