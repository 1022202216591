import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function FormItemLabel({ children }) {
  return (
    <Text strong>{children}</Text>
  );
}

export default FormItemLabel;
