import { ShopOutlined } from '@ant-design/icons';
import { fetchMyOrganizations } from 'api/organizations';
import DebounceSelect from 'components/Select/DebounceSelect';
import { AdminContext } from 'constants/contexts';
import React, { useContext, useState } from 'react';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import { PartnerLogos } from './constants';
import { organizationSelector, organizationSelectorIcon, organizationSelectorIconContainer } from './styles';

function SelectOrganization({ collapsed, setCollapsed }) {
  const [loading, setLoading] = useState(true);
  const { organization, setOrganization } = useContext(AdminContext);

  const getOrganizations = (searchKey = '') => {
    setLoading(true);
    return fetchMyOrganizations(searchKey).then((res) => {
      const data = getData(res)?.results?.map((o) => ({
        value: o.id,
        label: o.name,
        ...o,
      }));
      if (organization && !data.some((d) => d.value === organization.id)) {
        data.push({
          value: organization.id,
          label: organization.name,
          ...organization,
        });
      }
      setLoading(false);
      return data;
    }).catch((e) => {
      setLoading(false);
      captureException(e);
    });
  };

  const handleChangeOrganization = (org, organizationData) => {
    setOrganization(organizationData);
  };

  return (
    <div style={organizationSelector}>
      <span style={organizationSelectorIconContainer(collapsed)}>
        {PartnerLogos[organization?.id] ? (
          <img
            src={PartnerLogos[organization.id]}
            onClick={() => collapsed && setCollapsed(false)}
            alt="Logo"
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
            }}
          />
        ) : (
          <ShopOutlined onClick={() => collapsed && setCollapsed(false)} style={organizationSelectorIcon} />
        )}

      </span>
      <DebounceSelect
        id="organizations"
        labelInValue
        placeholder="Select an organization"
        fetchOptions={getOrganizations}
        onChange={handleChangeOrganization}
        value={!loading && organization?.id}
        showSearch
        loading={loading}
        className={collapsed ? 'hidden' : 'w-full flex-1 organization-selector'}
        bordered={false}
      />
    </div>
  );
}
export default SelectOrganization;
