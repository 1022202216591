import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 50%;

  @media (max-width: 1350px) {
    width: 100%;
  }

  .review {
    &-box {
      margin-top: 32px;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #1a1f36;
      margin-left: 24px;

      @media (max-width: 1350px) {
        margin-left: 0;
      }
    }

    &-nps {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-left: 6px;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;
    }

    &-table {
      border-top: 1px solid #d8dce2;
      border-bottom: 1px solid #d8dce2;
      height: 248px;
      padding-top: 10px;
      overflow: auto;
    }

    &-table-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 9px 10px 9px 24px;
      color: ${Colors.textPrimary1};
      width: 358px;

      @media (max-width: 1350px) {
        padding-left: 10px;
      }
    }

    &-time {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
      padding: 9px 10px 9px;
      white-space: nowrap;
      /* width: 131px; */
    }
  }

  .tooltip {
    display: none;
    width: 252px;
    height: fit-content !important;
    border-radius: 6px;
    background: #ffffff;
    border: 0.5px solid rgba(118, 118, 118, 0.28);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    opacity: 1;
    left: 35px;
    top: 30px;
    top: 28px;
    right: -7px;
    left: unset;
    transform: none;
    padding: 14px 12px 10px;
    animation: down-to-up 0.5s;

    &-box {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0.5px solid rgba(118, 118, 118, 0.28);
      position: absolute;
      top: 1px;
      left: 50%;
      transform: rotate(45deg) translateY(-4px) translateX(-50%);
      border-bottom: none;
      border-right: none;
      border-radius: 3px 0 0 0;
      background-color: white;
      left: 92%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-current {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
      margin: 0 0 7px;
    }

    &-previous {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6e7579;
    }

    &-bottom {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #4a5256;
      background: #f9f9fa;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 74px;
      left: 0;
      padding: 10px 12px;
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const StyledScore = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => (p.score < 8 && p.score > 4
    ? '#CC7A00'
    : p.score >= 8
      ? '#0E623F'
      : '#D53C2E')};
  background-color: ${(p) => (p.score < 8 && p.score > 4
    ? '#FFECD0'
    : p.score >= 8
      ? '#D4F3D2'
      : '#FCE9EB')};
  margin-left: 25px;
`;
