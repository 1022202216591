import { Checkbox, Col, Row } from 'antd';
import DebounceSelect from 'components/Select/DebounceSelect';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { fetchRestaurantTaxOption } from '../requests';

function AdditionalTax({ detail, onChange }) {
  const { id: merchantId } = useParams();
  const [hasTax, setHasTax] = useState(false);
  useEffect(() => {
    if (detail.tax && !hasTax) setHasTax(true);
  }, [detail.tax]);

  const getTaxOptions = (searchTerm = '') => fetchRestaurantTaxOption({ searchTerm, merchantId })
    .then((data) => {
      const fetchedMerchants = data.results?.map((m) => ({ label: m.title, value: m.id, key: m.id }));
      return fetchedMerchants;
    })
    .catch((e) => {
      captureException(e);
    });

  return (
    <Row gutter={8} align="middle">
      <Col xs={10} sm={7}>
        <Checkbox
          checked={hasTax}
          onChange={(e) => {
            setHasTax(e.target.checked);
            if (!e.target.checked) {
              onChange('tax', null);
            }
          }}
        >
          Additional Tax
        </Checkbox>
      </Col>
      {hasTax && (
        <Col xs={14} sm={11}>
          <DebounceSelect
            labelInValue={false}
            id="tax"
            allowClear
            placeholder="Select tax"
            fetchOptions={getTaxOptions}
            onChange={(value, option) => {
              onChange('tax', value);
            }}
            value={detail.tax}
            showSearch
            style={{ width: '100%' }}
          />
        </Col>
      )}
    </Row>
  );
}

export default AdditionalTax;
