import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  position: relative;

  .btn {
    &-style {
      font-family: "Manrope";
      position: relative;
      margin: 0 8px 0 0;
      background: #ffffff;
      border-radius: 5px;
      color: ${Colors.textPrimary1};
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      box-shadow: 0px 0px 0px 1px #d5d6d6;
      display: flex;
      align-items: center;
      height: 32px;
      padding: 6px 10px;
      border: 0.5px solid #d0d1d2;

      &--active {
        box-shadow: 0px 0px 0px 2px rgba(43, 100, 245, 0.4),
          0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05);
      }

      &:hover {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .clock {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    padding: 2.9px;

    &-box {
      display: flex;
      align-items: center;
    }

    &-times {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 1000;
      background-color: #ffffff;
      border-radius: 4px;
      cursor: initial;
      width: 276px;
      height: 228px;
      border: 0.5px solid #d0d1d2;
      padding: 12px 0;
      overflow: hidden;
    }

    &-titles {
      display: flex;
      flex-direction: row;
    }

    &-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #a1a4a5;
      width: 33%;
      height: 28px;
      padding: 8px 16px;
    }

    &-cols {
      display: flex;
      flex-direction: row;
    }

    &-time-col {
      width: 33%;
      overflow: auto;
      height: 189px;
      padding-bottom: 12px;
    }

    &-time-item {
      width: 76px;
      height: 36px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background: #f3f4f4;
      }
    }
  }

  .active-time {
    background: #f3f4f4;
  }
`;
