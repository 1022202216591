import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  .ant-radio-wrapper{
    width: 100%;
  }
  .ant-checkbox-wrapper span:nth-child(even), .ant-radio-wrapper span:nth-child(even){
    flex: 1;
  }
  .top {
    height: 60px;
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.textPrimary1};
    border-bottom: 1px solid #dde1e4;
    height: 56px;
    padding: 16px 20px;
  }

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #dde1e4;
    display: flex;
  }

  .reject-btn {
    background: #ffffff;
    /* Button/Neutral/Normal */
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;
    margin-right: 12px;
  }

  .confirm-btn {
    background: #02862f;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #02862f,
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }

  .tip-text {
    display: flex;
    justify-content: space-between;
  }

  .section {
    border-bottom: 1px solid #dde1e4;
    background: #f5f8f7;
    padding: 16px 20px;

    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #131b1f;
      margin-bottom: 15px;
    }
  }

  .wrapper {
    padding: 0 !important;
  }

  .t-list-item-custom {
    margin-left: 0;
  }

  .item-m-bottom {
    height: 60px;
    display: flex;
    position: sticky;
    bottom: 0;
    background: white;
    width: 100%;
    justify-content: space-between;
    padding: 16px 20px;
  }
`;
