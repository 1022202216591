import { Flex } from 'antd';
import React from 'react';
import ModifierActions from './ModifierActions';
import ModifiersTable from './ModifiersTable';

function ModifierOverview() {
  return (
    <Flex vertical gap="middle">
      <ModifierActions />
      <ModifiersTable />
    </Flex>
  );
}

export default ModifierOverview;
