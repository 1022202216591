export const getCouponTypeDisplayTitle = (type) => {
  switch (type) {
  case 0:
    return 'Percentage';

    break;
  case 1:
    return 'Fixed Amount';

    break;
  default:
    return 'Percentage';
    break;
  }
};
