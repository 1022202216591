import { Col, Form } from 'antd';
import React from 'react';
import FormItem from 'components/FormItem';
import SelectLocation from './SelectLocation';
import SelectRole from './SelectRole';
import SelectUser from './SelectUser';
import SelectMerchants from './SelectMerchants';

function FormSection({ isEditMode }) {
  const userRoles = Form.useWatch('userRoles') || [];

  return (
    <Col xs={24} sm={12} className="modal-section-container section-divider">
      {!isEditMode && <SelectUser />}
      <SelectRole />
      {
        userRoles.filter((r) => r !== 'Restaurant Admin').length > 0 && (
          <SelectLocation />
        )
      }
      <FormItem
        name="restaurants_administrated"
        label="Select merchants"
        hidden={userRoles.length !== 1 || userRoles?.[0] !== 'Restaurant Admin'}
      >
        <SelectMerchants />
      </FormItem>

    </Col>
  );
}

export default FormSection;
