import {
  Flex, Input, Space, Switch, Typography,
} from 'antd';
import React from 'react';

function GeneralInfo({ detail, onChangeDetail }) {
  return (
    <>
      <Flex vertical>
        <Typography.Title level={5}>Name</Typography.Title>
        <Input
          placeholder="Modifier name"
          value={detail.title}
          onChange={(e) => onChangeDetail('title', e.target.value)}
        />
      </Flex>

      <Space
        style={{
          background: '#E6F3EB', borderRadius: 5, padding: 16, marginTop: 16,
        }}
        direction="vertical"
      >
        <Flex justify="space-between" align="center">
          <Typography.Text strong>Mark as available</Typography.Text>
          <Switch
            defaultChecked
            checked={detail.isActive}
            onChange={(value) => onChangeDetail('isActive', value)}
            style={{ marginRight: 8 }}
          />
        </Flex>

        <Typography.Text>
          Customers can view modifier and select its available options on all items that use it.
        </Typography.Text>
      </Space>
    </>
  );
}

export default GeneralInfo;
