import { Table, Typography } from 'antd';
import SimpleModal from 'components/SimpleModal';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { captureException } from 'utils/errors';
import { changeModifierAvailability, deleteModifier } from './requests';
import { ModalMode } from '../../constants';
import { ModifierContext } from '../../context';
import ModifierModal from '../ModifierModal';
import { getFilteredModifiers } from '../../utils';
import columns from './columns';

export const ConfirmMode = {
  Delete: 'delete',
  Deactivate: 'deactivate',
  CLOSED: 'closed',
};

function ModifiersTable() {
  const {
    modifiers: allModifiers, modifiersLoading, setModifiersLoading, searchTerm, fetchModifiers, showMessage,
  } = useContext(ModifierContext);
  const [pagination, setPagination] = useState({ current: 1, total: 0 });
  const [modifiers, setModifiers] = useState([]);
  const [selectedModifier, setSelectedModifier] = useState();
  const [mode, setMode] = useState(ModalMode.CLOSED);
  const [confirmMode, setConfirmMode] = useState(ConfirmMode.CLOSED);

  useEffect(() => {
    setPagination({ current: 1, total: modifiers.length });
  }, [modifiers]);
  const handlePagination = ({ current, total }) => {
    setPagination({ current, total });
  };

  useEffect(() => {
    setModifiers(getFilteredModifiers({ searchTerm, modifiers: allModifiers }));
  }, [searchTerm, allModifiers]);
  const onEditCategory = (e, record) => {
    e.preventDefault();
    setSelectedModifier(record);
    setMode(ModalMode.EDIT);
  };
  const onRemoveCategory = (e, record) => {
    e.preventDefault();
    setSelectedModifier(record);
    setConfirmMode(ConfirmMode.Delete);
  };
  const onChangeAvailability = (record) => {
    setSelectedModifier(record);
    setConfirmMode(ConfirmMode.Deactivate);
  };

  const tableColumns = useMemo(() => columns({
    onChangeAvailability,
    onEditCategory,
    onRemoveCategory,
  }), []);

  const onConfirm = () => {
    setModifiersLoading(true);
    if (confirmMode === ConfirmMode.Delete) {
      deleteModifier(selectedModifier?.id).then((res) => {
        setSelectedModifier();
        fetchModifiers();
        setConfirmMode(ConfirmMode.CLOSED);
      })
        .catch((e) => {
          captureException(e);
          showMessage({ type: 'error' });
        })
        .finally(() => setModifiersLoading(false));
    } else {
      changeModifierAvailability({ modifierId: selectedModifier?.id, modifier: { is_active: !selectedModifier.is_active } })
        .then((res) => {
          setSelectedModifier();
          fetchModifiers();
          setConfirmMode(ConfirmMode.CLOSED);
        })
        .catch((e) => {
          captureException(e);
          showMessage({ type: 'error' });
        })
        .finally(() => setModifiersLoading(false));
    }
  };
  return (
    <>
      {!!modifiers.length && (
        <Table
          columns={tableColumns}
          dataSource={modifiers}
          loading={modifiersLoading}
          pagination={{
            total: pagination.total,
            showTotal: (total) => `${total} results`,
            size: 'small',
            showSizeChanger: false,
            current: pagination.current,
            defaultPageSize: 10,
          }}
          onChange={handlePagination}
          scroll={{ x: 400 }}
        />
      )}
      <ModifierModal mode={mode} setMode={setMode} modifier={selectedModifier} />

      <SimpleModal
        title={confirmMode === ConfirmMode.Delete ? `Removing "${selectedModifier?.title}"` : 'Deactivating nested modifier'}
        open={confirmMode !== ConfirmMode.CLOSED}
        setOpen={() => setConfirmMode(ConfirmMode.CLOSED)}
        submitProps={{ danger: true }}
        onSubmit={onConfirm}
      >
        <Typography.Text>
          {confirmMode === ConfirmMode.Delete ? `By confirming this modal the selected modifier (${selectedModifier?.title}) will be removed.`
            :
            'This modifier is intricately linked to other modifiers, and by disabling it, the modifier will be excluded from the available options in the modifier list.'}
        </Typography.Text>
      </SimpleModal>
    </>
  );
}

export default ModifiersTable;
