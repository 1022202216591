import {
  Divider, Flex, Input, Space, Switch, Typography,
} from 'antd';
import React from 'react';
import ActiveHours from './ActiveHours';

function MenuForm({ detail, setDetail }) {
  const onChangeDetail = (key, value) => {
    setDetail({ ...detail, [key]: value });
  };

  return (
    <Flex vertical>
      <Flex vertical>
        <Typography.Title level={5}>Name</Typography.Title>
        <Input placeholder="Menu name" value={detail.name} onChange={(e) => onChangeDetail('name', e.target.value)} />
      </Flex>

      <Space
        style={{
          background: '#E6F3EB', borderRadius: 5, padding: 16, marginTop: 16,
        }}
        direction="vertical"
      >
        <Flex justify="space-between" align="center">
          <Typography.Text strong>Mark as available</Typography.Text>
          <Switch
            defaultChecked
            checked={detail.isActive}
            onChange={(value) => onChangeDetail('isActive', value)}
            style={{ marginRight: 8 }}
          />
        </Flex>

        <Typography.Text>
          Customers can view menu and select its available items.
        </Typography.Text>
      </Space>

      <Divider />

      <ActiveHours detail={detail} onChangeDetail={onChangeDetail} />
    </Flex>
  );
}

export default MenuForm;
