import { ReactComponent as NoItemIcon } from 'assets/image/noItem.svg';
import React, { useState, useEffect } from 'react';
import SkeletonLoading from '../SkeletonLoading';
import { StyledPaginate } from './styles';
import Checkbox from '../Checkbox';

function PaginateTable({
  headers,
  dataArray,
  onViewAllClick,
  rowCreators,
  detailed,
  rangeEl,
  viewMoreText,
  loading,
  dataPaging,
  getData,
  pagination,
  onLoadMore,
  loadMoreText,
  hideResults,
  noRecord,
  onClick,
  newRowClasses,
  subRow,
  onSelectChange,
  tableStyle,
  ...props
}) {
  const [selectionStatuses, setSelectionStatuses] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const hasRowSelection = !!onSelectChange;

  useEffect(() => {
    setSelectionStatuses(dataArray.map((_) => false));
  }, [dataArray]);

  useEffect(() => {
    if (dataArray.length > 0) {
      const numSelectedRows = selectionStatuses.reduce((total, curr) => total + (+curr), 0);
      setAllSelected(numSelectedRows === dataArray.length);
    }
  }, [selectionStatuses]);

  const changeStatuses = (selections) => {
    setSelectionStatuses(selections);
    onSelectChange(selections);
  };
  return (
    <StyledPaginate>
      <table style={tableStyle}>
        <tbody>
          <tr className={rangeEl && 'no-border'} style={{ textAlign: 'left' }}>
            {hasRowSelection && (
              <th
                key={-1}
                className={rangeEl && 'th-range'}
                style={{ width: '40px' }}
              >
                <div>
                  <Checkbox
                    key={dataArray.length + 1}
                    id={dataArray.length + 1}
                    checked={allSelected}
                    onChange={() => {
                      setAllSelected(!allSelected);
                      if (allSelected) {
                        changeStatuses(Array(selectionStatuses.length).fill(false));
                      } else {
                        changeStatuses(Array(selectionStatuses.length).fill(true));
                      }
                    }}
                    label=""
                    labelClasses="item-label"
                    classes="item-checkbox"
                  />
                </div>
              </th>
            )}
            {headers.map((header, index) => (
              <th
                key={index}
                className={rangeEl && 'th-range'}
                style={{ width: header.width, ...(header.style || {}) }}
              >
                {header.title}
              </th>
            ))}
            {rangeEl}
          </tr>
          {dataArray?.length === 0
            ? null
            : dataArray?.map((row, rowIndex) => (
              <>
                <tr
                  key={rowIndex}
                  className={
                    newRowClasses ? (row?.new ? newRowClasses : null) : null
                  }
                  onClick={() => (onClick ? onClick(row) : null)}
                >
                  {onSelectChange && (
                    <td>
                      <Checkbox
                        key={row.id}
                        id={row.id}
                        checked={selectionStatuses[rowIndex]}
                        onChange={() => {
                          const selectionsCopy = [...selectionStatuses];
                          selectionsCopy[rowIndex] = !selectionsCopy[rowIndex];
                          changeStatuses(selectionsCopy);
                        }}
                        label=""
                        labelClasses="item-label"
                        classes="item-checkbox"
                      />
                    </td>
                  )}
                  {rowCreators.map((rowCreator, index) => (
                    <td key={index} style={{ verticalAlign: 'middle' }}>
                      {loading && !row?.id ? (
                        <SkeletonLoading
                          borderRadius="2px"
                          width="70%"
                          height="22px"
                          count={1}
                        />
                      ) : (
                        rowCreator(row, rowIndex)
                      )}
                    </td>
                  ))}
                </tr>
                {subRow && subRow(row, rowIndex) ? (
                  <tr>
                    <td colSpan="5" style={{ padding: 0 }}>
                      {subRow(row, rowIndex)}
                    </td>
                  </tr>
                ) : null}
              </>
            ))}
        </tbody>
      </table>
      {dataArray?.length === 0 ? (
        <div className="no-item">
          <NoItemIcon />
          <p>
            {noRecord || 'There are no records to display for the selected date.'}
          </p>
        </div>
      ) : null}
      <div className={`table-footer ${detailed ? 'detailed-footer' : ''}`}>
        {hideResults ? null : loading ? (
          <p className="results">
            <SkeletonLoading
              borderRadius="3px"
              width="100px"
              height="18px"
              count={1}
            />
          </p>
        ) : dataArray?.length > 0 ? (
          <p className="results">
            <strong>{dataPaging?.count}</strong>
            {' '}
&nbsp; results
          </p>
        ) : null}

        {loadMoreText ? (
          <p className="view-all center" onClick={onLoadMore}>
            {loading && dataArray?.length && dataArray[0]?.id ? (
              <span>Loading</span>
            ) : (
              loadMoreText
            )}
          </p>
        ) : null}

        {detailed && <div id="page">{pagination}</div>}
      </div>
    </StyledPaginate>
  );
}

export default PaginateTable;
