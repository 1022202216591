export const getFilteredModifiers = ({ searchTerm, modifiers }) => {
  const searchTerms = searchTerm?.split(' ').filter((s) => s);
  if (!searchTerm || !searchTerms.length) {
    return modifiers;
  }
  const filteredModifiers = JSON.parse(JSON.stringify(modifiers))
    .filter(({ title }) => title.toLowerCase().includes(searchTerm));
  if (filteredModifiers.length > 0) return filteredModifiers;

  return JSON.parse(JSON.stringify(modifiers))
    .filter(({ title }) => searchTerms.some((term) => title.toLowerCase().includes(term)));
};

export const getNormalizedFilteredModifiers = ({ searchTerm, modifiers, excludeNested }) => getFilteredModifiers({ searchTerm, modifiers })
  .filter((m) => (excludeNested ? m.options_parents?.length === 0 : true))
  .map((mod) => ({
    key: mod.id,
    id: mod.id,
    value: mod.title,
    title: mod.title,
    modifiers_children: mod.options,
  }));
