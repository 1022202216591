const KEY_PREFIX = '@strg';

export const LocalJsonStorage = jsonStorageFactory(localStorage);
export const SessionJsonStorage = jsonStorageFactory(sessionStorage);

function jsonStorageFactory(storage = localStorage) {
  Object.keys(storage).forEach((key) => {
    if (!key.startsWith(KEY_PREFIX)) {
      return;
    }
    const persistedValue = getParsedData(key);
    if (persistedValue === null || persistedValue?.value === null) {
      storage.removeItem(key);
    }
  });

  function getKey(key) {
    return `${KEY_PREFIX}/${key}`;
  }
  function set({ key, value, ttl }) {
    storage.setItem(
      getKey(key),
      JSON.stringify({
        value,
        expireAt: ttl ? Date.now() + ttl : null,
      }),
    );
  }

  function get(key) {
    try {
      const persistedValue = getParsedData(getKey(key));
      return persistedValue && persistedValue.value;
    } catch (e) {
      storage.removeItem(getKey(key));
      return null;
    }
  }
  function getParsedData(populatedKey) {
    const rawPersistedValue = storage.getItem(populatedKey);
    if (rawPersistedValue === null) {
      return null;
    }
    const persistedValue = JSON.parse(rawPersistedValue);
    if (
      persistedValue.expireAt && persistedValue.expireAt < Date.now()
    ) {
      return null;
    }
    return persistedValue;
  }

  return { get, set };
}
