import { Select } from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useEffect } from 'react';
import { fetchMenus } from './requests';

function Menus({ loading, setLoading }) {
  const {
    menus, setMenus, order, setSelectedMenu, selectedMenu, setMenuItems,
  } = useContext(CallInOrderContext);

  useEffect(() => {
    if (order.selectedMerchant?.value) getMenus();
  }, [order.selectedMerchant, order.scheduledFor]);

  const getMenus = () => {
    setLoading(true);
    fetchMenus({ merchantId: order.selectedMerchant?.value, scheduledTime: order.scheduledFor }).then((data) => {
      const fetchedMenu = data.results?.map((menu) => ({
        ...menu,
        value: menu.id,
        label: menu.name,
      }));
      setMenus(fetchedMenu);
      setSelectedMenu(fetchedMenu[0]);
      setMenuItems(fetchedMenu[0]?.categories?.sort((a, b) => a.id - b.id));
      setLoading(false);
    });
  };

  const onChange = (val) => {
    setSelectedMenu(menus.find((m) => m.id === val));
    setMenuItems(menus.find((m) => m.id === val)?.categories?.sort((a, b) => a.id - b.id));
  };

  return (
    <Select
      placeholder="Select a menu"
      className="pr-0 md:pr-1 mb-2 lg:mb-0"
      style={{ width: '100%' }}
      onChange={onChange}
      options={menus}
      value={selectedMenu}
      disabled={loading}
      loading={loading}
    />
  );
}

export default Menus;
