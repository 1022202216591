import http from 'utils/http';

export const createMenu = (menuDetail) => http.post('v1/menus/', menuDetail).then((res) => res.data);

export const editMenu = ({
  menuId,
  menuDetail,
}) => http.patch(`v1/menus/${menuId}/`, menuDetail).then((res) => res.data);

export const duplicateMenu = (menuId) => http.post(`v1/menus/${menuId}/duplicate/`).then((res) => res.data);
export const removeMenu = (menuId) => http.delete(`v1/menus/${menuId}/`).then((res) => res.data);
