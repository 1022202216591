import { Table } from 'antd';
import ColumnWithTooltip from 'containers/Reports/components/ColumnWithTooltip';
import { tableLocale } from 'containers/Reports/utils';
import React, { useMemo } from 'react';
import ReportTitle from '../components/ReportTitle';
import { getSumOfArrays, transformReportLabel } from '../utils';
import columns from './columns';

function OverallSummary({ reportData, loading }) {
  const sortingArray = ['order_counts_by_source', 'total_charged_to_customer', 'payment_processing_fees',
    'merchant_net', 'rds_totals', 'delivery_fees', 'tips', 'adjustments', 'taxes', 'credit_granted',
    'credit_used', 'coupon_used', 'average_order_value', 'average_subtotal'];
  const renameCategory = (prop) => {
    switch (prop) {
    case 'order_counts_by_source':
      return 'Order Totals';
    case 'merchant_net':
      return (
        <ColumnWithTooltip
          title="Merchant net"
          description="Payment processing fee has been deducted from this amount. This is the final value that merchants should expect to receive."
        />
      );
    case 'rds_totals':
      return (
        <ColumnWithTooltip
          title="RDS total"
          description="Payment processing fee has not been deducted from this amount. To calculate the final net amount please deduct payment processing fees (RDS) from this number."
        />
      );
    case 'delivery_fees':
      return 'Total delivery fees';
    case 'taxes':
      return 'Total taxes';
    case 'rds':
      return 'RDS';
    default:
      return transformReportLabel(prop);
    }
  };

  const getParentTotal = (reportItem) => {
    const subReports = [];
    Object.keys(reportItem).map((key) => subReports.push(reportItem[key]));
    return getSumOfArrays(subReports);
  };

  const getReportItem = (reportItem, key, parentLabel) => {
    if (Array.isArray(reportItem)) {
      return {
        key: `${parentLabel ? `${parentLabel}_` : ''}${key}`,
        category: renameCategory(key),
        breakfast: reportItem[0],
        lunch: reportItem[1],
        dinner: reportItem[2],
        total: reportItem[3],
        parent: parentLabel,
      };
    }
    if (typeof reportItem === 'number') {
      return {
        key: `${parentLabel ? `${parentLabel}_` : ''}${key}`,
        category: renameCategory(key),
        breakfast: '-',
        lunch: '-',
        dinner: '-',
        total: reportItem,
        parent: parentLabel,
        detail: reportData[`${key}_count`],
      };
    }
    const parentTotal = reportItem.totals || getParentTotal(reportItem);
    const children = Object.keys(reportItem).filter((k) => k !== 'totals').map((k) => getReportItem(reportItem[k], k, key));
    return {
      key,
      category: renameCategory(key),
      breakfast: parentTotal[0]?.toFixed(2),
      lunch: parentTotal[1]?.toFixed(2),
      dinner: parentTotal[2]?.toFixed(2),
      total: parentTotal[3]?.toFixed(2),
      children,
    };
  };

  const convertedReportData = useMemo(() => (reportData ?
    Object.keys(reportData)?.map((key) => getReportItem(reportData[key], key))
      .filter((item) => sortingArray.indexOf(item.key) !== -1)
      .sort((a, b) => sortingArray.indexOf(a.key) - sortingArray.indexOf(b.key))
    : []), [reportData]);

  const expandedRows = convertedReportData.filter((r) => r.children).map((r) => r.key);
  return (
    <>
      <ReportTitle
        title="Overall summary"
        description="Here you can see the overall information of how much your customers are charged during a specified time frame and the breakdown of the fees, taxes, merchant net, RDS net, driver net, etc."
      />

      <Table
        columns={columns}
        dataSource={convertedReportData}
        pagination={false}
        locale={tableLocale}
        expandable={{ defaultExpandAllRows: true, expandedRowKeys: expandedRows, expandIcon: () => null }}
        loading={loading}
        sticky
        scroll={{ x: 750 }}
      />
    </>
  );
}

export default OverallSummary;
