import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import { FORM_PERCENT_VALIDATION } from 'constants/form';
import FormItem from 'components/FormItem';
import React from 'react';

function ExtraCommissionInput() {
  return (
    <>
      <FormItem
        name="extra_commission_amount"
        label="Minimum commission payout amount"
        tooltip={{
          title: 'Once the merchant\'s commissions reach this amount, we\'ll transfer it to you from his card. Keep in mind the Stripe charges per transaction, so set the amount accurately.',
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input
          type="number"
          prefix="$"
          placeholder="e.g. 15"
        />
      </FormItem>

      <FormItem
        name="extra_commission_percent"
        label="Extra commission"
        rules={[FORM_PERCENT_VALIDATION]}
      >
        <Input
          type="number"
          placeholder="e.g. 20"
          prefix="%"
        />
      </FormItem>
    </>
  );
}

export default ExtraCommissionInput;
