import { renderPercentageColumn } from 'containers/Reports/utils';

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: '',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
  },
  {
    title: '',
    dataIndex: 'percentage',
    key: 'percentage',
    width: '45%',
    render: renderPercentageColumn,
  },
];
export default columns;
