import { Button, Drawer, Flex } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { OptionMode } from './constants';
import { ModifierContext } from '../../context';
import { createModifier, duplicateModifier, editModifier } from './requests';
import { ModalMode } from '../../constants';
import ModifierForm from './ModifierForm';

const initialValue = {
  title: '',
  isActive: true,
  options: [],
  isMultipleSelection: false,
  minimum: null,
  maximum: null,
};

function ModifierModal({
  setMode, mode = ModalMode.CLOSED, modifier, onNestedCreated,
}) {
  const { id: merchantId } = useParams();
  const {
    showMessage, fetchModifiers, setLoading: setTableLoading, modifiers,
  } = useContext(ModifierContext);
  const [detail, setDetail] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDetail(mode === ModalMode.EDIT ? {
      ...modifier,
      isActive: modifier.is_active,
      minimum: modifier.min_selections,
      maximum: modifier.max_selections,
      isMultipleSelection: modifier.modifier_type == 2,
      options: modifier.options?.map((o) => ({ ...o, type: OptionMode.NOT_CHANGED })),
    } : initialValue);
  }, [modifier, mode]);
  const onClose = () => {
    setDetail(initialValue);
    setMode(ModalMode.CLOSED);
  };

  const onSubmit = () => {
    const options = detail.options.filter((o) => !(!o.id && o.type === OptionMode.DELETED))
      .map((option) => ({
        ...option,
        price: option.real_price?.toString() || null,
        modifiers_children: option.modifiers_children?.length ? [option.id || option.localId] : [],
      }));

    const data = {
      restaurant: Number(merchantId),
      title: detail.title,
      min_selections: detail.minimum,
      max_selections: detail.maximum,
      options_: options,
      modifier_type: 2,
      is_active: detail.isActive,
    };
    setLoading(true);
    (mode === ModalMode.NEW ? createModifier(data) : editModifier({
      modifierId: modifier.id,
      modifier: data,
    })).then((modifier) => {
      onNestedCreated && onNestedCreated(modifier);
      onClose();
      fetchModifiers();
      setLoading(false);
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
    }).finally(() => {
      setLoading(false);
      setTableLoading(false);
      onClose();
    });
  };

  const onDuplicate = () => {
    setLoading(true);
    setTableLoading(true);
    duplicateModifier(modifier.id).then((res) => {
      fetchModifiers();
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
    }).finally(() => {
      setLoading(false);
      setTableLoading(false);
      onClose();
    });
  };

  return (
    <Drawer
      title={`${mode === ModalMode.NEW ? 'Add' : 'Edit'} Modifier`}
      placement="right"
      width={575}
      onClose={onClose}
      open={mode !== ModalMode.CLOSED}
      footer={(
        <Flex justify="end" gap={8}>
          <Button loading={loading} onClick={onDuplicate}>Duplicate</Button>
          <Button loading={loading} type="primary" onClick={onSubmit}>Save</Button>
        </Flex>
      )}
    >
      <ModifierForm detail={detail} setDetail={setDetail} mode={mode} />
    </Drawer>
  );
}

export default ModifierModal;
