import { ShoppingCartOutlined } from '@ant-design/icons';
import {
  Col, List, Row, Space, Typography,
} from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import { getItemTotalPrice } from 'containers/CreateOrder/Form/OrderItems/MerchantItemsModal/ItemDetailModal/item';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import { priceTransformer } from 'utils';
import Quantity from '../Quantity';

function Cart() {
  const { order } = useContext(CallInOrderContext);
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        height: isMobile ? 250 : 450,
        overflow: 'auto',
        marginRight: -20,
        paddingRight: 10,
      }}
    >
      <List
        dataSource={order?.items}
        locale={{
          emptyText: <Space direction="vertical" style={{ marginTop: isMobile ? '25%' : '50%' }}>
            <ShoppingCartOutlined style={{ fontSize: 50 }} />
            <Typography.Text type="secondary">User’s cart is empty</Typography.Text>
          </Space>,
        }}
        renderItem={(item, index) => (
          <List.Item
            style={{
              display: 'flex',
              flex: 1,
              marginLeft: 12,
            }}
          >
            <Space
              direction="vertical"
              style={{ width: '100%' }}
              size="small"
            >
              <Row align="middle">
                <Col xs={24} lg={20}>
                  <Typography.Text>{item?.name}</Typography.Text>
                </Col>
                <Col xs={24} lg={4}>
                  <Typography.Text style={{ fontWeight: 700 }}>
                    {priceTransformer(getItemTotalPrice(item))}
                  </Typography.Text>
                </Col>
              </Row>
              <Space split="·" wrap size={2}>
                {item?.selection?.modifiers?.map((modifier) => (
                  <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {modifier?.qty > 1 ? `${modifier?.qty}x ` : ''}
                    {modifier?.title}
                    {' '}
                    ($
                    {modifier?.price}
                    )
                  </Typography.Text>
                ))}
              </Space>
              <Row>
                <Quantity item={item} index={index} />
              </Row>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
}

export default Cart;
