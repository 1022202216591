import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: fit-content;

  .input {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #d5d6d6, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05);
    border: none;
    min-height: 38px;
    height: 148px;
    width: 338px;
    padding: 10px 12px;

    &:focus,
    &:focus-visible {
      box-shadow: 0px 0px 0px 2px rgba(2, 134, 47, 0.4),
        0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05);
      outline: none;
    }

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #7d8284;
    }
  }
`;
