import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { captureException } from 'utils/errors';
import { MenuContext } from './context';
import { fetchMenusById, fetchMenusByMerchantId } from './requests';

function MenuProvider({ children }) {
  const { id: merchantId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState();
  const [menuDetail, setMenuDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    fetchMenus();
  }, [merchantId]);

  const fetchMenus = () => {
    setLoading(true);
    fetchMenusByMerchantId(merchantId).then((data) => {
      setMenus(data.results);
      if (data.results?.length) {
        setSelectedMenu(data.results[0].id);
      }
    }).catch((e) => {
      captureException(e);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (selectedMenu) fetchMenuDetail();
  }, [selectedMenu]);

  const fetchMenuDetail = () => {
    setItemsLoading(true);
    fetchMenusById(selectedMenu).then((data) => {
      setMenuDetail(data);
    }).catch((e) => {
      captureException(e);
    }).finally(() => {
      setItemsLoading(false);
    });
  };

  const showMessage = ({ type, content }) => {
    content = content || (type === 'error' && 'Something went wrong! please try again');
    if (!content) return;
    messageApi.open({ type, content });
  };
  const contextValue = useMemo(() => ({
    menus,
    setMenus,
    selectedMenu,
    setSelectedMenu,
    menuDetail,
    setMenuDetail,
    loading,
    setLoading,
    fetchMenus,
    fetchMenuDetail,
    itemsLoading,
    setItemsLoading,
    showMessage,
    searchTerm,
    setSearchTerm,
  }), [
    menus,
    setMenus,
    selectedMenu,
    setSelectedMenu,
    menuDetail,
    setMenuDetail,
    loading,
    setLoading,
    fetchMenus,
    fetchMenuDetail,
    itemsLoading,
    setItemsLoading,
    showMessage,
    searchTerm,
    setSearchTerm,
  ]);

  return (
    <MenuContext.Provider value={contextValue}>
      {children}
      {contextHolder}
    </MenuContext.Provider>
  );
}

export default MenuProvider;
