import React from 'react';

import {
  Col,
  Form as BaseForm, Input, Row, Select, Button, Space,
} from 'antd';
import { useNavigate } from 'react-router';
import { makeSelectOptions } from 'utils/input';
import AmountInput from 'components/AmountInput';
import MerchantInput from './MerchantInput';

function Form({ form, onSubmit, loading }) {
  const wrapperCol = {
    xs: 24,
    md: 12,
    lg: 8,
  };
  const navigate = useNavigate();
  return (
    <BaseForm
      name="basic"
      layout="vertical"
      autoComplete="off"
      form={form}
      onFinish={onSubmit}
      wrapperCol={wrapperCol}
      initialValues={{
        type: 'INITIAL',
        paymentHolder: 'ADMIN',
      }}
    >
      <BaseForm.Item
        label="Amount"
        name="amount"
        rules={[{ required: true }]}
      >
        <AmountInput className="w-full" />
      </BaseForm.Item>
      <BaseForm.Item
        label="Type"
        name="type"
        rules={[{ required: true }]}
      >
        <Select
          options={makeSelectOptions([
            'INITIAL',
            'ENGAGEMENT',
            'MANUAL_REFUND',
          ])}
        />
      </BaseForm.Item>
      <Row gutter={{ xs: 0, md: 10 }}>
        <Col {...wrapperCol}>
          <BaseForm.Item
            label="Payment Holder"
            name="paymentHolder"
            rules={[{ required: true }]}
            className="flex-grow-1"
            wrapperCol={{ sm: 24 }}
          >
            <Select
              style={{ width: '100%' }}
              options={makeSelectOptions([
                'ADMIN',
                'MERCHANT',
                'COURIER',
              ])}
              disabled
            />
          </BaseForm.Item>
        </Col>
        <Col {...wrapperCol}>
          <BaseForm.Item
            shouldUpdate={(prevValues, currentValues) => prevValues.paymentHolder !== currentValues.paymentHolder}
            className="flex-grow-1 mb-0"
            wrapperCol={{ sm: 24 }}
          >
            {({ getFieldValue }) => (getFieldValue('paymentHolder') === 'merchant' ? (
              <MerchantInput />
            ) : null)}
          </BaseForm.Item>
        </Col>
      </Row>
      <BaseForm.Item
        label="Description"
        name="description"
        rules={[
          { required: true },
        ]}
      >
        <Input.TextArea />
      </BaseForm.Item>
      <BaseForm.Item>
        <Space className="mt-3">
          <Button loading={loading} type="primary" htmlType="submit">Send Credit</Button>
          <Button disabled={loading} onClick={() => navigate(-1)}>Cancel</Button>
        </Space>
      </BaseForm.Item>

    </BaseForm>

  );
}

export default Form;
