import { Images } from 'assets/image';
import { StyledNav } from 'components/Layout/Navbar/styles';
import React from 'react';

function Navbar() {
  return (
    <StyledNav variant="light" expand="lg">
      <div style={{ margin: '24px auto' }}>
        <nav
          as={Navbar.Brand}
          href="#home"
          style={{ padding: '15px 0px', margin: 'auto' }}
        >
          <img src={Images.omniLogo} style={{ height: 50, paddingLeft: 0 }} />
          <strong
            style={{
              margin: '5px 0 0 10px',
              fontSize: '20px',
              color: '#727272',
            }}
          >
            (Admin)
          </strong>
        </nav>
      </div>
    </StyledNav>
  );
}

export default Navbar;
