import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  position: relative;

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title-row {
    padding: 21px 51px;
    margin-bottom: 4px;
    display: block;
    @media (max-width: 900px) {
      padding: 18px;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.textPrimary1} !important;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .back {
      color: #717679;
      width: 24px;
      height: 24px;
      margin-right: 3px;

      &-btn {
        padding: 0;
      }

      &-text {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #717679;
      }
    }
  }
  .order {
    &-row {
      margin: 12px 56px 20px;
      @media (max-width: 900px) {
        margin: 8px 18px;
      }
    }

    &-name {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1};
      margin-right: 12px;
    }
  }
  
  .box-header {
    margin-bottom: 20px;
  }

  .box-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131B1F;
    margin-bottom: 8px;
    display: block;
  }
  .box-title-condition {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #A7A9A9;
    margin-bottom: 12px;
  }

  .box-sub-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #666B6D;
  }
  .input-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #131B1F;
    margin-bottom: 10px;
    display: block;
  }
`;
