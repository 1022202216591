import { Typography } from 'antd';
import { fetchMerchantsListByEnability } from 'api/merchants';
import DebounceSelect from 'components/Select/DebounceSelect';
import React from 'react';
import { captureException } from 'utils/errors';

function SelectMerchants({ value = [], onChange }) {
  const getMerchants = (searchKey = '') => fetchMerchantsListByEnability(false, searchKey)
    .then((res) => {
      if (res.status === 'success') {
        const fetchedMerchants = res.data.results?.map((m) => ({
          label: m.name, value: m.id,
        }));
        return fetchedMerchants;
      }
    })
    .catch((e) => {
      captureException(e);
    });

  return (
    <DebounceSelect
      id="merchants"
      allowClear
      placeholder="Select merchants"
      fetchOptions={getMerchants}
      onChange={(value, option) => {
        onChange(value);
      }}
      value={value}
      showSearch
      size="large"
      mode="multiple"
    />
  );
}

export default SelectMerchants;
