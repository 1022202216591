import { Select } from 'antd';
import React from 'react';

const OPTIONS = [
  'Web', 'iOS', 'Android', 'Google', 'Direct Entry', 'DataDreamers', 'DoorDash', 'Grubhub', 'UberEats', 'Merchant Website',
].map((source) => ({ value: source, label: source }));
function SourceSelectInput({ value, onChange, ...props }) {
  return (
    <Select
      value={value}
      style={{ width: 200 }}
      onChange={onChange}
      options={OPTIONS}
      mode="multiple"
      placeholder="Sources"
      allowClear
      {...props}
    />

  );
}

export default SourceSelectInput;
