import {
  Checkbox, Col, Form, Input, Radio, Row,
} from 'antd';
import { OrderType } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useMemo, useState } from 'react';
import { SubStyledWrapper } from './styles';

function AdditionalDetails() {
  const { order, setOrderDetails } = useContext(CallInOrderContext);

  const [hasCustomDeliveryFee, setHasCustomDeliveryFee] = useState(false);
  const [hasAdjustment, setHasAdjustment] = useState(false);

  const tipOptions = useMemo(() => {
    if (!order.selectedMerchant?.merchant) return [5, 10, 15, 20];
    return order.selectedMerchant?.merchant?.parent?.tip_data?.tip_options || [5, 10, 15, 20];
  }, [order.selectedMerchant?.merchant]);

  return (
    <SubStyledWrapper>
      <div className="box">
        <h6 className="box-title">
          Additional details
          <span className="box-title-condition"> (optional)</span>
        </h6>
        <Form.Item name="customDeliveryFee">
          <Checkbox
            key={1}
            checked={hasCustomDeliveryFee}
            onChange={(e) => {
              setHasCustomDeliveryFee(e.target.checked);
              setOrderDetails({ deliveryFee: 0 });
            }}
          >
            Custom delivery fee
          </Checkbox>
          {' '}
          {hasCustomDeliveryFee && (
            <Col xs={24} sm={24} md={18} lg={6} xl={4}>
              <Input
                className="check-box-input"
                type="number"
                id="customDeliveryFee"
                name="delivery_fee"
                value={order.deliveryFee}
                onChange={(e) => {
                  setOrderDetails({ deliveryFee: e.target.value });
                }}
                inputClasses="price-input"
                suffix="$"
              />
            </Col>
          )}
        </Form.Item>
        <Form.Item name="adjustment">
          <Checkbox
            ke={1}
            checked={hasAdjustment}
            onChange={(e) => {
              setHasAdjustment(e.target.checked);
              setOrderDetails({ adjustmentAmount: 0 });
            }}
          >
            Add adjustment
          </Checkbox>
          {hasAdjustment && (
            <Col xs={24} sm={24} md={18} lg={6} xl={4}>
              <Input
                className="check-box-input"
                type="number"
                id="adjustment"
                name="adjustment_amount"
                value={order.adjustmentAmount}
                onChange={(e) => {
                  setOrderDetails({ adjustmentAmount: e.target.value });
                }}
                inputClasses="price-input"
                suffix="$"
              />
            </Col>
          )}
        </Form.Item>
        {order.orderType === OrderType.Delivery && (
          <Form.Item name="deliveryTip" wrapperCol={{ span: 24 }}>
            <Checkbox ke={1} checked onChange={(e) => undefined}>
              Delivery tip
            </Checkbox>
            <Row gutter={20} align="middle" style={{ marginTop: 16 }}>
              <Col>
                <Radio.Group
                  onChange={(e) => {
                    setOrderDetails({ tipPercentage: e.target.value, tip: 0 });
                  }}
                  name="tip_percentage"
                  value={order.tipPercentage}
                  className="flex flex-col md:flex-row gap-2 md:items-center"
                >
                  {tipOptions.map((tip) => (
                    <Radio value={tip} key={tip}>
                      {tip}
                      %
                    </Radio>
                  ))}
                  <Radio value={-1}>
                    {' '}
                    <Input
                      disabled={order.tipPercentage !== -1}
                      type="text"
                      id="tip"
                      name="tip"
                      value={order.tip}
                      onChange={(e) => {
                        setOrderDetails({ tip: e.target.value });
                      }}
                      inputClasses="price-input"
                      suffix="$"
                    />
                    {' '}
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>
        )}
        <Form.Item label="Delivery notes">
          <Col xs={24} lg={12}>
            <Input.TextArea
              name="delivery_message"
              placeholder="Delivery notes"
              onChange={(e) => {
                setOrderDetails({ deliveryMessage: e.target.value });
              }}
              showCount
              maxLength={100}
              rows={4}
            />
          </Col>
        </Form.Item>
      </div>
    </SubStyledWrapper>
  );
}

export default AdditionalDetails;
