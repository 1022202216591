import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';
import { UserManagementContext } from 'containers/AccountSettings/UserManagement/constants';
import { checkIsSubordinateRole, getRelatedRoles } from 'containers/AccountSettings/UserManagement/utils';
import { difference, dropWhile, find } from 'lodash-es';
import React, {
  useContext, useEffect, useMemo, useRef,
} from 'react';
import FormItemLabel from './FormItemLabel';
import { formSection } from './styles';

function SelectRole() {
  const { roles } = useContext(UserManagementContext);
  const form = Form.useFormInstance();
  const userRoles = Form.useWatch('userRoles');
  const editedModeRef = useRef(false);
  useEffect(() => {
    if (userRoles && !editedModeRef.current) {
      editedModeRef.current = true;
      onSelectRoles(userRoles);
    }
  }, [userRoles]);

  const mainRoles = useMemo(() => roles?.map((r) => ({
    key: r.name,
    value: r.name,
    label: r.name,
    disabled: checkIsSubordinateRole({ current: r.name, selectedRoles: userRoles, roles }),
  })), [roles, userRoles]);

  const onSelectRoles = (values) => {
    const diff = difference(userRoles, values);
    let allRoles = getRelatedRoles({ roles, selectedRoles: values });
    if (diff.length) {
      const diffSubRoles = find(roles, { name: diff[0] })?.included_roles;
      allRoles = dropWhile(allRoles, (o) => diffSubRoles?.includes(o));
    }
    form.setFieldsValue({ userRoles: allRoles });
  };
  return (
    <div style={formSection}>
      <Form.Item
        label={<FormItemLabel>Select user role</FormItemLabel>}
        tooltip={{ title: 'Choose roles that match new user responsibilities.', icon: <InfoCircleOutlined /> }}
        name="userRoles"
        rules={[
          {
            required: true,
            message: 'You must select at least one role.',
          },
        ]}
      >
        <Checkbox.Group options={mainRoles} style={{ flexDirection: 'column', gap: 6 }} onChange={onSelectRoles} />
      </Form.Item>
    </div>
  );
}

export default SelectRole;
