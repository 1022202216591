import { message } from 'antd';
import { resetPasswordConfirm } from 'api/auth';
import Layout from 'components/Layout';
import Navbar from 'components/Layout/Navbar';
import Loading from 'components/Loading';
import { AdminContext } from 'constants/contexts';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { captureException } from 'utils/errors';

function Login({ location, match }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState({});
  const { isLoggedIn } = useContext(AdminContext);

  const handleChange = ({ target: { value, name } }) => {
    setForm({ ...form, [name]: value });
  };

  const submit = () => {
    if (!form.new_password1 || !form.new_password2) return;
    setLoading(true);
    const { uid, token } = match.params;
    resetPasswordConfirm({ ...form, uid, token })
      .then((res) => {
        message.success('Password successfully changed!');
        setRedirect(true);
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setRedirect(isLoggedIn);
  }, [isLoggedIn]);

  const { from } = location.state || { from: { pathname: '/console' } };

  if (redirect === true) {
    return <Navigate to={from} />;
  }

  return (
    <Layout hasSidebar={false}>
      <Navbar />
      <div className="min-h-screen flex items-center justify-center">
        <div className="container mx-auto px-4">
          <div className="flex justify-center">
            <div className="w-full xl:w-4/5 lg:w-full md:w-3/4">
              <div className="bg-white rounded-lg shadow-lg my-5">
                <div className="p-3">
                  <div className="text-center">
                    <h3 className="text-xl font-bold text-gray-800 mb-2">
                      Confirm Reset Password
                    </h3>
                    <p className="text-gray-600">
                      Enter your new password
                    </p>
                  </div>

                  <div className="p-5">
                    <form onSubmit={submit}>
                      <div className="space-y-4">
                        <div className="max-w-md mx-auto">
                          <input
                            type="password"
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                            placeholder="New Password"
                            name="new_password1"
                            onChange={handleChange}
                            value={form.email}
                          />
                          {error.firstnameError && (
                            <p className="text-red-500 text-sm mt-1">*required</p>
                          )}
                        </div>

                        <div className="max-w-md mx-auto">
                          <input
                            type="password"
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                            placeholder="Confirm New Password"
                            name="new_password2"
                            onChange={handleChange}
                            value={form.email}
                          />
                          {error.firstnameError && (
                            <p className="text-red-500 text-sm mt-1">*required</p>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          onClick={submit}
                          className="bg-[#04B261] text-white font-bold py-2 px-6 rounded-lg min-w-[120px] hover:bg-[#039252] transition-colors"
                          type="button"
                        >
                          {!loading ? 'Reset Password' : <Loading noPadding />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
