import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext } from 'react';
import { Button } from 'antd';
import {
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { StyledWrapper } from './styles';

function Quantity({ item, index }) {
  const { order, setOrderDetails } = useContext(CallInOrderContext);
  const { qty: count } = item;

  const handleDeleteItem = () => {
    setOrderDetails({
      items: order?.items.filter(
        (_, selectedListIndex) => index !== selectedListIndex,
      ),
    });
  };
  const handleChangeCount = (qty) => {
    setOrderDetails({
      items: order?.items.map((orderItem) => {
        if (item?.id == orderItem.id) {
          orderItem.qty = qty;
        }
        return orderItem;
      }),
    });
  };
  return (
    <StyledWrapper>
      <Button
        icon={item.qty === 1 ? <DeleteOutlined /> : <MinusOutlined />}
        width="fit-content"
        onClick={() => {
          if (item.qty === 1) handleDeleteItem();
          else {
            handleChangeCount(count - 1);
          }
        }}
        style={{ borderRadius: '4px 0 0 4px' }}
      />
      <div
        style={{
          display: 'flex',
          borderTop: 'solid 1px #d9d9d9',
          borderBottom: 'solid 1px #d9d9d9',
          width: 36,
          height: 32,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span>{count}</span>
      </div>
      <Button
        icon={<PlusOutlined />}
        width="fit-content"
        onClick={() => handleChangeCount(count + 1)}
        style={{ borderRadius: '0 4px 4px 0' }}
      />
    </StyledWrapper>
  );
}

export default Quantity;
