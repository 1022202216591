import Layout from 'components/Layout';
import { AdminContext } from 'constants/contexts';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { getOrderDetailByOrderId } from '../../api/orders';
import { getData } from '../../utils';
import OrderForm from './Form';

function EditingOrder({ props }) {
  const [loading, setLoading] = useState(false);
  const [ordersDetail, setOrdersDetail] = useState(null);
  const { organizationId } = useContext(AdminContext);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getOrderDetailByOrderId(id)
        .then((res) => {
          setOrdersDetail(getData(res));
          setLoading(false);
        })
        .catch((e) => {
          captureException(e);
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <Layout header={(
      <Layout.Header
        title="Edit order"
        backTitle="Orders"
        backPath="/console/orders"
      />
    )}
    >
      <OrderForm
        data={ordersDetail}
        setData={setOrdersDetail}
        loading={loading}
        organizationId={organizationId}
        merchantId={id}
      />
    </Layout>
  );
}

export default withGuard(EditingOrder, [PERMISSIONS.ORDERS], { redirect: true });
