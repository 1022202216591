import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Select, Switch, Typography } from 'antd';
import SimpleModal from 'components/SimpleModal';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import useIsDesktop from 'hooks/useIsDesktop';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import http from 'utils/http';
import dayjs from 'dayjs';
import { filterTimesAfterNow, formatTime, isDateToday } from 'utils/date';

function ScheduledSection() {
  const isDesktop = useIsDesktop();
  const { order, setOrderDetails } = useContext(CallInOrderContext);
  const [loading, setLoading] = useState(false);
  const [hasScheduling, setHasScheduling] = useState(false);
  const [dates, setDates] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [tempSlot, setTempSlot] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    if (!order.scheduledFor && hasScheduling) {
      setHasScheduling(false);
      setSelectedDate(null);
      setSelectedTime(null);
    }
  }, [order.scheduledFor, order.selectedMerchant?.value]);
  useEffect(() => {
    if (!order.selectedMerchant?.value || !hasScheduling) {
      return;
    }
    setLoading(true);
    http
      .post(`v1/restaurants/${order.selectedMerchant.value}/schedule-times/`, {
        items: [],
      })
      .then(({ data: { data } }) => {
        setDates(
          Object.entries(data)
            .filter(([_, times]) => times.length > 0)
            .map(([date, times], index) => ({
              index,
              label: dayjs(date).format('ddd, DD MMM'),
              value: index,
              date,
              times,
            })),
        );
        setLoading(false);
      });
  }, [order.selectedMerchant?.value, hasScheduling]);

  const timeOptions = useMemo(() => {
    if (selectedDate === null || selectedDate === undefined) {
      return [];
    }
    const { times, date } = dates[selectedDate];
    const isToday = isDateToday(date);
    return times
      .reduce((all, d) => [...all, ...(isToday ? filterTimesAfterNow(d.slots) : d.slots)], [])
      .map((s, index) => ({
        value: index,
        label: `${formatTime(s.start)} - ${formatTime(s.end)}`,
        time: s,
      }));
  }, [selectedDate]);

  const handleSwitchScheduling = (value) => {
    if (order.items?.length) {
      setTempSlot({ hasScheduling: value });
      setShowConfirmModal(true);
    } else {
      setHasScheduling(value);
      if (!value) {
        setSelectedDate(null);
        setSelectedTime(null);
        setOrderDetails({ scheduledFor: '' });
      }
    }
  };

  const handleSelectDate = (value) => {
    if (order.items?.length) {
      setTempSlot({ date: value });
      setShowConfirmModal(true);
    } else {
      setSelectedDate(value);
      setSelectedTime(null);
    }
  };
  const handleSelectTime = (value) => {
    if (order.items?.length) {
      setTempSlot({ time: value });
      setShowConfirmModal(true);
    } else {
      setSelectedTime(value);
      setOrderDetails({ scheduledFor: formatScheduledData({ timeIndex: value, dateIndex: selectedDate }) });
    }
  };

  const formatScheduledData = ({ timeIndex, dateIndex }) => {
    if (dateIndex !== null && timeIndex !== null) {
      const { date } = dates[dateIndex];
      const selectedTimeSlot = timeOptions[timeIndex];
      return `${date}T${selectedTimeSlot?.time?.start}`;
    }
    return '';
  };

  const onChangeConfirm = () => {
    if (!tempSlot) {
      return;
    }

    let changedScheduled = formatScheduledData({
      timeIndex: tempSlot.time || selectedTime,
      dateIndex: tempSlot.date || selectedDate,
    });

    if (tempSlot.hasOwnProperty('hasScheduling')) {
      setHasScheduling(tempSlot.hasScheduling);
      if (!tempSlot.hasScheduling) {
        setSelectedDate(null);
        setSelectedTime(null);
        changedScheduled = '';
      }
    } else if (tempSlot.time) {
      setSelectedTime(tempSlot.time);
    } else {
      setSelectedDate(tempSlot.date);
      setSelectedTime(null);
    }

    setOrderDetails({ items: [], scheduledFor: changedScheduled });
  };

  return (
    <div
      className="flex flex-col lg:flex-row justify-center items-start lg:items-center mx-0 lg:mx-2 mt-2 lg:my-0 gap-2 lg:gap-4"
      style={{
        backgroundColor: '#E8F3EB',
        padding: '3px 4px',
        border: '1px solid #e3e7ea',
        borderRadius: 4,
        minHeight: 40,
        width: !hasScheduling ? 'fit-content' : (isDesktop ? '90%' : '100%'),
      }}
    >
      <div>
        <Switch checked={hasScheduling} onChange={handleSwitchScheduling} />
        <Typography.Text className="ml-1 mr-6">
          Scheduled ordering
        </Typography.Text>
      </div>
      {hasScheduling && (
        <>
          <Typography.Text>Date:</Typography.Text>
          <Select
            disabled={loading}
            style={{ width: isDesktop ? '20%' : '100%' }}
            className="row-right-margin"
            name="scheduled_for_date"
            suffixIcon={<CalendarOutlined />}
            loading={loading}
            value={selectedDate}
            onChange={handleSelectDate}
            options={dates}
            allowClear
          />
          <Typography.Text>Time:</Typography.Text>
          <Select
            disabled={loading}
            style={{ width: isDesktop ? '30%' : '100%' }}
            name="scheduled_for"
            className="row-right-margin"
            suffixIcon={<ClockCircleOutlined />}
            loading={loading}
            value={selectedTime}
            onChange={handleSelectTime}
            options={timeOptions}
            allowClear
          />
        </>
      )}

      <SimpleModal
        title="Confirm action"
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onSubmit={onChangeConfirm}
      >
        <Typography.Text>
          Upon confirming this action, the selected items will be deleted, and you should choose items from the new
          menu.
        </Typography.Text>
      </SimpleModal>
    </div>
  );
}

export default ScheduledSection;
