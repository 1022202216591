import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { UserManagementContext } from 'containers/AccountSettings/UserManagement/constants';
import React, { useContext, useState } from 'react';
import RemoveUserModal from './RemoveUserModal';

function TableAction({ data, onRemove }) {
  const { handleOpenPermissionForm } = useContext(UserManagementContext);
  const [open, setOpen] = useState(false);

  const items = [
    {
      label: <div onClick={() => handleOpenPermissionForm(data)}>Edit</div>,
      key: '0',
    },
    {
      label: <div onClick={() => setOpen(true)} style={{ color: 'red' }}>Remove</div>,
      key: '1',
    }].slice(data.is_active ? 0 : 1, 2);

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight" arrow>
        <Button type="text">
          <EllipsisOutlined style={{ fontSize: 20 }} />
        </Button>
      </Dropdown>

      <RemoveUserModal open={open} setOpen={setOpen} data={data} onRemove={onRemove} />
    </>
  );
}
export default TableAction;
