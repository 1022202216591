import { Descriptions, Row, Skeleton } from 'antd';
import React from 'react';
import { formatAmount } from 'utils';

function PaymentDetail({ order, loading }) {
  if (!order || loading) {
    return <Skeleton paragraph={{ rows: 8 }} />;
  }

  function renderFinancialInfo() {
    const fields = [
      ['Total', formatAmount(order.value)],
      ['Subtotal', formatAmount(order.food_value)],
      ['Merchant Tip', formatAmount(order.order_nets?.restaurant_tip_amount)],
      ['Merchant Net', formatAmount(order.restaurant_net)],
      ['Delivery Fee', formatAmount(order.delivery_fare)],
      ['Driver tip', formatAmount(order.order_nets?.rideshare_tip_amount)],
      ['Service Fee', formatAmount(order.service_fee)],
      [
        'Coupon',
        order.coupon_amount != null ? formatAmount(order.coupon_amount) : '-',
      ],
      ['Adjustment Amount', formatAmount(order.adjustment_amount)],
      [
        'Payment Fee',
        formatAmount(order.order_nets?.stripe || order.order_nets?.moneris),
      ],
    ];

    return (
      <Row className="items-center mt-4">
        {renderItems()}
      </Row>
    );

    function renderItems() {
      return (
        <Descriptions
          bordered
          size="small"
          column={1}
          contentStyle={{ whiteSpace: 'nowrap' }}
          className="overflow-auto"
        >
          {
            fields.map(([title, value]) => (
              <Descriptions.Item key={title} label={title} className="min-w-[150px]">
                {value}
              </Descriptions.Item>
            ))
          }
        </Descriptions>
      );
    }
  }

  return renderFinancialInfo();
}
export default PaymentDetail;
