import React from 'react';
import { useNavigate } from 'react-router';
import http from 'utils/http';
import DrawerForm from './DrawerForm';

function CreateForm({ open, onClose }) {
  const navigate = useNavigate();

  async function handleCreate(data) {
    http.post('/v1/organizations/{{organizationId}}/companies/', data)
      .then((result) => {
        navigate(`/console/companies/${result.data.id}/info`)
        onClose(true);
      })
  }

  return (
    <DrawerForm
      open={open}
      title="Add company"
      onSubmit={handleCreate}
      onClose={onClose}
    />
  );
}

export default CreateForm;
