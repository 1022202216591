import { Checkbox, Flex, Typography } from 'antd';
import React, { useState } from 'react';

function Togglable({
  title,
  children,
  defaultVisible = false,
}) {
  const [visible, setVisible] = useState(defaultVisible);
  return (
    <Flex vertical gap={10}>
      <Checkbox checked={visible} onChange={(e) => setVisible(e.target.checked)}>{title}</Checkbox>
      <div className="ml-5" style={{ display: visible ? 'block' : 'none' }}>
        {children}
      </div>
    </Flex>
  );
}

export default Togglable;
