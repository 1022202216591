import { Typography } from 'antd';
import SimpleModal from 'components/SimpleModal';
import React from 'react';

function ChangeStatusConfirmation(
  {
    open, setOpen, onConfirmChange, state, nextState, onCancel,
  },
) {
  return (
    <SimpleModal
      title="Confirm Order Status Change"
      open={open}
      setOpen={setOpen}
      onSubmit={onConfirmChange}
      onCancel={onCancel}
    >
      <Typography.Text>
        Are you sure you want to change the order status from
        {' '}
        <b>{state}</b>
        {' '}
        to
        {' '}
        <b>{nextState}</b>
        ?
      </Typography.Text>
    </SimpleModal>
  );
}

export default ChangeStatusConfirmation;
