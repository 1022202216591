import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';

function StripeForm({
  onClose, onSubmit, loading, title = 'Enter the card that you want to use when placing an order with',
}) {
  const [cardIsFilled, setCardIsFilled] = useState(false);

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: 8 }}>
      <Typography.Text>
        {title}
      </Typography.Text>

      <PaymentElement onChange={(e) => setCardIsFilled(e.complete)} onLoadError={captureException} />

      <Button
        block
        disabled={!cardIsFilled || loading}
        type="primary"
        size="large"
        onClick={onSubmit}
        style={{ marginTop: 12, marginBottom: 8 }}
      >
        Add card
      </Button>
      <Button
        block
        disabled={loading}
        type="default"
        size="large"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Space>
  );
}

export default StripeForm;
