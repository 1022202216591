import dayjs from 'dayjs';
import { ModalMode } from '../../../constants';

const weekDays = [
  { key: 'MON', title: 'Monday' },
  { key: 'TUE', title: 'Tuesday' },
  { key: 'WED', title: 'Wednesday' },
  { key: 'THU', title: 'Thursday' },
  { key: 'FRI', title: 'Friday' },
  { key: 'SAT', title: 'Saturday' },
  { key: 'SUN', title: 'Sunday' },
].map((d) => ({
  ...d,
  isActive: false,
  activeTimes: [{ from: '', to: '' }],
}));

export const getWorkingHoursByDay = ({ weekday, details }) => {
  let activeHours = [];
  const currentHours = details?.filter((a) => a.weekday == weekday)
    ?.sort(({ from_hour: a }, { from_hour: b }) => dayjs(a, 'HH:mm:ss').valueOf() - dayjs(b, 'HH:mm:ss').valueOf());

  if (currentHours?.length) {
    activeHours = currentHours.map((h) => {
      const durationSlots = h.duration.split(':');
      return {
        from: dayjs(h.from_hour, 'HH:mm:ss'),
        to: dayjs(h.from_hour, 'HH:mm:ss')
          .add(durationSlots[0], 'hour')
          .add(durationSlots[1], 'minute')
          .add(durationSlots[2], 'second'),
      };
    });
  }
  return activeHours;
};

export const formatWorkingHours = ({ menuDetail, mode }) => {
  const workingDays = JSON.parse(JSON.stringify(weekDays));
  if (mode === ModalMode.EDIT) {
    workingDays.forEach((d) => {
      const currentHours = menuDetail.active_hours?.filter((a) => a.weekday == d.key)
        ?.sort(({ from_hour: a }, { from_hour: b }) => dayjs(a, 'HH:mm:ss').valueOf() - dayjs(b, 'HH:mm:ss').valueOf());
      if (currentHours?.length) {
        d.activeTimes = currentHours.map((h) => {
          const durationSlots = h.duration.split(':');
          return {
            from: dayjs(h.from_hour, 'HH:mm:ss'),
            to: dayjs(h.from_hour, 'HH:mm:ss')
              .add(durationSlots[0], 'hour')
              .add(durationSlots[1], 'minute')
              .add(durationSlots[2], 'second'),
          };
        });
        d.isActive = true;
      }
    });
  }
  return workingDays;
};

const getDurationTime = (start, end) => {
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const seconds = dayjs(end, 'HH:mm:ss').diff(dayjs(start, 'HH:mm:ss'), 'seconds');
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return `${padTo2Digits(hours % 24)}:${padTo2Digits(minutes % 60)}:${padTo2Digits(seconds % 60)}`;
};

export const formatPostWorkingHours = (workingDays) => {
  const formattedHours = [];
  workingDays.filter((d) => !d.isClosed).forEach((workingDay) => {
    workingDay.activeTimes?.forEach((time) => {
      if (time.from) {
        formattedHours.push({
          weekday_name: workingDay.key,
          from_hour: time.from.format('HH:mm:ss'),
          duration: getDurationTime(time.from, time.to),
        });
      }
    });
  });
  return formattedHours;
};
