import React from 'react';
import { Input } from 'antd';

function CentInput({ value, onChange, ...props }) {
  function handleChange({ target: { value: v } }) {
    onChange(v ? v * 100 : null);
  }
  return <Input prefix="$" type="number" {...props} onChange={handleChange} value={ (value || value === 0) ? (value / 100) : undefined } />;
}

export default CentInput;
