import React from 'react';
import { Form, Input, Select } from 'antd';
import FormItem from 'components/FormItem';

function PhoneFields() {
  const supportLine = Form.useWatch('support_phone');

  return (
    <>
      <FormItem
        name="support_phone"
        label="Support line"
        rules={[{ required: true, message: 'Please select a support line' }]}
      >
        <Select
          options={[
            { value: 'parent', label: 'Location\'s Support Line' },
            { value: 'restaurant', label: 'Merchant Phone Number' },
            { value: 'hide', label: 'Hide Support Line' },
          ]}
          defaultValue="parent"
          placeholder="Select support line"
        />
      </FormItem>

      { supportLine === 'restaurant' && (
        <FormItem
          name="phone"
          label="Phone number"
          rules={[
            { required: true, message: 'Please enter the phone number' },
          ]}
        >
          <Input placeholder="Phone number" />
        </FormItem>
      )}
    </>
  );
}

export default PhoneFields;
