import { PaymentStatus, PaymentStatusLabel } from 'constants/paymentStatus';
import useIsDesktop from 'hooks/useIsDesktop';
import React, {
  useContext, useRef, useState,
} from 'react';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import BaseTable from 'components/Table';
import {
  Badge, Button, Popover, Space, Tag, Tooltip, Typography,
} from 'antd';
import { getOrderType } from 'utils/order';
import { getPaymentType } from 'utils/orders';
import { TABLE_RENDER_TYPES } from 'utils/table';
import { NewOrdersContext } from 'constants/contexts';
import {
  FieldTimeOutlined, InfoCircleOutlined, PlusOutlined, WarningOutlined,
} from '@ant-design/icons';
import PlateIcon from 'components/PlateIcon';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';
import { useParams } from 'react-router-dom';
import OrderOverlay from 'containers/OrderOverlay';
import * as orderStatus from '../../constants/status';
import { priceTransformer } from '../../utils';

function Table({ fetchOrders, refetch }) {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const isScheduled = (order) => order?.scheduled_for;
  const [updatedOrderIds, setUpdatedOrderIds] = useState([]);
  const { newOrderIds, setSeen, newScheduledOrderIds } = useContext(NewOrdersContext);
  const badgedOrderIds = [...newOrderIds, ...updatedOrderIds, ...newScheduledOrderIds];
  const isFirstUpdate = useRef(true);
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const { orderId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState();

  const getStatus = (_, order) => {
    switch (order?.state) {
    case orderStatus.NEW:
      return (
        <Tag
          color="gold"
          icon={isScheduled(order) ? <CalendarOutlined /> : null}
        >
          New
        </Tag>
      );
    case orderStatus.IN_PROGRESS:
      return (
        <Tag
          color="green"
          icon={isScheduled(order) ? <CalendarOutlined /> : null}
        >
          Accepted
        </Tag>
      );
    case orderStatus.CANCELLED_BY_RESTAURANT:
      return <Tag color="error">Cancelled</Tag>;
    case orderStatus.READY:
      return <Tag color="green">Ready</Tag>;
    case orderStatus.ON_THE_WAY:
      return <Tag>On the Way</Tag>;
    case orderStatus.COMPLETED:
      return <Tag color="green">Completed</Tag>;
    case orderStatus.STRANDED:
      return <Tag color="red">Stranded</Tag>;
    case orderStatus.REJECTED_BY_RESTAURANT:
      return <Tag color="red">Rejected</Tag>;
    case orderStatus.NEEDS_APPROVAL:
      if (order.has_dispatcher_error || order.has_integration_error) {
        return <Tag icon={<WarningOutlined />} color="blue">Warning</Tag>;
      }
      return <Tag color="blue">Needs Approval</Tag>;
    case orderStatus.DELIVERED:
      return <Tag color="green">Delivered</Tag>;
    case orderStatus.PAYMENT_SCHEDULED:
      return <Tag color="blue">Payment Scheduled</Tag>;
    default:
      break;
    }
  };

  const isEstimatedTimePassed = (order) => {
    const remainingTime = dayjs(
      order?.estimated_pickup_time || order?.estimate,
    ).diff(dayjs(), 'minutes');

    if (remainingTime && remainingTime < 0 && isOrderInProgressOrReady(order)) {
      return true;
    }
    return false;
  };

  const isOrderInProgressOrReady = (order) => {
    /** Return true if it's In Progress/Ready */
    if (
      order?.state === orderStatus.IN_PROGRESS ||
      order?.state === orderStatus.READY
    ) {
      return true;
    }
    return false;
  };

  const handleEstimateTime = (order) => {
    /** Map estimated time to a text */
    if (
      order.state === orderStatus.NEW ||
      order.state === orderStatus.CANCELLED_BY_RESTAURANT
    ) {
      return '-';
    }
    if (order.has_delivery) {
      /** Delivery type of orders */

      if (isOrderInProgressOrReady(order) && order.delivery_state < 2) {
        /** for 'In Progress' / 'Ready' but no driver assigned yet: 'Looking for Drivers' */
        return 'Looking for drivers';
      }
      if (isOrderInProgressOrReady(order) && order.delivery_state >= 2) {
        /** for 'In Progress' / 'Ready' and driver assigned: Estimated minutes to arrival */
        const remainingTime = dayjs(order?.estimated_pickup_time).diff(
          dayjs(),
          'minutes',
        );
        if (remainingTime > 2) {
          return `${remainingTime} minutes until arrive`;
        }
        if (remainingTime >= 0) {
          /** for 'In Progress' / 'Ready' and status is 'Driver Arriving' in Spare (or less than 2 mins): 'Driver Arriving' */
          return 'Driver Arriving';
        }
        return dayjs(order.estimate).fromNow();
      }
      if (
        [orderStatus.COMPLETED, orderStatus.DELIVERED].includes(order.state)
      ) {
        return 'Done';
      }
    } else {
      if (order.estimate && isOrderInProgressOrReady(order)) {
        return dayjs(order.estimate).fromNow();
      }
      if (
        [orderStatus.COMPLETED, orderStatus.DELIVERED].includes(order.state)
      ) {
        return 'Done';
      }
    }
  };

  const getTime = (_, row) => {
    if (
      isScheduled(row) &&
      row?.state !== orderStatus.COMPLETED &&
      row?.state !== orderStatus.CANCELLED_BY_RESTAURANT
    ) {
      return (
        <div
          style={{ fontSize: 12, whiteSpace: 'nowrap' }}
          className={`flex flex-col align-items-center eta ${isEstimatedTimePassed(row) ? 'eta--red' : ''}`}
        >
          <div className="eta--first-row">
            <FieldTimeOutlined className="mr-1 text-base" />
            {
              row.is_catering ? (
                <Typography.Text>
                  {dayjs(row.requested_pickup_time || row.scheduled_for).format('hh:mm')}
                </Typography.Text>
              ) : (
                <Typography.Text>
                  {dayjs(row.scheduled_for).format('hh:mm')}
                  -
                  {dayjs(row.scheduled_for).add(30, 'minute').format('hh:mm')}
                </Typography.Text>
              )
            }
          </div>
          <span className="eta--sec-row text-sm font-extrabold">
            {dayjs(row.requested_pickup_time || row.scheduled_for).format('ddd, MMM DD')}
          </span>
        </div>
      );
    }
    return (
      <div className={`eta ${isEstimatedTimePassed(row) ? 'eta--red' : ''}`}>
        {handleEstimateTime(row)}
      </div>
    );
  };

  const getPaymentStatus = (order) => <Tag color={order.payment.status == PaymentStatus.FAILED ? 'orange' : ''}>{PaymentStatusLabel[order.payment.status]}</Tag>;
  const columns = [
    { title: 'number', width: 130, fixed: 'left' },
    {
      title: 'merchant',
      render: TABLE_RENDER_TYPES.MERCHANT_LINK,
      width: 150,
      fixed: isDesktop ? 'left' : false,
    },
    { title: 'status', render: getStatus, width: 145 },
    { title: 'Pick up time', width: 110, render: getTime },
    {
      title: 'customer',
      width: 150,
      render: (_, order) => (badgedOrderIds.includes(order?.id) ? (
        <Badge
          status="error"
          className="whitespace-nowrap"
          text={TABLE_RENDER_TYPES.CUSTOMER_LINK(_, order)}
        />
      ) : (
        TABLE_RENDER_TYPES.CUSTOMER_LINK(_, order)
      )),
    },
    isAdmin && {
      title: 'city',
      width: 140,
      render: (_, { merchant }) => merchant?.parent?.title,
    },
    { title: 'Order Type', width: 110, render: (_, order) => getOrderType(order) },
    isAdmin && {
      title: 'Order total',
      key: 'order_total',
      width: 100,
      render: (value) => priceTransformer(value),
    },
    {
      title: 'order source', key: 'source', width: 130, render: TABLE_RENDER_TYPES.TAG,
    },
    isAdmin && {
      title: 'Type',
      width: 120,
      render: (_, { merchant }) => merchant.merchant_type,
    },
    isAdmin && {
      title: 'payment',
      width: 120,
      render: (type, { payment_display }) => (
        <Popover
          placement="bottomLeft"
          content={payment_display?.description}
          overlayStyle={{ maxWidth: '280px' }}
        >
          <Tag>
            {payment_display?.title}
            <InfoCircleOutlined className="ml-1" style={{ fontSize: '10px' }} />
          </Tag>
        </Popover>
      ),
    },
    {
      title: 'date', key: 'timestamp', width: 110, render: TABLE_RENDER_TYPES.DATE,
    },
  ].filter(Boolean);

  function handleUpdate(newState, prevState) {
    if (JSON.stringify(prevState.pagination) !== JSON.stringify(newState.pagination)) {
      return;
    }
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    if (newState.pagination.current !== 1) {
      return;
    }

    const changedRecords = findChangedRecords({
      newRecords: newState.records,
      oldRecords: prevState.records,
    });

    setUpdatedOrderIds((ids) => [...ids, ...changedRecords.map((r) => r.id)]);

    function findChangedRecords({
      newRecords,
      oldRecords,
    }) {
      const changedRecords = [];
      for (const record of newRecords) {
        const currentRecord = oldRecords.find((r) => r.id === record.id);
        if (!currentRecord) {
          continue;
        }
        if (currentRecord.state !== record.state) {
          changedRecords.push(currentRecord);
        }
      }
      return changedRecords;
    }
  }

  return (
    <>
      <OrderOverlay
        orderId={orderId}
        onClose={() => {
          setSelectedOrder(null);
          navigate('/console/orders');
        }}
        data={selectedOrder}
        handleOrderUpdate={refetch}
      />
      <BaseTable
        columns={columns}
        fetchFn={fetchOrders}
        updateInterval={1000 * 60 * 3}
        onUpdate={handleUpdate}
        empty={(
          <Space direction="vertical" className="my-10 mx-5">
            <PlateIcon />
            <Typography.Paragraph>Looks like today's plate is as empty as this tab!</Typography.Paragraph>
            {
              isAdmin && (
                <Button
                  onClick={() => {
                    navigate('/console/orders/direct-entry');
                  }}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Create order!
                </Button>
              )
            }

          </Space>
        )}
        onRow={(record) => ({
          onClick: (event) => {
            if (event.isLink) {
              return;
            }
            setUpdatedOrderIds((ids) => ids.filter((id) => id !== record.id));
            setSeen(record.id);
            setSelectedOrder(record);
            navigate(`/console/orders/${record.id}`);
          },
        })}
        scroll={{ x: 400 }}
      />
    </>

  );
}

export default Table;
