import { renderPriceColumn } from 'containers/Reports/utils';

const columns = [
  {
    title: '',
    dataIndex: 'category',
    key: 'category',
    width: '40%',
  },
  {
    title: 'BREAKFAST',
    dataIndex: 'breakfast',
    key: 'breakfast',
    width: '15%',
    render: renderPriceColumn,
  },
  {
    title: 'LUNCH',
    dataIndex: 'lunch',
    key: 'lunch',
    width: '15%',
    render: renderPriceColumn,
  },
  {
    title: 'DINNER',
    dataIndex: 'dinner',
    key: 'dinner',
    width: '15%',
    render: renderPriceColumn,
  },
  {
    title: 'TOTAL',
    dataIndex: 'total',
    key: 'total',
    width: '15%',
    render: (value, record) => `${renderPriceColumn(value, record)} ${record.detail ? `(${record.detail})` : ''}`,
  },
];
export default columns;
