import { Space, Tag, Typography } from 'antd';
import JumpLink from 'components/JumpLink';
import SuccessDocumentIcon from 'components/SuccessDocument';
import BaseTable from 'components/Table';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { formatPhoneNumber, priceTransformer } from 'utils';
import { generateColumns } from 'utils/table';

function Table({ fetchCustomers }) {
  const navigate = useNavigate();

  const renderStatus = (status) => {
    let color = '';
    switch (status) {
      case 'active':
        color = 'purple';
        break;
      case 'lost':
        color = 'red';
        break;
      case 'new':
        color = 'orange';
        break;
      default:
        color = '';
    }
    return <Tag color={color} style={{textTransform: 'capitalize'}}>{status}</Tag>;
  };
  const columns = [
    {
      title: 'Name',
      sorter: true,
      key: 'name',
      width: '20%',
      columnKey: 'name',
      render: (_, { id, name }) => (
        <JumpLink href={`/console/contacts/${id}`} text={name}/>
      ),
    },
    {
      title: 'STATUS',
      key: 'status',
      width: '8%',
      render: renderStatus,
    },
    {
      title: 'Company',
      width: '12%',
      key: 'company_name',
    },
    {
      title: 'Email',
      width: '15%',
      key: 'email',
    },
    {
      title: 'Phone number',
      key: 'phone',
      width: '15%',
      render: formatPhoneNumber
    },
    {
      title: 'Last order',
      key: 'last_order_date',
      sorter: true,
      width: '12%',
      render: (d) => d ? dayjs(d)?.format('MMM D') : ' - ',
    },
    {
      title: 'Orders',
      key: 'orders_count',
      width: '8%',
    },
    {
      title: 'Spent',
      key: 'total_spent',
      sorter: true,
      width: '10%',
      render: (value) => priceTransformer(value?.toFixed(2)),
    },
  ];
  const normalizedColumns = useMemo(() => generateColumns(columns), [columns]);
  return (
    <BaseTable
      columns={normalizedColumns}
      fetchFn={fetchCustomers}
      empty={(
        <Space direction="vertical" className="my-10 mx-5">
          <SuccessDocumentIcon/>
          <Typography.Paragraph>It looks like our contacts list is as empty as a dining room at closing
            time!</Typography.Paragraph>
        </Space>
      )}
      onRow={(record) => ({
        onClick: (event) => {
          if (event.isLink) {
            return;
          }
          navigate(`/console/contacts/${record?.id}`);
        },
      })}
      scroll={{ x: 400 }}
    />
  );
}

export default Table;
