import React from 'react';
import { Navigate, Routes as ReactRoutes } from 'react-router';
import { Route } from 'react-router-dom';

import { INNER_ROUTES, MainRoutes } from 'constants/routes';

const ROUTES = [...Object.values(MainRoutes), ...INNER_ROUTES];
function Routes() {
  return (
    <ReactRoutes>
      {ROUTES.map((route, index) => {
        const Element = route.component;
        if (route.redirect) return <Navigate to={route.redirect} key={route.path} />;
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<Element />}
          />
        );
      })}
    </ReactRoutes>
  );
}

export default Routes;
