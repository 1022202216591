import {
  Flex, Skeleton, Spin,
  Timeline, Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import http from 'utils/http';
import { EventItems } from './constants';

const { Text, Title } = Typography;

function Activities({ created_at }) {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [eventPages, setEventPages] = useState({});

  const handleScroll = useCallback(
    debounce((e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
      if (bottom && !loading && hasMore) {
        setPage((prev) => prev + 1);
      }
    }, 200),
    [loading, hasMore],
  );

  const fetchEvents = useCallback((page) => {
    setLoading(true);
    http.get(`v1/organizations/{{organizationId}}/companies/${id}/activities/?page=${page}&page_size=20`)
      .then((res) => {
        setEventPages((c) => ({ ...c, [page]: res.data.results }));
        setHasMore(!!res.data.next);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    fetchEvents(page);
  }, [page, fetchEvents]);


  const eventItems = useMemo(() => (
    Object.entries(eventPages)
      .flatMap(([page, events]) => events.map((e) => ({ ...e, page })))
      .map((event) => {
        const Component = EventItems[event.event_type].component;
        return {
          dot: EventItems[event.event_type].icon,
          children: <Component event={event} refresh={() => fetchEvents(event.page)}/>,
          color: EventItems[event.event_type].color,
        };
      })
      .concat(loading ? [{
        dot: <Spin id="loading-dot" size="large"/>,
      }] : [])
      .concat([
        {
          children: <CompanyCreatedBox dateTime={created_at}/>,
        }])), [loading, eventPages]);

  return (
    <Flex vertical className="bg-[#F5F8F7] rounded py-4 ">
      <Title level={4} className="font-bold mb-8 px-6">
        Activities
      </Title>
      {loading && Object.keys(eventPages).length === 0 && <Skeleton active/>}
      <div
        onScroll={handleScroll}
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
          padding: '0 32px',
          marginLeft: 8,
        }}
      >
        <Timeline
          className="company-activity mt-4"
          items={eventItems}
          style={{ paddingTop: 30 }}
        />
      </div>
    </Flex>
  );
}

function CompanyCreatedBox({ dateTime }) {
  return (
    <Flex
      gap={24}
      justify="flex-end"
      align="baseline"
      style={{ width: 'calc(100% + 24px)', marginTop: 18, marginLeft: -22 }}
    >
      <div style={{
        flex: 1,
        height: '20px',
        border: '2px dashed rgba(5, 5, 5, 0.06)',
        borderRadius: '0 0 0 8px',
        borderRight: 'none',
        borderTop: 'none',
      }}
      />
      <Text className="font-bold">Company created!</Text>
      <Text>{dayjs(dateTime).format('MMM DD, hh:mm A')}</Text>
    </Flex>
  );
}

export default Activities;
