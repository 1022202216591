import React, { useState, useEffect } from 'react';

export default () => {
  const [modal, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [hideCloseBtn, setHideCloseBtn] = React.useState(null);
  const [classes, setClasses] = React.useState('');

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modal]);

  const handleModal = (content = false, hideCross, classes) => {
    if (hideCross) {
      setHideCloseBtn(true);
    } else {
      setHideCloseBtn(false);
    }
    if (classes) {
      setClasses(classes);
    } else {
      setClasses('');
    }
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return {
    modal,
    handleModal,
    modalContent,
    hideCloseBtn,
    classes,
    setClasses,
  };
};
