import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './styles';

function BadgeLabel({
  label,
  leadingIcon,
  trailingIcon,
  classes,
  ...props
}) {
  return (
    <StyledWrapper>
      <div className={`badge ${classes}`}>
        {leadingIcon ? (
          <span className="icon icon--lead">{leadingIcon}</span>
        ) : null}
        <span>{label}</span>
        {trailingIcon ? (
          <span className="icon icon--trail">{trailingIcon}</span>
        ) : null}
      </div>
    </StyledWrapper>
  );
}

BadgeLabel.propTypes = {
  label: PropTypes.element,
  classes: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
};

export default BadgeLabel;
