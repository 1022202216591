import { find, union } from 'lodash-es';

export const getRelatedRoles = ({ roles, selectedRoles }) => {
  let allRoles = selectedRoles;
  selectedRoles?.forEach((role) => {
    allRoles = union(allRoles, find(roles, { name: role })?.included_roles);
  });
  return allRoles;
};

export const checkIsSubordinateRole = ({ current, selectedRoles, roles }) => {
  let isSubordinate = false;
  selectedRoles?.every((userRole) => {
    const topLevelRole = find(roles, { name: userRole });
    isSubordinate = topLevelRole?.included_roles?.includes(current);
    if (isSubordinate) return false;
  });
  return isSubordinate;
};
