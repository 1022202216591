import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  color: #02862f;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  a {
    text-decoration: none;
    color: #02862f;

    &:hover {
      text-decoration: underline;
      color: #02862f;
    }
  }
`;
