import { captureException } from 'utils/errors';
import ExportToCSVAction from './ExportAction';
import FiltersBox from './FiltersBox';
import React, { useEffect, useState } from 'react';
import { getData } from 'utils';
import './index.css';
import { MERCHANT_TYPE } from 'constants';
import { fetchAccessibleParents } from 'api/parents';

function ReportToolbar(
  {
    filterData,
    setFilterData,
    type,
    hasLocationFilter = true,
    hasOrderTypeFilter,
    hasMerchantFilter,
    hasExport = true,
    hasExcludeStateFilter = false,
  },
) {
  const [loading, setLoading] = useState(hasLocationFilter);
  const [filterOptions, setFilterOptions] = useState({
    hasAccessToAllParents: false,
    locations: null,
    orderTypes: [
      { value: 'delivery', label: 'Delivery' },
      { value: 'pickup', label: 'Pickup' },
    ],
    merchantTypes: Object.values(MERCHANT_TYPE).map((type) => ({
      value: type,
      label: type,
    })),
  });
  const fetchParents = () => {
    if (hasLocationFilter) {
      fetchAccessibleParents()
        .then((res) => {
          const locations = getData(res)?.parents.map((p) => ({ value: p.id, label: p.title }));
          setFilterOptions({
            ...filterOptions,
            locations,
            hasAccessToAllParents: getData(res)?.has_access_to_all_parents,
          });
          setLoading(false);
        }).catch((e) => {
          captureException(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  useEffect(() => {
    if (hasLocationFilter) setFilterData({ ...filterData, options: filterOptions });
  }, [filterOptions]);

  return (
    <div className="toolbar-container">
      <FiltersBox
        filters={filterData.selected}
        setFilters={(filters) => setFilterData({ selected: filters, options: filterOptions })}
        filterOptions={filterOptions}
        hasLocationFilter={hasLocationFilter}
        hasOrderTypeFilter={hasOrderTypeFilter}
        hasMerchantFilter={hasMerchantFilter}
        hasExcludeStateFilter={hasExcludeStateFilter}
        loading={loading}
      />

      {hasExport && (
        <ExportToCSVAction
          filterData={filterData}
          disabled={loading}
          type={type}
          hasLocationFilter={hasLocationFilter}
          hasOrderTypeFilter={hasOrderTypeFilter}
          hasMerchantFilter={hasMerchantFilter}
        />
      )}
    </div>
  );
}

export default ReportToolbar;
