import { InfoCircleOutlined, MessageOutlined } from '@ant-design/icons';
import {
  List, Row, Skeleton, Space,
} from 'antd';
import React from 'react';
import { formatAmount } from 'utils';
import { renderServingSizeLabel } from 'utils/orders';

function OrderDetail({ orderItems, loading }) {
  const modifiers = (item) => (item.order_options?.length > 0
    ? item.order_options
    : item.order_modifier);

  if (!orderItems || loading) {
    return <Skeleton paragraph={{ rows: 8 }} />;
  }
  return (
    <Row>
      <List
        className="w-full"
        itemLayout="horizontal"
        size="small"
        dataSource={orderItems || []}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              title={(
                <div className="flex justify-between">
                  <div>

                    {item.qty && (
                      <>
                        {item.qty}
                        <span className="mx-2">x</span>
                      </>
                    )}
                    {' '}
                    {item.item_name}
                    {' '}
                    {item.size?.trim() && `(${item.size})`}
                    {' '}
                  </div>
                  {formatAmount(item.total_value)}
                </div>
              )}
              description={(
                <>
                  {modifiers(item).length > 0 && (
                    <span className="ml-8 block">
                      {(modifiers(item))
                        .map(
                          (m) => `${m.qty > 1 ? m.qty : ''} ${
                            m.modifier_option?.title || m.title
                          } (${m.modifier_option?.price >= 0 ? `$${m.modifier_option?.price}` : m.price})`,
                        )
                        .join(' · ')}

                    </span>
                  )}
                  {item.message && (
                    <Space className="flex ml-8">
                      <MessageOutlined />
                      {item.message}
                    </Space>
                  )}
                  {item.item_related?.serving_size && (
                    <Space className="flex ml-8 mt-2" size="small">
                      <InfoCircleOutlined />
                      <span>
                        {renderServingSizeLabel(item.item_related.serving_size)}
                      </span>
                    </Space>
                  )}
                </>

              )}
            />
          </List.Item>
        )}
      />
    </Row>
  );
}
export default OrderDetail;
