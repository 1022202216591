import React, { useEffect, useState } from 'react';

export const useTimer = (seconds) => {
  const [sec, setSec] = useState(seconds);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    let timeInterval = null;
    timeInterval = setInterval(() => {
      setSec(sec - 1);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [sec]);

  useEffect(() => {
    if (sec < 0) {
      setSec(seconds);
      setReset(true);
    } else if (reset) {
      setReset(false);
    }
  }, [sec]);

  return [sec, reset];
};
