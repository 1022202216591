import { createContext } from 'react';

export const UserManagementContext = createContext({
  openPermissionForm: false,
  handleClosePermissionForm: () => {
  },
  handleOpenPermissionForm: () => {
  },
  currentUser: null,
  roles: [],
});

export const locationRestrictionType = {
  all: 'all',
  specific: 'specific',
};
