import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: center;
  width: fit-content;

  .error {
    box-shadow: 0px 0px 0px 1px red, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05) !important;
  }
  .ending {
    color: #7d8284;
    position: absolute;
    right: 12px;
  }
  .input {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #d5d6d6, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05);
    border: none;
    height: 38px;
    width: 338px;
    padding: 10px 12px;
    box-shadow: ${(p) => (p.err
    ? `0px 0px 0px 1px red, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05) !important`
    : 'unset')};

    &:focus,
    &:focus-visible {
      box-shadow: 0px 0px 0px 2px rgba(2, 134, 47, 0.4),
        0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05);
      outline: none;
    }

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #7d8284;
    }
  }

  input:disabled {
    color: #7d8284;
  }
  .warn {
    align-self: start;
    margin-top: 10px;
    margin-left: 3px;
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: red;
  margin-left: 4px;
`;

export const Err = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #d40508;
  margin-top: -12px;
  margin-left: 16px;
  margin-bottom: 5px;
`;

export const StyledErrorMessage = styled.div`
  color: #eb5255;
  margin-top: 6px;
  margin-left: 14px;
  font-size: 12px;
  line-height: 16px;
  display: inline;
`;

export const StyledRightMessage = styled.div`
  color: gray;
  margin-top: 6px;
  margin-right: 14px;
  float: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
