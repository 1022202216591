import useIsDesktop from 'hooks/useIsDesktop';
import React from 'react';
import { default as MPagination } from '@mui/material/Pagination';
import { PagingRow } from './styles';
import SkeletonLoading from '../SkeletonLoading';

/*
 * Pagination it gives, currentPage,...
 */

function Pagination({
  paginationObj, page, setPage, loading, ...props
}) {
  const isDesktop = useIsDesktop();
  return (
    <PagingRow>
      {paginationObj.count ? (
        <MPagination
          size="small"
          count={Math.ceil(paginationObj.count / 10)}
          siblingCount={isDesktop ?? 0}
          boundaryCount={isDesktop ?? 0}
          onChange={(event, number) => {
            setPage(number);
          }}
        />
      ) : loading ? (
        <div className="skeleton">
          <SkeletonLoading
            borderRadius="4px"
            width="76px"
            height="26px"
            count={1}
          />
          {' '}
          <SkeletonLoading
            borderRadius="4px"
            width="28px"
            height="26px"
            count={1}
          />
          {' '}
          <SkeletonLoading
            borderRadius="4px"
            width="28px"
            height="26px"
            count={1}
          />
          {' '}
          <SkeletonLoading
            borderRadius="4px"
            width="28px"
            height="26px"
            count={1}
          />
          {' '}
          <SkeletonLoading
            borderRadius="4px"
            width="28px"
            height="26px"
            count={1}
          />
          {' '}
          <SkeletonLoading
            borderRadius="4px"
            width="67px"
            height="26px"
            count={1}
          />
        </div>
      ) : null}
    </PagingRow>
  );
}

export default Pagination;
