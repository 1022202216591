import { Col, Row, Skeleton } from 'antd';
import Layout from 'components/Layout';
import useFetchedData from 'hooks/useFetchedData';
import React from 'react';
import { useParams } from 'react-router-dom';
import Activities from './Activities';
import Contacts from './Contacts';
import CustomFields from './CustomFields';
import Details from './Details';
import './index.css';

function CompanyInfo() {
  const { id } = useParams();
  const { data: company = {}, loading, mutate: mutateCompany } = useFetchedData(
    `v1/organizations/{{organizationId}}/companies/${id}/`,
  );

  return (
    <Layout header={(
      <Layout.Header
        backTitle="Companies"
        backPath="/console/companies"
      />
    )}
    >
      <Row className="w-full" gutter={[16, 24]}>
        <Col span={24} lg={8}>
          {loading && (
            <Skeleton />
          )}
          {!loading && (
            <Details company={company} fetchCompany={mutateCompany} />
          )}
          <Contacts />
          {(company?.integration_meta && company?.integration_meta?.custom_fields) && (
            <CustomFields meta={company?.integration_meta} />
          )}
        </Col>
        <Col span={24} lg={16}>
          <Activities created_at={company?.created_at} />
        </Col>
      </Row>
    </Layout>
  );
}

export default CompanyInfo;
