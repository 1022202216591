import Table from 'components/Table';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { columns } from './constants';
import DetailsModal from './DetailsModal';
import { fetchCreditHistory } from './requests';

function CreditHistory() {
  const [detailsData, setDetailsData] = useState(null);
  const { id } = useParams();
  const fetchHistory = useCallback(() => fetchCreditHistory(id).then(({
    results,
    count,
  }) => ({
    data: results,
    total: count,
  })), [id]);
  return (
    <>
      <DetailsModal
        data={detailsData}
        onClose={() => {
          setDetailsData(null);
        }}
      />
      <Table
        columns={columns}
        fetchFn={fetchHistory}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            setDetailsData(record);
          },
        })}
        scroll={{ x: 400 }}
      />
    </>

  );
}

export default CreditHistory;
