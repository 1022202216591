import { Col, Row } from 'antd';
import { GeneralReportIcon, MerchantReportIcon, OrdersReportIcon } from 'assets/icons';
import CardLink from 'components/CardLink';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React from 'react';

function ReportTypesContainer() {
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const actions = [
    isAdmin && {
      key: 'general_report',
      title: 'General Report',
      icon: <GeneralReportIcon />,
      description: 'A comprehensive summary of your business which includes information such as your total sales, total number of orders, etc.',
      link: '/console/reports/general',
    },
    {
      key: 'merchants_report',
      title: 'Merchants Report',
      icon: <MerchantReportIcon />,
      description: 'This report includes general information about your merchants separated by merchant’s name.',
      link: '/console/reports/merchants',
    },
    isAdmin && {
      key: 'orders_report',
      title: 'Orders Report ',
      icon: <OrdersReportIcon />,
      description: 'This report includes a log of all completed and cancelled orders as well as details such as merchant net, RDS net, etc.',
      link: '/console/reports/orders',
    },
  ].filter(Boolean);
  return (
    <Row gutter={[16, 16]}>
      {actions.map(({
        key, title, icon, description, link,
      }) => (
        <Col xs={24} sm={12} lg={8} key={key}>
          <CardLink
            title={title}
            icon={icon}
            description={description}
            link={link}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ReportTypesContainer;
