import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Typography } from 'antd';
import campaign from 'assets/image/campaign.svg';
import React from 'react';

function EmptyComponent({
  title = 'No data!', description, hasAction = true, icon = campaign, actionButtonText = 'Add', onClick,
}) {
  return (
    <Empty
      image={icon}
      style={{ marginTop: '15%', marginBottom: '10%' }}
      imageStyle={{
        height: 60,
        marginBottom: 20,
      }}
      description={(
        <div>
          <Typography.Title level={5}>
            {title}
          </Typography.Title>
          <Typography.Text>
            {description}
          </Typography.Text>
        </div>
      )}
    >
      {hasAction && (
        <Button
          icon={<PlusOutlined />}
          onClick={onClick}
          type="primary"
        >
          {actionButtonText}
        </Button>
      )}
    </Empty>
  );
}

export default EmptyComponent;
