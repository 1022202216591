import {
  Button, Flex, Form, Space, Typography,
} from 'antd';
import { PaymentTypes } from 'constants';
import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import StepsProvider, { StepsContext } from './StepsProvider';

function Steps({ children }) {
  return (
    <StepsProvider>
      <div style={{ border: '1px solid #DCDDDD', borderRadius: 5 }}>
        {children}
      </div>
    </StepsProvider>
  );
}

function Step(
  {
    step,
    summary,
    children,
  },
) {
  const form = Form.useFormInstance();
  const { title, id: stepNumber } = step;
  const {
    activeStep, onNextStep, onPrevStep, loading, isActiveStep, isCompleted, locked,
  } = useContext(StepsContext);
  const isActive = isActiveStep(stepNumber);
  const isLastStep = stepNumber === 5;
  const isCurrentOrCompleted = isCompleted(stepNumber) || isActive;
  const titleLevel = isCurrentOrCompleted ? 4 : 5;
  const isMobile = useIsMobile();
  const payment_method = Form.useWatch('payment_method');

  const getSubmitButtonLabel = () => {
    if (isLastStep) {
      return payment_method?.id === PaymentTypes.QUOTED ? 'Send as quote' : 'Create';
    }
    return 'Next step';
  };
  return (
    <Flex vertical>
      <Flex
        vertical
        style={{
          padding: isCurrentOrCompleted ? '12px 24px 24px' : '12px 24px 0',
          backgroundColor: isCurrentOrCompleted ? '#F5F8F7' : '#FFFFFF',
          borderBottom: isLastStep && !isActive ? 'unset' : '1px solid #DCDDDD',
          borderRadius: isLastStep && !isActive ? '0 0 5px 5px' : stepNumber === 1 ? '5px 5px 0 0' : 'unset',
        }}
      >
        <Space size={isMobile ? 0 : 4} align="baseline" direction={isMobile ? 'vertical' : 'horizontal'} style={{ marginBottom: isActive ? 24 : isCompleted ? 12 : 2 }}>
          <Space>
            <Typography.Title level={titleLevel} style={{ color: '#00000073' }}>
              {stepNumber}
              .
            </Typography.Title>
            <Typography.Title level={titleLevel} style={{ fontWeight: isCompleted ? 700 : 500, color: isCompleted ? 'inherit' : '#00000073' }}>
              {title}
            </Typography.Title>
          </Space>
          {isActive && activeStep !== 5 &&
          <Typography.Text style={{ marginLeft: 8, fontWeight: 700, color: '#A7A9A9' }}>Required</Typography.Text>}
        </Space>

        <div style={{ display: isActive ? 'block' : 'none' }}>
          {children}
        </div>
        {!isActive && isCompleted(stepNumber) && summary}
      </Flex>

      {isActive && (
        <Space
          size={16}
          style={{
            padding: 16,
            justifyContent: 'flex-end',
            borderBottom: isLastStep ? 'unset' : '1px solid #DCDDDD',
          }}
        >
          {activeStep !== 1 && <Button onClick={onPrevStep} size="large" disabled={loading}>Previous step</Button>}
          <Button
            htmlType={isLastStep ? 'submit' : 'button'}
            type="primary"
            size="large"
            onClick={() => onNextStep(form)}
            loading={loading}
            disabled={locked || loading}
          >
            {getSubmitButtonLabel()}
          </Button>
        </Space>
      )}
    </Flex>
  );
}

Steps.Step = Step;
export default Steps;
