import {
  CheckSquareOutlined, LockOutlined, LogoutOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import CardLink from 'components/CardLink';
import Layout from 'components/Layout';
import withGuard from 'components/withGuard';
import { AdminContext } from 'constants/contexts';
import { PERMISSIONS } from 'constants/permissions';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import React, { useContext } from 'react';

function AccountSettings() {
  const hasUserManagementPermission = useHasAnyOfPermissions([PERMISSIONS.USER_MANAGEMENT]);
  const hasBillingPermission = useHasAnyOfPermissions([PERMISSIONS.BILLING]);
  const { logoutUser } = useContext(AdminContext);

  return (
    <Layout header={(
      <Layout.Header
        title="Account settings"
        description="All of your account’s utilities gathered here."
      />
    )}
    >
      <Row gutter={[16, 16]}>
        {hasBillingPermission && (
          <Col xs={24} sm={12} md={8} xxl={6}>
            <CardLink title="Subscription" icon={<CheckSquareOutlined />} link="/console/setting/subscription" />
          </Col>
        )}
        {hasUserManagementPermission && (
          <Col xs={24} sm={12} md={8} xxl={6}>
            <CardLink title="User Management" icon={<SafetyCertificateOutlined />} link="/console/user-management" />
          </Col>
        )}
        <Col xs={24} sm={12} md={8} xxl={6}>
          <CardLink title="Change Password" icon={<LockOutlined />} link="/forget-password" />
        </Col>
        <Col xs={24} sm={12} md={8} xxl={6}>
          <CardLink
            title="Logout"
            icon={<LogoutOutlined />}
            onClick={() => {
              logoutUser();
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default withGuard(AccountSettings, null, { redirect: true });
