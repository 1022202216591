import {
  Form, notification, Radio, Select, Space,
} from 'antd';
import { fetchAccessibleParents } from 'api/parents';
import { locationRestrictionType } from 'containers/AccountSettings/UserManagement/constants';
import React, { useEffect, useState } from 'react';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import FormItemLabel from './FormItemLabel';
import { formSection } from './styles';

const { Option } = Select;

function SelectLocation({}) {
  const locationRestriction = Form.useWatch('locationRestriction');
  const [locations, setLocations] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();
  useEffect(() => {
    fetchAccessibleParents()
      .then((res) => {
        const data = getData(res)?.parents.map((p) => ({ value: p.id, label: p.title }));
        setLocations(data);
      }).catch((e) => {
        captureException(e);
        notificationApi.error({ message: 'Something went wrong in getting parents!' });
      });
  }, []);

  return (
    <div style={formSection}>
      {contextHolder}
      <Form.Item
        label={<FormItemLabel>Select locations</FormItemLabel>}
        name="locationRestriction"
        rules={[
          {
            required: true,
            message: 'Please select location',
          },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={locationRestrictionType.all}>All locations</Radio>
            <Radio value={locationRestrictionType.specific}>Specific locations</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {locationRestriction === locationRestrictionType.specific && (
        <Form.Item
          label=""
          name="locations"
          rules={[
            {
              required: locationRestriction === locationRestrictionType.specific,
              message: 'Please select at least one location to proceed.',
            },
          ]}
        >
          <Select
            className="multi-select-filter-box"
            mode="multiple"
            allowClear
            maxTagCount={3}
            placeholder="Add location"
            filterOption={(inputValue, option) => (typeof option.children === 'string' ? option.children : option.children.join('')).toLowerCase().includes(inputValue.toLowerCase())}
          >
            {locations?.map((option) => <Option key={option.value}>{option.label}</Option>)}
          </Select>
        </Form.Item>
      )}
    </div>
  );
}

export default SelectLocation;
