import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  .badge {
    position: relative;
    margin: 0;
    border: none;
    color: #4f566a;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${Colors.textPrimary1};
  }

  .icon {
    width: 18px;
    height: 18px;

    &--lead {
      margin-right: 8px;
    }

    &--trail {
      margin-left: 8px;
    }
  }
`;
