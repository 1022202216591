import {
  Button, Checkbox, Col, Collapse, Input, Row,
} from 'antd';
import { SearchIcon } from 'assets/icons';
import React, { useState } from 'react';

const { Panel } = Collapse;

function ModalFilterBox({
  filter = [], setFilters, options, title,
}) {
  const [searchOptions, setSearchOptions] = useState(options);
  const getHeaderSelectOption = () => (
    <>
      <span style={{ marginRight: '4px' }}>Select</span>
      <Button type="text" onClick={selectAll}>
        All
      </Button>
      <Button type="text" onClick={clearAll}>
        None
      </Button>
    </>
  );

  const clearAll = (e) => {
    e.stopPropagation();
    setFilters([]);
  };

  const selectAll = (e) => {
    e.stopPropagation();
    const allOptions = options.map((option) => String(option.value));
    setFilters(allOptions);
  };

  const onOptionChange = (option) => {
    const selected = option?.target.value;
    setFilters(option.target.checked ? [...filter, selected] : filter.filter((f) => f !== selected));
  };

  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchOptions(options.filter((option) => option.label?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !== -1));
  };

  return (
    <div>
      <Collapse defaultActiveKey={['1']} ghost className="modal-filter-box">
        <Panel header={title} key="1" extra={getHeaderSelectOption()}>
          <div className="modal-filter-container">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="modal-search-icon" />}
              bordered={false}
              onChange={onSearch}
              className="modal-search-input"
            />
            <div className="filter-option-container">
              <Checkbox.Group style={{ width: '100%' }} value={filter}>
                <Row>
                  {searchOptions?.map((option) => (
                    <Col span={12} className="filter-option" key={option.value}>
                      <Checkbox value={String(option.value)} onChange={onOptionChange}>{option.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </div>
          </div>
        </Panel>
      </Collapse>

    </div>
  );
}
export default ModalFilterBox;
