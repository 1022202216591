import { camelCase, get } from 'lodash-es';

export function injectParams(config) {
  const serialized = JSON.stringify(config);
  const injectedSerialized = serialized.replace(/\{\{([0-9a-zA-Z\.\[\]_]*)\}\}/g, (_, keyPath) => get(config, keyPath));
  return JSON.parse(injectedSerialized);
}

export function prepareConfig({
  client_config: {
    general,
    ...restClientConfig
  },
  ...rest
}) {
  return deepCamelCase(injectParams({
    general: {
      ...general,
      ...rest,
    },
    ...restClientConfig,
  }));
}

const deepCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => deepCamelCase(v));
  } if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.startsWith("'") ? key.replace(/^'(.*)'$/, '$1') : camelCase(key)]: deepCamelCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
