import React from 'react';
import Icon from '@ant-design/icons';

const FileSVGIcon = function () {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M58.8845 27.4405C63.261 27.4405 66.8172 30.9729 66.8437 35.3487C66.8437 35.8054 66.8461 36.2645 66.8461 36.729C66.8461 46.9997 66.1109 54.6206 65.4421 59.6053C64.6353 63.7981 61.8221 66.5267 58.8715 67.734C53.5768 69.9021 43.6246 69.9021 33.6734 69.9021C23.7222 69.9021 13.7694 69.9021 8.48293 67.6807C5.56125 66.4545 2.74816 63.7639 1.94896 59.5918C1.23515 54.6212 0.5 46.999 0.5 36.7291C0.5 26.2837 1.26179 18.5797 1.95726 13.6086C2.7321 9.63559 5.18163 6.71391 8.09557 5.64175C13.7694 3.55596 27.0381 3.55596 33.6728 3.55596H35.0157C39.4052 3.56366 42.9589 7.12564 42.9589 11.5151C42.9613 11.5151 42.9613 11.5175 42.9613 11.5175C42.9613 20.3042 50.0978 27.4407 58.8845 27.4407L58.8845 27.4405ZM58.8845 0.901855C64.7444 0.901855 69.5 5.65743 69.5 11.5173C69.5 17.3772 64.7444 22.1328 58.8845 22.1328C53.0247 22.1328 48.2691 17.3772 48.2691 11.5173C48.2691 5.65743 53.0247 0.901855 58.8845 0.901855Z" fill="#DAEADE" />
    </svg>
  );
};
function FileIcon(props) {
  return (
    <Icon component={FileSVGIcon} {...props} />
  );
}

export default FileIcon;
