import React from 'react';

function NotFound(props) {
  return (
    <div className="text-center">
      <div className="error mx-auto" data-text="404">
        404
      </div>
      <p className="lead text-gray-800 mb-5">Page Not Found</p>
      <a href="/">&larr; Back to Home</a>
    </div>
  );
}

export default NotFound;
