import { ShoppingCartOutlined } from '@ant-design/icons';
import {
  Flex, List, Space, Typography,
} from 'antd';
import QuantityInput from 'components/QuantityInput';
import React from 'react';
import { priceTransformer } from 'utils';
import { renderServingSizeLabel } from 'utils/orders';
import { getItemTotalPrice } from '../ItemDetailOverlay/item';
import { pluralize } from './utils';

function Cart({ value: orderItems, onChange, isCatering }) {
  const handleChange = ({ id, qty }) => {
    if (qty === 0) {
      const newValues = orderItems.filter((i) => i.id !== id);
      onChange(newValues);
    } else {
      const newValues = orderItems.map((i) => (i.id === id ? { ...i, qty } : i));
      onChange(newValues);
    }
  };

  return (
    <div>
      <List
        dataSource={orderItems}
        className="h-full"
        locale={{
          emptyText: <Space direction="vertical" style={{ marginTop: '100%' }}>
            <ShoppingCartOutlined style={{ fontSize: 50 }} />
            <Typography.Text type="secondary">User’s cart is empty</Typography.Text>
          </Space>,
        }}
        renderItem={(item, index) => (
          <List.Item className="ml-4">
            <Flex vertical className="w-full" gap={12}>
              <Flex align="flex-start" justify="space-between" gap={8}>
                <Typography.Text className="font-bold flex-shrink-1" wrap>{item.item_related.name}</Typography.Text>
                <Typography.Text className="flex-shrink-0" wrap>{priceTransformer(getItemTotalPrice(item))}</Typography.Text>
              </Flex>

              <Flex vertical>
                <Typography.Text className="text-[#5A5F62] text-xs font-semibold">
                  {item.size?.name}
                </Typography.Text>
                {item?.order_options?.map((modifier) => (
                  <Typography.Text className="text-[#5A5F62] text-xs font-semibold">
                    +
                    {' '}
                    {modifier.qty > 1 ? `${modifier?.qty}x ` : ''}
                    {modifier.modifier_option?.title}
                    {' '}
                    ($
                    {modifier.modifier_option?.real_price}
                    )
                  </Typography.Text>
                ))}
              </Flex>

              {item.message && (
                <Flex vertical>
                  <Typography.Text className="text-[#5A5F62] text-xs font-semibold">
                    Special note:
                  </Typography.Text>
                  <Typography.Text className="text-[#5A5F62] text-xs font-semibold">
                    {item.message}
                  </Typography.Text>
                </Flex>
              )}

              <Flex justify="space-between" align="center" gap={8}>
                <Space className="flex-shrink-0">
                  <QuantityInput
                    value={item.qty}
                    onChange={(qty) => handleChange({ id: item.id, qty })}
                    useTrashIcon
                  />
                  {isCatering && (
                    <Typography.Text className="text-[#5A5F62] text-xs font-semibold">
                      {pluralize(item.item_related.catering_label, item.qty)}
                    </Typography.Text>
                  )}
                </Space>
                {isCatering && item.item_related.catering_label && (
                  <Typography.Text className="text-[#5A5F62] text-xs font-semibold flex-shrink-1 text-right">
                    {renderServingSizeLabel(item.qty * (item.size?.serving_size || 1))}
                  </Typography.Text>
                )}
              </Flex>
            </Flex>
          </List.Item>
        )}
      />
    </div>
  );
}

export default Cart;
