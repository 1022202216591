import MultiSelectFilter from 'containers/Reports/components/ReportToolbar/FiltersBox/MultiSelectFilter';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import DateFilter from 'containers/Reports/components/ReportToolbar/FiltersBox/DateFilter';
import './index.css';

function FiltersBox({
  filters,
  setFilters,
  filterOptions,
  hasLocationFilter,
  hasOrderTypeFilter,
  hasMerchantFilter,
  hasExcludeStateFilter,
  loading,
}) {
  const isMobile = useIsMobile();
  const onFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };
  return (
    <div className="filter-container">
      <DateFilter setFilters={onFilterChange} />

      {hasLocationFilter && (
        <MultiSelectFilter
          placeholder="Locations"
          options={filterOptions.locations}
          filters={filters?.locations}
          setFilters={(values) => onFilterChange('locations', values)}
          width={isMobile ? '100%' : 200}
          loading={loading}
        />
      )}

      {hasOrderTypeFilter && (
        <MultiSelectFilter
          placeholder="Order Type"
          options={filterOptions.orderTypes}
          filters={filters?.orderTypes}
          setFilters={(values) => onFilterChange('orderTypes', values)}
          width={isMobile ? '100%' : 150}
        />
      )}

      {hasMerchantFilter && (
        <MultiSelectFilter
          placeholder="Merchant Type"
          options={filterOptions.merchantTypes}
          filters={filters?.merchantTypes}
          setFilters={(values) => onFilterChange('merchantTypes', values)}
          width={isMobile ? '100%' : 150}
        />
      )}

      {
        hasExcludeStateFilter && (
          <MultiSelectFilter
            placeholder="Exclude states"
            options={['COMPLETED', 'CANCELLED', 'REJECTED'].map((s) => ({ value: s, label: s }))}
            filters={filters?.excludeStates}
            setFilters={(values) => onFilterChange('excludeStates', values)}
            width={isMobile ? '100%' : 200}
            addAllOption={false}
          />
        )
      }

    </div>
  );
}

export default FiltersBox;
