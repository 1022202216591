import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';
import { MenuContext } from '../context';
import { createCategory } from './requests';

function AddCategory({ buttonType }) {
  const {
    menuDetail: menu, setItemsLoading, fetchMenuDetail, showMessage,
  } = useContext(MenuContext);
  const [showCategoryInput, setShowCategoryInput] = useState(false);

  const onSaveCategory = (category) => {
    setItemsLoading(true);
    createCategory({ name: category, menuId: menu.id }).then((res) => {
      setShowCategoryInput(false);
      fetchMenuDetail();
    }).catch((e) => {
      captureException(e);
      showMessage({ type: 'error' });
    }).finally(() => setItemsLoading(false));
  };

  return (
    <>
      {!showCategoryInput && (
        <Button
          type={buttonType}
          style={{ width: 'fit-content' }}
          onClick={() => setShowCategoryInput(true)}
          icon={<PlusOutlined />}
        >
          Add category
        </Button>
      )}
      {showCategoryInput && (
        <CategoryInput
          onSave={onSaveCategory}
          onCancel={() => setShowCategoryInput(false)}
        />
      )}
    </>
  );
}

export function CategoryInput({
  value, onSave, onCancel, disabled,
}) {
  const [category, setCategory] = useState(value);
  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        placeholder="Type then press save to add category"
        defaultValue={value}
        onChange={(e) => setCategory(e.target.value)}
      />
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={() => onSave(category)} disabled={!category || disabled}>Save</Button>
    </Space.Compact>
  );
}
export default AddCategory;
