import { Tabs } from 'antd';
import Layout from 'components/Layout';
import React from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import AdjustBalance from './AdjustBalance';
import CreditHistory from './CreditCardHistory';
import GiftCardHistory from './GiftCardHistory';
import Overview from './Overview';
import { StyledWrapper } from './styles';

const ROUTES = [
  {
    label: 'Overview',
    path: '',
    Component: Overview,
    key: 1,
  },
  {
    label: 'Credit history',
    path: '/credit-history',
    Component: CreditHistory,
    key: 2,
  },
  {
    label: 'Gift card details',
    path: '/gift-card',
    Component: GiftCardHistory,
    key: 3,
  },
  {
    label: 'Adjust balance',
    path: '/adjust-balance',
    Component: AdjustBalance,
    key: 4,
  },
  {
    title: 'Overview',
    path: '/orders/:orderId',
    Component: Overview,
    id: 6,
  },
];
function Container() {
  const navigate = useNavigate();
  const { id: customerId } = useParams();

  return (
    <Layout header={(
      <Layout.Header
        backTitle="Customers"
        backPath="/console/contacts"
      />
    )}
    >
      <StyledWrapper>
        <Tabs
          items={ROUTES}
          defaultActiveKey={ROUTES[0].key}
          onChange={(key) => navigate(`/console/contacts/${customerId}${ROUTES[key - 1].path}`)}
        />
        <Outlet />
      </StyledWrapper>
    </Layout>
  );
}

export default Container;
