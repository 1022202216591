import Layout from 'components/Layout';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { captureException } from 'utils/errors';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { getNotificationById } from '../../api/marketing';
import { StyledWrapper } from '../../styles/formStyledWrapper';
import { getData } from '../../utils';
import Form from './Form';

function MarketingForm() {
  const [notificationDataLoading, setNotificationDataLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      // Get notifications
      setNotificationDataLoading(true);
      getNotificationById(id).then((res) => {
        setCampaignData(getData(res));
        setNotificationDataLoading(false);
      })
        .catch((e) => {
          captureException(e);
          setNotificationDataLoading(false);
        });
    }
  }, [id]);

  return (
    <Layout header={(
      <Layout.Header
        title={campaignData?.title || 'New push notification'}
        backTitle="Marketing"
        backPath="/console/marketing"
      />
    )}
    >
      <StyledWrapper>
        {/* Form */}
        {id && notificationDataLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <Form data={campaignData} />
        )}
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(MarketingForm, [PERMISSIONS.MARKETING], { redirect: true });
