import { Divider, Form } from 'antd';
import { fetchMenus } from 'components/ItemsInput/AddOrderItemOverlay/requests';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from 'utils/errors';
import http from 'utils/http';
import { StepsContext } from '../Steps/StepsProvider';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import { useIsCatering } from '../useIsCatering';
import OrderType from './OrderType';
import RecipientInfo from './RecepientInfo';
import SchedulingInput from './SchedulingInput';
import Summary from './Summary';

function DeliveryInfo() {
  const isCatering = useIsCatering();
  const form = Form.useFormInstance();
  const merchant = form.getFieldValue('merchant');


  return (
    <Steps.Step step={STEPS.DELIVERY_INFO} summary={<Summary />}>
      <OrderType />
      {isCatering && <RecipientInfo />}
      {(isCatering || merchant?.has_scheduling_order) && (
        <>
          <Divider />
          <OrderFormItem
            name="scheduled_for"
            label="Scheduling time"
            fullWidth
            validateTrigger="onBlur"
            rules={[
              { required: isCatering },
            ]}
          >
            <SchedulingInput
              isCatering={isCatering}
              merchantId={merchant.id}
            />
          </OrderFormItem>
        </>
      )}
    </Steps.Step>
  );
}

export default DeliveryInfo;
