import { AdminContext } from 'constants/contexts';
import { useCallback, useContext } from 'react';

export function useHasAnyOfPermissions(permissions) {
  const { permissions: userPermissions, isLoggedIn } = useContext(AdminContext);
  if (!isLoggedIn) {
    return false;
  }
  if (!permissions?.length) {
    return true;
  }
  return userPermissions.some((p) => permissions.includes(p));
}

export function useHasAnyOfPermissionsCallback() {
  const { permissions: userPermissions, isLoggedIn } = useContext(AdminContext);

  return useCallback((permissions) => {
    if (!isLoggedIn) {
      return false;
    }
    if (!permissions) {
      return true;
    }
    return userPermissions.some((p) => permissions.includes(p));
  }, [isLoggedIn, userPermissions]);
}
