import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Divider, Select, Space,
} from 'antd';
import React, { useContext } from 'react';
import { MenuContext } from '../context';

function MenuSelector({ onAddNew }) {
  const { menus, selectedMenu, setSelectedMenu } = useContext(MenuContext);

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Select menu"
      onSelect={setSelectedMenu}
      value={selectedMenu}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Button type="text" icon={<PlusOutlined />} onClick={onAddNew}>
              Add menu
            </Button>
          </Space>
        </>
      )}
      options={menus?.map((item) => ({ label: item.name, value: item.id }))}
    />
  );
}

export default MenuSelector;
