import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import http, { getHttpErrorMessage } from 'utils/http';
import { captureException } from 'utils/errors';
import DrawerForm from './DrawerForm';

function EditForm({ company, open, onClose }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function handleEdit(data) {
    await http.put(`/v1/organizations/{{organizationId}}/companies/${id}`, data);
    onClose(true);
  }

  const onDelete = () => {
    setLoading(true);
    return http.delete(`v1/organizations/{{organizationId}}/companies/${id}/`)
      .then(() => {
        navigate(`/console/companies`);
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
        captureException(e);
      })
      .finally(() => setLoading(false));
  };


  return (
    <DrawerForm
      open={open}
      title="Edit company"
      onSubmit={handleEdit}
      onClose={onClose}
      data={company}
      onDelete={onDelete}
      loading={loading}
    />
  );
}

export default EditForm;
