import Companies from 'containers/Companies';
import CompanyInfo from 'containers/Companies/CompanyInfo';
import React from 'react';
import AcceptCateringOrder from 'containers/AcceptCateringOrder';
import AccountSettings from 'containers/AccountSettings';
import Subscription from 'containers/AccountSettings/Subscription';
import MenuManagement from 'containers/MenuManagement';
import Login from 'containers/auth';
import ForgetPasswordConfirm from 'containers/auth/ForgetPasswordConfirm';
import Onboarding from 'containers/auth/Onboarding';
import ForgetPassword from 'containers/auth/ResetPassword';
import SignUp from 'containers/auth/SignUp';
import CouponsForm from 'containers/CouponForm';
import Coupons from 'containers/Coupons';
import CustomerDetail from 'containers/CustomerDetail';
import Customers from 'containers/Customers';
import Dashboard from 'containers/Dashboard';
import EditAddress from 'containers/EditAddress';
import EditingOrder from 'containers/EditOrders';
import Marketing from 'containers/Marketing';
import MarketingDetail from 'containers/MarketingDetail';
import MarketingForm from 'containers/MarketingForm';
import Merchants from 'containers/Merchants';
import MerchantEditFrom from 'containers/MerchantEditForm';
import OrdersTab from 'containers/OrdersTab';
import UserManagement from 'containers/AccountSettings/UserManagement';
import CreateOrder from 'containers/CreateOrder';
import DirectEntry from 'containers/DirectEntryOrder';
import Location from 'containers/Location';
import MerchantCreateFrom from 'containers/MerchantCreateForm';
import Support from 'containers/Support';
import {
  BarChartOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  InboxOutlined,
  PushpinOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import MerchantDetail from 'containers/MerchantDetail';
import {
  DetailMerchantReport, GeneralReport, MerchantsReport, OrdersReport, Reports,
} from 'containers/Reports';
import NotFound from 'containers/404';
import { Route } from 'react-router-dom';
import { PERMISSIONS } from './permissions';
import { SIDEBAR_ITEMS } from './sidebar-items';

export const MainRoutes = {
  LOGIN: {
    path: '/login',
    component: Login,
  },
  SIGNUP: {
    path: '/sign-up',
    component: SignUp,
  },
  DASHBOARD: {
    path: '/console',
    component: Dashboard,
  },
  FORGET_PASSWORD: {
    path: '/forget-password',
    component: ForgetPassword,
  },
  CUSTOMER_ADDRESS_EDIT: {
    path: '/console/customer-address/:id/:addressId',
    component: EditAddress,
  },
  CUSTOMERS: {
    path: '/console/contacts',
    component: Customers,
  },
  LOCATIONS: {
    path: '/console/locations/*',
    component: Location,
  },
  MERCHANTS: {
    path: '/console/restaurants',
    component: Merchants,
  },
  ACCOUNT_SETTINGS: {
    path: '/console/setting',
    component: AccountSettings,
  },
  COUPONS: {
    path: '/console/coupons',
    component: Coupons,
  },
  DIRECT_ENTRY: {
    path: '/console/orders/direct-entry',
    component: DirectEntry,
  },
  ORDERS: {
    path: '/console/orders/:orderId?',
    component: OrdersTab,
  },
  REPORTS: {
    path: '/console/Reports',
    component: Reports,
  },
  SUPPORT: {
    path: '/console/support',
    component: Support,
  },
  MARKETING: {
    path: '/console/marketing',
    component: Marketing,
  },
  COMPANIES: {
    path: '/console/companies',
    component: Companies,
  },
};

// TODO: this routes should be move into nested level on their parents
export const INNER_ROUTES = [
  {
    path: '/onboarding',
    component: Onboarding,
  },
  {
    path: '/redirect/api/user/rest-auth/password/reset/confirm/:uid/:token/',
    component: ForgetPasswordConfirm,
  },
  {
    path: '/redirect/api/v1/user/rest-auth/password/reset/confirm/:uid/:token/',
    component: ForgetPasswordConfirm,
  },
  {
    path: '/console/contacts/:id/*',
    component: CustomerDetail,
  },
  {
    path: '/console/marketing/create',
    component: MarketingForm,
  },
  {
    path: '/console/marketing/edit/:id',
    component: MarketingForm,
  },
  {
    path: '/console/restaurants/edit/:id',
    component: MerchantEditFrom,
  },
  {
    path: '/console/restaurants/:id',
    component: MerchantDetail,
  },
  {
    path: '/console/restaurants/create',
    component: MerchantCreateFrom,
  },
  {
    path: '/console/restaurants/:id/menus',
    component: MenuManagement,
  },

  {
    path: '/console/marketing/duplicate/:id',
    component: MarketingForm,
  },
  {
    path: '/console/marketing/detail/:id',
    component: MarketingDetail,
  },
  {
    path: '/console/stand-orders/',
    permissions: [],
  },
  {
    path: '/console/orders/:id/edit',
    component: EditingOrder,
  },

  {
    path: '/console/orders/call-in/create',
    component: CreateOrder,
  },
  {
    path: '/console/coupons/form',
    component: CouponsForm,
  },
  {
    path: '/console/coupons/form/:id',
    component: CouponsForm,
  },

  {
    path: '/console/user-management',
    component: UserManagement,
  },

  {
    path: '/console/setting/subscription',
    component: Subscription,
  },
  {
    path: '/accept-order/:orderNumber',
    component: AcceptCateringOrder,
  },

  {
    path: '/console/Reports/general',
    component: GeneralReport,
  },
  {
    path: '/console/Reports/merchants',
    component: MerchantsReport,
  },
  {
    path: '/console/Reports/orders',
    component: OrdersReport,
  },
  {
    path: '/console/Reports/merchants/:merchantId',
    component: DetailMerchantReport,
  },
  {
    path: '/console/companies/:id/info',
    component: CompanyInfo,
  },
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const resizeIcon = (Icon) => <Icon style={{ fontSize: '16px' }} />;

// to prevent dep-cycle the sidebar-items is populated indirectly
if (SIDEBAR_ITEMS.length === 0) {
  SIDEBAR_ITEMS.push(...[
    {
      route: MainRoutes.DASHBOARD,
      icon: resizeIcon(DashboardOutlined),
      label: 'Dashboard',
    },
    {
      route: {
        path: '/console/orders',
        component: OrdersTab,
      },
      label: 'Orders',
      permissions: [PERMISSIONS.ORDERS],
      icon: resizeIcon(InboxOutlined),
      badge: 'orders',
      action: {
        route: MainRoutes.DIRECT_ENTRY.path,
        label: 'Create order',
      },
    },
    {
      route: MainRoutes.COMPANIES,
      permissions: [PERMISSIONS.COMPANIES, PERMISSIONS.CUSTOMERS],
      label: 'Customer center',
      icon: resizeIcon(TeamOutlined),
      subMenus: [
        {
          route: MainRoutes.COMPANIES,
          permissions: [PERMISSIONS.COMPANIES],
          label: 'Companies',
        },
        {
          route: MainRoutes.CUSTOMERS,
          permissions: [PERMISSIONS.CUSTOMERS],
          label: 'Contacts',
        },
      ],
    },
    {
      route: MainRoutes.MERCHANTS,
      permissions: [PERMISSIONS.MERCHANTS],
      label: 'Restaurants',
      icon: resizeIcon(ShopOutlined),
      action: {
        route: '/console/restaurants/create',
        label: 'Create restaurant',
      },
    },
    {
      route: {
        path: '/console/locations',
        component: Location,
      },
      permissions: [PERMISSIONS.PARENTS],
      label: 'Locations',
      icon: resizeIcon(PushpinOutlined),
    },
    {
      route: MainRoutes.REPORTS,
      permissions: [PERMISSIONS.REPORTS],
      label: 'Reports',
      icon: resizeIcon(BarChartOutlined),
    },
    {
      route: MainRoutes.MARKETING,
      permissions: [PERMISSIONS.MARKETING, PERMISSIONS.COUPONS],
      label: 'Marketing',
      icon: resizeIcon(RocketOutlined),
      subMenus: [
        {
          route: MainRoutes.MARKETING,
          permissions: [PERMISSIONS.MARKETING],
          label: 'Push notification',
        },
        {
          route: MainRoutes.COUPONS,
          permissions: [PERMISSIONS.COUPONS],
          label: 'Coupons',
        },
      ],
    },
    {
      route: MainRoutes.SUPPORT,
      label: 'Help center',
      icon: resizeIcon(CustomerServiceOutlined),
      permissions: [PERMISSIONS.ADMIN_ACCESS],
      bottom: true,
    },
    {
      route: MainRoutes.ACCOUNT_SETTINGS,
      label: 'Account settings',
      icon: resizeIcon(SettingOutlined),
      bottom: true,
    },
  ]);
}
