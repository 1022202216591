import PropTypes from 'prop-types';
import React from 'react';
import { StyledWrapper } from './styles';

/** A Radio group selection */

function DeprecatedRadioButtonGroup({
  options,
  selectedOption,
  setSelectedOption,
  optionSameText,
  classes,
  labelClasses,
  checkmarkClasses,
  multi,
  ...props
}) {
  return (
    <StyledWrapper multiSelect={multi}>
      <div className={`wrapper ${classes}`}>
        {options?.map((option) => (
          <div>
            <label className={`container ${labelClasses}`}>
              {option.title}
              {' '}
              {optionSameText}
              <input
                type="radio"
                onClick={() => {
                  setSelectedOption(option);
                }}
                onChange={() => {}}
                checked={
                  multi
                    ? selectedOption?.includes(option.id)
                    : option.id === selectedOption?.id
                      ? 'checked'
                      : ''
                }
              />
              <span
                className={`checkmark ${checkmarkClasses}  ${
                  multi ? 'multi-select-check' : ''
                }`}
              />
            </label>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
}

DeprecatedRadioButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  optionSameText: PropTypes.func,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  checkmarkClasses: PropTypes.string,
  multi: PropTypes.bool,
};

export default DeprecatedRadioButtonGroup;
