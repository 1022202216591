import { generateQuery, generateURLWithParams } from 'utils';
import http from 'utils/http';
import { apiUrlDeprecated } from 'constants';
import { capitalize } from 'lodash-es';
import { jsonToFormData } from 'utils/object';

export const fetchMerchantsList = (query) => {
  const url = generateURLWithParams('v1/organizations/{{organizationId}}/merchants/', query);
  return http.get(url);
};

export const fetchMerchantsListByEnability = (isDisabled, searchKey) => {
  const query = generateQuery([
    searchKey ? `search=${searchKey}` : '',
    `is_disabled=${isDisabled}`,
  ]);
  return http
    .get(`v1/organizations/{{organizationId}}/merchants/${query}`)
    .then((res) => res.data);
};
export const fetchOmniMerchants = ({ zipCode, parentId }) => {
  const query = generateQuery([`zip_code=${zipCode}`, `parent_id=${parentId}`]);
  return http.get(`organizations/{{organizationId}}/omni-merchants/v2/${query}`, {
    baseURL: apiUrlDeprecated,
  }).then((res) => res.data);
};

export const addNewOmniMerchants =
  ({ storeIds, parentId }) => http.post('organizations/{{organizationId}}/omni-merchants/v2/', {
    store_ids: storeIds,
    parent_id: parentId,
  }, {
    baseURL: apiUrlDeprecated,
  }).then((res) => res.data);

export const fetchOrganizationMerchantsList =
  (organization, page = 1) => async () => await http
    .get(`v1/merchant_claimers/organization/${organization}/?page=${page}`)
    .then((res) => res.data);
export const changeMerchantStatus =
  (merchant_user_id, status, description) => async () => await http
    .put(`v1/merchant_claimers/details/${merchant_user_id}/status/`, {
      status,
      description,
    })
    .then((res) => res.data);
export const getMerchantById = (merchantId) => http
  .get(`v1/organizations/{{organizationId}}/merchants/${merchantId}/`)
  .then((res) => res.data);
export const getMerchantClaimersById = (merchant_user_id) => async () => await http
  .get(`v1/merchant_claimers/details/${merchant_user_id}/`)
  .then((res) => res.data);
export const getMerchantOrders =
  (merchantId, page = 1) => {
    const query = generateQuery([page > 1 ? `page=${page}` : '']);
    return http
      .get(
        `v1/organizations/{{organizationId}}/merchants/${merchantId}/orders/${query}`,
      )
      .then((res) => res.data);
  };
export const addMerchantOwner =
  (merchantId, { email, password }) => http
    .post(`v1/organizations/{{organizationId}}/merchants/${merchantId}/owners/`, {
      email,
      password,
    })
    .then((res) => res.data);
export const
  addNewMerchantDeprecated = (organizationId, data) => async () => {
    const payload = {
      name: data.name,
      ...(data.email && { email: data.email }),
      is_partner: data.type === 'Partner',
      phone: data.phone,
      address: data.address,
      application_delivery_fee: data.deliveryCommission / 100 || 0,
      application_pickup_fee: data.pickupCommission / 100 || 0,
      markup: data.markup || 0,
      has_send_gift: data.gift,
      has_scheduling_order: data.schedule,
      parent: data.parent ?? 16,
      location: data.location,
    };

    return await http
      .post(`v1/organizations/${organizationId}/merchants/`, payload)
      .then((res) => res.data);
  };

export function createMerchant(data) {
  return http
    .post('v1/organizations/{{organizationId}}/merchants/', data)
    .then((res) => res.data);
}

export const updateMerchant = (id, data) => {
  ['cover', 'logo'].forEach((fileField) => {
    if (data[fileField] === null) {
      data[fileField] = '';
    } else if (typeof data[fileField] === 'string') {
      delete data[fileField];
    }
  });
  data = Object.entries(data).reduce((acc, [key, value]) => {
    if (typeof value === 'boolean') {
      value = capitalize(value.toString());
    } else if (value == null) {
      value = '';
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  data.location = JSON.stringify(data.location);
  return http
    .put(`v1/organizations/{{organizationId}}/merchants/${id}/`, jsonToFormData(data))
    .then((res) => res.data);
};

export const getParents = (searchTerm) => http
  .get(`v1/organization/{{organizationId}}/parents/${searchTerm ? `?search=${searchTerm}` : ''}`)
  .then((res) => res.data);

export const getMerchantJustById = (merchantId) => http.get(`v1/restaurants/${merchantId}/`).then((res) => res.data);
