import { getMerchantById } from 'api/merchants';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import { App } from 'antd';
import { captureException } from 'utils/errors';
import { ModifierContext } from './context';
import { fetchModifiersByMerchantId } from './Modifiers/requests';

function ModifierProvider({ children }) {
  const { id: merchantId } = useParams();
  const [modifiers, setModifiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modifiersLoading, setModifiersLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [merchant, setMerchant] = useState();
  const navigate = useNavigate();
  const { message } = App.useApp();

  useEffect(() => {
    fetchModifiers();
  }, [merchantId]);

  useEffect(() => {
    getMerchantById(merchantId).then((res) => setMerchant(res.data)).catch((e) => {
      captureException(e);
      message.error('Restaurant not found!');
      navigate('/');
    });
  }, [merchantId]);

  const fetchModifiers = useCallback(() => {
    setLoading(true);
    fetchModifiersByMerchantId(merchantId).then(({ data }) => setModifiers(data?.sort((a, b) => b.id - a.id)))
      .catch((e) => {
        captureException(e);
      }).finally(() => {
        setLoading(false);
      });
  }, [merchantId]);

  const showMessage = ({ type, content }) => {
    content = content || (type === 'error' && 'Something went wrong! please try again');
    if (!content) return;
    message.open({ type, content });
  };
  const contextValue = useMemo(() => ({
    modifiers,
    setModifiers,
    loading,
    setLoading,
    fetchModifiers,
    modifiersLoading,
    setModifiersLoading,
    showMessage,
    searchTerm,
    setSearchTerm,
    merchant,
  }), [
    modifiers,
    setModifiers,
    loading,
    setLoading,
    fetchModifiers,
    modifiersLoading,
    setModifiersLoading,
    showMessage,
    searchTerm,
    setSearchTerm,
    merchant,
  ]);

  return (
    <ModifierContext.Provider value={contextValue}>
      {children}
    </ModifierContext.Provider>
  );
}

export default ModifierProvider;
