import http from 'utils/http';
import { generateURLWithParams } from 'utils';

export const reorderItemPosition = ({ itemId, position, categoryId }) => http.post(
  `v1/items/${itemId}/move`,
  { item: itemId, position, category: categoryId },
).then((res) => res.data);

export const fetchItemById = (id) => http.get(`v1/items/${id}/`).then((res) => res.data);

export const addItem = (item) => http.post('v1/items/', item).then((res) => res.data);

export const editItem = ({ id, data }) => http.patch(`v1/items/${id}/`, data).then((res) => res.data);

export const deleteItem = (itemId) => http.delete(`v1/items/${itemId}/`).then((res) => res.data);

export const changeItemAvailability = (itemId) => http.post(`v1/items/${itemId}/status/`).then((res) => res.data);

export const fetchRestaurantTaxOption = ({ searchTerm, merchantId }) => http.get(generateURLWithParams('v1/tax/active-tax/', {
  search: searchTerm,
  restaurant: merchantId,
})).then((res) => res.data);

export const updateItemImage = ({ id, data }) => http.post(`v1/restaurant-images/${id}/change/`, data).then((res) => res.data);
