import { Col, Form, Row } from 'antd';
import { PaymentTypes } from 'constants/index';
import { useIsCatering } from 'containers/DirectEntryOrder/useIsCatering';
import React from 'react';
import QuotePaymentForm from './QuotePaymentForm';
import usePaymentMethods from './usePaymentMethods';
import CustomPaymentForm from './CustomPaymentForm';
import { STEPS } from '../constants';
import OrderFormItem from '../OrderFormItem';
import Steps from '../Steps';
import PaymentMethods from './PaymentMethods';
import Summary from './Summary';
import UserCards from './UserCards';
import './index.css';

function PaymentInfo() {
  const {
    paymentMethods,
  } = usePaymentMethods();
  const isCatering = useIsCatering();
  const payment_method = Form.useWatch('payment_method');
  return (
    <Steps.Step step={STEPS.PAYMENT_INFO} summary={<Summary />}>
      <Row>
        <Col xs={24} lg={5}>
          <OrderFormItem
            name="payment_method"
            label="Pay with"
            fullWidth
            rules={[{ required: true, message: 'Please select a payment method!' }]}
          >
            <PaymentMethods paymentMethods={paymentMethods} />
          </OrderFormItem>
        </Col>

        <Col xs={24} lg={19}>
          {payment_method?.type === PaymentTypes.CARD && (
            <OrderFormItem
              name="card_id"
              fullWidth
              rules={[{ required: true, message: 'Please add a credit card!' }]}
            >
              <UserCards />
            </OrderFormItem>
          )}

          {payment_method?.meta && (
            <CustomPaymentForm meta={payment_method.meta} />
          )}

          {isCatering && payment_method?.type === PaymentTypes.QUOTED && (
            <QuotePaymentForm />
          )}
        </Col>
      </Row>
    </Steps.Step>
  );
}

export default PaymentInfo;
