import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { fetchAccessibleParents } from 'api/parents';
import { captureException } from 'utils/errors';

function LocationSelectInput({ value, onChange, ...props }) {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    fetchAccessibleParents()
      .then((res) => {
        const locations = res.data.parents.map((p) => ({ value: p.id, label: p.title }));
        setOptions(locations);
      }).catch((e) => {
        captureException(e);
      });
  }, []);
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      loading={options === null}
      value={value}
      style={{ width: 200 }}
      onChange={onChange}
      options={options}
      mode="multiple"
      allowClear
      placeholder="Locations"
      filterOption={filterOption}
      {...props}
    />

  );
}

export default LocationSelectInput;
