import { Button, Modal, Space } from 'antd';
import React from 'react';

function MoreDetailModal({ open, setOpen, data }) {
  if (!data) return null;
  return (
    <Modal
      title={`${data.type} for ${data.user}`}
      centered
      open={open}
      width={450}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" type="primary" onClick={() => setOpen(false)}>
          Close
        </Button>]}
    >
      <Space direction="vertical">{data?.options.map((option) => <div>{option}</div>)}</Space>

    </Modal>
  );
}
export default MoreDetailModal;
