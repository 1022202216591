import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title-row {
    padding: 21px 51px;
    margin-bottom: 4px;
    display: block;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.textPrimary1} !important;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .back {
      color: #717679;
      width: 24px;
      height: 24px;
      margin-right: 3px;

      &-btn {
        padding: 0;
      }

      &-text {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #717679;
      }
    }
  }

  h1 {
    margin: 0;
  }

  .page-title {
    margin: 0 56px 48px !important;
    padding: 0;
  }

  .title {
    &-row {
      margin: 12px 0 20px;
    }

    &-name {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1};
      /* margin-right: 12px; */

      &-text {
        margin-right: 12px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 34px;
      }
    }
  }

  .status {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: capitalize;

    &--completed {
      background: #cbf4c9;
      color: #0e623f !important;
    }

    &--need-action {
      background: #fce9eb;
      color: #b62927 !important;
    }
  }

  .merchant-grid {
    display: grid;
    grid-template-columns: 238px auto;

    &-left {
      display: flex;
      flex-direction: column;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &-title {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #d8dce2;
      padding: 0 0 10px 0;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
    }
  }

  .utils {
    padding: 0 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    justify-content: flex-start !important;
    position: relative;
    top: unset !important;

    .icon--lead {
      color: #898d8f;
      width: 43px;
    }

    &--red {
      color: #d53c2e;
    }

    &-list {
      width: 197px !important;

      &-item {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.textPrimary1};
      }
    }

    &-btn {
      width: 91px !important;
      padding: 6px;
      line-height: 18px;
    }
  }

  .pagination {
     margin-top: -48px;
     @media (max-width:575.98px) {
      margin-top: 0;
    }
  }
`;
