import React from 'react';
import {
  Input, Radio, Space,
} from 'antd';

const DEFAULT_TYPES = { '%': '%', $: '$' };
function MultiTypeAmountInput({
  onChange, value = {}, types = DEFAULT_TYPES, ...props
}) {
  return (
    <Space>
      <Input
        type="number"
        value={value.amount}
        onChange={(e) => onChange({ ...value, amount: e.target.value })}
        {...props}
      />
      <Radio.Group
        value={value.type}
        onChange={(e) => onChange({ ...value, type: e.target.value })}
      >
        {
          Object.entries(types).map(([value, label]) => (<Radio.Button key={value} value={value}>{ label}</Radio.Button>))
        }
      </Radio.Group>
    </Space>
  );
}

MultiTypeAmountInput.RequiredRule =
  () => ({
    validator(_, value) {
      if (value.amount) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('This field is required!'),
      );
    },
  });

export default MultiTypeAmountInput;
