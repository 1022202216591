import http from 'utils/http';

const mockData = new Array(30).fill(
  {
    id: 11,
    state: 'AVAILABLE',
    amount: 10,
    code: 'brobtlzu',
    first_name: null,
    last_name: null,
    nickname: null,
    benefactor_name: 'Your dad',
    message: 'Happy birthday ma boy!',
    created_at: '2022-12-13T16:11:55.315815Z',
    expires_at: '2027-12-12',
    redeemed_at: null,
    receiver_email: 'johndoe@gmail.com',
    receiver_phone: '+1234567890',
    send_messages_at: '2023-01-01T12:12:12Z',
    is_paid: true,
  },
);

export const fetchGiftCardHistory = (id) => http.get(`/v1/billing/organizations/{{organizationId}}/customers/${id}/gift-cards/`).then((res) => res.data);
// export const fetchGiftCardHistory = () => new Promise((resolve, reject) => {
//   setTimeout(
//     () => resolve({
//       data: { results: mockData },
//       total: mockData.length,
//     }),
//     // () => reject('sf'),
//     1000,
//   );
// });
