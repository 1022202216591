import { getParents } from 'api/merchants';
import DebounceSelect from 'components/Select/DebounceSelect';
import React from 'react';
import { captureException } from 'utils/errors';

function ParentInput({ ...props }) {
  const fetchParents = (searchKey = '') => getParents(searchKey)
    .then(({ results: parents }) => {
      if (!parents.some((p) => p.id === props.selectedParent?.id)) {
        parents.push(props.selectedParent);
      }
      return parents.map((parent) => ({
        key: parent?.id,
        value: parent?.id,
        label: parent?.title,
      }));
    })
    .catch((e) => captureException(e));

  return (
    <DebounceSelect
      id="parent"
      labelInValue
      allowClear
      placeholder="Select parent"
      fetchOptions={fetchParents}
      showSearch
      {...props}
    />
  );
}

export default ParentInput;
