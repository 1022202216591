import { CheckCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { fetchMerchantsListByEnability } from 'api/merchants';
import DebounceSelect from 'components/Select/DebounceSelect';
import { truncate } from 'lodash-es';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import EditConfirmation from '../EditConfirmation';
import './index.css';

function MerchantInput({ value, onChange }) {
  const [open, setOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const form = useFormInstance();
  const getMerchants = (searchKey = '') => fetchMerchantsListByEnability(false, searchKey)
    .then((res) => {
      if (res.status === 'success') {
        const fetchedMerchants = res.data.results?.map((m) => ({
          label: m.name, value: m.id, merchant: m,
        }));
        return fetchedMerchants;
      }
    })
    .catch((e) => {
      captureException(e);
    });

  const onMerchantChange = (data) => {
    const { items, scheduled_for } = form.getFieldsValue();
    if (items?.length || scheduled_for) {
      setSelectedMerchant(data);
      setOpen(true);
    } else {
      onChange(data);
    }
  };

  const onConfirmEdit = () => {
    onChange(selectedMerchant);
    form.setFieldsValue({ items: [], scheduled_for: '' });
  };
  return (
    <>
      <DebounceSelect
        dropdownClassName="merchants-dropdown"
        allowClear
        placeholder="e.g. Lovely burger"
        fetchOptions={getMerchants}
        value={value}
        onChange={(value, option) => {
          onMerchantChange(value ? { ...value, ...option?.merchant } : null);
        }}
        showSearch
        suffixIcon={value && <CheckCircleFilled style={{ color: '#319D56' }} />}
        optionRender={(option) => (
          <Space className="justify-between w-full px-3">
            <span className="font-weight-bold">{truncate(option.label, { length: 30 })}</span>
            <span className="text-sm text-[#666B6D]">{option.data?.merchant?.merchant_type}</span>
          </Space>
        )}
      />
      <EditConfirmation open={open} setOpen={setOpen} onConfirmEdit={onConfirmEdit} />
    </>
  );
}
export default MerchantInput;
