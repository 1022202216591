/* eslint-disable camelcase */
import { App, Button, Drawer, Flex, Form, Skeleton } from 'antd';
import FormLayout from 'components/FormLayout';
import { EMPTY_OBJECT } from 'constants';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getHttpErrorMessage } from 'utils/http';
import { captureException } from 'utils/errors';
import AddressInfo from './AddressInfo';
import GeneralInfo from './GeneralInfo';

function DrawerForm({
  data = EMPTY_OBJECT,
  loading,
  open,
  onClose,
  title,
  onSubmit,
  onDelete,
}) {
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      size="large"
      styles={{ body: { padding: 0, paddingBottom: 64 } }}
    >
      {loading ? (
        <FormLayout.Section divider={false}>
          <Skeleton paragraph={{ rows: 10 }}/>
        </FormLayout.Section>
      ) : (
        <CompanyForm
          data={data}
          onSubmit={onSubmit}
          onDelete={onDelete}
          onClose={onClose}
          loading={loading}
        />
      )}
    </Drawer>
  );
}

function CompanyForm({
  data, onSubmit, onDelete, onClose,
}) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const isEditMode = Boolean(id);
  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFieldsValue(deserialize(data));
    setTimeout(() => {
      form.setFieldValue('stateOfCountry', data.state);
    }, 200);
  }, [data, form]);

  const handleSubmit = async (data) => {
    setLoading(true);
    onSubmit(serialize(data))
      .then((res) => {
        message.success(`Company ${id ? 'updated' : 'created'} successfully!`);
        form.resetFields();
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e));
        captureException(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={deserialize(data)}
      style={{ marginTop: 24 }}
    >
      <GeneralInfo isEditMode={isEditMode}/>
      {isEditMode && <AddressInfo/>}
      <FormLayout.Footer>
        <Flex justify={isEditMode ? 'space-between' : 'flex-end'} className="w-full">
          <div>
            {onDelete && (
              <Button danger disabled={loading} onClick={onDelete}>
                Delete
              </Button>
            )}
          </div>
          <Flex gap={4}>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {isEditMode ? 'Save' : 'Add'}
            </Button>
          </Flex>
        </Flex>
      </FormLayout.Footer>
    </Form>
  );
}

function serialize({
  stateOfCountry,
  country,
  employee_count,
  ...data
}) {
  return {
    ...data,
    country: country?.label,
    state: stateOfCountry,
    employee_count: employee_count || 0,
  };
}

function deserialize({
  state,
  country,
  employee_count,
  ...data
}) {
  return {
    ...data,
    country: country && {
      label: country,
      value: country === 'Canada' ? 'CA' : 'US',
    },
    state: {
      label: state,
      value: state,
    },
    employee_count: Boolean(employee_count) ? employee_count : 0
  };
}

export default DrawerForm;
