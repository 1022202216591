import { Button } from 'antd';
import React from 'react';

/** Error fallback UI component */

function ErrorFallback({ error }) {
  return (
    <div className="flex flex-col gap-2 items-center h-screen justify-center">
      <pre>Oops!! Something went wrong</pre>
      <Button
        type="primary"
        size="large"
        onClick={() => window.location.reload()}
      >
        Try refreshing your browser
      </Button>
      <pre className="text">
        If you still face issues please reach out to us at
        {' '}
      </pre>
      <pre className="email">contact@omnicart.tech</pre>
    </div>
  );
}

export default ErrorFallback;
