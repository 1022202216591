import React from 'react';
import { Tag } from 'antd';
import { TABLE_RENDER_TYPES } from 'utils/table';

export const columns = [
  {
    title: 'Issued date',
    key: 'created_at',
    render: TABLE_RENDER_TYPES.DATE,
  },
  {
    title: 'code',
    render: TABLE_RENDER_TYPES.TAG,
  },
  {
    title: 'amount',
    render: TABLE_RENDER_TYPES.CENT,
  },
  {
    title: 'state',
    render(status) {
      const COLOR_MAP = {
        AVAILABLE: 'green',
        REDEEMED: 'blue',
        EXPIRED: 'gray',
      };
      return <Tag color={COLOR_MAP[status]}>{status}</Tag>;
    },
  },
  'Redeemed by',
  {
    title: 'Exp date',
    key: 'expires_at',
    render: TABLE_RENDER_TYPES.DATE,
  },
];
