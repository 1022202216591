import React from 'react';
import { Tag, Typography } from 'antd';
import { snakeCase } from 'lodash-es';
import { formatAmount } from 'utils';
import dayjs from 'dayjs';
import JumpLink from 'components/JumpLink';

export const TABLE_RENDER_TYPES = {
  TAG: (value) => (
    <Tag>
      {value}
    </Tag>
  ),
  DATE: (value) => (value ? dayjs(value).format('MMM D, hh:mm A') : null),
  // Todo: use code after removing bootstrap (it style conflict)
  CODE: (value) => <Typography.Text strong>{value}</Typography.Text>,
  DOLLAR: (value) => <Typography.Text strong>{formatAmount(value)}</Typography.Text>,
  CENT: (value) => <Typography.Text strong>{formatAmount(value, { isCent: true })}</Typography.Text>,
  MERCHANT_LINK: ({ id, name }) => (
    <JumpLink href={`/console/restaurants/${id}`} text={name} maxWidth={110} />
  ),
  CUSTOMER_LINK: ({ name, id }) => (
    <JumpLink href={`/console/contacts/${id}`} text={name} />
  ),
};

export function generateColumns(cols) {
  return cols.map((col) => {
    if (typeof col === 'string') {
      const key = snakeCase(col);
      const title = col;
      const dataIndex = key;
      return {
        title: title?.toUpperCase(),
        dataIndex,
        key,
      };
    }

    // eslint-disable-next-line prefer-const
    let {
      title, key, render, filters, onFilter, width, align, fixed, sorter, hidden, onCell,
    } = col;

    key = key || snakeCase(title);

    return {
      title: title?.toUpperCase?.() ?? title,
      key,
      render,
      dataIndex: key,
      filters,
      onFilter,
      width,
      align,
      fixed,
      sorter,
      hidden,
      onCell,
    };
  });
}
