import Table from 'components/Table';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { columns } from './constants';
import DetailsModal from './DetailsModal';
import { fetchGiftCardHistory } from './requests';

function GiftCardHistory() {
  const [detailsData, setDetailsData] = useState(null);
  const { id } = useParams();
  const fetchFn = useCallback(() => fetchGiftCardHistory(id).then(({
    data: {
      results,
      count,
    },
  }) => ({
    data: results,
    total: count,
  })), [id]);

  return (
    <>
      <DetailsModal
        data={detailsData}
        onClose={() => {
          setDetailsData(null);
        }}
      />
      <Table
        columns={columns}
        fetchFn={fetchFn}
        onRow={(record) => ({
          onClick: () => {
            setDetailsData(record);
          },
        })}
        scroll={{ x: 400 }}
      />
    </>

  );
}

export default GiftCardHistory;
