import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  .delete-modal {
    width: 412px;
    padding: 20px 0;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.textPrimary1};
    margin-bottom: 8px;
    padding: 0 20px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666b6d;
    padding: 0 20px;
    margin-bottom: 12px;
  }

  .qa {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .btns {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dde1e4;
    padding: 16px 20px;
    padding: 16px 20px 0;
    justify-content: flex-end;
  }

  .modal-content {
    padding: 16px 20px;
    background: #f5f8f7;
    border-radius:0
  }
`;
