export const Colors = {
  black: '#0000000',
  white: '#ffffff',
  green: '#68d300',
  lightOrange: '#f8aa30',
  orange: '#f48530',
  purple: '#672792',
  brandColor: '#E45628',
  success: '#4BB43A',
  brandColor: '#04B261',
  primary1: '#02862F',
  textPrimary1: '#131b1f',
};
