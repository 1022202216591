import PropTypes from 'prop-types';
import React from 'react';
import { StyledCheckbox } from './styles';

function Checkbox({
  checked,
  onChange,
  name,
  label,
  classes,
  labelClasses,
  loading,
  disabled,
  ...props
}) {
  return (
    <StyledCheckbox className={`item-checkbox ${classes}`} disabled={disabled}>
      <label htmlFor={name}>
        {!loading ? (
          <>
            <input
              id={name}
              type="checkbox"
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
            <span
              className={`checkmark-icon ${
                disabled ? 'checkmark-icon--disabled' : ''
              }`}
            />
          </>
        ) : (
          <span className="checkmark-icon--loading">
            <SkeletonLoading
              borderRadius={4}
              width={15}
              height={15}
              count={1}
            />
          </span>
        )}
        <div className={labelClasses}>{label}</div>
      </label>
    </StyledCheckbox>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.any,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  loading: PropTypes.bool,
};

export default Checkbox;
