import React, {
  Fragment, useEffect, useState, forwardRef,
} from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as WarnIcon } from 'assets/image/warn.svg';
import {
  Err,
  ErrorMessage,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledRightMessage,
} from './styles';

const Input = forwardRef(
  (
    {
      label,
      onChange,
      type,
      leadingIcon,
      trailingIcon,
      classes,
      id,
      value,
      placeholder,
      labelClasses,
      inputClasses,
      message,
      onKeyDown,
      autofocus,
      validateType,
      errorMessage,
      err,
      ending = '',
      disabled,
      as: As = 'input',
      endingStyle,
      list,
      textarea,
      rightMessage,
      singleErrorMessage,
      ...props
    },
    ref,
  ) => {
    const emailReg =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const phoneReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const [isValid, setIsValid] = useState(true);
    const Tag = textarea ? 'textarea' : 'input';

    useEffect(() => {
      if (validateType && value) {
        switch (validateType) {
        case 'email':
          if (!String(value).toLowerCase().match(emailReg)) {
            setIsValid(false);
          } else {
            setIsValid(true);
          }
          break;
        case 'phone':
          if (!String(value).toLowerCase().match(phoneReg)) {
            setIsValid(false);
          } else {
            setIsValid(true);
          }
          break;
        default:
          setIsValid(true);
        }
      }
    }, [value]);
    return (
      <>
        <StyledInputWrapper err={err}>
          <label className={`label ${labelClasses}`} htmlFor={id}>
            {label}
          </label>
          {leadingIcon || null}
          <As
            ref={ref}
            id={id}
            type={type}
            className={`input ${inputClasses} ${isValid ? '' : 'error'}`}
            value={value}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(e.target.value, e)}
            placeholder={placeholder}
            autoComplete="off"
            autoFocus={autofocus}
            disabled={disabled}
            {...props}
          />
          {list || null}
          {ending && (
            <span className="ending" style={endingStyle}>
              {ending}
            </span>
          )}
          {err && <WarnIcon className="warn" />}
          {message ? <div>{message}</div> : null}
          {trailingIcon || null}
        </StyledInputWrapper>
        {!isValid && errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
        {err && <Err>{err}</Err>}
        {/* Messages */}
        {singleErrorMessage ? (
          <StyledErrorMessage>{singleErrorMessage}</StyledErrorMessage>
        ) : null}
        {rightMessage && (
          <StyledRightMessage>{rightMessage}</StyledRightMessage>
        )}
      </>
    );
  },
);

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  type: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.element,
  singleErrorMessage: PropTypes.element,
  onKeyDown: PropTypes.func,
  autofocus: PropTypes.bool,
  as: PropTypes.string,
};

export default Input;
