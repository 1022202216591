import { PERMISSIONS } from 'constants/permissions';
import OrderOverlay from 'containers/OrderOverlay';
import { usePermission } from 'hooks/usePermission';
import React, { useState } from 'react';
import PaginateTable from 'components/PaginateTable';
import BadgeLabel from 'components/BadgeLabel';
import dayjs from 'dayjs';
import { priceTransformer } from '../../../utils';
import { StyledTable } from './styles';
import {
  NEW,
  IN_PROGRESS,
  CANCELLED_BY_RESTAURANT,
  READY,
  ON_THE_WAY,
  COMPLETED,
} from '../../../constants/status';

function Table({
  categoryTitle,
  loading,
  items,
  pagination,
  resetPaging,
  refetch,
  ...props
}) {
  const hasOrderPermission = usePermission(PERMISSIONS.ORDERS);
  const [selectedOrder, setSelectedOrder] = useState();
  const handleStatus = (state) => {
    let statusName = '';
    let statusClassName = '';
    switch (state) {
    case NEW:
    case NEW:
      statusName = 'New';
      statusClassName = 'New';
      break;
    case IN_PROGRESS:
      statusName = 'In Progress';
      statusClassName = 'in-progress';
      break;
    case ON_THE_WAY:
      statusName = 'On the Way';
      statusClassName = 'on-the-way';
      break;
    case READY:
      statusName = 'Ready';
      statusClassName = 'ready';
      break;
    case COMPLETED:
      statusName = 'Completed';
      statusClassName = 'completed';
      break;
    case CANCELLED_BY_RESTAURANT:
      statusName = 'Cancelled';
      statusClassName = 'cancelled';
      break;

    default:
      break;
    }

    return (
      <BadgeLabel
        label={statusName}
        classes={`status status--${statusClassName?.toLowerCase()}`}
      />
    );
  };

  const handleFormatDate = (d) => (d ? dayjs(d).format('MMM D, hh:mm A') : '-');

  return (
    <StyledTable>
      <OrderOverlay
        orderId={selectedOrder?.id}
        onClose={() => {
          setSelectedOrder(null);
        }}
        data={selectedOrder}
        handleOrderUpdate={refetch}
      />
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: 'Payment date', width: '146px' },
          { title: 'Status', width: '152px' },
          { title: 'Order number', width: '184px' },
          { title: 'Amount', width: '149px' },
          { title: 'Customer', width: 'auto' },
        ]}
        dataArray={items}
        rowCreators={[
          (row, index) => (
            <div className="">{handleFormatDate(row.state_modified)}</div>
          ),
          (row, index) => (
            <div className="strong">{handleStatus(row.state)}</div>
          ),
          (row, index) => <div className="mono">{row?.number}</div>,
          (row, index) => (
            <div className="strong">{priceTransformer(row.value)}</div>
          ),
          (row, index) => (
            <div className="strong">{row?.user_guest || ' - '}</div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={(record) => {
          if (hasOrderPermission) {
            setSelectedOrder(record);
          }
        }}
        newRowClasses="new-row"
        dataPaging={pagination}
      />
    </StyledTable>
  );
}

export default Table;
