import { App, Table } from 'antd';
import { getMerchantById } from 'api/merchants';
import ReportToolbar from 'containers/Reports/components/ReportToolbar';
import ReportLayout from 'containers/Reports/layout';
import { getDateRange, getFilterQuery, tableLocale } from 'containers/Reports/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import columns from './columns';
import DetailSummary from './DetailSummary';
import { fetchDetailMerchantReport } from './requests';

function DetailMerchantReport() {
  const { message } = App.useApp();
  const [filterData, setFilterData] = useState({
    selected: {
      excludeStates: [],
    },
  });
  const [reportData, setReportData] = useState();
  const [pagination, setPagination] = useState({ current: 1, total: 0, needAPICall: false });
  const [loading, setLoading] = useState(true);
  const [merchantInfo, setMerchantInfo] = useState();
  const [merchantLoading, setMerchantLoading] = useState(true);

  const { merchantId } = useParams();

  useEffect(() => {
    setMerchantLoading(true);
    getMerchantById(merchantId)
      .then((res) => {
        if (res.status === 'success') {
          setMerchantInfo(getData(res));
        } else message.error(getData(res)?.title || 'Something went wrong');
        setMerchantLoading(false);
      }).catch((e) => {
        captureException(e);
        setMerchantLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filterData && pagination.needAPICall) {
      getReportData(pagination.current);
    }
  }, [pagination.current]);

  useEffect(() => {
    if (filterData) {
      getReportData(1);
    }
  }, [filterData?.selected]);

  const getReportData = (page) => {
    setLoading(true);

    fetchDetailMerchantReport({ merchantId, query: getFilterQuery(filterData), page })
      .then((res) => {
        setReportData(getData(res)?.results);
        setPagination({ current: page, total: getData(res)?.count, needAPICall: false });
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        setLoading(false);
      });
  };
  const handlePagination = ({ current, total }) => {
    setPagination({ current, total, needAPICall: true });
  };
  const reportSummary = useMemo(() => {
    let subTotal = 0;
    let tax = 0;
    let commission = 0;
    let payment_fee = 0;
    let net = 0;
    reportData?.forEach((data) => {
      subTotal += data.subtotal;
      tax += +data.tax;
      commission += Number(data.commission || 0);
      payment_fee += Number(data.payment_fee || 0);
      net += Number(data.net || 0);
    });
    return {
      subTotal, tax, commission, payment_fee, net,
    };
  }, [reportData]);
  const dateRange = getDateRange({
    range: filterData?.selected?.date?.range,
    separator: '-',
  });
  return (
    <ReportLayout
      title={merchantInfo?.name}
      tag={merchantInfo?.merchant_type}
      description={`Changes to your account from ${dateRange}, based on the date of the account change. Useful to record how your account changed.`}
      backTitle="Total merchants receivable"
      defaultRoute="/console/reports/merchants"
      hasExcludeStateFilter
      loading={merchantLoading}
    >
      <ReportToolbar
        filterData={{ ...filterData }}
        setFilterData={setFilterData}
        hasLocationFilter={false}
        hasExcludeStateFilter
        type={`merchants/${merchantId}`}
      />

      <Table
        columns={columns}
        dataSource={reportData}
        locale={tableLocale}
        scroll={{ y: 800 }}
        summary={() => reportData?.length > 0 && DetailSummary(reportSummary)}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          showTotal: (total) => `${total} results`,
          size: 'small',
          showSizeChanger: false,
          defaultPageSize: 100,
        }}
        loading={loading}
        onChange={handlePagination}
        sticky
      />
    </ReportLayout>
  );
}

export default DetailMerchantReport;
