import { AutoComplete, Flex, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ModifierContext } from '../../../context';
import { getNormalizedFilteredModifiers } from '../../../utils';

function OptionSelector({
  option, setOption, width = '100%', selectedOptions,
}) {
  const { modifiers } = useContext(ModifierContext);
  const [nestedOptions, setNestedOptions] = useState(modifiers.map((mod) => ({
    id: mod.id,
    value: mod.title,
    label: mod.title,
    modifiers_children: mod.options,
  })) || []);

  useEffect(() => {
    const filteredOptions = nestedOptions
      .filter((nested) => !selectedOptions?.find((option) => (option.localId || option.modifiers_children?.[0]?.id) === nested.id));
    setNestedOptions(filteredOptions);
  }, [selectedOptions]);
  const onSelect = (value, option) => {
    setOption(option);
  };

  const getSearchResult = (searchTerm) => (!searchTerm ? [] : getNormalizedFilteredModifiers({ searchTerm, modifiers }));
  return (
    <AutoComplete
      options={nestedOptions}
      style={{ width }}
      onSelect={onSelect}
      defaultValue={option}
      onChange={(value) => setOption({ value })}
      onSearch={(text) => setNestedOptions(getSearchResult(text))}
      placeholder="Write name then enter"
      dropdownStyle={{ width: 350 }}
      optionRender={(option) => (
        <Flex gap={4}>
          <Typography.Text>{option.data.value}</Typography.Text>
          <Typography.Text type="secondary" ellipsis>
            {option.data.modifierOptions?.map((option) => option.title)?.join(', ')}
          </Typography.Text>
        </Flex>
      )}
    />
  );
}
export default OptionSelector;
