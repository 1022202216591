import styled from 'styled-components';

export const StyledWrapper = styled.div`

  .box {
    background: #f5f8f7;
    padding: 16px 24px 52px 24px;
    box-shadow: 0px 0px 0px 1px #dcdddd, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: row;

    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #131b1f;
      margin-bottom: 12px;
    }

    &-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 52px;
      padding: 12px 20px;
      background: #ffffff;
      border-radius: 0px 0px 5px 5px;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  
  .map {
    position: relative;
    width: 100%;
    height: 380px;
    margin-bottom: 16px;
  }
  
`;
