import React from 'react';
import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';

function QuantityInput({
  value: qty,
  max,
  min = 0,
  disabled,
  onChange,
  size = 32,
  ...props
}) {
  const minDisabled = disabled || qty <= min;
  return (
    <Space
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        ...props.style,
      }}
      {...props}
    >
      <Button
        type="text"
        style={{
          display: 'flex',
          width: size,
          height: size,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        disabled={minDisabled}
        onClick={() => {
          onChange(qty - 1);
        }}
      >
        {qty === 1 && !minDisabled ? (
          <DeleteOutlined style={{ color: 'error.light' }} />
        ) : (
          <MinusOutlined />
        )}
      </Button>
      <Typography.Text strong>{qty}</Typography.Text>
      <Button
        type="text"
        style={{
          display: 'flex',
          width: size,
          height: size,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        disabled={disabled || qty >= max}
        onClick={() => {
          onChange(qty + 1);
        }}
      >
        <PlusOutlined />
      </Button>

    </Space>
  );
}

export default QuantityInput;
