import { getMerchantJustById } from 'api/merchants';
import {
  useCallback, useState, useMemo, useContext, useEffect,
} from 'react';
import { captureException } from 'utils/errors';
import { CallInOrderContext } from '../../context';
import { fetchUserCards } from './requests';
import { PaymentTypes } from '../../constants';

function usePaymentMethods({ customPayments }) {
  const { order, setOrderDetails } = useContext(CallInOrderContext);
  const [stripeKey, setStripeKey] = useState('');
  const [cardsLoading, setCardsLoading] = useState(false);
  const [cards, setCards] = useState([]);

  const getMerchantDetail = () => {
    if (order.selectedMerchant?.value) {
      getMerchantJustById(order.selectedMerchant.value).then((res) => {
        setStripeKey(res?.data?.stripe_api_public_key);
        setOrderDetails({ selectedMerchant: { ...order.selectedMerchant, merchant: res?.data } });
      });
    }
  };

  useEffect(() => {
    getMerchantDetail();
  }, [order.selectedMerchant?.value]);

  const getUserCards = () => {
    setCardsLoading(true);
    fetchUserCards(order.userId).then((res) => {
      if (res?.data) {
        setCards(res?.data);
        setCardsLoading(false);
        setOrderDetails({ cardId: res?.data?.length ? res?.data[0]?.id : null });
      }
    }).catch((e) => {
      captureException(e);
      setCardsLoading(false);
    });
  };

  useEffect(() => {
    if (order.paymentMethod?.type === PaymentTypes.CARD && order.userId) getUserCards();
  }, [order.userId, order.paymentMethod]);

  const paymentMethods = useMemo(
    () => [
      {
        id: PaymentTypes.CARD,
        title: 'Credit card',
        type: PaymentTypes.CARD,
      },
      ...customPayments?.map((pm) => ({
        ...pm,
        type: PaymentTypes.CUSTOM,
      })),
      {
        id: PaymentTypes.IN_STORE,
        title: 'Paid by merchant',
        type: PaymentTypes.IN_STORE,
      },
    ],
    [cards, customPayments],
  );

  const reFetch = useCallback(() => {
    getUserCards();
  }, [getUserCards]);

  return useMemo(
    () => ({
      paymentMethods,
      loading: cardsLoading,
      reFetch,
      stripeKey,
      cards,
    }),
    [
      paymentMethods,
      cardsLoading,
      reFetch,
      stripeKey,
      cards,
    ],
  );
}

export default usePaymentMethods;
