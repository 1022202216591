import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Radio, Row, Space, Tooltip, Typography,
} from 'antd';
import { fetchCustomerAddresses } from 'api/address';
import { OrderType } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useEffect, useState } from 'react';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import AddAddressModal from './AddAddressModal';

function CustomerAddresses() {
  const { order, setOrderDetails } = useContext(CallInOrderContext);

  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [isAddAddressOpen, setIsAddressOpen] = useState(false);

  useEffect(() => {
    if (order.orderType === OrderType.Delivery && order.userId) {
      getCustomerAddresses();
    }
  }, [order.userId, order.orderType]);

  const handleSelectAddress = (value) => {
    setOrderDetails({ address: value });
  };
  const getCustomerAddresses = () => {
    setLoading(true);
    setAddresses([]);
    fetchCustomerAddresses(order.userId)
      .then((res) => {
        const data = getData(res);
        setAddresses(data.addresses);
        handleSelectAddress(data?.addresses?.length ? data?.addresses[0]?.id : null);
      })
      .catch(captureException)
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <AddAddressModal
        showAddAddressModal={isAddAddressOpen}
        setShowAddAddressModal={setIsAddressOpen}
        onSave={() => getCustomerAddresses()}
      />
      {loading ? <LoadingOutlined /> : (
        <>
          <Typography.Text className="input-title" style={{ marginTop: '20px' }}>Address</Typography.Text>
          <Row gutter={20} align="middle" style={{ marginTop: '10px' }}>
            <Radio.Group
              name="address"
              defaultValue={order.address}
            >
              <Space style={{ marginLeft: '10px' }} size={[8, 10]} wrap>
                {addresses?.map((addrs) => (
                  <Radio.Button
                    style={{
                      width: '200px',
                      background: '#f5f7f8',
                      height: 40,
                    }}
                    key={addrs.id}
                    value={addrs.id}
                    onChange={(event) => {
                      handleSelectAddress(event?.target?.value);
                    }}
                  >
                    <Tooltip
                      placement="topLeft"
                      title={
                        `${addrs?.title ? `(${addrs?.title})` : ''}${
                          addrs?.address}`
                      }
                    >
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          height: '100%',
                          paddingTop: 5,
                        }}
                      >
                        {`${addrs?.title ? `(${addrs?.title})` : ''}`}
                        {addrs?.address}
                      </div>
                    </Tooltip>
                  </Radio.Button>
                ))}
                {loading && <LoadingOutlined />}

                <Button
                  key="add-order"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAddressOpen(true)}
                  type="default"
                  disabled={!order.userId}
                >
                  Add
                </Button>
              </Space>
            </Radio.Group>
          </Row>
        </>
      )}
    </>
  );
}

export default CustomerAddresses;
