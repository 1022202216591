import React, {
  useMemo, useRef, useState,
} from 'react';
import {
  Form, Select,
} from 'antd';
import { debounce } from 'lodash-es';

import { fetchMerchants } from './requests';

function MerchantInput() {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchRef = useRef(0);

  const debouncedMerchantFetcher = useMemo(() => {
    const loadOptions = (query) => {
      if (!query) {
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setLoading(true);
      fetchMerchants({ query }).then(({ data: { results } }) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(results.map((r) => ({
          label: r.name,
          value: r.id,
        })));
        setLoading(false);
      });
    };
    return debounce(loadOptions, 500);
  }, []);

  return (

    <Form.Item
      label="Merchant"
      name="merchant"
      rules={[{ required: true }]}
    >

      <Select
        showSearch
        labelInValue
        filterOption={false}
        loading={loading}
        options={options}
        onSearch={debouncedMerchantFetcher}
      />
    </Form.Item>

  );
}

export default MerchantInput;
