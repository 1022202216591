import * as Sentry from '@sentry/react';
import { App as AntApp, ConfigProvider } from 'antd';
import AdminProvider from 'components/AdminProvider';
import Intercom from 'components/Intercom';
import NewOrdersProvider from 'components/NewOrdersProvider';
import OrganizationConfigProvider from 'components/OrganizationConfigProvider';
import { getBaseURL } from 'constants';
import React, { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { getQueryStringValue } from 'utils';
import { SessionJsonStorage } from 'utils/storage';

import Routes from 'routes';
import './App.css';
import ErrorBoundary from './components/errorBoundary';
import ErrorFallback from './components/errorFallback';
import { ModalProvider } from './context/modalContext';

const theme = {
  primary: '#9f51b0',
  dark: '#222',
  orange: '#FF7D00',
  orangeLow: '#FF7D0099',
};

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
          }),
        ],
      });
    }

    const baseURL = getQueryStringValue('baseURL');
    if (baseURL) {
      SessionJsonStorage.set({
        key: 'baseURL',
        value: baseURL,
      });
      window.location.replace(window.location.pathname);
    }
  }, []);
  const baseURL = getBaseURL();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {baseURL.customized && (
        <div style={{
          background: 'crimson',
          color: '#fff',
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 10000,
          fontSize: 12,
        }}
        >
          baseURL:
          {' '}
          {baseURL.url}
        </div>
      )}
      <AdminProvider>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                addonBg: 'white',
              },
            },
            token: {
              colorPrimary: '#02862F',
              colorSuccess: '#02862F',
              colorError: '#E42326',
              colorLink: '#02862F',
              colorLinkHover: '#02862F',
              borderRadius: 4,
              fontSize: 14,
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <AntApp>
              <OrganizationConfigProvider>
                {
                  ['prod', 'stage'].includes(process.env.REACT_APP_ENV) && (
                    <Intercom />
                  )
                }
                <ModalProvider>
                  <Router basename={process.env.REACT_APP_BASENAME || ''}>
                    <NewOrdersProvider>
                      <Routes />
                    </NewOrdersProvider>
                  </Router>
                </ModalProvider>
              </OrganizationConfigProvider>
            </AntApp>
          </ThemeProvider>
        </ConfigProvider>
      </AdminProvider>
    </ErrorBoundary>
  );
}

export default App;
