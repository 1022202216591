import { Button, Tag } from 'antd';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import ActivateModal from 'containers/Coupons/ActivateModal';
import { getCouponById } from 'containers/Coupons/coupons';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router'
import { captureException } from 'utils/errors';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import { ModalContext } from '../../context/modalContext';
import CouponForm from './Form';
import { StyledWrapper } from './styles';

function CouponsForm() {
  const [couponDataLoading, setCouponDataLoading] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleGoToListPage = () => {
    navigate('/console/coupons');
  };
  const {
    modal, handleModal, modalContent, hideCloseBtn, classes,
  } =
    useContext(ModalContext);

  const handleActivate = () => {
    if (couponData) {
      handleModal(
        <ActivateModal row={couponData} reload={handleGoToListPage} />,
      );
    }
  };

  useEffect(() => {
    if (id) {
      // Get coupons
      setCouponDataLoading(true);
      getCouponById(id)
        .then((data) => {
          data.holder_merchant = {
            label: data?.holder_merchant?.name,
            value: data?.holder_merchant?.id,
          };
          data.merchants = data?.merchants?.map((mer) => ({
            label: mer.name,
            value: mer.id,
          }));
          data.parents = data?.parents?.map((pr) => ({
            label: pr.title,
            value: pr.id,
          }));
          setCouponData(data);
          setCouponDataLoading(false);
        })
        .catch((e) => {
          captureException(e);
          setCouponDataLoading(false);
        });
    }
  }, [id]);

  return (
    <Layout header={(
      <Layout.Header
        title={couponData?.code || 'New Coupon'}
        loading={couponDataLoading}
        tag={id && (
          <Tag className="tag-margin" color={couponData?.is_disabled ? 'red' : 'green'}>
            {couponData?.is_disabled ? 'Deactive' : 'Active'}
          </Tag>
        )}
        backTitle="Coupons"
        backPath="/console/coupons"
        extra={id && [
          <Button
            type="default"
            loading={couponDataLoading}
            onClick={handleActivate}
          >
            {couponData
              ? couponData.is_disabled
                ? 'Activate'
                : 'Deactive'
              : undefined}
          </Button>,
        ]}
      />
    )}
    >
      <StyledWrapper>
        {/* Form */}
        {id && couponDataLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <CouponForm data={couponData} couponLoading={couponDataLoading} />
        )}
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(CouponsForm, [PERMISSIONS.COUPONS], { redirect: true });
