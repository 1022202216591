import React, { useState } from 'react';
import { Input } from 'antd';
import AddAddressModal from './AddAddressModal';

function AddressInput({ value, onChange, ...props }) {
  const [open, setOpen] = useState(false);

  console.log('addd', value);
  return (
    <>
      <Input
        onFocus={() => setOpen(true)}
        type="text"
        placeholder="address"
        value={value?.text ?? ''}
        {...props}
      />

      <AddAddressModal open={open} value={value} onChange={onChange} onClose={() => setOpen(false)} />
    </>

  );
}

export default AddressInput;
