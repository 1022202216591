import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import useDebouncedEffect from 'use-debounced-effect';

function SearchItemInput({ onChange, ...props }) {
  const [value, setValue] = useState(props.value);

  useDebouncedEffect(() => {
    onChange(value);
  }, 1000, [value]);

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder="Lovely burger"
      onChange={(e) => setValue(e.target.value)}
      {...props}
      value={value}
    />
  );
}

export default SearchItemInput;
