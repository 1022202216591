import React from 'react';
import './index.css';

function ReportTitle({ title, description }) {
  return (
    <div className="report-title-section">
      <div className="report-title">{title}</div>
      <div className="report-description">{description}</div>
    </div>
  );
}
export default ReportTitle;
