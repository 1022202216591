import http from 'utils/http';
import { generateQuery } from '../utils';

export const getOverviews =
  (start, end, tz) => {
    const query = generateQuery([
      start ? `start=${start}` : '',
      end ? `end=${end}` : '',
      tz ? `tz=${encodeURIComponent(tz)}` : '',
    ]);

    return http
      .get(`v1/organiztions/{{organizationId}}/analytics/overview/${query}`)
      .then((res) => res.data);
  };

export const getTopMerchants =
  (start, end, tz) => {
    const query = generateQuery([
      start ? `start=${start}` : '',
      end ? `end=${end}` : '',
      tz ? `tz=${encodeURIComponent(tz)}` : '',
    ]);

    return http
      .get(`v1/organiztions/{{organizationId}}/analytics/top-merchants/${query}`)
      .then((res) => res.data);
  };

export const getReviews =
  (start, end, tz) => {
    const query = generateQuery([
      start ? `start=${start}` : '',
      end ? `end=${end}` : '',
      tz ? `tz=${encodeURIComponent(tz)}` : '',
    ]);

    return http
      .get(`v1/organiztions/{{organizationId}}/analytics/reviews/${query}`)
      .then((res) => res.data);
  };

export const getAvgNps =
  (start, end, tz) => {
    const query = generateQuery([
      start ? `start=${start}` : '',
      end ? `end=${end}` : '',
      tz ? `tz=${encodeURIComponent(tz)}` : '',
    ]);
    return http
      .get(`v1/organiztions/{{organizationId}}/analytics/avg-nps/${query}`)
      .then((res) => res.data);
  };
