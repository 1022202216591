import { Input, Select } from 'antd';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import { SIC_LIST } from '../constants';
import React from 'react';

function GeneralInfo({ isEditMode }) {

  return (
    <FormLayout.Section title={isEditMode ? 'General info' : ''} divider={false}>
      <FormItem name="name" label="Company name" rules={[{ required: true, message: 'Please specify the name' }]}>
        <Input/>
      </FormItem>
      <FormItem name="website" label="Website">
        <Input addonBefore="https://"/>
      </FormItem>
      {isEditMode && (
        <>
          <FormItem name="employee_count" label="Employee count"
                    rules={[{
                      type: 'number',
                      transform: (value) => Number(value || undefined),
                      message: 'Employee count is not a valid number' }]}>
            <Input type="number"/>
          </FormItem>
          <FormItem name="sic_category" label="SIC category">
            <Select options={SIC_LIST}/>
          </FormItem>
        </>
      )}
    </FormLayout.Section>
  );
}

export default GeneralInfo;
