import { Typography } from 'antd';
import { fetchMerchantsListByEnability } from 'api/merchants';
import DebounceSelect from 'components/Select/DebounceSelect';
import SimpleModal from 'components/SimpleModal';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';

function SearchMerchant() {
  const { order, setOrderDetails } = useContext(CallInOrderContext);
  const [tempMerchant, setTempMerchant] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const getMerchants = (searchKey = '') => fetchMerchantsListByEnability(false, searchKey)
    .then((res) => {
      if (res.status === 'success') {
        const fetchedMerchants = res.data.results?.map((m) => ({
          label: m.name, value: m.id, merchant: m, key: m.id,
        }));
        return fetchedMerchants;
      }
    })
    .catch((e) => {
      captureException(e);
    });

  const onSelectMerchant = (option) => {
    if (order.items?.length) {
      setTempMerchant(option);
      setShowConfirmModal(true);
    } else {
      setOrderDetails({ selectedMerchant: option, scheduledFor: '' });
    }
  };
  const onChangeConfirm = () => {
    setOrderDetails({ items: [], selectedMerchant: tempMerchant, scheduledFor: '' });
  };

  return (
    <>
      <Typography.Text>
        Merchant
      </Typography.Text>
      <DebounceSelect
        id="merchants"
        allowClear
        placeholder="Select merchants"
        fetchOptions={getMerchants}
        onChange={(value, option) => {
          onSelectMerchant(option);
        }}
        value={order.selectedMerchant?.value}
        showSearch
        size="large"
        style={{ width: '100%', height: 40, marginTop: 8 }}
      />
      <SimpleModal
        title="Confirm action"
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onSubmit={onChangeConfirm}
      >
        <Typography.Text>
          Upon confirming this action, the selected items will be deleted, and you should choose items from the new
          menu.
        </Typography.Text>
      </SimpleModal>
    </>
  );
}

export default SearchMerchant;
