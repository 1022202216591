import {
  App, Button, Space, message,
} from 'antd';
import { ExportIcon } from 'assets/icons';
import { getDateRange, getFilterQuery } from 'containers/Reports/utils';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import ExportToCSVModal from './ExportModal';
import './index.css';
import { fetchCSVReport } from './requests';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';

function ExportToCSVAction(
  {
    filterData, hasLocationFilter, hasOrderTypeFilter, hasMerchantFilter, type, disabled,
  },
) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message: messageApi, modal: modalApi } = App.useApp();
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const handleExport = (filters) => {
    const exportFilter = {
      options: filterData.options,
      selected: filters,
    };

    setLoading(true);
    fetchCSVReport({ type, query: getFilterQuery(exportFilter), tz: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}_reports(${getDateRange({ range: filterData.selected?.date?.range })}).csv`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        setOpen(false);
      })
      .catch((e) => {
        captureException(e);
        messageApi.open({
          type: 'error',
          content: 'Something went wrong during export report!',
        });
        setLoading(false);
        setOpen(false);
      });
  };

  function handleBulkExport() {
    setLoading(true);
    fetchCSVReport({
      type, bulk: true, query: getFilterQuery(filterData), tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((res) => {
        modalApi.success({
          title: 'Report generated successfully',
          content: 'Please check your email!',
        });
      })
      .catch((e) => {
        captureException(e);
        messageApi.open({
          type: 'error',
          content: 'Something went wrong during export report!',
        });
        setLoading(false);
        setOpen(false);
      });
  }
  return (
    <>
      <Space>
        {
          isAdmin && type === 'merchants' && (
            <Button
              danger
              onClick={handleBulkExport}
              disabled={disabled || loading}
            >
              Export All to CSV
            </Button>
          )
        }

        <Button
          icon={<ExportIcon className="export-icon" />}
          onClick={() => setOpen(true)}
          disabled={disabled || loading}
        >
          Export to CSV
        </Button>
      </Space>

      <ExportToCSVModal
        open={open}
        setOpen={setOpen}
        loading={loading}
        handleExport={handleExport}
        filterData={filterData}
        hasLocationFilter={hasLocationFilter}
        hasOrderTypeFilter={hasOrderTypeFilter}
        hasMerchantFilter={hasMerchantFilter}
      />
    </>
  );
}

export default ExportToCSVAction;
