export function getImage({ data, size = 'sm' }) {
  if (!data) return null;
  const SIZE_MAP = {
    xs: 'thumbnail',
    sm: 'small',
    md: 'med',
    lg: 'large',
  };
  return data.images?.[0]?.[`image_${SIZE_MAP[size]}`];
}
