import { Table } from 'antd';
import { tableLocale } from 'containers/Reports/utils';
import React, { useMemo } from 'react';
import ReportTitle from '../components/ReportTitle';
import { transformReportLabel } from '../utils';
import columns from './columns';

function OrderSourceReport({ reportData, loading, dateRange }) {
  const getReportItem = (reportItem, key, totalOrders) => ({
    key,
    category: transformReportLabel(key),
    amount: reportItem[3],
    percentage: (reportItem[3] / (totalOrders || 1) * 100).toFixed(2),
  });

  const convertedReportData = useMemo(() => {
    if (!reportData) return [];

    return reportData ?
      Object.keys(reportData)?.filter((k) => k !== 'totals').map((key) => getReportItem(reportData[key], key, reportData.totals[3]))
      : {};
  }, [reportData]);

  return (
    <div className="report-section">
      <ReportTitle
        title="Order source"
        description="Here you can see how many orders you received from different sources and compare them."
      />
      <Table
        columns={columns}
        dataSource={convertedReportData}
        showHeader={false}
        pagination={false}
        locale={tableLocale}
        loading={loading}
      />
      <div className="report-footer">
        Results from
        {dateRange}
      </div>
    </div>
  );
}
export default OrderSourceReport;
