import { Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import { truncate } from 'lodash-es';
import React, { useState } from 'react';

const { Text } = Typography;
function NoteEvent({ event: { data: note, occurred_at: date } }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [open, setOpen] = useState(false);
  const text = note.note_html.replace(/<[^>]+>/g, '');

  return (
    <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-full" onClick={() => text.length >= 70 && setOpen(!open)}>
      <Flex justify="space-between" vertical={isMobile} className="w-full">
        <Text className="font-bold text-[16px]">
          Note
        </Text>
        <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
      </Flex>

      <Flex
        vertical={isMobile}
        justify="space-between"
        align="flex-start"
        gap={4}
      >
        <Text style={{ height: open ? 'auto' : '20px', overflow: 'hidden' }}>
          {open ? text : truncate(text, { length: 70, omission: '...' })}
        </Text>
        {
          !open && text.length >= 70 && (
            <Button type="link" className="pr-0">
              Show more
            </Button>
          )
        }
      </Flex>
    </Flex>
  );
}
export default NoteEvent;
