export async function reverseGeoCode(latLng) {
  await waitUntil(() => window.google?.maps, 20 * 1000);
  const { Geocoder } = await window.google.maps.importLibrary('geocoding');
  const geocoder = new Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { location: latLng },
      (results, status) => {
        if (status === 'OK') {
          resolve(results[0].formatted_address);
          return;
        }
        reject(new Error(status));
      },
    );
  });
}

async function waitUntil(condition, time = 10 * 1000) {
  const start = Date.now();
  return new Promise((resolve, reject) => {
    check();

    function check() {
      if (condition()) {
        resolve();
        return;
      }
      if (Date.now() - start > time) {
        reject(new Error(`Loading Timeout ${condition}`));
        return;
      }
      setTimeout(check, 500);
    }
  });
}
