export const STEPS = {
  GENERAL_INFO: {
    title: 'General Info',
    fields: ['merchant', 'customer'],
    id: 1,
  },
  DELIVERY_INFO: {
    title: 'Delivery / Pickup instructions',
    fields: ['has_delivery', 'address', 'scheduled_for', 'recipient_name', 'recipient_last_name', 'recipient_phone'],
    id: 2,
  },
  ITEMS: {
    title: 'Items',
    fields: ['headcount', 'items'],
    id: 3,
  },
  PAYMENT_INFO: {
    title: 'Payment info',
    fields: ['payment_method', 'card_id'],
    id: 4,
  },
  ADDITIONAL_DETAILS: {
    title: 'Additional Details',
    fields: [],
    id: 5,
  },
};
