import { MERCHANT_TYPE } from 'constants/index';
import React from 'react';

export const TABS = [
  {
    label: 'All',
    param: '',
    id: 1,
    key: 1,
  },
  {
    label: 'Completed',
    param: { title: 'need_actions', value: false },
    id: 2,
    key: 2,
  },
  {
    label: 'Need action',
    param: { title: 'need_actions', value: true },
    id: 3,
    key: 3,
  },
  {
    label: 'Catering',
    param: { title: 'is_catering', value: true },
    id: 4,
    key: 4,
  },
  {
    label: 'Partner',
    param: { title: 'merchant_type', value: MERCHANT_TYPE.Partner },
    id: 5,
    key: 5,
  },
  {
    label: 'Non-Partner',
    param: {
      title: 'merchant_type',
      value: MERCHANT_TYPE.NonPartner,
    },
    id: 6,
    key: 6,
  },
  {
    label: 'Omni-Merchant',
    param: { title: 'merchant_type', value: MERCHANT_TYPE.Omni },
    id: 7,
    key: 7,
  },
  {
    label: 'Disabled',
    param: { title: 'is_disabled', value: true },
    id: 8,
    key: 8,
  },
];

export const SORT_OPTIONS = [
  {
    title: 'Spent amount',
    element: <div className="">Spent amount</div>,
  },
  {
    title: 'Orders',
    element: <div className="">Orders</div>,
  },
  {
    title: 'Order date',
    element: <div className="">Order date</div>,
  },
];
