import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
import React, { useContext } from 'react';
import { MenuContext } from './context';
import MenuActions from './MenuActions';
import MenuItemsTable from './MenuItemsTable';

function OverviewContainer() {
  const { loading } = useContext(MenuContext);

  return (
    <Flex vertical gap="middle">
      {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 36, marginTop: 24 }} spin />} />}
      {!loading && (
        <>
          <MenuActions />
          <MenuItemsTable />
        </>
      )}
    </Flex>
  );
}

export default OverviewContainer;
