/* eslint-disable camelcase */
import { App, Button, Drawer, Flex, Form, Input, Skeleton, Spin } from 'antd';
import { fetchCustomers } from 'api/customers';
import FormItem from 'components/FormItem';
import FormLayout from 'components/FormLayout';
import { EMPTY_OBJECT } from 'constants';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { getHttpErrorMessage } from 'utils/http';
import { captureException } from 'utils/errors';

function DrawerForm({
  data = EMPTY_OBJECT,
  loading,
  open,
  onClose,
  onSubmit,
  onDelete,
}) {

  return (
    <Drawer
      title={`${Boolean(data?.id) ? 'Edit' : 'Add'} contact`}
      placement="right"
      onClose={onClose}
      open={open}
      size="large"
      styles={{ body: { padding: 0, paddingBottom: 64 } }}
    >
      {loading ? (
        <FormLayout.Section divider={false}>
          <Skeleton paragraph={{ rows: 10 }}/>
        </FormLayout.Section>
      ) : (
        <ContactForm
          data={data}
          onSubmit={onSubmit}
          onDelete={onDelete}
          onClose={onClose}
          open={open}
        />

      )}
    </Drawer>
  );
}

function ContactForm({
  data, onSubmit, onDelete, onClose, open,
}) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerHasAccount, setCustomerHasAccount] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const isEditMode = Boolean(data?.id);
  useEffect(() => {
    if (!data) {
      return;
    }
    form.setFieldsValue(data);
  }, [data, form]);
  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsComplete(false);
      setCustomerHasAccount(false);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };
  const handleSubmit = async (data) => {
    setLoading(true);
    return onSubmit(serialize(data))
      .then((res) => {
        message.success(`Contact ${isEditMode ? 'updated' : 'created'} successfully!`);
        handleClose();
      })
      .catch((e) => {
        message.error(getHttpErrorMessage(e, false));
        captureException(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchCustomer = (value) => {
    setCustomerLoading(true);
    fetchCustomers({ searchKey: value })
      .then((res) => {
        setCustomerHasAccount(Boolean(res.data.results.length));
        if (res.data.results.length) {
          form.setFieldValue('customer_data', res.data.results[0]);
        } else {
          form.setFieldValue('customer_data', { phone: value });
        }
      })
      .catch((e) => {
        setCustomerHasAccount(false);
        captureException(e);
      })
      .finally(() => setCustomerLoading(false));
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ marginTop: 24 }}
    >
      <FormLayout.Section divider={false}>
        <FormItem name={['customer_data', 'id']} hidden/>
        <FormItem name={['customer_data', 'phone']} label="Phone number"
                  rules={[{ required: true, message: 'Please enter customer phone' }]}>
          <PhoneInput
            country="us"
            buttonClass="phone-country"
            inputClass="input"
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            onChange={(value, data, event, formattedValue) => {
              // Check if the phone number is complete based on the country format
              const phoneIsComplete = formattedValue.length >= data.format.length;
              if (phoneIsComplete) {
                setIsComplete(true);
                searchCustomer(value);
              } else if (isComplete) {
                setIsComplete(false);
              }
            }}
            inputStyle={{ width: '100%' }}
            placeholder="Enter phone number"
            autofocus
            preferredCountries={['us', 'ca']}
            enableSearch
            regions={['america', 'europe']}
            countryCodeEditable={false}
            masks={{
              pr: '(...) ...-....',
            }}
            disabled={isEditMode || customerLoading}
          />
        </FormItem>
        {customerLoading && (
          <Flex className="mt-4">
            <Spin size="large"/>
          </Flex>
        )}
        {((!customerLoading && isComplete) || isEditMode) && <>
          <FormItem name={['customer_data', 'name']} label="Full name"
                    rules={[{ required: true, message: 'Enter customer full name' }]}>
            <Input disabled={isEditMode || customerHasAccount}/>
          </FormItem>
          <FormItem name={['customer_data', 'email']} label="Email"
                    rules={[{ required: true, type: 'email', message: 'Enter a valid email' }]}>
            <Input disabled={isEditMode || customerHasAccount}/>
          </FormItem>
          <FormItem name="role" label="Role">
            <Input/>
          </FormItem>
        </>}

      </FormLayout.Section>
      <FormLayout.Footer>
        <Flex justify={isEditMode ? 'space-between' : 'flex-end'} className="w-full">
          {isEditMode && onDelete && (
            <Button danger disabled={loading} onClick={onDelete}>
              Delete
            </Button>
          )}
          <Flex gap={4}>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {isEditMode ? 'Save' : 'Add'}
            </Button>
          </Flex>
        </Flex>
      </FormLayout.Footer>
    </Form>
  );
}

function serialize({ customer_data, role }) {
  return {
    customer_data: customer_data.id ? { id: customer_data.id } : { ...customer_data, phone: `+${customer_data.phone}` },
    role,
  };
}

export default DrawerForm;
