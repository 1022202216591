import React from 'react';
import Icon from '@ant-design/icons';

const SuccessDocumentSVGIcon = function () {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M53.8052 49.7826L42.8152 60.7726C42.2902 61.2626 41.6252 61.5076 40.9602 61.5076C40.2952 61.5076 39.6302 61.2626 39.1052 60.7726L33.4702 55.1026C32.4202 54.0876 32.4202 52.4426 33.4702 51.3926C34.4852 50.3776 36.1302 50.3776 37.1802 51.3926L40.9602 55.1726L50.0952 46.0726C51.1102 45.0576 52.7552 45.0576 53.8052 46.0726C54.8202 47.0876 54.8202 48.7676 53.8052 49.7826ZM60.8402 31.0227H24.1602C16.8102 31.0227 10.8252 37.0426 10.8252 44.3926V62.4176C10.8252 69.8026 16.8102 75.7876 24.1602 75.7876H60.8402C68.1902 75.7876 74.1752 69.8026 74.1752 62.4176V44.3926C74.1752 37.0426 68.1902 31.0227 60.8402 31.0227Z" fill="#DAEADE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.0156 13.6626H53.9826C55.4316 13.6626 56.6076 12.4866 56.6076 11.0376C56.6076 9.5886 55.4316 8.4126 53.9826 8.4126H31.0156C29.5666 8.4126 28.3906 9.5886 28.3906 11.0376C28.3906 12.4866 29.5666 13.6626 31.0156 13.6626Z" fill="#DAEADE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.1357 24.5557H62.8617C64.3107 24.5557 65.4867 23.3797 65.4867 21.9307C65.4867 20.4817 64.3107 19.3057 62.8617 19.3057H22.1357C20.6867 19.3057 19.5107 20.4817 19.5107 21.9307C19.5107 23.3797 20.6867 24.5557 22.1357 24.5557Z" fill="#DAEADE" />
    </svg>

  );
};
function SuccessDocumentIcon(props) {
  return (
    <Icon component={SuccessDocumentSVGIcon} {...props} />
  );
}

export default SuccessDocumentIcon;
