import { colors } from 'constants';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;

  @media (max-width: 900px) {
    width: 100vw;
  }

  .map {
    position: relative;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    overflow: hidden;
    margin-bottom: 16px;
  }

  .currentLocation {
    color: ${colors.primary1};

    &-box {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
      border-radius: 100px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 42px;
      height: 42px;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }

    &--down {
      bottom: 105px;
    }

    &--disabled {
      opacity: 0.2;
      cursor: initial;
    }
  }


  .content {
    margin-bottom: 0;
    padding: 16px 20px;
    /* height: 332px; */
    padding-bottom: 0;
    width:100%

    .input-box {
      width: 100%;
      margin-top: 14px;
    }

    &-sm-address {
      max-width: 167px;
      white-space: nowrap;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text {
    margin-bottom: 12px;
    color: "#131B1F;
    font-weight: 600;
  }

  .b-btn {
  }

  .tools {
    width: 60px;
  }

  .card-num {
    font-weight: 600;
    font-size: 16px;
  }

  .ex {
    color: #5A5F62;
    font-weight: 500;
    font-size: 14px;
  }

  .edit-icon {
    font-size: 20px;
    margin-right: 18px;
    color: 1px solid #131B1F;
    cursor: pointer;
  }

  .search-input {
    width: calc(100% - 32px) !important;
    position: absolute !important;
    top: 85px;
    left: 16px;
    z-index: 2;
    height: 48px;

    input {
      width: 100% !important;
      height: 48px;
      padding: 14px 48px;
      border-radius: 4px;
      border: 1px solid #d0d1d2;
    }

    .clear {
      right: 16px;
    }
  }

  .list {
    top: 113%;
    box-shadow: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    color: #131b1f;
    padding: 0 !important;
    overflow: hidden;
    border: 1px solid #d0d1d2;
    width:100%

    .text {
      font-size: 14px !important;
      color: #131b1f !important;
      font-weight: 400 !important;
      width: 100%;
    }

    .not-found {
      font-weight: 300 !important;
      color: #5a5f62 !important;
    }

    div {
      width: 100%;
      padding-left: 17px !important;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f6f6f6;

        .text {
          color: #131b1f !important;
        }
      }
    }
  }

  .content-add {
    padding: 0 20px 16px;

    @media (max-width: 900px) {
      padding: 20px 16px;
      position: fixed;
      background: white;
      width: 100%;
      bottom: 77px;
    }

    &-title {
      color: #5A5F62;
      margin-bottom: 12px;
    }

    &-address {
      width: 91%;
      height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .info-icon {
    font-size: 19px;
    margin-right: 4px;
  }

  .c-row {
    display: flex;
    align-items: center;

    &-left {
      justify-content: flex-start !important;
    }
  }

  .adjust-btn {
    font-size: 14px;
    color:#42494C";
    white-space: nowrap;

    svg {
      font-size: 16px;
      margin-right: 4px;
      color: #42494C;
    }
  }

  .loc-icon {
    margin-right: 20px;
  }

  .delete-icon {
    margin-right: 0 !important;
  }

  .details-input {
  }

  .textarea {
    height: 108px;
  }
`;
