import styled from 'styled-components';

export const StyledPaginate = styled.div`
  overflow: auto hidden;
  table {
    width: calc(100% - 80px);
    margin: 16px 40px;
    margin-bottom: 14px;
    position: relative;

    th {
      color: #1a1f36;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 8px;
      text-transform: uppercase;
      min-width: 80px;
      padding-left: 10px;
    }

    td {
      color: #3c4257;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      height: 38px;
      padding-left: 10px;
    }

    tr {
      border-bottom: 1px solid #e1e5ec;

      &:hover {
        td {
          background-color: #f8f9fa;
          cursor: pointer;
        }
      }
    }
  }

  .info {
    color: #8a92a1;
    margin-left: 4px;
    margin-top: -2px;
  }

  @keyframes table-down-to-up {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .tooltip {
    width: 252px;
    background: #f9f9fa;
    border: 0.5px solid rgba(118, 118, 118, 0.28);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #4a5256;
    right: -30px;
    left: unset !important;
    height: fit-content !important;
    top: 25px;
    animation: table-down-to-up 0.5s;
    padding: 9px 12px !important;
    color: #4a5256 !important;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 18px !important;

    &:before {
      right: 32px;
      left: unset !important;
    }
  }

  .no-border {
    border-bottom: none !important;
  }

  .th-range {
    padding-bottom: 54px;
    border-bottom: none;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      border-bottom: 1px solid #e1e5ec;
    }
  }

  .paid {
    background: #dbf5d6;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px 2px 4px;
    color: #187c32;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
    height: 22px;

    span {
      margin-left: 2px;
    }
  }

  .refund {
    color: #a92221;
    background: #fce9eb;
  }

  .bold {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    margin-bottom: 24px;
  }

  .detailed-footer {
    margin-top: 24px;
    margin-bottom: 0;
    margin-top: 24px;
    margin-bottom: 0;
    position: absolute;
    width: calc(100% - 48px);
    bottom: 24px;
  }

  .results {
    font-size: 14px;
    line-height: 18px;
    color: #1a1f36;
    padding-left: 50px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .view-all {
    color: #3765f0;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    padding-right: 50px;
    margin-bottom: 0;

    &:hover {
      color: #0f58d5;
    }

    svg {
      margin-left: 4px;
      &:hover {
        color: #0f58d5;
      }
    }
  }

  .export-all {
    position: relative;
    margin: 0 0 0 15px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    color: #4f566a;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
      0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 6px 8px;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }

  .no-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #6e7579;
      margin-top: 16px;
      width: 245px;
      text-align: center;
    }
  }

  .center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    svg {
      margin: 0;
      width: 15px;
      height: 15px;
    }
  }
`;
