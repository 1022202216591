import { Divider, Flex } from 'antd';
import React from 'react';
import { DAYS } from 'constants';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import WorkingDayInput from './WorkingDayInput';

dayjs.extend(dayjsDuration);

function ActiveHoursInput({ value = {}, onChange }) {
  return (
    <Flex vertical gap="middle">
      {Object.entries(DAYS).map(([key, title], index) => (
        <>
          <WorkingDayInput
            key={key}
            title={title}
            onChange={(v) => onChange({ ...value, [key]: v })}
            value={value[key]}
          />
          {index < DAYS.length - 1 && <Divider style={{ margin: 0 }} />}
        </>
      ))}
    </Flex>
  );
}

ActiveHoursInput.serialize = (activeHours) => Object.entries(activeHours || {})
  .flatMap(([weekday, { hours, isClosed }]) => (isClosed ? null : (hours || [])
    .filter(([start, end]) => start && end)
    .map(([start, end]) => ({
      weekday,
      from_hour: start.format('HH:mm:ss'),
      duration: dayjs.duration(end.diff(start)).format('HH:mm:ss'),
    }))))
  .filter(Boolean);

ActiveHoursInput.deserialize = (activeTimes) => Object.keys(DAYS)
  .reduce((acc, day) => {
    const hours = (activeTimes || [])
      .filter((a) => a.weekday === day)
      .sort(({ from_hour: a }, { from_hour: b }) => dayjs(a, 'HH:mm:ss').valueOf() - dayjs(b, 'HH:mm:ss').valueOf())
      .map(({ from_hour, duration }) => {
        const [h, m, s] = duration.split(':');
        return [
          dayjs(from_hour, 'HH:mm:ss'),
          dayjs(from_hour, 'HH:mm:ss').add(dayjs.duration({
            seconds: s,
            minutes: m,
            hours: h,
          })),
        ];
      });
    return {
      ...acc,
      [day]: {
        isClosed: hours.length === 0,
        hours: hours.length ? hours : [[null, null]],
      },
    };
  }, {});
export default ActiveHoursInput;
