import CheckSvg from 'assets/image/checkWhite.svg';
import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledSwitch = styled.div`
  cursor: pointer;
  height: 20px;
  width: 36px;

  label {
    cursor: pointer;
    position: relative;
  }

  .checkmark {
    position: absolute;
    left: 0;
    width: 36px;
    height: 20px;
    border-radius: 100px;
    background: #e7e8e9;
    border: 1px solid #dddee1;
    box-sizing: border-box;
    border-radius: 100px;

    &--loading {
      position: absolute;
      left: -10px;
      top: 2px;
      margin-top: -2px;
    }

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 0;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
      border-radius: 100px;
      transition: all 0.3s ease;
    }
  }

  input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background: ${Colors.primary1};
      border: none;
      box-sizing: border-box;
      border-radius: 100px;

      &:after {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.12);
        border-radius: 100px;
        transition: all 0.3s ease;
        width: 18px;
        height: 18px;
        transform: translateX(17px) translateY(1px);
      }
    }
  }
`;
