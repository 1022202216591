import React from 'react';
import { useNavigate } from 'react-router';
import http from 'utils/http';
import DrawerForm from './DrawerForm';

function Create() {
  const navigate = useNavigate();

  async function handleCreate(data) {
    await http.post('/v1/organization/{{organizationId}}/parents/', data);
    handleBack();
  }
  function handleBack() {
    navigate('/console/locations', { replace: true });
  }

  return (
    <DrawerForm
      open
      onSubmit={handleCreate}
      onClose={handleBack}
    />
  );
}

export default Create;
