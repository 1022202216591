import { getMerchantOrders } from 'api/merchants';
import { ReactComponent as AlertIcon } from 'assets/image/alert.svg';
import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import SkeletonLoading from 'components/SkeletonLoading';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { captureException } from 'utils/errors';
import usePagination from '../../../hooks/usePagination';
import { getData, priceTransformer } from '../../../utils';
import Table from '../Table';
import { StyledWrapper } from './styles';
import ConnectStripeAlert from './ConnectStripeButton';

function RightBox({ merchantData, merchantDataLoading, ...props }) {
  const [merchantOrders, setMerchantOrders] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [merchantOrdersLoading, setMerchantOrdersLoading] = useState(false);
  const { id } = useParams();

  const handleFormatDate = (d) => (d ? dayjs(d).fromNow() : '-');
  const MERCHANTS_TABLE_CELLS = [
    {
      title: 'Sales',
      dataGetter: (data) => priceTransformer(merchantData?.total_sales),
      badgedataGetter: (data) => `${merchantData?.orders_count} orders`,
    },
    {
      title: 'Last order',
      dataGetter: (data) => handleFormatDate(merchantData?.last_order_date),
      badgedataGetter: (data) => '',
    },
    {
      title: 'Type',
      dataGetter: () => {
        if (merchantData?.is_catering) {
          return 'Catering';
        }
        return merchantData?.merchant_type;
      },
      badgedataGetter: (data) => '',
    },
    {
      title: 'Incoming orders',
      dataGetter: (data) => (
        <div className="coming-soon">
          <ClockIcon />
          <p className='m-0'>Coming soon... </p>
        </div>
        // <Switch
        //   checked={merchantOrdersSwitch}
        //   onChange={() => setMerchantOrdersSwitch(!merchantOrdersSwitch)}
        // />
      ),
      badgedataGetter: (data) => '',
    },
  ];

  const getMerchantsOrders = () => {
    setMerchantOrdersLoading(true);
    setMerchantOrders([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    getMerchantOrders(id, page)
      .then((res) => {
        setMerchantOrders(getData(res).results);
        setPagination({
          count: getData(res)?.count,
          next: getData(res)?.next,
          previous: getData(res)?.previous,
        });
        setMerchantOrdersLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setMerchantOrdersLoading(false);
      });
  };

  const {
    setPage, page, pagination, setPagination, paginationEl,
  } =
    usePagination(getMerchantsOrders, merchantOrdersLoading);

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  return (
    <StyledWrapper>
      {/* Merchant Tabs */}
      <div className="merchant-tabs">
        {MERCHANTS_TABLE_CELLS.map((tab, index) => (
          <div className="merchant-tab" key={index}>
            <div className="merchant-tab-row-left">{tab.title}</div>
            <div className="merchant-tab-row">
              <div className="merchant-tab-desc">
                {merchantDataLoading ? (
                  <SkeletonLoading
                    borderRadius="2px"
                    width="52px"
                    height="24px"
                    count={1}
                  />
                ) : (
                  tab.dataGetter()
                )}
              </div>
            </div>
            {tab.badgedataGetter() ? (
              <div className="tab-sub">
                {merchantDataLoading ? (
                  <SkeletonLoading
                    borderRadius="2px"
                    width="59px"
                    height="16px"
                    count={1}
                  />
                ) : (
                  tab.badgedataGetter()
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <ConnectStripeAlert merchantId={merchantData?.id} />

      {/* Pending actions */}
      {merchantData?.needed_actions?.length ? (
        <>
          <div className="flex-row-end pending start items-center ">
            <h6 className="pending-title">Pending actions</h6>
            <AlertIcon className="alert-icon" />
          </div>
          <div className="pending-box">
            {merchantData?.needed_actions?.map((a, index) => (
              <div key={index} className="flex-row-end pending-item">
                <div className="num">{index + 1}</div>
                <span>{a}</span>
              </div>
            ))}
          </div>
        </>
      ) : null}
      {/* Orders Table */}
      <div className="history">Orders history</div>
      {/* Tabs */}
      {/* <Tabs
        classes="tabs-box"
        tabsList={TABS}
        activeTab={activeTab}
        setActiveTab={(t) => {
          resetPaging();
          setActiveTab(t);
        }}
      /> */}
      {/* Table */}
      <div className="table-box">
        <Table
          loading={merchantOrdersLoading}
          pagination={pagination}
          items={merchantOrders}
          refetch={getMerchantsOrders}
        />
        <div className="pagination">{paginationEl}</div>
      </div>
    </StyledWrapper>
  );
}

export default RightBox;
