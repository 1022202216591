/*
 * Order statuses:
 */
export const NEW = 0;
export const IN_PROGRESS = 1;
export const CANCELLED_BY_RESTAURANT = 2;
export const READY = 4;
export const ON_THE_WAY = 5;
export const COMPLETED = 6;
// export const IN_PROGRESS_UPDATED = 7;
// export const READY_UPDATED = 8;
export const STRANDED = 9;
export const REJECTED_BY_RESTAURANT = 10;
export const NEEDS_APPROVAL = 11;
export const DELIVERED = 12;
export const PAYMENT_SCHEDULED = 34;

export const OrderStatusLabel = {
  [NEW]: 'New',
  [IN_PROGRESS]: 'Accepted',
  [CANCELLED_BY_RESTAURANT]: 'Cancelled',
  [READY]: 'Ready',
  [ON_THE_WAY]: 'On the Way',
  [COMPLETED]: 'Completed',
  [STRANDED]: 'Stranded',
  [REJECTED_BY_RESTAURANT]: 'Rejected by Restaurant',
  [NEEDS_APPROVAL]: 'Needs Approval',
  [DELIVERED]: 'Delivered',
  [PAYMENT_SCHEDULED]: 'Payment Scheduled',
};

export const OrderStatus = {
  NEW,
  IN_PROGRESS,
  CANCELLED_BY_RESTAURANT,
  READY,
  ON_THE_WAY,
  COMPLETED,
  STRANDED,
  REJECTED_BY_RESTAURANT,
  NEEDS_APPROVAL,
  DELIVERED,
  PAYMENT_SCHEDULED,
};

export const OrderStatusTagColor = {
  [NEW]: 'gold',
  [IN_PROGRESS]: 'green',
  [CANCELLED_BY_RESTAURANT]: 'error',
  [READY]: 'green',
  [ON_THE_WAY]: '',
  [COMPLETED]: 'green',
  [STRANDED]: 'red',
  [REJECTED_BY_RESTAURANT]: 'orange',
  [NEEDS_APPROVAL]: 'geekblue',
  [DELIVERED]: 'green',
  [PAYMENT_SCHEDULED]: 'blue',
};
