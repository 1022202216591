import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Alert, App, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { captureException } from 'utils/errors';
import { fetchSetupIntent } from './requests';

function StripeContainer({ children, stripeKey, merchantId }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (!stripeKey) {
      return;
    }
    const p = loadStripe(stripeKey, {
      apiVersion: '2020-08-27',
    });
    setStripePromise(p);
    setLoading(true);
    fetchSetupIntent(merchantId)
      .then((res) => {
        setOptions({ clientSecret: res.data?.client_secret });
        setLoading(false);
      })
      .catch((e) => {
        captureException(e);
        setLoading(false);
        message.error('The payment section could not be loaded!');
      });
  }, [stripeKey]);

  if (loading) {
    <Space style={{
      display: 'flex', justifyContent: 'center', m: 7, background: '#f5f7f8',
    }}
    >
      <LoadingOutlined />
    </Space>;
  }
  return options ? (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  ) : (
    <Alert message="The payment section could not be loaded!" type="error" />
  );
}
export default StripeContainer;
