import { ConfigContext } from 'constants/contexts';
import { get } from 'lodash-es';
import { useContext } from 'react';

function useConfig(path, defaultValue) {
  const config = useContext(ConfigContext);
  if (!path) {
    return config;
  }
  return get(config, path) ?? defaultValue;
}

export default useConfig;
