import React, { useState } from 'react';
import {
  Button, Flex, Form, Select, App, Typography,
} from 'antd';
import { useNavigate } from 'react-router';
import { captureException } from 'utils/errors';
import { getHttpErrorMessage } from 'utils/http';
import FormLayout from 'components/FormLayout';
import FormItem from 'components/FormItem';
import DebounceSelect from 'components/Select/DebounceSelect';
import useConfig from 'hooks/useConfig';
import useIsMobile from 'hooks/useIsMobile';
import { InfoCircleOutlined } from '@ant-design/icons';
import EmailNotificationReceivers from 'components/EmailNotificationReceivers';
import SMSNotificationReceivers from 'components/SMSNotificationReceivers';
import { addNewMerchant, addNewOmniMerchant } from '../requests';
import { getParents } from '../../../api/merchants';
import OmniMerchantForm from './OmniMerchantForm';
import CateringForm from './CateringForm';
import Results from './Results';
import NonPartnerForm from './NonPartnerForm';
import PhoneFields from './PhoneFields';

const { Title } = Typography;

function MerchantForm() {
  const [form] = Form.useForm();
  const general = useConfig('general');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useIsMobile();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const handleSave = async (values, continueAdding) => {
    setLoading(true);
    const { type, parentId, url } = values;

    try {
      if (type === 'Omni' || url) {
        await addNewOmniMerchant(serialize(values));
      } else {
        await addNewMerchant({
          data: serialize(values),
          parent: parentId,
          is_catering: type === 'Catering',
        });
      }

      message.success('Merchant created successfully!');
      if (continueAdding) {
        form.resetFields();
      } else {
        setSubmitted(true);
      }
    } catch (e) {
      captureException(e);
      message.error(getHttpErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchParents = async (searchKey = '') => {
    try {
      const { results } = await getParents(searchKey);
      return results.map((parent) => ({
        value: parent.id,
        label: parent.title,
      }));
    } catch (e) {
      captureException(e);
      return [];
    }
  };

  if (submitted) {
    return <Results />;
  }

  return (
    <FormLayout>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => handleSave(values, false)}
        initialValues={{
          type: 'Catering',
          deliveryCommissionType: '%',
          pickupCommissionType: '%',
          markupType: '%',
          schedule: false,
          support_phone: 'parent',
        }}
      >
        <FormLayout.Section divider={false} title="Restaurant details">
          <FormItem
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select a type' }]}
          >
            <Select
              options={[
                { value: 'Non-partner', label: 'Non-partner' },
                { value: 'Omni', label: 'Omni Merchant' },
                { value: 'Catering', label: 'Catering Merchant' },
              ]}
              placeholder="Select Type"
            />
          </FormItem>

          <FormItem
            name="parentId"
            label="Parent"
            rules={[{ required: true, message: 'Please select a parent' }]}
          >
            <DebounceSelect
              allowClear
              placeholder="Select parent"
              fetchOptions={fetchParents}
              labelInValue={false}
            />
          </FormItem>
        </FormLayout.Section>
        <FormLayout.Section>
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
            {({ getFieldValue }) => {
              const type = getFieldValue('type');
              switch (type) {
              case 'Non-partner':
                return <NonPartnerForm />;
              case 'Omni':
                return <OmniMerchantForm />;
              case 'Catering':
                return <CateringForm />;
              default:
                return null;
              }
            }}
          </Form.Item>
          <PhoneFields />

        </FormLayout.Section>
        <FormLayout.Section
          title="Notification"
          subtitle={(
            <>
              <InfoCircleOutlined size={20} className="mr-1" />
              {' '}
              By adding another email or phone number, notifications (e.g., order submission, order edits, etc.) will be sent to the provided contact information.
            </>
          )}
          style={{ marginBottom: 18 }}
        >
          <Typography.Paragraph type="secondary" />
          <FormItem name="email_notification_receivers" label="Email" onStyle fullWidth>
            <EmailNotificationReceivers />
          </FormItem>
          <FormItem name="sms_notification_receivers" label="SMS" onStyle fullWidth>
            <SMSNotificationReceivers />
          </FormItem>
        </FormLayout.Section>
        <FormLayout.Footer>
          <Flex vertical={isMobile} justify="end" gap={6} className="w-full">
            <Button onClick={handleBack}>Cancel</Button>
            <Button htmlType="submit" type="primary" loading={loading}>Submit</Button>
            <Button onClick={() => form.validateFields().then((values) => handleSave(values, true))} type="primary" loading={loading}>
              Submit and add another restaurant
            </Button>
          </Flex>
        </FormLayout.Footer>
      </Form>
    </FormLayout>
  );
}

function serialize({
  address, integration, parentId, markup, type, support_phone, ...data
}) {
  return {
    type,
    parent_id: parentId,
    ...data,
    ...(address ? {
      address: address.text,
      location: {
        lat: address.lat,
        lng: address.lng,
      },
    } : {}),
    ...(markup ? {
      markup: markup.amount,
      markup_type: markup.type,
    } : {}),
    integration: type === 'Catering' ? 'EzCater' : integration,
    config: {
      support_phone,
    },
  };
}

export default MerchantForm;
