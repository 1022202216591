import dayjs from 'dayjs';


export const isDateToday = (date) => dayjs(date).diff(new Date(), 'days', true) <= 0;

export const filterTimesAfterNow = (list) => list.filter((t) => dayjs(dayjs(), 'HH:mm:ss').isSameOrBefore(dayjs(t.end, 'HH:mm:ss')));

export function isSameDay({ date1, date2 }) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth();
}

export const formatTime = ({ fromHour, duration }) => {
  if (!duration) {
    return dayjs(fromHour, 'HH:mm:ss').format('hh:mm A');
  }
  return dayjs(fromHour, 'HH:mm:ss')
    .add(dayjs.duration(duration).hours(), 'hours')
    .format('hh:mm A');
};
