import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import { modifierContext } from '../ModifierProvider';
import { getModifierMaxSelections, isAutoApplied } from '../utils';
import OptionRow from '../OptionRow';
import QuantityInput from './QuantityInput';

function MultiSelectModifier({ modifier, disabled }) {
  const { options } = modifier;
  const maxSelections = getModifierMaxSelections(modifier);
  const {
    isSelected, handleChange, getTotalQty, getSelectedOption,
  } =
    useContext(modifierContext);

  function renderOption(option) {
    const { supports_multiple_quantity: supportMultipleQty } = option;
    const selected = isSelected({ option, modifier });
    const qty = getSelectedOption({ option, modifier })?.qty ?? 0;
    const otherQty = getTotalQty(modifier) - qty;
    const maxQty = Math.min(maxSelections - otherQty, option.max_qty || 100);

    if (isAutoApplied(modifier) && !selected) {
      handleChange({ modifier, option, value: { ...option, qty: 1 } });
    }
    if (supportMultipleQty) {
      return (
        <QuantityInput
          key={option.id}
          style={{ marginRight: 15 }}
          value={qty}
          min={0}
          max={maxQty}
          onChange={(qty) => {
            if (qty === 0) {
              return handleChange({ option, modifier, value: undefined });
            }
            handleChange({ option, modifier, value: { ...option, qty } });
          }}
          disabled={disabled}
        >
          <OptionRow option={option} />
        </QuantityInput>
      );
    }
    return (

      <Checkbox
        key={option.id}
        style={{ marginBottom: 12, display: 'flex' }}
        checked={isSelected({ option, modifier })}
        onChange={() => {
          if (selected) {
            return handleChange({ option, modifier, value: undefined });
          }
          handleChange({
            option,
            modifier,
            value: { ...option, qty: 1, modifierTitle: modifier?.title },
          });
        }}
        disabled={
          disabled || (maxQty === 0 && !isSelected({ modifier, option }))
        }
      >
        <OptionRow option={option} />
      </Checkbox>
    );
  }

  return options.map(renderOption);
}

export default MultiSelectModifier;
