import styled from 'styled-components';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  .time-dialogue {
    width: 380px;
    height: 404px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 5px;
    z-index: 2;

    &-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: black;
      opacity: 0.32;
      z-index: 2;
    }
  }

  .top {
    height: 112px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1a1f36 !important;
    margin-bottom: 2px;
  }

  .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #666b6d;
  }

  .time-list {
    padding: 0 !important;
    border-bottom: 1px solid #dde1e4;
  }

  .time-input {
    width: 306px;
  }

  .time-list-item {
    margin: 0;
    border-top: 1px solid #dde1e4;
    border-right: none;
    border-left: none;
    border-bottom: none !important;
    padding: 15px 21px;
    padding-left: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #f5f7f8;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.textPrimary1};

    &:last-child {
      border-bottom: 1px solid #dde1e4;
    }
  }

  .checkmark {
    top: 50%;
    transform: translateY(-50%);
    left: 21px;
    height: 16px;
    width: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px #dcdddd, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1);
    border: none;

    &:after {
      width: 7px !important;
      height: 7px !important;
      background: white !important;
    }
  }

  .container input:checked ~ .checkmark {
    background: #2b64f5;
    box-shadow: 0px 0px 0px 1px #2b64f5, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .time-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 16px 20px;
  }

  .cancel-btn {
    margin-right: 12px;
    padding: 5px 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .confirm-btn {
    padding: 5px 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .time-input {
    position: relative;
  }

  .unit {
    position: absolute;
    right: 32px;
    top: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #7d8284;
    transform: translateY(-50%);
  }
`;
