import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`

  .merchant-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    border: 1px solid #d8dce2;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }

  .merchant-tab {
    width: 25%;
    height: 94px;
    padding: 14px 18px;
    border-right: 1px solid #d8dce2;

    &:last-child {
      border: none;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        justify-content: flex-start;
        margin-bottom: 4px;
      }
    }

    &-desc {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: ${Colors.textPrimary1};
    }

    &-sub {
      font-weight: 600;
      font-size: 13px;
      line-height: 23px;
      color: #7d8284;
    }
  }

  @media (max-width:575.98px) {
    .merchant-tabs {
      flex-direction: column;
    }
    .merchant-tab {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid #d8dce2;
    }
  }
  
  .history {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: ${Colors.textPrimary1};
    margin-right: 8px;
    margin-top: 40px;
  }

  .pending {
    margin-top: 40px;

    &-title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.textPrimary1};
      margin-right: 8px;
    }

    &-box {
      background: #f5f8f7;
      padding: 16px;
      display: flex;
      flex-direction: column;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
    }

    &-item {
      margin: 8px 0;
    }
  }

  .alert-icon {
    color: #d53c2e;
    margin-top: -6px;
  }

  .num {
    width: 10px;
    margin-right: 8px;
  }

  .tabs-box {
    border-bottom: 1px solid #e7e8e9;
    margin: 0 0 12px;
    margin-top: 6px;

    .tab {
      padding: 11px 18px;
    }
  }

  .coming-soon {
    background: #faf3e6;
    color: #cc8601;
    border-radius: 22px;
    padding: 4px 8px;
    width: fit-content;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    svg {
      margin-right: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .start {
    align-items: flex-start;
  }
`;
