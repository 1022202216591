import * as modifierTypes from './constants';

export function getModifierMinSelections(modifier) {
  return Math.min(
    getModifierMaxSelections(modifier),
    Math.max(modifier.min_selections || 0, modifier.mandatory ? 1 : 0),
  );
}

export function getModifierMaxSelections(modifier) {
  if (modifier.modifier_type === modifierTypes.Single_Selected) {
    return 1;
  }
  return modifier.max_selections ?? modifier.options?.length;
}

export function hasOptionChildren(option) {
  return option.modifiers_children?.length > 0;
}

export function getModifierSelectionsText(modifier) {
  const maxSelections = getModifierMaxSelections(modifier);
  const minSelections = getModifierMinSelections(modifier);

  if (!minSelections && !maxSelections) {
    return null;
  }

  if (maxSelections === minSelections) {
    return `Select ${maxSelections}`;
  }
  if (minSelections > 0) {
    return `Select at least ${minSelections},`;
  }
  if (maxSelections > 1) {
    return `Select up to ${maxSelections}`;
  }
  return null;
}

export function isMultiSelect(modifier) {
  const maxSelection = getModifierMaxSelections(modifier);
  if (maxSelection === 1) {
    return false;
  }
  return (
    modifier.modifier_type === modifierTypes.Multi_Selected ||
    isAutoApplied(modifier) ||
    getModifierMinSelections(modifier) > 1
  );
}

export function isAutoApplied(modifier) {
  return modifier.modifier_type === modifierTypes.Auto_Applied;
}
