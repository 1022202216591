import React from 'react';

function withSkeleton(Component, SkeletonComponent) {
  return function (props) {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.loading) return <SkeletonComponent {...props} />;
    return <Component {...props} />;
  };
}

export default withSkeleton;
