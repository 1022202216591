import { Alert, Button, Space } from 'antd';
import useConfig from 'hooks/useConfig';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useHasAnyOfPermissions } from 'hooks/useHasPermission';
import { PERMISSIONS } from 'constants/permissions';

function Banners() {
  const config = useConfig('general');
  const navigate = useNavigate();
  const isAdmin = useHasAnyOfPermissions([PERMISSIONS.ADMIN_ACCESS]);
  const banners = useMemo(() => {
    if (!config) return [];
    const data = [];
    if (config.isSuspended) {
      data.push({
        title: 'Payment Issue Detected - Action Required!',
        description: 'Your payment has failed, causing a pause in order processing. Please update your payment method ASAP to resume services. Contact support if needed. Thank you!',
        type: 'error',
        actionTitle: 'Billing history',
        onClick: () => navigate('/console/setting/subscription'),
        hideActionInScreens: ['subscription'],
      });
    } else if (config.hasUnpaidInvoice && isAdmin) {
      data.push({
        title: 'Our attempts to charge your credit card has failed!',
        description: `Your payment due is in ${dayjs(config.invoiceDueDatetime).format('MMM DD, YYYY')}. Please check and update your payment method info to prevent any service disruption. Thank you!`,
        type: 'warning',
        actionTitle: 'Billing history',
        onClick: () => navigate('/console/setting/subscription'),
        hideActionInScreens: ['subscription'],
      });
    }
    return data;
  }, [config]);

  const getButtonColor = (type) => {
    switch (type) {
    case 'success': return '#38924d';
    case 'info': return '#478dc4';
    case 'warning': return '#FAAD14';
    case 'error': return '#cf7168';
    default: return '#d9d9d9';
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
      {banners.map((info, index) => (
        <Alert
          key={index}
          message={info.title}
          description={info.description}
          type={info.type || 'info'}
          action={info.actionTitle ? (
            <Button size="small" type="text" onClick={info.onClick} style={{ border: `1px solid ${getButtonColor(info.type)}` }}>
              {info.actionTitle}
            </Button>
          ) : null}
        />
      ))}

    </Space>
  );
}

export default Banners;
