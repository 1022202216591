import IntercomSDK from '@intercom/messenger-js-sdk';
import { AdminContext } from 'constants/contexts';
import { useContext } from 'react';

export default function Intercom() {
  const { user, isLoggedIn } = useContext(AdminContext);

  if (user && isLoggedIn) {
    IntercomSDK({
      app_id: 'ienxrwt3',
      user_id: user?.pk,
      name: user?.first_name,
      email: user?.email,
    });
  }

  return null;
}
