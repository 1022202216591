import {
  Col, Radio, Row, Space, Typography,
} from 'antd';
import { OrderType } from 'containers/CreateOrder/constants';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext } from 'react';
import CustomerAddresses from './CustomerAddressess';

function OrderTypes() {
  const { order, setOrderDetails } = useContext(CallInOrderContext);

  return (
    <div style={{ marginTop: 12 }}>
      <Typography.Text className="input-title">
        Order type
      </Typography.Text>
      <Row gutter={20} align="middle">
        <Col xs={24} lg={7}>
          <Radio.Group
            onChange={(e) => {
              setOrderDetails({ orderType: e.target.value });
            }}
            value={order.orderType}
            defaultValue={order.orderType}
            name="order_type"
            disabled={!order.userId}
          >
            <Space>
              <Radio value={OrderType.Pickup}>Pick Up</Radio>
              <Radio value={OrderType.Delivery}>Delivery</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      {order.orderType === OrderType.Delivery && <CustomerAddresses />}
    </div>
  );
}

export default OrderTypes;
