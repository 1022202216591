import { Button, Space } from 'antd';
import { deleteCouponById } from 'containers/Coupons/coupons';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';
import { ModalContext } from '../../../context/modalContext';
import { StyledWrapper } from './styles';

function DeleteModal({ row, reload, ...props }) {
  const { handleModal } = useContext(ModalContext);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = (row) => {
    setDeleteLoading(true);
    deleteCouponById(row?.id)
      .then((res) => {
        if (res === 204) {
          setDeleteLoading(false);
          handleModal();
          reload();
        }
      })
      .catch((e) => {
        captureException(e);
        setDeleteLoading(false);
      });
  };

  return (
    <StyledWrapper>
      {' '}
      <div className="delete-modal">
        <p className="title">
          Delete
          {row?.code}
        </p>
        <div className="modal-content">
          <p className="qa">
            Are you sure you want to delete the coupon
            {' '}
            {row?.code}
            {' '}
            This can't be
            undone.
          </p>
        </div>

        <div className="btns">
          <Space>
            <Button onClick={() => handleModal()} label="Cancel" type="default">
              Cancel
            </Button>

            <Button
              onClick={() => handleDelete(row)}
              loading={deleteLoading}
              danger
              type="primary"
            >
              Delete
            </Button>
          </Space>
        </div>
      </div>
    </StyledWrapper>
  );
}

export default DeleteModal;
