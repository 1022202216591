import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 50%;

  @media (max-width: 1350px) {
    width: 100%;
  }

  .top-merchant {
    &-box {
      margin-top: 32px;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #1a1f36;
    }
  }

  .table-wrapper {
    width: 100%;
    border: 1px solid #e1e5ec;
    border-left: none;
    height: 248px;
    overflow-y: auto;

    @media (max-width: 1350px) {
      border-right: none;
    }
  }

  table {
    width: 100%;

    th {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      color: #131b1f;
      padding: 11px;
      text-transform: uppercase;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #3c4257;
      padding: 10px;
    }

    tr {
      border-top: 1px solid #e1e5ec;
    }
  }

  .strong {
    color: #000000;
    font-weight: 700;
  }

  .ellipsis {
    text-overflow: ellipsis;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
  }
`;
