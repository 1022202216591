import { fetchMerchantCard } from 'containers/MerchantEditForm/Form/PaymentInfo/requests';
import React, { useCallback, useEffect, useState } from 'react';

import SkeletonLoading from 'components/SkeletonLoading';
import dayjs from 'dayjs';
import { StyledWrapper } from './styles';
import Owner from './Owner';

function MerchantDetailsBox({
  merchantData,
  merchantDataLoading,
  ...props
}) {
  const handleFormatDate = (d) => (d ? dayjs(d)
    .format('MMM D, YYYY') : '-');

  // Todo: use better approach to set new owner after refactor (maybe context)
  const [newOwner, setNewOwner] = useState();
  const [card, setCard] = useState();
  const [loading, setLoading] = useState();

  const loadPaymentCard = useCallback(() => {
    if (!merchantData?.id) return;
    setLoading(true);
    fetchMerchantCard(merchantData?.id).then((res) => {
      setCard(res.data?.card?.card);
      setLoading(false);
    });
  }, [merchantData?.id]);

  useEffect(() => {
    loadPaymentCard();
  }, [loadPaymentCard]);

  return (
    <StyledWrapper>
      <div className="box">
        <div className="box-title">Restaurant details</div>
        <div className="details">
          <div className="details-cell">
            {merchantDataLoading ? (
              <SkeletonLoading
                borderRadius="2px"
                width="148px"
                height="18px"
                count={1}
              />
            ) : (
              <Owner
                setOwner={setNewOwner}
                owner={newOwner || merchantData.owner}
              />
            )}
          </div>
        </div>
        <div className="details">
          <div className="details-cell">
            <div className="details-cell-title">Restaurant Email</div>
            <div className="details-cell-data  details-cell-data--green">
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                merchantData?.email || '-'
              )}
            </div>
          </div>
          <div className="details-cell">
            <div className="details-cell-title">Phone</div>
            <div className="details-cell-data">
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                merchantData?.phone || '-'
              )}
              {' '}
            </div>
          </div>
          <div className="details-cell">
            <div className="details-cell-title">Parent</div>
            <div className="details-cell-data">
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                merchantData?.parent?.title || '-'
              )}
              {' '}
            </div>
          </div>
          <div className="details-cell">
            <div className="details-cell-title">Address</div>
            <div className="details-cell-data">
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                merchantData?.address || '-'
              )}
              {' '}
            </div>
          </div>

          <div className="details-cell">
            <div className="details-cell-title">Signup date</div>
            <div className="details-cell-data">
              {merchantDataLoading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                handleFormatDate(merchantData?.created_at) || '-'
              )}
              {' '}
            </div>
          </div>
          <div className="details-cell">
            <div className="details-cell-title">Payment info</div>
            <div className="details-cell-data">
              {loading ? (
                <SkeletonLoading
                  borderRadius="2px"
                  width="148px"
                  height="18px"
                  count={1}
                />
              ) : (
                <>
                  <span style={{ marginRight: 4, textTransform: 'capitalize' }}>
                    {card?.brand}
                  </span>
                  <span>
                    {' '}
                    ****
                    {card?.last4}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
}

export default MerchantDetailsBox;
