import { Flex } from 'antd';
import React from 'react';
import Form from './Form';
import { ReactComponent as CaterLocallyLogo } from './CaterLocallyLogo.svg';

function AcceptCateringOrder() {
  return (
    <Flex vertical className="p-6 lg:px-16 lg:py-8" gap={12}>
      <CaterLocallyLogo />
      <Form />
    </Flex>
  );
}
export default AcceptCateringOrder;
