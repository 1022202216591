import React from 'react';
import {
  Modal, Tag, Typography,
} from 'antd';
import {
  CalendarOutlined,
} from '@ant-design/icons';

import { Divider } from '@mui/material';
import dayjs from 'dayjs';

function DetailsModal({
  data, onClose,
}) {
  const {
    code,
    first_name: fistName,
    last_name: lastName,
    benefactor_name: benefactorName,
    receiver_email: receiverEmail,
    receiver_phone: receiverPhone,
    expires_at: expDate,
    state,
    created_at: issuedDate,
    message,
  } = data || {};

  function getBody() {
    if (!data) {
      return null;
    }
    return (
      <>
        <div className="d-flex justify-space-between">
          <Item
            title={(
              <>
                <CalendarOutlined />
                Scheduled for
              </>
            )}
            value={issuedDate ? dayjs(issuedDate).format('MMM D, hh:mm A') : '-'}
          />
          <Item
            title="Exp Date"
            value={expDate ? dayjs(issuedDate).format('MMM D, hh:mm A') : '-'}
          />
        </div>
        <Divider className="my-2" />
        <Item
          title="Sender"
          value={(
            <div className="d-flex justify-space-between">
              <Typography.Text>{benefactorName}</Typography.Text>
              {/* <Typography.Link href={`tel:${sender.phone}`}>
                {sender.phone}
              </Typography.Link>
              <Typography.Link href={`mailto:${sender.email}`}>
                {sender.email}
              </Typography.Link> */}
            </div>
          )}
        />
        <Divider className="my-2" />
        <Item
          title="Receiver"
          value={(
            <div className="d-flex justify-space-between">
              <Typography.Text>
                {fistName}
                {' '}
                { lastName}
              </Typography.Text>
              <Typography.Link href={`tel:${receiverPhone}`}>
                {receiverPhone}
              </Typography.Link>
              <Typography.Link href={`mailto:${receiverEmail}`}>
                {receiverEmail}
              </Typography.Link>
            </div>
          )}
        />
        {
          message && (
            <>
              <Divider className="my-3" />
              <Item title="Message" value={message} />
            </>
          )
        }
      </>
    );
  }
  return (
    <Modal
      title={(
        <>
          <Typography.Text strong copyable>{code}</Typography.Text>
          <Tag className="ml-2">{state}</Tag>
        </>
      )}
      onCancel={onClose}
      onOk={onClose}
      open={Boolean(data)}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {getBody()}
    </Modal>

  );
}

function Item({ title, value }) {
  return (
    <div>
      <Typography.Text type="secondary" className="d-flex align-center">{title}</Typography.Text>
      <Typography.Text strong>{value || '-'}</Typography.Text>
    </div>
  );
}

export default DetailsModal;
