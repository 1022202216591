import { Tooltip } from 'antd';
import { InfoSquareIcon } from 'assets/icons';

function ColumnWithTooltip({ title, description, placement = 'top' }) {
  return (
    <div className="d-flex align-items-center">
      <span>{title}</span>
      <Tooltip placement={placement} title={description}>
        <InfoSquareIcon style={{ color: '#7D8284' }} />
      </Tooltip>
    </div>
  );
}
export default ColumnWithTooltip;
