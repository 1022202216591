import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Row, Switch, TimePicker, Tooltip, Typography,
} from 'antd';
import React from 'react';

const { RangePicker } = TimePicker;

function WorkingDay({
  weekday, changeTimeSlot, addTimeSlot, removeTimeSlot, onChangeAvailability, parentHours,
}) {
  const renderTooltip = () => (
    <div style={{ fontSize: 12 }}>
      <span color="white">Parent active hour is</span>
      <br />
      <span>{parentHours.join('\n') || 'not defined!'}</span>
    </div>
  );
  return (
    <Row gutter={8} align={weekday.activeTimes?.length > 1 ? 'baseline' : 'middle'}>
      <Col xs={24} md={5}>
        <Typography.Text>{weekday.title}</Typography.Text>
        <Tooltip placement="top" title={renderTooltip}>
          <InfoCircleOutlined style={{ marginLeft: 2 }} />
        </Tooltip>
      </Col>
      <Col xs={18} md={14}>
        <Flex vertical gap={8}>
          {weekday.activeTimes.map((activeTime, index) => (
            <Flex key={index}>
              <RangePicker
                style={{ width: '100%' }}
                value={[activeTime.from, activeTime.to]}
                disabled={weekday.isClosed}
                onChange={(value) => changeTimeSlot({ weekday: weekday.key, index, value })}
              />
              {index === 0 && (
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  disabled={weekday.isClosed}
                  onClick={() => addTimeSlot({ weekday: weekday.key })}
                />
              )}
              {index > 0 && (
                <Button
                  type="text"
                  icon={<MinusOutlined />}
                  disabled={weekday.isClosed}
                  onClick={() => removeTimeSlot({ weekday: weekday.key, index })}
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Col>
      <Col xs={6} md={5}>
        <Switch
          checked={weekday.isClosed}
          onChange={(value) => onChangeAvailability({ value, weekday: weekday.key })}
          style={{ marginRight: 6 }}
        />
        <Typography.Text>Closed</Typography.Text>
      </Col>

    </Row>
  );
}

export default WorkingDay;
