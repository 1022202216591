import { Button, Modal } from 'antd';
import React from 'react';

function SimpleModal(
  {
    children,
    open,
    setOpen,
    loading = false,
    title,
    footer,
    onSubmit,
    onCancel,
    width = 570,
    submitProps,
    confirmButtonText = 'Confirm',
  },
) {
  const handleCancel = () => {
    onCancel && onCancel();
    setOpen(false);
  };

  const handleOk = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={title}
      width={width}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      styles={{
        content: {
          padding: 0,
        },
        header: {
          padding: '16px 24px 8px',
        },
        body: {
          backgroundColor: '#F5F8F7',
          padding: '16px 24px',
          borderTop: '1px solid #F0F0F0',
          borderBottom: '1px solid #F0F0F0',
        },
        footer: {
          padding: '4px 24px 16px',
        },
      }}
      footer={footer !== undefined ? footer : [
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk} {...submitProps}>
          {confirmButtonText}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
}

export default SimpleModal;
