import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { StyledWrapper } from './styles';
import { useOutsideClick } from '../../hooks/useClickOutside';
import 'react-datepicker/dist/react-datepicker.css';

function DatePickerComponent({
  actionOnSelect,
  isActive,
  selectedDate,
  setSelectedDate,
  ...props
}) {
  const [date, setDate] = useState(
    selectedDate ? new Date(selectedDate) : new Date(),
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => handleCloseDatePicker());

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const onChange = (date) => {
    setDate(date);
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const getDayAndMonth = (date) => `${MONTHS[new Date(date).getMonth()]} ${new Date(
    date,
  ).getDate()}, ${new Date(date).getFullYear()}`;

  return (
    <StyledWrapper>
      <Button
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
        label={date ? getDayAndMonth(date) : ''}
        classes={`btn-style ${showDatePicker ? 'btn-style--active' : ''}`}
      />
      {showDatePicker && (
        <div ref={dropdownRef} className="date-picker">
          <DatePicker
            selected={date}
            onChange={onChange}
            inline
            monthsShown={1}
          />
        </div>
      )}
    </StyledWrapper>
  );
}

DatePickerComponent.propTypes = {
  actionOnSelect: PropTypes.func,
  startDate: PropTypes.any,
  setStartDate: PropTypes.func,
  endDate: PropTypes.any,
  setEndDate: PropTypes.func,
  showDatePicker: PropTypes.bool,
  setShowDatePicker: PropTypes.func,
  isActive: PropTypes.bool,
};

export default DatePickerComponent;
