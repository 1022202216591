import { Layout as AntLayout } from 'antd';
import usePersistedState from 'hooks/usePersistedState';
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

function Layout({ hasSidebar = true, header, ...props }) {
  const [collapsed, setCollapsed] = usePersistedState(
    false,
    'sidebar/collapsed',
  );
  if (!hasSidebar) {
    return (
      <AntLayout className="site-layout">
        <AntLayout.Content style={{ overflowX: 'scroll' }}>
          {props.children}
        </AntLayout.Content>
      </AntLayout>
    );
  }
  return (
    <AntLayout style={{ minHeight: '100vh' }} hasSider>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntLayout className="site-layout" style={{ marginLeft: collapsed ? 80 : 240 }}>
        <AntLayout.Content style={{ overflowX: 'scroll' }}>
          {header}
          <div className="ml-5 lg:ml-16 mr-4 lg:mr-8 mt-2 lg:mt-4 mb-10">
            {props.children}
          </div>
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>

  );
}

Layout.Header = Header;
export default Layout;
