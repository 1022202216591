import {
  Button, Modal, notification, Typography,
} from 'antd';
import React, { useState } from 'react';
import { captureException } from 'utils/errors';
import { deleteUserPermissions } from '../../requests';

function RemoveUserModal({
  open, setOpen, data, onRemove,
}) {
  const [loading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  const onClose = () => {
    setLoading(false);
    setOpen(false);
  };
  const onConfirmRemove = () => {
    setLoading(true);
    deleteUserPermissions(data.id)
      .then((res) => {
        notificationApi.success({ message: 'Successfully removed' });
        onClose();
        onRemove();
      })
      .catch((e) => {
        captureException(e);
        notificationApi.error({ message: 'Something went wrong!' });
        onClose();
      });
  };

  if (!data) return;
  return (
    <>
      <Modal
        title="Remove member from panel"
        centered
        open={open}
        width={450}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)} disabled={loading}>
            Close
          </Button>,
          <Button key="remove" type="primary" danger onClick={onConfirmRemove} loading={loading}>
            Remove user
          </Button>]}
      >
        <Typography.Text strong>{data.email}</Typography.Text>
        {' '}
        will no longer be able to access this account
      </Modal>
      {contextHolder}
    </>
  );
}
export default RemoveUserModal;
