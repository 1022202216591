import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import {
  ChristmasOCLogoIcon, ChristmasOCSmallLogoIcon, OCLogoIcon, OCSmallLogoIcon,
} from 'assets/icons';
import { HAS_CHRISTMAS_THEME } from 'constants/index';
import usePersistedState from 'hooks/usePersistedState';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Snowfall from 'react-snowfall';
import SelectOrganization from './select-organization';
import {
  sidebar,
  sidebarItemsContainer,
  sidebarLogo,
  sidebarTopContainer,
  sidebarTrigger,
  sidebarTriggerIcon,
} from './styles';
import useSideBarItems from './useSideBarItems';
import './index.css';

function Sidebar({ collapsed, setCollapsed }) {
  const { bottomItems, topItems } = useSideBarItems(collapsed);
  const navigate = useNavigate();
  const location = useLocation();
  const [openKeys, setOpenKeys] = usePersistedState(
    [],
    'sidebar/open-keys',
  );
  const [currentPath, setCurrentPath] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/console'
      : location.pathname.replace(/\/+$/, ''),
  );

  const handleGoHome = () => {
    navigate('/console');
  };

  const Logo = useMemo(() => (HAS_CHRISTMAS_THEME ? (collapsed ? ChristmasOCSmallLogoIcon : ChristmasOCLogoIcon)
    : (collapsed ? OCSmallLogoIcon : OCLogoIcon)), [collapsed]);
  const trigger = useMemo(() => {
    const Trigger = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
    return (
      <div
        className={`flex items-center justify-${collapsed ? 'center' : 'start'} h-full`}
        style={sidebarTrigger(collapsed)}
      >
        <Trigger style={sidebarTriggerIcon}/>
      </div>
    );
  }, [collapsed]);

  const onSelectMenu = (e) => {
    setCurrentPath(e.key);
  };

  return (
    <Layout.Sider
      style={sidebar}
      width={240}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={trigger}
    >
      <div className="flex flex-col justify-between" style={sidebarTopContainer}>
        <div>
          <div className="flex justify-start" style={{ ...sidebarLogo, position: 'relative' }}>
            {HAS_CHRISTMAS_THEME && <Snowfall snowflakeCount={8} style={{ marginTop: -15 }}/>}
            <Logo onClick={handleGoHome}/>
          </div>

          <SelectOrganization collapsed={collapsed} setCollapsed={setCollapsed}/>
          <Menu
            style={{
              ...sidebarItemsContainer,
              maxHeight: 'calc(100vh - 280px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            selectedKeys={[currentPath]}
            onClick={onSelectMenu}
            mode="inline"
            className={`top-sidebar-menu ${collapsed ? 'collapsed' : ''}`}
            openKeys={openKeys}
            onOpenChange={setOpenKeys}
          >
            {topItems}
          </Menu>
        </div>
        <Menu
          style={sidebarItemsContainer}
          defaultSelectedKeys={['']}
          selectedKeys={[currentPath]}
          className="bottom-sidebar-menu"
        >
          {bottomItems}
        </Menu>
      </div>
    </Layout.Sider>
  );
}

export default Sidebar;
