export function objectWithFallback(obj, fallback) {
  return new Proxy(obj, {
    get(target, property) {
      return target[property] || fallback;
    },
  });
}

export function jsonToFormData(data) {
  const formData = new FormData();
  Object.entries(data).filter(([, val]) => val !== undefined).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, val);
    }
  });
  return formData;
}
