import { Divider, Flex } from 'antd';
import React from 'react';
import GeneralInfo from './GeneralInfo';
import Options from './Options';
import Rules from './Rules';

function ModifierForm({ detail, setDetail, mode }) {
  const onChangeDetail = (key, value) => {
    setDetail({ ...detail, [key]: value });
  };
  return (
    <Flex vertical>
      <GeneralInfo detail={detail} onChangeDetail={onChangeDetail} />
      <Divider />
      <Options options={detail?.options || []} onChange={(options) => onChangeDetail('options', options)} modifierModalMode={mode} />
      <Divider />
      <Rules detail={detail} onChangeDetail={onChangeDetail} />
    </Flex>
  );
}

export default ModifierForm;
