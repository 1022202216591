import withGuard from 'components/withGuard';
import React from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import AdjustBalance from './AdjustBalance';
import Container from './Container';
import CreditHistory from './CreditCardHistory';
import GiftCardHistory from './GiftCardHistory';
import Overview from './Overview';

const ROUTES = [
  {
    label: 'Overview',
    path: '',
    Component: Overview,
    key: 1,
  },
  {
    label: 'Credit history',
    path: '/credit-history',
    Component: CreditHistory,
    key: 2,
  },
  {
    label: 'Gift card details',
    path: '/gift-card',
    Component: GiftCardHistory,
    key: 3,
  },
  {
    label: 'Adjust balance',
    path: '/adjust-balance',
    Component: AdjustBalance,
    key: 4,
  },
  {
    title: 'Overview',
    path: '/orders/:orderId',
    Component: Overview,
    id: 6,
  },
];

function CustomerDetail() {

  return (
    <Routes>
      <Route path="" element={<Container/>}>
        {ROUTES.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component/>}/>
        ))}
      </Route>
    </Routes>
  );
}

export default withGuard(CustomerDetail, null, { redirect: true });
