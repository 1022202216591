import React, { useEffect, useState } from 'react';

import { ReactComponent as DislikeIcon } from 'assets/image/dislike.svg';
import { InfoSquareIcon } from 'assets/icons';
import { ReactComponent as LikeIcon } from 'assets/image/like.svg';
import { ReactComponent as NoItemIcon } from 'assets/image/noItem.svg';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import { StyledScore, StyledWrapper } from './styles';

const Reviews = React.memo(({ reviews, avgNps }) => (
  <StyledWrapper>
    <div className="review-box">
      <div className="review-top">
        <div className="review-title">Reviews</div>
        <div className="review-nps">
          <span>
            NPS:
            {avgNps || '-'}
          </span>
          <Tooltip
            tooltipBoxClasses="tooltip-box"
            label={<InfoSquareIcon />}
            tooltipBody={(
              <div className="tooltip-row tooltip-previous">
                <span>
                  Net Promoter Score (NPS) is a customer loyalty and
                  satisfaction measurement taken from asking customers how
                  likely they are to recommend your product or service to
                  others on a scale of 0-10.
                </span>
              </div>
            )}
            tooltipBodyClasses="tooltip"
          />
        </div>
      </div>
    </div>
    <div className="review-table">
      {reviews?.length === 0 ? (
        <div className="no-item">
          <NoItemIcon />
          <p>There are no records to display for today.</p>
        </div>
      ) : null}
      {reviews?.map((r, index) => (
        <div className="review-row">
          <StyledScore score={r?.nps_score}>{r?.nps_score}</StyledScore>
          <div className="review-table-title">{r?.message || '-'}</div>
          {/* Jan 28, 01:25 PM */}
          <div className="review-time">
            {dayjs(r?.timestamp)?.format('MMM DD, hh:mm A')}
          </div>
          {/* <div>{r?.nps_score > 5 ? <LikeIcon /> : <DislikeIcon />}</div> */}
        </div>
      ))}
    </div>
  </StyledWrapper>
));

export default Reviews;
