import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Flex, Space, Tag, Typography,
} from 'antd';
import JumpLink from 'components/JumpLink';
import StoreIcon from 'components/StoreIcon';
import SuccessDocumentIcon from 'components/SuccessDocument';
import BaseTable from 'components/Table';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { priceTransformer } from 'utils';
import { generateColumns } from 'utils/table';

function MerchantsTable({ fetchMerchants }) {
  const navigate = useNavigate();

  const handleFormatDate = (d) => (d ? dayjs(d)?.format('MMM D, hh:mm A') : ' - ');

  const handleReceivingOrders = (isReceiving) => (
    <Flex align="center" gap={6}>
      <div style={{
        width: 6, height: 6, borderRadius: '50%', backgroundColor: isReceiving ? 'green' : 'red',
      }}
      />
      <span>{isReceiving ? 'Incoming' : 'Stopped'}</span>
    </Flex>
  );

  const columns = [
    {
      title: 'Name',
      sorter: true,
      key: 'name',
      width: '19%',
      columnKey: 'name',
      render: (_, { id, name }) => (
        <JumpLink href={`/console/restaurants/${id}`} text={name} />
      ),
    },
    {
      title: 'STATUS',
      key: 'need_actions',
      width: '11%',
      render: (needActions, { is_disabled: disabled }) => {
        if (disabled) {
          return (
            <Tag color="red">
              Disabled
            </Tag>
          );
        }
        return (
          <Tag color={needActions ? 'red' : 'green'}>
            {needActions ? 'Need Action' : 'Complete'}
          </Tag>
        );
      },
    },
    {
      title: 'LAST ORDER',
      width: '12%',
      sorter: true,
      key: 'last_order_date',
      render: (date) => handleFormatDate(date),
    },
    {
      title: 'Parent',
      width: '15%',
      key: 'parent_title',
      sorter: true,
      render: (_, data) => data.parent_name,
    },
    {
      title: 'Total orders',
      key: 'orders_count',
      width: '11%',
    },
    {
      title: 'Sales',
      key: 'total_sales',
      width: '9%',
      render: (totalSale) => priceTransformer(totalSale),
    },
    {
      title: 'type',
      key: 'merchant_type',
      width: '11%',
      render: (_, data) => data.merchant_type,
    },
    {
      title: 'Receiving orders',
      sorter: true,
      key: 'get_orders_is_on',
      width: '12%',
      render: (isOrderOn) => handleReceivingOrders(isOrderOn),
    },
  ];
  const normalizedColumns = useMemo(() => generateColumns(columns), [columns]);
  return (
    <BaseTable
      columns={normalizedColumns}
      fetchFn={fetchMerchants}
      empty={(
        <Space direction="vertical" className="my-10 mx-5">
          <SuccessDocumentIcon />
          <Typography.Paragraph>It looks like our restaurant list is as empty as a dining room at closing time! Let's add some flavor to this list.</Typography.Paragraph>
          <Button
            onClick={() => {
              navigate('/console/restaurants/create');
            }}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create Restaurant!
          </Button>
        </Space>
      )}
      onRow={(record) => ({
        onClick: (event) => {
          if (event.isLink) {
            return;
          }
          navigate(`/console/restaurants/${record?.id}`);
        },
      })}
      scroll={{ x: 400 }}
    />
  );
}

export default MerchantsTable;
