import { Tabs } from 'antd';
import { fetchAllCustomers } from 'api/customers';
import Layout from 'components/Layout';
import withGuard from 'components/withGuard';
import { AdminContext } from 'constants/contexts';
import { PERMISSIONS } from 'constants/permissions';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { captureException } from 'utils/errors';
import { TABS } from './constants';
import Table from './Table';

function Customers() {
  const [search, setSearch] = useState('');
  const resetFetchRef = useRef(false);
  const [dataVersion, setDataVersion] = useState(1);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const { organizationId } = useContext(AdminContext);

  const handleRefetch = useCallback(() => setDataVersion((v) => v + 1), []);
  const handleResetFetch = useCallback(() => {
    resetFetchRef.current = true;
  }, []);

  useEffect(() => {
    handleRefetch();
  }, [handleRefetch]);

  const handleChangeTab = (value) => {
    handleResetFetch();
    const currentTab = TABS[value - 1];
    setActiveTab(currentTab);
  };

  const handleStatusName = () => {
    if (activeTab?.id === TABS[0]?.id) {
      return '';
    }
    return activeTab.label.toLocaleLowerCase();
  };

  const fetchCustomers = useCallback(
    (pagination, filter, sorter) => {
      const page = resetFetchRef.current ? 1 : pagination.current;
      const queries = {
        search,
        page,
        page_size: pagination.pageSize,
        // status: handleStatusName(),
      };
      if (sorter?.order && sorter?.columnKey) {
        queries.ordering = `${sorter?.order === 'descend' ? '-' : ''}${sorter?.columnKey}`;
      }
      if (activeTab?.id !== TABS[0]?.id) {
        queries.status = handleStatusName();
      }

      resetFetchRef.current = false;

      return fetchAllCustomers(queries)
        .then(({ data: { data: { results, count } } }) => ({
          data: results,
          total: count,
          page,
        }))
        .catch(captureException);
    },
    [
      search,
      activeTab,
      dataVersion,
      organizationId,
    ],
  );

  const handleSearchChange = (e) => {
    handleResetFetch();
    setSearch(e.target.value);
  };

  return (
    <Layout header={(
      <Layout.Header
        title="Contacts"
        onSearch={handleSearchChange}
        searchPlaceholder="Search contacts"
      />
    )}
    >
      <Tabs items={TABS} defaultActiveKey={TABS[0].key} onChange={handleChangeTab} />
      <Table fetchCustomers={fetchCustomers} />
    </Layout>
  );
}

export default withGuard(Customers, [PERMISSIONS.CUSTOMERS], { redirect: true });
