import ReportToolbar from 'containers/Reports/components/ReportToolbar';
import ReportLayout from 'containers/Reports/layout';
import { getDateRange } from 'containers/Reports/utils';
import React, { useState } from 'react';
import withGuard from 'components/withGuard';
import { PERMISSIONS } from 'constants/permissions';
import TotalMerchantsReport from './TotalMerchantsReport';

function MerchantsReport() {
  const [filterData, setFilterData] = useState();
  const dateRange = getDateRange({
    range: filterData?.selected?.date?.range,
    separator: '-',
  });
  return (
    <ReportLayout
      title="Merchants Report"
      description={`Changes to your account from <b>${dateRange}</b>, based on the date of the account change. Useful to record how your account changed.`}
      defaultRoute="/console/reports"
    >
      <ReportToolbar filterData={{ ...filterData }} setFilterData={setFilterData} hasMerchantFilter type="merchants" />
      <TotalMerchantsReport filterData={filterData} />
    </ReportLayout>
  );
}

export default withGuard(MerchantsReport, [PERMISSIONS.REPORTS], { redirect: true });
