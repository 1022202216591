import {
  Button, Result, Table as BaseTable,
} from 'antd';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { TABLE_RENDER_TYPES, generateColumns } from 'utils/table';
import { captureException } from 'utils/errors';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { useDebouncedCallback } from 'use-debounce';
import { useInterval } from 'react-use';

function Table(
  {
    columns,
    fetchFn,
    loading,
    onUpdate,
    updateInterval = null,
    customEmptyComponent,
    empty,
    ...rest
  },
) {
  const normalizedColumns = useMemo(() => generateColumns(columns), [columns]);
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [hasError, setHasError] = useState(false);
  const dataVersionRef = useRef(0);
  const [refetchVersion, setRefetchVersion] = useState(0);
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
    showSizeChanger: true,
  });
  const prevState = useRef({
    records,
    pagination: paginationConfig,
  });

  const updateList = useDebouncedCallback(() => {
    setIsLoading(true);
    setHasError(false);
    dataVersionRef.current += 1;
    const dataVersion = dataVersionRef.current;
    fetchFn(paginationConfig, filters, sorter)
      .then(({ data, total, page }) => {
        // Todo: cancel prev requests.
        if (dataVersion !== dataVersionRef.current) {
          return;
        }
        const newState = {
          records: data,
          pagination: paginationConfig,
        };
        if (onUpdate) {
          onUpdate(newState, prevState.current);
        }
        prevState.current = newState;

        setRecords(data.map((r) => ({ ...r, setRecords })));
        setPaginationConfig((current) => ({
          ...current,
          current: page ?? paginationConfig.current,
          total,
        }));
        setIsLoading(false);
      }).catch((e) => {
        captureException(e);
        setIsLoading(false);
        setHasError(true);
      });
  }, 500);

  const renderEmpty = useCallback(() => {
    if (!hasError) {
      return empty || (
        <Result
          style={{
            marginTop: 20,
            filter: 'grayscale(1)',
            opacity: 0.3,
          }}
          icon={<InboxOutlined />}
          title="No data"
        />
      );
    }
    return (
      <Result
        title="Something went wrong"
        status="error"
        style={{
          marginTop: 20,
        }}
        icon={<ExclamationCircleOutlined />}
        extra={(
          <Button onClick={updateList}>
            Try again!
          </Button>
        )}
      />
    );
  }, [hasError, updateList]);

  useEffect(() => {
    updateList();
  }, [fetchFn, updateList, refetchVersion]);

  function handlePaginationChange(config, filtersValue, sorterValue) {
    setPaginationConfig(config);
    setFilters(filtersValue);
    setSorter(sorterValue);
    dataVersionRef.current = 0;
    updateList();
  }

  useInterval(
    () => {
      setRefetchVersion((v) => v + 1);
    },
    updateInterval,
  );

  if (!records.length && !isLoading && customEmptyComponent) return customEmptyComponent;
  return (
    <BaseTable
      columns={normalizedColumns}
      dataSource={records}
      pagination={paginationConfig}
      onChange={handlePaginationChange}
      loading={isLoading || loading}
      locale={{
        emptyText: (renderEmpty()),
      }}
      {...rest}
    />
  );
}

Table.RENDER_TYPES = TABLE_RENDER_TYPES;
export default Table;
