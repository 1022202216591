import { Flex, Input, Select } from 'antd';
import FormItem from 'components/FormItem';
import React from 'react';

const options = Array.from(new Array(4)).map((_, i) => ({ value: i, label: `Preset ${i + 1}` }));
function Tip() {
  return (
    <>
      <Flex gap="middle">
        {options.map((o, index) => (
          <FormItem name={['tip_options', o.value]} label={o.label}>
            <Input type="number" prefix="%" />
          </FormItem>
        ))}
      </Flex>
      <FormItem name="tip_default_value" label="Tip default value">
        <Select options={options} />
      </FormItem>
      <FormItem
        name="tip_description"
        label="Tip description"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </FormItem>

    </>

  );
}

export default Tip;
