import {
  Form, Modal, notification, Row, Spin,
} from 'antd';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { getHttpErrorMessage } from 'utils/http';
import { captureException } from 'utils/errors';
import { locationRestrictionType, UserManagementContext } from '../../constants';
import { createNewUserPermission, getUserPermissions, updateUserPermissions } from '../../requests';
import { checkIsSubordinateRole } from '../../utils';
import FormSection from './form';
import RoleDescription from './RoleDescription';
import './styles.css';

function PermissionForm({ handleRefetch }) {
  const [form] = Form.useForm();
  const {
    openPermissionForm, currentUser, handleClosePermissionForm, roles,
  } = useContext(UserManagementContext);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const isEditMode = useMemo(() => Boolean(currentUser), [currentUser]);
  useEffect(() => {
    if (isEditMode) {
      setLoading(true);
      getUserPermissions(currentUser.id).then((res) => {
        const user = res.data;
        form.setFieldsValue({
          userEmailAddress: user.email,
          email: user.email,
          userRoles: user.groups?.map((p) => p.name),
          locationRestriction: user.access_all_parents ? locationRestrictionType.all : locationRestrictionType.specific,
          locations: user.access_all_parents ? [] : user.parents?.map((p) => String(p)),
          restaurants_administrated: user.restaurant_names.map((r) => ({
            value: r.id,
            label: r.name,
          })),
        });
        setLoading(false);
      }).catch((e) => {
        captureException(e);
        notificationApi.error({ message: 'Something went wrong in getting user info!' });
        onCloseModal();
      });
    }
  }, [currentUser]);

  const onSubmit = () => {
    const onFulfilled = () => {
      setConfirmLoading(true);
      const formValues = form.getFieldsValue();
      const uniqueRoles = formValues.userRoles?.filter((current) => !checkIsSubordinateRole({
        current,
        roles,
        selectedRoles: formValues.userRoles,
      }));
      const data = {
        userId: currentUser?.id,
        email: formValues.userEmailAddress || currentUser.email,
        roles: uniqueRoles || [],
        access_all_parents: formValues.locationRestriction === locationRestrictionType.all,
        parents: formValues.locations?.map(Number) || [],
        restaurants_administrated: formValues.restaurants_administrated?.map((m) => m.value),
      };

      (isEditMode ? updateUserPermissions : createNewUserPermission)(data).then((res) => {
        notificationApi.success({ message: `Successfully ${isEditMode ? 'updated' : 'added'}` });
        onCloseModal({ needRefetch: true });
        setConfirmLoading(false);
      }).catch((e) => {
        captureException(e);
        setConfirmLoading(false);
        notificationApi.error({ message: getHttpErrorMessage(e) || 'Something went wrong!' });
      });
    };

    form.validateFields({ validateOnly: true }).then(
      onFulfilled,
      () => {
        notificationApi.error({ message: 'Please fill all required fields' });
      },
    );
  };

  const onCloseModal = ({ needRefetch = false }) => {
    form.resetFields();
    handleClosePermissionForm();
    needRefetch && handleRefetch();
    setConfirmLoading(false);
  };

  return (
    <Modal
      title={isEditMode ? `Edit user (${form.getFieldValue('userEmailAddress') || currentUser.email})` : 'Add new member'}
      centered
      open={openPermissionForm}
      width={1000}
      onOk={onSubmit}
      confirmLoading={confirmLoading}
      okText={isEditMode ? 'Save' : 'Send invite'}
      onCancel={onCloseModal}
      rootClassName="permission-modal-body"
      style={{ padding: 0 }}
    >
      {contextHolder}

      {loading && <div className="flex justify-content-center m-10"><Spin size="large" /></div>}

      {!loading && (
        <Form
          form={form}
          layout="vertical"
        >
          <Row gutter={32}>
            <FormSection isEditMode={isEditMode} />
            <RoleDescription />
          </Row>
        </Form>
      )}
    </Modal>
  );
}

export default PermissionForm;
