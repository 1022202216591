import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Layout from 'components/Layout';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { getData } from 'utils';
import { UserManagementContext } from './constants';
import PermissionForm from './pages/PermissionForm';
import PermissionsTable from './pages/PermissionsTable';
import { fetchAccessibleRoles, fetchUsersPermissions } from './requests';

function UserManagement() {
  const [openPermissionForm, setOpenPermissionForm] = useState(false);
  const [roles, setRoles] = useState();
  const [currentUser, setCurrentUser] = useState(null);
  const [dataVersion, setDataVersion] = useState(1);
  const [searchValue, setSearchValue] = useState();
  const handleRefetch = useCallback(() => setDataVersion((v) => v + 1), []);

  const userManagementContext = useMemo(() => ({
    openPermissionForm,
    currentUser,
    roles,
    handleOpenPermissionForm: (user) => {
      setCurrentUser(user);
      setOpenPermissionForm(true);
    },
    handleClosePermissionForm: () => {
      setCurrentUser(null);
      setOpenPermissionForm(false);
    },
  }), [currentUser, openPermissionForm, roles]);

  const newPermissionAction = useMemo(() => (
    <Button icon={<PlusOutlined />} onClick={() => setOpenPermissionForm(true)}>
      New member
    </Button>
  ), []);

  useEffect(() => {
    fetchAccessibleRoles().then((rolesData) => {
      setRoles(rolesData.message.roles);
    });
  }, []);

  const fetchPermissions = useCallback((pagination, filters) => {
    const page = pagination.current;
    const queries = {
      page: searchValue ? 1 : page,
      page_size: pagination.pageSize,
      search: searchValue,
      role__in: filters.groups?.join(',') || '',
    };
    return fetchUsersPermissions(queries).then((res) => {
      const { results, count } = getData(res);
      return {
        data: results,
        total: count,
        page,
      };
    });
  }, [dataVersion, searchValue]);

  return (
    <Layout header={(
      <Layout.Header
        title="User management"
        searchPlaceholder="Search user"
        onSearch={(e) => setSearchValue(e.target.value)}
        extra={[newPermissionAction]}
        backTitle="Account settings"
        backPath="/console/setting"
      />
    )}
    >
      <UserManagementContext.Provider value={userManagementContext}>
        <PermissionsTable fetchPermissions={fetchPermissions} handleRefetch={handleRefetch} />
        <PermissionForm handleRefetch={handleRefetch} />
      </UserManagementContext.Provider>
    </Layout>
  );
}

export default UserManagement;
