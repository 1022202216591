export async function getGeoCode(placeId) {
  if (!window.google?.maps) {
    throw new Error('Google Maps not loaded!');
  }
  const { Geocoder } = await window.google.maps.importLibrary('geocoding');
  const geocoder = new Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { placeId },
      (responses, status) => {
        if (status === 'OK') {
          const lat = responses[0].geometry.location.lat();
          const lng = responses[0].geometry.location.lng();
          resolve({ lat, lng });
          return;
        }
        reject(new Error(status));
      },
    );
  });
}

export function normalizeLatLng(latLng) {
  return {
    lat: typeof latLng.lat === 'function' ? latLng.lat() : latLng.lat,
    lng: typeof latLng.lng === 'function' ? latLng.lng() : latLng.lng,
  };
}
