import {
  Divider,
  Typography,
} from 'antd';
import React from 'react';

function Row({
  children,
  className = 'items-center',
  divider = true,
  title = null,
  ...props
}) {
  if (!children) {
    return null;
  }
  return (
    <>
      {divider && (
        <Divider
          className="my-4"
          orientation="left"
        >
          {title && <Typography.Text type="secondary">{title}</Typography.Text>}
        </Divider>
      )}
      <div
        {...props}
        className={`flex justify-between w-full px-5 lg:px-8 ${className}`}
      >
        {children}
      </div>
    </>
  );
}

export default Row;
