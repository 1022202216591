import { PlusOutlined, TagFilled as CouponIcon } from '@ant-design/icons';
import { Button, Empty, Tabs } from 'antd';
import { Colors } from 'assets/colors';
import Layout from 'components/Layout';
import { AdminContext } from 'constants/contexts';
import { getAllCoupons } from 'containers/Coupons/coupons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { useDebouncedCallback } from 'use-debounce';
import { getData } from 'utils';
import { captureException } from 'utils/errors';

import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import { ModalContext } from '../../context/modalContext';
import usePagination from '../../hooks/usePagination';
import ActivateModal from './ActivateModal';
import { COUPON_LIST_ACTIVE, COUPON_LIST_ALL, COUPON_LIST_INACTIVE } from './constants';
import DeleteModal from './DeleteModal';
import { StyledWrapper } from './styles';
import Table from './Table';

function Coupons() {
  const [search, setSearch] = useState( '' );
  const [couponsLoading, setCouponsLoading] = useState( true );
  const [coupons, setCoupons] = useState( [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] );
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState( COUPON_LIST_ALL );
  const { organizationId } = useContext( AdminContext );

  const { handleModal } = useContext( ModalContext );

  const TABS = [
    {
      label: 'All',
      key: COUPON_LIST_ALL,
    },
    {
      label: 'Active',
      key: COUPON_LIST_ACTIVE,
    },
    {
      label: 'Inactive',
      key: COUPON_LIST_INACTIVE,
    },
  ];

  const debounced = useDebouncedCallback((value) => {
    resetPaging();
    setSearch(value);
  }, 500);
  useEffect(() => {
    getCoupons();
  }, [search, activeTab] );


  const handleCouponStatusFilter = (activeKey) => {
    switch (activeKey) {
    case COUPON_LIST_ACTIVE:
      return false;
    case COUPON_LIST_INACTIVE:
      return true;
    default:
      return undefined;
    }
  };

  const getCoupons = () => {
    setCouponsLoading(true);
    getAllCoupons(
      search,
      page,
      handleCouponStatusFilter(activeTab),
      organizationId,
    ).then((res) => {
      setCouponsLoading(false);
      setCoupons([...getData(res)?.results]);
      setPagination({
        count: getData(res)?.count,
        next: getData(res)?.next,
        previous: getData(res)?.previous,
      });
    })
      .catch((e) => {
        captureException(e);
        setCouponsLoading(false);
      });
  };

  const onDelete = (data) => {
    handleModal(<DeleteModal row={data} reload={getCoupons} />);
  };
  const onEditStatus = (data) => {
    handleModal(<ActivateModal row={data} reload={getCoupons} />);
  };
  const onEdit = (id) => {
    navigate(`/console/coupons/form/${id}`);
  };

  const {
    setPage, page, pagination, setPagination, paginationEl,
  } =
    usePagination(getCoupons, couponsLoading);

  const handleSearchChange = (e) => {
    debounced(e.target.value);
  };

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  const handleCreateCoupon = () => {
    navigate('/console/coupons/form');
  };

  return (
    <Layout header={(
      <Layout.Header
        title="Coupons"
        onSearch={handleSearchChange}
        searchPlaceholder="Search coupons ..."
        extra={[
          <Button
            className="flex-row"
            type="default"
            size="middle"
            onClick={handleCreateCoupon}
            icon={<PlusOutlined />}
          >
            Create new
          </Button>,
        ]}
      />
    )}
    >
      <StyledWrapper>
        {/* Tabs */}
        <Tabs
          items={TABS}
          defaultActiveKey={activeTab}
          onTabClick={(key) => setActiveTab(key)}
        />
        {/* Table */}
        {coupons?.length ? (
          <div className="table-box">
            <Table
              loading={couponsLoading}
              pagination={pagination}
              resetPaging={resetPaging}
              items={coupons}
              onDelete={onDelete}
              onEditStatus={onEditStatus}
              onEdit={onEdit}
            />
            <div className="pagination">{paginationEl}</div>
          </div>
        ) : (
          <div style={{ marginTop: 60 }}>
            <Empty
              image={(
                <CouponIcon
                  style={{
                    fontSize: '60px',
                    color: Colors.brandColor,
                    opacity: 0.2,
                  }}
                  rotate={-90}
                />
              )}
              imageStyle={{
                height: 60,
                marginBottom: 20,
              }}
              description={(
                <div>
                  <div className="empty-box-title ">
                    You don't have any coupons!
                  </div>
                  <div className="empty-box-sub-title ">
                    Create coupons that apply automatically to a customer’s cart.
                  </div>
                </div>
              )}
            >
              <Button
                style={{ display: 'inline-flex', alignItems: 'center' }}
                icon={<PlusOutlined />}
                onClick={() => handleCreateCoupon()}
                type="primary"
              >
                Create a coupon
              </Button>
            </Empty>
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
}

export default withGuard(Coupons, [PERMISSIONS.COUPONS], { redirect: true });
