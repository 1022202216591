import React from 'react';
import { Tag, Typography } from 'antd';
import { Row } from 'react-bootstrap';
import { priceTransformer } from 'utils';

function ItemPrice({
  price,
  discountedPrice,
  style,
  showZero = false,
  showPercentage = true,
}) {
  return (
    <Row style={{ ...style, marginRight: 0 }}>
      {!!discountedPrice && showPercentage && (
        <Tag color="#027C2E" style={{ borderRadius: 14, padding: '0 4px' }}>
          {Math.round((100 * (price - discountedPrice)) / price)}
          %
        </Tag>
      )}

      {discountedPrice && (
        <Typography.Text style={{ marginRight: 4 }}>
          {priceTransformer(discountedPrice)}
        </Typography.Text>
      )}

      <Typography.Text type="primary" delete={!!discountedPrice} disabled={!!discountedPrice}>
        {+price === 0 && !showZero ? null : priceTransformer(price)}
      </Typography.Text>

    </Row>
  );
}

export default ItemPrice;
