import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin-right: 32px;
  @media (max-width:575.98px) {
    margin-right: 0;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .box-form {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4e5457;
    &-section {
      margin-bottom: 16px;
    }

    &-title {
      margin-bottom: 8px;
    }
  }

  .input-icon {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #7d8284;
  }

  .input {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;
