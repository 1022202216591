import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button, Input, message, Modal,
} from 'antd';
import Typography from 'antd/es/typography/Typography';
import SkeletonLoading from 'components/SkeletonLoading';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import useIsDesktop from 'hooks/useIsDesktop';
import React, { useContext, useState } from 'react';
import { priceTransformer } from 'utils';
import { getItemPrices, getModifiersPrice } from './item';
import ItemTypes from './ItemTypes';
import { modifierContext } from './ModifierProvider';
import Modifiers from './Modifiers';
import { StyledWrapper } from './styles';

const { TextArea } = Input;

function ItemDetailModal({
  open,
  item,
  handleAddItem,
  onClose,
}) {
  const isDesktop = useIsDesktop();
  const { order } = useContext(CallInOrderContext);
  const showSpecialNotes = order?.selectedMerchant?.merchant?.config?.show_item_special_notes;
  const [count, setCount] = useState(1);
  const [size, setSize] = useState();
  const [specialNote, setSpecialNote] = useState('');
  const {
    getSelectedModifiersTree,
    getNormalizedSelectedModifiers,
    unSatisfiedModifiers,
    handleBack,
    parent,
    loading,
  } = useContext(modifierContext);
  const [messageApi, contextHolder] = message.useMessage();
  const cleanupState = () => {
    setCount(1);
    setSpecialNote('');
  };

  const hasNoModifierOrItemType = () => {
    if (!item) {
      return true;
    }
    return (
      item.modifiers?.length === 0 &&
      item.item_types?.filter(
        (type) => type.price != null && type.size_id && type.type,
      ).length <= 1
    );
  };

  const handleAddToCart = () => {
    const hasNothing = hasNoModifierOrItemType();
    const configuredItem = {
      ...item,
      discountedPrice: hasNothing
        ? item?.discount?.discounted_prices[0]?.discounted_price
        : getPriceAndDiscountedPrice()[1],
      originalPrice: hasNothing
        ? item?.item_types[0].price
        : getPriceAndDiscountedPrice()[0],
      qty: count,
      size: hasNothing ? item?.item_types[0] : size.id,
      sizeTitle: hasNothing ? item?.item_types[0]?.size_id : size?.title,
      message: specialNote,
      selection: {
        qty: 1,
        size: hasNothing ? item?.item_types[0] : size,
        selectedModifiers: hasNothing ? [] : getNormalizedSelectedModifiers(),
        modifiers: hasNothing ? [] : getNormalizedSelectedModifiers(),
        modifiersTree: getSelectedModifiersTree(),
      },
    };
    handleAddItem(configuredItem);
    onClose();
    cleanupState();
  };

  function handleContinue() {
    if (unSatisfiedModifiers.length) {
      messageApi.open({
        type: 'error',
        content: 'Please select required modifiers!',
      });
      return;
    }
    if (parent) {
      handleBack();
      return;
    }
    handleAddToCart();
    onClose();
  }

  const getPriceAndDiscountedPrice = (typeId) => {
    const itemPrice = item?.item_types?.find(
      (d) => d?.size_id === (typeId || size?.id),
    )?.price;
    const discountedPrice = item?.discount?.discounted_prices?.find(
      (d) => d?.size_id === (typeId || size?.id),
    )?.discounted_price;

    return [itemPrice, discountedPrice];
  };

  const getCartButtonLabel = () => {
    if (!item) return 'Add to Cart';
    if (parent || unSatisfiedModifiers.length > 0) {
      return 'Continue';
    }
    const modifiersPrice = getModifiersPrice(getNormalizedSelectedModifiers());
    const { price, discountedPrice } = getItemPrices({
      item,
      size: size?.id,
    });
    const finalPrice = priceTransformer(
      ((discountedPrice || price) + modifiersPrice),
    );
    return `Add to Cart · ${finalPrice}`;
  };

  return (
    <StyledWrapper>
      {contextHolder}
      <Modal
        bodyStyle={{
          backgroundColor: '#F5F8F7', maxHeight: 470, overflow: 'scroll',
        }}
        width={isDesktop ? '40%' : '95%'}
        open={open}
        title={item?.name}
        onCancel={() => {
          onClose();
          cleanupState();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              onClose();
              cleanupState();
            }}
          >
            Cancel
          </Button>,
          <Button
            disabled={loading}
            key="Add to cart"
            type="primary"
            onClick={handleContinue}
          >
            {getCartButtonLabel()}
          </Button>,
        ]}
      >
        {parent && (
          <Button type="link" size="small" onClick={handleBack} style={{ marginBottom: 6, marginLeft: -8 }}>
            <ArrowLeftOutlined />
            Back
          </Button>
        )}

        {!item ? (
          <SkeletonLoading />
        ) : (
          <StyledWrapper>
            {!hasNoModifierOrItemType() && (
              <>
                <ItemTypes
                  item={item}
                  value={size}
                  onChange={setSize}
                />
                <Modifiers />
              </>
            )}
            {showSpecialNotes && (
              <>
                <Typography.Text>Special notes</Typography.Text>
                <TextArea
                  style={{ marginTop: 10 }}
                  value={specialNote}
                  onChange={(e) => {
                    setSpecialNote(e.target.value);
                  }}
                  rows={4}
                  placeholder="Special notes"
                  maxLength={100}
                />
              </>
            )}
          </StyledWrapper>
        )}
      </Modal>
    </StyledWrapper>
  );
}

export default ItemDetailModal;
