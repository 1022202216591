import { useEffect } from 'react';

/*
*
This hook is for cases we need to fire an action on click outside of a specific ref
callback is the function that we want to fire on click outside
If there is another exception element we can pass it's ref to prevent that action call on click of this element
*/

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current?.contains(e.target)) {
      setTimeout(() => {
        callback();
      }, 0);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  });
};
