import {
  App,
  Button, Col, Divider, Drawer, Flex, Input, InputNumber, Row, Skeleton, Typography,
} from 'antd';
import DietaryTags from 'components/DietaryTags';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import http from 'utils/http';
import AmountInput from 'components/AmountInput';
import QuantityInput from 'components/QuantityInput';
import { renderServingSizeLabel } from 'utils/orders';
import ItemSizes from './ItemSizes';
import ModifierProvider, { modifierContext } from './ModifierProvider';
import Modifiers from './Modifiers';
import { ItemsInputContext } from '../constants';

const { TextArea } = Input;

function ItemDetailOverlay({
  onClose,
  onChange,
  nested,
  isCatering,
  editablePrice,
}) {
  const { selectedOrderItem } = useContext(ItemsInputContext);
  const [item, setItem] = useState(null);
  const selectedOptions = useMemo(() => selectedOrderItem?.order_options?.map((op) => ({
    id: op.modifier_option.id,
    qty: op.qty,
  }) ?? []), [selectedOrderItem]);

  useEffect(() => {
    if (selectedOrderItem && selectedOrderItem?.item_related?.id !== item?.id) {
      setItem(null);
      http.get(`v1/items/${selectedOrderItem.item_related.id}/`).then(({ data: { data } }) => {
        setItem({
          ...data,
          real_price: Number(data.real_price),
        });
      });
    }
  }, [selectedOrderItem, item]);

  return (
    <ModifierProvider modifiers={item?.modifiers} selectedOptions={selectedOptions}>
      <Overlay
        open={Boolean(selectedOrderItem)}
        onChange={onChange}
        onClose={onClose}
        orderItem={selectedOrderItem}
        item={item}
        nested={nested}
        isCatering={isCatering}
        editablePrice={editablePrice}
      />
    </ModifierProvider>
  );
}

function Overlay({
  open,
  orderItem,
  item,
  onChange,
  onClose,
  nested,
  isCatering,
  editablePrice,
}) {
  const [editingOrderItem, setEditingOrderItem] = useState({});
  useEffect(() => {
    setEditingOrderItem({ ...orderItem, size: orderItem?.size?.position });
  }, [orderItem]);

  const {
    getNormalizedSelectedModifiers,
    unSatisfiedModifiers,
    loading,
    getSelectedModifiersTree,
    getModifier,
    setSelectedModifiers,
  } = useContext(modifierContext);

  const { message } = App.useApp();

  const hasNoModifier = () => {
    if (!item) {
      return true;
    }
    return !item.modifiers || item.modifiers?.length === 0;
  };
  function getSize(sizeId) {
    if (item.sizes?.length) {
      return item.sizes.find(
        (type) => type.position === sizeId,
      );
    }
  }

  function getSizePrice(sizeId) {
    if (item.sizes?.length) {
      const size = item.sizes.find(
        (type) => type.position === sizeId,
      );
      return Number(editablePrice ? size.price : size.discounted_marked_up_price);
    }
    return 0;
  }

  const handleAddToCart = () => {
    const newOrderItem = {
      ...editingOrderItem,
      order_options: getNormalizedSelectedModifiers().map((o) => ({
        modifier_option: { ...o, modifier: getModifier(o) },
        real_price: o.price, // TODO: CHECK This
        qty: o.qty,
      })),
      modifiers_tree: getSelectedModifiersTree(),
      size: getSize(editingOrderItem.size),
    };
    onChange(newOrderItem);
    setSelectedModifiers({});
    onClose();
  };

  function handleContinue() {
    if (unSatisfiedModifiers.length) {
      message.open({
        type: 'error',
        content: 'Please select required modifiers!',
      });
      return;
    }
    handleAddToCart();
    onClose();
  }

  const getCartButtonLabel = () => {
    if (unSatisfiedModifiers.length > 0) {
      return 'Continue';
    }

    if (editingOrderItem?.isNew) {
      return 'Add to cart';
    }
    return 'Update';
  };

  function handleChange(payload) {
    setEditingOrderItem((c) => ({ ...c, ...payload }));
  }

  return (
    <Drawer
      title={orderItem?.item_related?.name || item?.name}
      placement="right"
      onClose={() => {
        onClose();
      }}
      width={nested ? 600 : 700}
      open={open}
      size="large"
      zIndex={2000}
      styles={{ body: { padding: '24px 0' } }}
      footer={(
        <Flex gap={8} justify="flex-end">
          <Button
            key="back"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            key="Add to cart"
            type="primary"
            onClick={handleContinue}
          >
            {getCartButtonLabel()}
          </Button>
        </Flex>
      )}
    >
      {renderContent()}
    </Drawer>
  );

  function renderContent() {
    if (!item) {
      return <Skeleton paragraph={{ rows: 5 }} className="px-6" />;
    }
    return (
      <>

        {(item.description || item.dietary_tags?.length > 0) && (
          <>
            <Flex vertical gap={8} className="px-6">
              {item.description && (
                <Typography.Text>{item.description}</Typography.Text>
              )}
              <DietaryTags
                tags={item.dietary_tags}
                maxCount={item.dietary_tags?.length}
                wrapperStyle={{ flexWrap: 'wrap' }}
                showTitle
              />
            </Flex>
            <Divider style={{ margin: '16px 0' }} />
          </>
        )}

        <Flex vertical gap={24} className="px-6">
          <Row>
            <Col xs={24} md={12}>
              <Flex vertical align="flex-start" gap={8}>
                <Typography.Text strong>Item price</Typography.Text>
                <AmountInput
                  value={editingOrderItem.real_price || null}
                  onChange={(real_price) => handleChange({ real_price })}
                  disabled={!editablePrice}
                  style={{ width: 180 }}
                />
              </Flex>
            </Col>
            <Col xs={24} md={12}>
              <Flex vertical gap={8}>
                <Typography.Text strong>Item quantity</Typography.Text>
                {isCatering ? (
                  <>
                    <InputNumber
                      suffix={editingOrderItem?.item_related?.catering_label}
                      value={editingOrderItem.qty}
                      onChange={(qty) => handleChange({ qty })}
                      style={{ width: 180 }}
                    />
                    <Typography.Text className="text-xs text-[#2B3235]">
                      {renderServingSizeLabel((getSize(editingOrderItem.size)?.serving_size || 1) * editingOrderItem.qty)}
                    </Typography.Text>
                  </>
                ) : (
                  <QuantityInput
                    value={editingOrderItem.qty}
                    onChange={(qty) => handleChange({ qty })}
                    size="middle"
                  />
                )}
              </Flex>
            </Col>
          </Row>

          <ItemSizes
            item={item}
            value={editingOrderItem?.size}
            isCatering={isCatering}
            onChange={(size) => {
              handleChange({ size, real_price: getSizePrice(size) });
            }}
          />
        </Flex>

        {!orderItem ? (
          <Skeleton paragraph={{ rows: 5 }} />
        ) : (
          <>
            {!hasNoModifier() && (
              <>
                <Divider />
                <Flex vertical className="px-6">
                  <Modifiers />
                </Flex>
                <Divider />
              </>
            )}
            <Flex vertical className="px-6">
              <Typography.Text>Special notes</Typography.Text>
              <TextArea
                style={{ marginTop: 10 }}
                value={editingOrderItem.message}
                onChange={(e) => {
                  handleChange({ message: e.target.value });
                }}
                rows={4}
                placeholder="Special notes"
                maxLength={100}
              />
            </Flex>
          </>
        )}
      </>
    );
  }
}

export default ItemDetailOverlay;
