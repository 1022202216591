import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button, Col, Flex, Row, Spin, Switch, Typography,
} from 'antd';
import EmptyComponent from 'components/Empty';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';
import { ModalMode } from '../../constants';
import { MenuContext } from '../context';
import { editMenu } from './requests';
import MenuDetailModal from './MenuDetailModal';
import SearchItemInput from '../../components/SearchItemInput';
import MenuSelector from './MenuSelector';

function MenuActions() {
  const {
    menus,
    itemsLoading,
    setItemsLoading,
    menuDetail,
    fetchMenuDetail,
    showMessage,
    setSearchTerm,
  } = useContext(MenuContext);
  const [mode, setMode] = useState(ModalMode.CLOSED);

  const onChangeAvailability = (checked) => {
    setItemsLoading(true);
    editMenu({ menuId: menuDetail.id, menuDetail: { is_active: checked } })
      .then((res) => {
        fetchMenuDetail();
      }).catch((e) => {
        captureException(e);
        showMessage({ type: 'error' });
      })
      .finally(() => setItemsLoading(false));
  };

  return (
    <>
      {!menus.length && (
        <EmptyComponent
          title="This merchant does not have any menu!"
          actionButtonText="Add menu"
          onClick={() => setMode(ModalMode.NEW)}
        />
      )}
      {menus.length > 0 && (
        <Flex vertical gap="middle">
          <Row gutter={[8, 8]} style={{ alignItems: 'center' }}>
            <Col xs={24} md={7} lg={8} xl={8}>
              <MenuSelector onAddNew={() => setMode(ModalMode.NEW)} />
            </Col>
            <Col xs={24} md={5} lg={4} xl={3}>
              <Button icon={<EditOutlined />} disabled={itemsLoading} onClick={() => setMode(ModalMode.EDIT)}>
                Edit menu
              </Button>
            </Col>
            <Col xs={24} md={5} lg={6} xl={3}>
              <Switch
                checked={menuDetail.is_active}
                disabled={itemsLoading}
                onChange={onChangeAvailability}
                style={{ marginRight: 6 }}
              />
              <Typography.Text>Available</Typography.Text>
            </Col>
            <Col xs={24} md={7} lg={5} xl={9} style={{ textAlign: 'end' }}>
              {itemsLoading && (
                <>
                  <Typography.Text type="success">Fetching the data</Typography.Text>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24, marginLeft: 8 }} spin />} />
                </>
              )}
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} md={12} lg={8}>
              <SearchItemInput disabled={itemsLoading} setSearchTerm={setSearchTerm} placeholder="Burger" />
            </Col>
          </Row>
        </Flex>
      )}

      <MenuDetailModal setMode={setMode} mode={mode} />
    </>
  );
}

export default MenuActions;
