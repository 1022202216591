import FormItem from 'components/FormItem';
import MultiTypeAmountInput from 'components/MultiTypeAmountInput';
import React from 'react';
import GeneralForm from './GeneralForm';

function NonPartnerForm() {
  return (
    <>
      <GeneralForm />
      <FormItem label="Markup">
        <FormItem
          name="markup"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.amount >= 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Markup amount must be greater than or equal 0'));
              },
            },
          ]}
        >
          <MultiTypeAmountInput placeholder="e.g. 15" />
        </FormItem>
      </FormItem>
    </>
  );
}

export default NonPartnerForm;
