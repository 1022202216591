import styled from 'styled-components';

export const StyledWrapper = styled.div`
 margin-right: 32px;
 @media (max-width:575.98px) {
  margin-right: 0;
 }
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

 
 `;
