import { PlusOutlined } from '@ant-design/icons';
import { NewOrdersContext } from 'constants/contexts';
import React, { useContext, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Badge, Button, Menu } from 'antd';
import { useHasAnyOfPermissionsCallback } from 'hooks/useHasPermission';
import { SIDEBAR_ITEMS } from 'constants/sidebar-items';

function useSidebarItems(collapsed) {
  const hasAccess = useHasAnyOfPermissionsCallback();

  return useMemo(() => {
    const topItems = generateSidebarItems(SIDEBAR_ITEMS.filter((i) => !i.bottom));
    const bottomItems = generateSidebarItems(SIDEBAR_ITEMS.filter((i) => i.bottom));

    return { topItems, bottomItems };

    function generateSidebarItems(items) {
      function generateMenuItem(menu) {
        return (
          <Menu.Item
            key={menu.route.path}
            icon={menu.icon}
            className={menu.action?.route && 'custom-menu-item'}
          >
            {menu.route?.path ? (
              <NavLink to={menu.route.path.replace(/\/:[a-zA-Z]*\?/g, '')} exact={menu.route.path === '/console'}
                       key={menu.key}>
                {menu.label}
                <NavBadge route={menu.route} badge={menu.badge}/>
              </NavLink>
            ) : menu.label}
            {menu.action && !collapsed && <Button size="small" icon={<PlusOutlined />} href={menu.action.route}/>}
          </Menu.Item>
        );
      }

      return items
        .filter((n) => hasAccess(n.permissions))
        .map((s) => (
          s.subMenus ? (
            <Menu.SubMenu
              key={s.label}
              icon={s.icon}
              title={s.label}
            >
              {s.subMenus.map(generateMenuItem)}
            </Menu.SubMenu>
          ) : generateMenuItem(s)
        ));
    }
  }, [hasAccess, collapsed]);
}

function NavBadge({ badge, route }) {
  const { newOrderIds, newScheduledOrderIds } = useContext(NewOrdersContext);
  const newOrdersCount = new Set([...newOrderIds, ...newScheduledOrderIds]).size;
  const location = useLocation();
  const badges = {
    orders: () => {
      if (newOrdersCount === 0) {
        return null;
      }
      return newOrdersCount > 9 ? '+9' : newOrdersCount;
    },
  };
  if (!badge || !badges?.[badge]() || location.pathname.includes(route.path)) {
    return null;
  }
  return <Badge count={badges[badge]()} className="ml-4"/>;
}

export default useSidebarItems;
