export const mergeItems = ({ items = [], item }) => {
  if (items.length === 0) {
    return [item];
  }

  const sameItemIndex = items.findIndex(
    (i) => i.id === item.id &&
      i.selection.size.id === item.selection.size.id &&
      modifiersAreSame(i.selection.modifiers, item.selection.modifiers),
  );

  if (sameItemIndex === -1) {
    return [...items, item];
  }

  const mergedItem = items[sameItemIndex];

  return [
    ...items.filter((_, index) => index !== sameItemIndex),
    {
      ...mergedItem,
      qty: mergedItem.selection.qty + item.selection.qty,

      selection: {
        ...mergedItem.selection,
        qty: mergedItem.selection.qty + item.selection.qty,
      },
    },
  ];
};

export function sortItems(items) {
  return items.sort((a, b) => a.id - b.id);
}

function modifiersAreSame(firstModifiers, secondModifiers) {
  const firstModifiersValue = Object.values(firstModifiers);
  const secondModifiersValue = Object.values(secondModifiers);
  if (firstModifiersValue.length !== secondModifiersValue.length) {
    return false;
  }
  return firstModifiersValue.every((fm) => secondModifiersValue.some(
    (sm) => sm.id === fm.id && sm.qty === fm.qty,
  ));
}
