import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import useIsMobile from 'hooks/useIsMobile';
import { truncate } from 'lodash-es';
import React, { useState } from 'react';

const { Text } = Typography;

function CallEvent({ event: { data: call, occurred_at: date } }) {
  const isMobile = useIsMobile();
  const isToday = dayjs(date).diff(new Date(), 'day') === 0;
  const [open, setOpen] = useState(false);

  return (
    <Flex vertical gap={4} className="ml-3 bg-white rounded py-2 px-3 w-full" onClick={() => call.note.length >= 70 && setOpen(!open)}>
      <Flex justify="space-between" vertical={isMobile} className="w-full">
        <Flex gap={12}>
          <ArrowRightOutlined style={{ transform: call.direction === 'inbound' ? 'rotate(45deg)' : 'rotate(-45deg)' }} />
          <Text className="font-bold text-[16px]">
            {call.customer_name}
          </Text>
          {
            call.disposition !== 'answered' ? (
              <Text>
                {dayjs.duration(call.duration, 'seconds').format('mm:ss')}
              </Text>
            ) : (
              <Text type="danger">
                Not answered
              </Text>
            )
          }
        </Flex>
        <Text>{dayjs(date).format(isToday ? 'hh:mm A' : 'MMM DD, hh:mm A')}</Text>
      </Flex>

      {
        call.note && (
          <Flex
            vertical={isMobile}
            justify="space-between"
            align="flex-start"
            gap={4}
          >
            <Text style={{ height: open ? 'auto' : '20px', overflow: 'hidden' }}>
              <Text className="text-[12px]" type="secondary">
                Note:
              </Text>
              {' '}
              {open ? call.note : truncate(call.note, { length: 70, omission: '...' })}
            </Text>
            {
              !open && call.note.length >= 70 && (
                <Button type="link" className="pr-0">
                  Show more
                </Button>
              )
            }
          </Flex>
        )
      }
    </Flex>
  );
}

export default CallEvent;
