import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from 'lodash-es';
import React, { useCallback } from 'react';

function SearchItemInput({ disabled, setSearchTerm, placeholder }) {
  const debouncedChangeHandler = useCallback(debounce((e) => {
    setSearchTerm(e.target.value?.toLowerCase());
  }, 400), []);

  return (
    <Input
      suffix={<SearchOutlined />}
      placeholder={placeholder}
      onChange={debouncedChangeHandler}
      disabled={disabled}
    />
  );
}

export default SearchItemInput;
