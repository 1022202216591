export const sidebar = {
  position: 'fixed',
  background: '#F2F7F4',
  height: 'calc(100vh - 48px)',
  left: 0,
  top: 0,
  bottom: 0,
  borderRight: '1px solid rgba(16, 17, 18, 0.1)',
};

export const sidebarTrigger = (collapsed) => ({
  background: '#F2F7F4',
  borderTop: '1px solid rgba(16, 17, 18, 0.1)',
  paddingLeft: collapsed ? 0 : '20px',
  borderRight: '1px solid rgba(16, 17, 18, 0.1)',
});
export const sidebarTriggerIcon = {
  fontSize: '16px',
};

export const sidebarLogo = {
  paddingLeft: '20px',
  paddingBottom: '32px',
};

export const sidebarItemsContainer = {
  background: '#F2F7F4',
};

export const sidebarTopContainer = {
  padding: '24px 0',
  height: '96vh',
};

export const organizationSelector = {
  padding: '4px 10px',
  margin: '0 5px 12px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  backgroundColor: '#0000000A',
  borderRadius: 10,
  justifyContent: 'center',
};

export const organizationSelectorIconContainer = (collapsed) => ({
  background: '#fff',
  borderRadius: '5px',
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: collapsed ? 0 : '2px',
});

export const organizationSelectorIcon = {
  fontSize: '16px',
  color: '#ADB2B8',
};
