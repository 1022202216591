import { InfoCircleFilled } from '@ant-design/icons';
import {
  Radio, Space, Tooltip, Typography,
} from 'antd';
import { SubStyledWrapper } from 'containers/CreateOrder/Form/styles';
import React, { useCallback, useContext } from 'react';
import { CallInOrderContext } from '../../context';
import CustomPaymentForm from './CustomPaymentForm';
import usePaymentMethods from './usePaymentMethods';
import UserCards from './UserCards';
import { PaymentTypes } from '../../constants';

function PaymentInfo() {
  const { order, setOrderDetails } = useContext(CallInOrderContext);
  const customPaymentConfig = order.selectedMerchant?.merchant?.config?.custom_payments || [];
  const {
    paymentMethods, loading, reFetch, stripeKey, cards,
  } = usePaymentMethods({ customPayments: customPaymentConfig });

  const handleChange = (value) => {
    const paymentMethod = paymentMethods.find((p) => p.id === value);
    setOrderDetails({ paymentMethod });
  };

  const isPaymentDisable = useCallback((type) => {
    if (type === PaymentTypes.IN_STORE) return true;
    if (type === PaymentTypes.CARD && (!order.userId || !order.selectedMerchant?.value)) return true;
    return false;
  }, [order.userId, order.selectedMerchant?.value]);
  return (
    <SubStyledWrapper>
      {/* Payment info */}
      <div className="box">
        <div className="box-header">
          <h6 className="box-title">
            Payment info
            {' '}
            <span className="box-title-condition">(Required)</span>
          </h6>
          <h6 className="box-sub-title">
            <InfoCircleFilled style={{ color: '#898D8F', marginRight: 3 }} />
            Please select how the customer paid their order
          </h6>
        </div>
        <Typography.Text className="input-title">
          Pay with
        </Typography.Text>
        <Radio.Group
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={order.paymentMethod?.id}
          defaultValue={order.paymentMethod?.id}
          name="payment_type"
        >
          <Space className="flex-col md:flex-row items-start">
            {paymentMethods?.map((pm) => (
              <Tooltip title={pm.description}>
                <Radio value={pm.id} key={pm.id} disabled={isPaymentDisable(pm.type)}>{pm.title}</Radio>
              </Tooltip>
            ))}
          </Space>
        </Radio.Group>
        {order.paymentMethod?.type == PaymentTypes.CARD &&
          order.userId &&
          order.selectedMerchant?.value && (
          <UserCards loading={loading} stripeKey={stripeKey} cards={cards} reFetch={reFetch} />
        )}

        {order.paymentMethod?.type == PaymentTypes.CUSTOM && order.paymentMethod?.meta && (
          <CustomPaymentForm paymentMethod={order.paymentMethod} />
        )}
      </div>
    </SubStyledWrapper>
  );
}

export default PaymentInfo;
