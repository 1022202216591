import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title-row {
    margin-bottom: 40px;
    display: block;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1} !important;
      margin-right: 27px;
      margin-bottom: 0;
    }
  }

  .table-box {
    margin-bottom: 100px;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -48px;
    margin-right: 0;
  }

  .tabs-box {
    border-bottom: 1px solid #e7e8e9;
    margin: 0 56px 12px;
  }

  .sort {
    padding: 0 !important;
    width: 140px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;
    justify-content: flex-start !important;
    position: relative;
    top: unset !important;

    .icon--lead {
      color: #898d8f;
      width: 43px;
    }

    &-list {
      width: 185px !important;

      &-item {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #131b1f;
      }
    }

    &-btn {
      width: 185px !important;
      padding: 6px;
      line-height: 18px;
    }
  }

  .add-btn {
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    padding: 5px 8px 5px 6px !important;
    margin: 0 !important;
    height: 28px !important;
  }

  .empty {
    margin: 126.5px auto;
    width: 291px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${Colors.textPrimary1};
      margin: 20.7px auto 2px;
    }

    &-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666b6d;
      margin-bottom: 20px;
      width: ;
    }

    &-btn {
      padding: 5px 8px 5px 6px !important;
      margin: 0 !important;
    }

    .tag {
      color: #b8bbbc;
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1350px) {
      flex-direction: column;
    }
  }

  .loading {
    margin-top: 150px;
  }

  .no-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #6e7579;
      margin-top: 16px;
      width: 245px;
      text-align: center;
    }
  }
`;
