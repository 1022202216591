import { PlusSquareOutlined } from '@ant-design/icons';
import {
  Empty,
  Flex, Form, Image, List, Select, Skeleton, Space, Typography,
} from 'antd';
import { Colors } from 'assets/colors';
import { fallbackItemImage } from 'containers/CreateOrder/constants';
import DietaryTags from 'components/DietaryTags';
import React, { useEffect, useMemo, useState } from 'react';
import { errorHandling } from 'utils';
import { captureException } from 'utils/errors';
import { getImage } from 'utils/image';
import { renderServingSizeLabel } from 'utils/orders';
import { getItemPrices } from '../ItemDetailOverlay/item';
import ItemPrice from './ItemPrice';
import SearchItemInput from './SearchItemInput';
import { fetchMenus } from './requests';

function MenuItems({
  merchantId, onAdd, isCatering,
}) {
  const [loading, setLoading] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState();
  const [menus, setMenus] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const form = Form.useFormInstance();
  const scheduledFor = form.getFieldValue('scheduled_for');
  useEffect(() => {
    if (merchantId) {
      setLoading(true);
      fetchMenus({ merchantId, scheduledTime: scheduledFor })
        .then((data) => {
          const fetchedMenus = data.results?.map((menu) => ({
            ...menu,
            value: menu.id,
            label: menu.name,
          }));
          setMenus(fetchedMenus);
          setSelectedMenu(fetchedMenus[0]);
        })
        .catch((e) => {
          captureException(e);
          errorHandling(e.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, scheduledFor]);

  const categories = useMemo(() => {
    const allCats = selectedMenu?.categories ?? [];
    const terms = searchTerm.toLowerCase().split(' ').filter((s) => s);
    if (!terms.length) {
      return allCats.filter((cat) => cat.items?.length > 0);
    }
    const exactFilteredItems = JSON.parse(JSON.stringify(allCats)).map((cat) => ({
      ...cat,
      items: cat.items
        .filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase())),
    })).filter((cat) => cat.items.length > 0);

    if (exactFilteredItems.length) {
      return exactFilteredItems;
    }

    const filteredItems = JSON.parse(JSON.stringify(allCats)).map((cat) => ({
      ...cat,
      items: cat.items
        .filter(({ name }) => terms.some((term) => name.toLowerCase().includes(term))),
    })).filter((cat) => cat.items.length > 0);

    return filteredItems;
  }, [selectedMenu, searchTerm]);

  const onMenuChange = (val) => {
    setSelectedMenu(menus.find((m) => m.id === val));
  };

  return (
    <div className="md:pr-5">
      <Flex gap={8}>
        <Select
          placeholder="Select a menu"
          style={{ width: '50%' }}
          onChange={onMenuChange}
          options={menus}
          value={selectedMenu}
          disabled={loading}
          loading={loading}
        />
        <SearchItemInput
          style={{ width: '50%' }}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </Flex>
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          marginTop: 8,
        }}
      >
        {loading ? (
          <Skeleton paragraph={{ rows: 5 }} className="mt-4" />
        ) : (
          categories?.length ? categories?.sort((a, b) => a.position - b.position)?.map((cat) => (
            <List
              key={cat.id}
              header={<Typography.Text className="font-bold">{cat.name}</Typography.Text>}
              dataSource={cat?.items}
              renderItem={(item) => (
                <List.Item>
                  <Flex gap={16} align="center" className="w-full">
                    <Image
                      width={62}
                      height={62}
                      wrapperStyle={{ flexShrink: 0 }}
                      style={{ borderRadius: 4, border: '1px solid #DDE1E4' }}
                      src={getImage({ data: item })}
                      fallback={fallbackItemImage}
                      preview={Boolean(getImage({ data: item }))}
                    />
                    <Flex justify="space-between" align="center" className="w-full min-w-0 flex-shrink-1">
                      <Flex vertical align="start" gap={4} className="min-w-0 w-full flex-shrink-1">
                        <Typography.Text
                          style={{ width: '95%' }}
                          ellipsis={{
                            tooltip: item?.name,
                          }}
                        >
                          {item?.name}
                        </Typography.Text>
                        {isCatering && (
                          <Typography.Text
                            className="text-xs text-[#5A5F62] font-normal"
                          >
                            {renderServingSizeLabel(item?.minimum_serving_size)}
                          </Typography.Text>
                        )}
                        <Space align="center">
                          <ItemPrice
                            price={getItemPrices({ item })?.price}
                            discountedPrice={getItemPrices({ item })?.discountedPrice}
                          />
                          <DietaryTags tags={item?.dietary_tags} maxCount={5} hasTagStyle={false} />
                        </Space>

                      </Flex>
                      <PlusSquareOutlined
                        className="flex-shrink-0"
                        onClick={() => {
                          onAdd(item);
                        }}
                        style={{ color: Colors.primary1, fontSize: 20 }}
                      />
                    </Flex>
                  </Flex>
                </List.Item>
              )}
            />
          )) : (
            <Empty className="mt-8" />
          )
        )}
      </div>
    </div>
  );
}

export default MenuItems;
