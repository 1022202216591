import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

function MultiSelectFilter({
  placeholder, options, filters, setFilters, maxTagCount = 2, width = '25%', loading,
  addAllOption = true,
}) {
  const handleSelect = (value) => {
    let selectedOptions;
    if (value && value.length && value.includes('all')) {
      selectedOptions = value.length === options.length + 1 ? [] : options.map((o) => o.value.toString());
    } else {
      selectedOptions = value;
    }

    setFilters(selectedOptions);
  };
  return (
    <Select
      className="multi-select-filter-box"
      mode="multiple"
      allowClear
      style={{ width }}
      maxTagCount={maxTagCount}
      placeholder={placeholder}
      onChange={handleSelect}
      loading={loading}
      value={filters}
      filterOption={(inputValue, option) => (typeof option.children === 'string' ? option.children : option.children.join('')).toLowerCase().includes(inputValue.toLowerCase())}
    >
      {
        addAllOption && (
          <Option key="all" value="all">
            All
            {' '}
            {placeholder}
          </Option>
        )
      }

      {options?.map((option) => <Option key={option.value}>{option.label}</Option>)}
    </Select>
  );
}
export default MultiSelectFilter;
