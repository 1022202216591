import { message } from 'antd';
import dayjs from 'dayjs';

export const slugify = (text) => text
  .toString()
  .toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(/[^\w\-]+/g, '') // Remove all non-word chars
  .replace(/\-\-+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, '');

export const isEmpty = (obj) => {
  if (typeof obj !== typeof true && typeof obj !== typeof 0 && !obj) return true;
  if (obj.length === 0) return true;
  if (obj === '') return true;
  if (typeof obj === typeof {} && obj.hasOwnProperty('id')) return obj.id.value ? obj.id.value === '' : obj.id === '';
  return false;
};

export const isMobileSize = () => (!(window.innerWidth > 900));

export const immutableMove = (arr, from, to) => arr.reduce((prev, current, idx, self) => {
  if (from === to) {
    prev.push(current);
  }
  if (idx === from) {
    return prev;
  }
  if (from < to) {
    prev.push(current);
  }
  if (idx === to) {
    prev.push(self[from]);
  }
  if (from > to) {
    prev.push(current);
  }
  return prev;
}, []);

export const checkRegex = (str, regex) => {
  if (!regex) return true;
  const patt = new RegExp(regex);
  const res = patt.exec(str);
  if (res === null) return false;
  let ok = false;
  for (let i = 0; i < getData(res)?.length; i++) {
    if (getData(res)[i] === str) ok = true;
  }
  return ok;
};

export const subtractArrays = (a, b) => a.filter((x) => !b.includes(x));

export const insertDecimal = (num) => {
  if (!num) return 0;
  // return (Math.round(num * 100) / 100).toFixed(2);
  return Number(num).toFixed(2);
};

export const askUserPermission = async () => await Notification.requestPermission();

export const formatPhoneNumber = (phone) => {
  if (!phone) {
    return '';
  }
  phone = `${phone.startsWith('+') ? '' : '+'}${phone}`;
  const regex = /([+]\d{1,4})(\d{3})(\d{3})(\d{4})/;
  const match = phone.match(regex);

  if (!match) {
    return phone;
  }

  const [, prefix, middle1, middle2, last] = match;

  return `${prefix} (${middle1}) ${middle2} ${last}`;
};

export const generateURLWithParams = (url, params) => {
  if (!params) return url;
  let newURL = url;
  Object.keys(params).filter((key) => params[key]).forEach((key, index) => {
    const sign = index === 0 ? '?' : '&';
    newURL += `${sign}${key}=${params[key]}`;
  });
  return newURL;
};

export const getData = (res) =>
  /** Get the data part of response */
  res?.data || res;
export const errorHandling = (res) => {
  /** Following the new pattern of error responses from server,
  we should show the messages, errors and details */
  if (res?.data?.status === 'success') {
    if (res?.data?.message) {
      /** Success messages */
      message.success(res?.data?.message);
    }
  }

  if (res?.data?.status === 'failed') {
    /** Error messages */
    if (res?.data?.detail) {
      /** Detail */
      message.error(res?.data?.detail);
    }
    if (res?.data?.errors) {
      if (Object.values(res?.data?.errors)?.length > 0) {
        /** Errors array */
        const errors = Object.values(res?.data?.errors);
        for (let i = 0; i < errors?.length; i++) {
          const textError = errors[i];
          message.error(textError);
        }
      }
    }
  }
  if (res?.status === 500) {
    message.error('Something went wrong!');
  }
};

export const priceTransformer = (value, hideSign) => {
  const sign = hideSign ? '' : '$';
  const price = value === 0 ? 0 : (Math.round((Number(value) || 0) * 100) / 100).toFixed(
    2,
  );
  return `${sign}${price}`;
};

export function formatAmount(amount, { isCent, hideSign } = {}) {
  const value = isCent ? amount / 100 : amount;
  return `${hideSign ? '' : '$'}${(
    Math.round((value || 0) * 100) / 100
  ).toFixed(2)}`;
}

export const generateQuery = (queryArray) => {
  /** Get an array of queries and generate a query string */
  const validatedQueryArray = [];
  for (let i = 0; i < queryArray.length; i++) {
    const element = queryArray[i];
    if (element) {
      validatedQueryArray.push(element);
    }
  }

  return validatedQueryArray?.length ? `?${validatedQueryArray.join('&')}` : '';
};

export const calcSomeDaysAgoDate = (numberOfDaysBefore) =>
  /** Calculte numberOfDaysBefore days before now  */
  formatDate(new Date().setDate(new Date().getDate() - numberOfDaysBefore));
export const formatDate = (date) =>
  /** Formate date to YYYY-MM-DD */
  (date ? dayjs(date).format('YYYY-MM-DD') : null);
export const getQueryStringValue = (key) => new URLSearchParams(window.location.search).get(key);
