import React from 'react';
import { Flex } from 'antd';
import {
  FileTextTwoTone, InboxOutlined, MailTwoTone, PhoneTwoTone,
} from '@ant-design/icons';
import OrderEvent from './OrderEvent';
import CallEvent from './CallEvent';
import NoteEvent from './NoteEvent';
import EmailEvent from './EmailEvent';

export const EventType = {
  ORDER: 'Order',
  CALL: 'Call',
  NOTE: 'Note',
  EMAIL: 'Email',
};

export const EventItems = {
  [EventType.ORDER]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: '#fff',
          borderColor: '#00000040',
        }}
      >
        <InboxOutlined style={{ fontSize: 24, color: '#00000040' }} />
      </TimelineIcon>
    ),
    component: OrderEvent,
  },
  [EventType.CALL]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(246, 255, 237, 1)',
          borderColor: 'rgba(82, 196, 26, 1)',
        }}
      >
        <PhoneTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(82, 196, 26, 1)" />
      </TimelineIcon>
    ),
    component: CallEvent,
  },
  [EventType.NOTE]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(255, 247, 230, 1)',
          borderColor: 'rgba(250, 140, 22, 1)',
        }}
      >

        <FileTextTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(250, 140, 22, 1)" />
      </TimelineIcon>
    ),
    component: NoteEvent,
  },
  [EventType.EMAIL]: {
    icon: (
      <TimelineIcon
        style={{
          backgroundColor: 'rgba(230, 247, 255, 1)',
          borderColor: 'rgba(24, 144, 255, 1)',
        }}
      >
        <MailTwoTone style={{ fontSize: 24 }} twoToneColor="rgba(24, 144, 255, 1)" />
      </TimelineIcon>
    ),
    component: EmailEvent,
  },
};
function TimelineIcon({ style, children, ...props }) {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        width: 44,
        height: 44,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        ...style,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
}
