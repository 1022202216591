import {
  Col, Flex, InputNumber, Row, Space, Switch, Typography,
} from 'antd';
import React, { useContext } from 'react';
import { ModifierContext } from '../../context';

function Rules({ detail, onChangeDetail }) {
  const { showMessage } = useContext(ModifierContext);

  const handleMinimumChange = (value) => {
    if (value > detail.maximum) {
      showMessage({ type: 'error', content: 'Minimum selection could not be greater than Maximum selection' });
      return;
    }
    onChangeDetail('minimum', value);
  };
  const handleMaximumChange = (value) => {
    if (value < detail.minimum) {
      showMessage({ type: 'error', content: 'Maximum selection could not be less than Minimum selection' });
      return;
    }
    onChangeDetail('maximum', value);
  };
  return (
    <>
      <Typography.Title level={5}>Rules</Typography.Title>
      {/* <Space style={{ marginTop: 16 }} direction="vertical">*/}
      {/*  <Flex justify="space-between" align="center">*/}
      {/*    <Typography.Text strong>Required a selection</Typography.Text>*/}
      {/*    <Switch*/}
      {/*      checked={detail.isRequired}*/}
      {/*      onChange={(value) => onChangeDetail('isRequired', value)}*/}
      {/*      style={{ marginRight: 8 }}*/}
      {/*    />*/}
      {/*  </Flex>*/}
      {/*  <Typography.Text>*/}
      {/*    If you turn on this switch, customers will have to select at least one option in order to add the item to the*/}
      {/*    cart.*/}
      {/*  </Typography.Text>*/}
      {/* </Space>*/}

      {/* <Space style={{ marginTop: 16 }} direction="vertical">
        <Flex justify="space-between" align="center">
          <Typography.Text strong>Allow multiple selection</Typography.Text>
          <Switch
            checked={detail.isMultipleSelection}
            onChange={(value) => onChangeDetail('isMultipleSelection', value)}
            style={{ marginRight: 8 }}
          />
        </Flex>
        <Typography.Text>
          If you turn on this switch, customers will be able to select more than one option.
          You can modify how many quantity users can select per options.
        </Typography.Text>
      </Space> */}

      <Row gutter={8} style={{ marginTop: 16, alignItems: 'center' }}>
        <Col xs={24} md={4} lg={4} xl={4}>
          <Typography.Text strong>Minimum: </Typography.Text>
        </Col>
        <Col xs={24} md={8} lg={8} xl={8}>
          <InputNumber
            type="number"
            value={detail.minimum}
            // disabled={!detail.isMultipleSelection}
            placeholder="Min"
            style={{ width: '100%' }}
            max={30}
            onChange={handleMinimumChange}
          />
        </Col>
        <Col xs={24} md={4} lg={4} xl={4}>
          <Typography.Text strong>Maximum: </Typography.Text>
        </Col>
        <Col xs={24} md={8} lg={8} xl={8}>
          <InputNumber
            type="number"
            value={detail.maximum}
            // disabled={!detail.isMultipleSelection}
            placeholder="Max"
            style={{ width: '100%' }}
            max={30}
            onChange={handleMaximumChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default Rules;
