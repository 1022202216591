import {
  Divider, Flex, Skeleton, Typography,
} from 'antd';
import React from 'react';

function FormLayout({ style, ...props }) {
  return (
    <div
      className="py-8 lg:pt-10 lg:pb-20 relative omc-form-layout"
      style={{
        background: '#f6f8f7',
        border: '1px solid #DCDDDD',
        ...style,
      }}
      {...props}
    />
  );
}

function Section({
  children, title, subtitle = null, divider = true, className, ...props
}) {
  return (
    <>
      {divider && <Divider />}
      <div
        className={`px-5 lg:px-6 w-full ${className || ''}`}
        {...props}
      >
        {title && <Typography.Title level={5} style={{ fontWeight: 700 }} className={subtitle ? 'mb-0' : 'mb-6'}>{title}</Typography.Title>}
        {subtitle && <Typography.Paragraph type="secondary" className="mb-6">{subtitle}</Typography.Paragraph>}
        {children}
      </div>

    </>

  );
}
function Footer(props) {
  return (
    <Flex
      className="p-4 absolute w-full"
      style={{
        background: '#fff',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: '1px solid #DCDDDD',
        textAlign: 'right',
      }}
      justify="end"
      {...props}
    />
  );
}

FormLayout.Section = Section;
FormLayout.Footer = Footer;
FormLayout.Skeleton = function () {
  return (
    <FormLayout>
      <Section>
        <Skeleton paragraph={{ rows: 10 }} />
      </Section>
    </FormLayout>
  );
};
export default FormLayout;
