import Layout from 'components/Layout';
import React from 'react';
import { PERMISSIONS } from 'constants/permissions';
import withGuard from 'components/withGuard';
import Form from './Form';

function MerchantCreateFrom() {
  return (
    <Layout header={(
      <Layout.Header
        title="New restaurant"
        backTitle="Restaurants"
        backPath="/console/restaurants"
      />
    )}
    >
      <Form />
    </Layout>
  );
}

export default withGuard(MerchantCreateFrom, [PERMISSIONS.MERCHANTS], { redirect: true });
