import { EllipsisOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { priceTransformer } from 'utils';
import { BillingStatus } from './BillingStatus';

const columns = ({ onDetailClick }) => ([
  {
    title: 'Invoice Number', key: 'id', dataIndex: 'id',
  },
  {
    title: 'Issue Date',
    key: 'created_at',
    dataIndex: 'created_at',
    render: (date) => dayjs(date).format('MMM DD, HH:MM'),
  },
  {
    title: 'Amount',
    key: 'invoice_total',
    dataIndex: 'invoice_total',
    render: (_, { invoice_total: total }) => (total ? priceTransformer(total) : '-'),
  },
  {
    title: 'Status', key: 'status', dataIndex: 'status', render: BillingStatus,
  },
  {
    title: 'Due Date',
    key: 'due_date',
    dataIndex: 'due_date',
    render: (date) => dayjs(date).format('MMM DD, HH:MM'),
  },
  {
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, data) => (<Button icon={<EllipsisOutlined />} onClick={() => onDetailClick(data)} />),
  },
]);
export default columns;
