import CheckSvg from 'assets/image/checkboxIcon.svg';
import { Colors } from 'assets/colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  .wrapper {
    width: 100%;
    padding: 0 26px;
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 58px;
    text-align: left;
    margin-bottom: 18px;
    cursor: pointer;
    font-size: 16px;
    color: #000000bf;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #979797;

    border-radius: ${(p) => (p.multiSelect ? '4px' : '50%')};
    border: 2px solid ${(p) => (p.multiSelect ? 'green' : '#979797')};
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background: white;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${(p) => (p.multiSelect ? 'green' : 'white')};
    border-color: ${Colors.primary1};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 50%;
    left: 50%;
    width: ${(p) => (p.multiSelect ? '12px' : '13px')};
    height: ${(p) => (p.multiSelect ? '8px' : '13px')};
    border-radius: ${(p) => (p.multiSelect ? 'unset' : '50%')};
    transform: translate(-50%, -50%);
    background: ${Colors.primary1};
    background-image: ${(p) => (p.multiSelect ? `url(${CheckSvg})` : 'unset')};
  }
`;
