import React from 'react';
import { Modal, Tag, Typography } from 'antd';

import { Divider } from '@mui/material';
import { priceTransformer } from 'utils';

function ConfirmModal({
  open, data, onClose, onConfirm,
}) {
  const {
    paymentHolder, courier, type, description, amount,
  } = data;
  return (
    <Modal
      title="Confirming credit balance"
      onCancel={onClose}
      onOk={() => {
        onConfirm();
        onClose();
      }}
      open={open}
    >
      <Typography.Title level={3} className="flex justify-between">
        <span>
          Amount
        </span>
        <span>
          {priceTransformer(amount)}
        </span>
      </Typography.Title>
      <Divider className="m-3" />
      <Typography.Title level={5} className="flex justify-between">
        <span>Payment Date</span>
        <strong>{new Date().toLocaleString()}</strong>
      </Typography.Title>
      <Typography.Title level={5} className="flex justify-between">
        <span>Payment Holder</span>
        <strong>{paymentHolder || '-'}</strong>
      </Typography.Title>
      <Typography.Title level={5} className="flex justify-between">
        <span>Type</span>
        <Tag>{type || '-'}</Tag>
      </Typography.Title>
      <Typography.Title level={5} className="flex justify-between">
        <span>Courier</span>
        <strong>{courier || '-'}</strong>
      </Typography.Title>
      {
        description && (
          <>
            <Divider className="m-3" />
            <Typography.Title level={5}>
              Description
            </Typography.Title>
            <Typography.Paragraph>{description}</Typography.Paragraph>
          </>
        )
      }
    </Modal>

  );
}

export default ConfirmModal;
