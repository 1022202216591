import { ReactComponent as CheckIcon } from 'assets/image/check.svg';
import { ReactComponent as MoreIcon } from 'assets/image/more.svg';
import { ReactComponent as ScheduledIcon } from 'assets/image/scheduled.svg';

import BadgeLabel from 'components/BadgeLabel';
import Dropdown from 'components/Dropdown';
import PaginateTable from 'components/PaginateTable';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router'
import { ModalContext } from '../../../context/modalContext';
import DeleteModal from '../DeleteModal';
import { StyledTable } from './styles';

function Table({
  categoryTitle,
  loading,
  items,
  pagination,
  resetPaging,
  reload,
  ...props
}) {
  const navigate = useNavigate();
  const ROW_OPTIONS = [
    {
      title: 'Edit notification',
      element: (row) => (
        <div
          className={`more-btn--list-item ${
            isCampaignCompleted(row) ? 'disabled' : ''
          }`}
        >
          Edit notification
        </div>
      ),
    },
    {
      title: 'Duplicate',
      element: (row) => <div className="more-btn--list-item">Duplicate</div>,
    },
    {
      title: 'Delete',
      element: (row) => (
        <div
          className={`more-btn--list-item more-btn--list-item-red ${
            isCampaignCompleted(row) ? 'disabled' : ''
          }`}
        >
          Delete
        </div>
      ),
    },
  ];

  const { handleModal } = useContext(ModalContext);

  const handleStatus = (row) => {
    const statusTitle = getCampaignTitle(row);
    const statusClassName = row?.status;
    return (
      <BadgeLabel
        label={statusTitle}
        classes={`status status--${statusClassName}`}
        leadingIcon={
          isCampaignCompleted(row) ? (
            <CheckIcon />
          ) : isCampaignScheduled(row) ? (
            <ScheduledIcon />
          ) : null
        }
      />
    );
  };

  const getCampaignTitle = (row) => {
    switch (row?.status) {
    case 'D':
      return 'Done';
    case 'S':
      return 'Scheduled';
    case 'C':
      return 'Canceled';
    case 'F':
      return 'Failed';
    case 'I':
      return 'In Progress';
    default:
      break;
    }
  };

  const isCampaignCompleted = (row) => row.status === 'D';

  const isCampaignScheduled = (row) => row.status === 'S';

  const handleFormatDate = (d) => (d ? (
    <div>
      <div>{dayjs(d)?.format('ddd, MMM D')}</div>
      <div className="clock">{dayjs(d)?.format('h:mm A')}</div>
    </div>
  ) : (
    ' - '
  ));

  const handleEditNotification = (row) => {
    navigate(`/console/marketing/edit/${row?.id || 830}`);
  };

  const handleDuplicateNotification = (row) => {
    navigate(`/console/marketing/duplicate/${row?.id || 830}`);
  };

  const handleMoreOption = (val, row) => {
    if (val === ROW_OPTIONS[0].title) {
      if (isCampaignCompleted(row)) {

      } else {
        handleEditNotification(row);
      }
    } else if (val === ROW_OPTIONS[1].title) {
      handleDuplicateNotification(row);
    } else if (isCampaignCompleted(row)) {

    } else {
      handleModal(<DeleteModal row={row} reload={reload} />);
    }
  };

  const handleReceivers = (row) => {
    const receiversArr = row.receivers?.split(', ');

    if (receiversArr?.length && receiversArr[0] === '') {
      return 'All customers';
    }
    return row.receivers;
  };

  const handleTitle = (row) => (
    <div>
      <div className="n-title">{row?.title || '-'}</div>
      <div className="n-body">{row?.body || '-'}</div>
    </div>
  );

  const createOptions = (row) => ROW_OPTIONS.map((r) => ({ ...r, element: r.element(row) }));

  return (
    <StyledTable>
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: 'Notification', width: '382px' },
          { title: 'Status', width: '204px' },
          { title: 'Target', width: '218px' },
          { title: 'Time', width: '200px' },
          { title: '', width: 'auto' },
        ]}
        dataArray={loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : items}
        rowCreators={[
          (row, index) => <div className="">{handleTitle(row)}</div>,
          (row, index) => <div className="">{handleStatus(row)}</div>,
          (row, index) => <div className="">{handleReceivers(row)}</div>,
          (row, index) => (
            <div className="">{handleFormatDate(row.send_at)}</div>
          ),
          (row, index) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                label={<MoreIcon />}
                options={createOptions(row)}
                onChange={(val) => handleMoreOption(val, row)}
                selected={null}
                noArrow
                btnClass="more-btn"
                btnOpenClass="more-btn--open"
                optionClass="list-item-custom"
              />
            </div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={(row) => {
          navigate(`/console/marketing/detail/${row?.id}`);
        }}
        newRowClasses="new-row"
        dataPaging={pagination}
      />
    </StyledTable>
  );
}

export default Table;
