import {
  DeleteOutlined, PlusOutlined,
} from '@ant-design/icons';
import { Button, Flex, Upload } from 'antd';
import React, { useState } from 'react';

function FileInput({
  value, onChange, id,
}) {
  const [thumbnail, setThumbnail] = useState(null);

  async function handleChange({ file }) {
    setThumbnail(await getBase64(file.originFileObj));
    onChange(file.originFileObj);
  }
  function renderContent() {
    const img = thumbnail || (value && (typeof value === 'string') ? value : null);
    if (img) {
      return (
        <Flex
          align="center"
          style={{
            height: '100%',
            position: 'relative',
          }}
        >
          <img src={img} alt={id} style={{ width: '100%' }} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setThumbnail(null);
              onChange(null);
            }}
            shape="circle"
            icon={<DeleteOutlined />}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          />
        </Flex>
      );
    }

    return (
      <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8, textTransform: 'capitalize' }}>{id}</div>
      </button>
    );
  }

  return (
    <Upload
      listType="picture-card"
      onChange={handleChange}
      showUploadList={false}
      onRemove={() => { }}
    >
      {renderContent()}
    </Upload>
  );
}
const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export default FileInput;
