import { Button, Space } from 'antd';
import { editCoupon, getCouponById } from 'containers/Coupons/coupons';
import React, { useContext, useState } from 'react';
import { captureException } from 'utils/errors';
import { ModalContext } from '../../../context/modalContext';
import { StyledWrapper } from './styles';

function ActivateModal({ row, reload, ...props }) {
  const { handleModal } = useContext(ModalContext);
  const [activateLoading, setActivatingLoading] = useState(false);

  const handleActivate = ({ id, is_disabled }) => {
    setActivatingLoading(true);
    getCouponById(id)
      .then((data) => {
        data.is_disabled = !is_disabled;
        data.holder_merchant = data.holderMerchant?.id;
        data.parents = data.parents?.map((m) => m.id);
        data.merchants = data.merchants?.map((m) => m.id);

        editCoupon(data)
          .then((res) => {
            setActivatingLoading(false);
            handleModal();
            reload();
          })
          .catch((e) => {
            captureException(e);
            setActivatingLoading(false);
          });
      })
      .catch((e) => {
        captureException(e);
        setActivatingLoading(false);
      });
  };
  return (
    <StyledWrapper>
      {' '}
      <div className="delete-modal">
        <p className="title">
          {row?.is_disabled ? 'Activate' : 'Deactive' + ' '}
          {' '}
          {row?.code}
        </p>
        <div className="modal-content">
          {row?.is_disabled ? (
            <p className="qa">
              This coupopn will become active now and will have no end date. All
              unsaved changes will be lost.
            </p>
          ) : (
            <p className="qa">
              This coupon will expire now and all unsaved changes will be lost.
            </p>
          )}
        </div>
        <div className="btns">
          <Space>
            <Button onClick={() => handleModal()} label="Cancel" type="default">
              Cancel
            </Button>
            <Button
              onClick={() => handleActivate(row)}
              type="primary"
              loading={activateLoading}
            >
              {row?.is_disabled === true ? 'Activate' : 'Deactive'}
            </Button>
          </Space>
        </div>
      </div>
    </StyledWrapper>
  );
}

export default ActivateModal;
