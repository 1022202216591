import { InfoCircleFilled } from '@ant-design/icons';
import {
  Alert, Col, Divider, Row, Typography,
} from 'antd';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useMemo } from 'react';
import OrdersTable from './OrdersTable';
import AddOrderItems from './AddOrderItems';
import ScheduledSection from './ScheduledSection';
import SearchMerchant from './SearchMerchant';

function OrderItems() {
  const { order } = useContext(CallInOrderContext);
  const isOrderingOn = order.selectedMerchant?.merchant?.get_orders_is_on;

  const getWarnMessage = useMemo(() => {
    if (!order.selectedMerchant?.merchant || isOrderingOn) return '';
    if (!isOrderingOn) {
      if (order.selectedMerchant.merchant.has_scheduling_order) return 'Merchant is closed! Please select another time to continue.';
      return 'Merchant is closed! Please select another merchant.';
    }
  }, [order.selectedMerchant?.merchant]);
  return (
    <>
      <div className="box">
        <div className="box-header">
          <Typography.Text className="box-title">
            Order Items
            {' '}
            <span className="box-title-condition">(Required)</span>
          </Typography.Text>
          <h6 className="box-sub-title">
            <InfoCircleFilled style={{ color: '#898D8F', marginRight: 2 }} />
            Please select the merchant then add the items
          </h6>
        </div>
        <Row style={{ alignItems: 'flex-end' }} gutter={0}>
          <Col xs={24} md={12} xl={5}><SearchMerchant /></Col>
          <Col xs={24} xl={16}>{order.selectedMerchant?.merchant?.has_scheduling_order && <ScheduledSection />}</Col>
          <Col xs={24} xl={3}>
            <AddOrderItems disable={!(order.selectedMerchant?.merchant && (isOrderingOn || (!isOrderingOn && order?.scheduledFor)))} />
          </Col>

          <Col xs={24} lg={10}>
            {order.selectedMerchant?.merchant && !isOrderingOn && (!order.selectedMerchant?.merchant?.has_scheduling_order ||
            (order.selectedMerchant?.merchant?.has_scheduling_order && !order?.scheduledFor)) &&
            <Alert message={getWarnMessage} type="warning" style={{ marginTop: 8 }} />}
          </Col>
        </Row>
        {Boolean(order.items?.length) && <OrdersTable />}
      </div>

      <Divider style={{ margin: 0 }} />
    </>
  );
}

export default OrderItems;
