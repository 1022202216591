import { createContext } from 'react';
import { LocalJsonStorage } from 'utils/storage';

export const AdminContext = createContext();
export const NewOrdersContext = createContext();
export const ConfigContext = createContext({});

export const AuthContext = createContext({});
AuthContext.getUser = () => LocalJsonStorage.get('admin-provider/user');
AuthContext.getOrganization = () => LocalJsonStorage.get('admin-provider/organization');
