import * as Sentry from '@sentry/react';

export function captureException(e, ctx) {
  if (e == null || e?.isCaptured) {
    return;
  }

  console.error(e);

  if (typeof e === 'string') {
    Sentry.captureException(new Error(e), ctx);
    return;
  }
  if (typeof e !== 'object') {
    return;
  }

  e.isCaptured = true;

  if (e instanceof Error) {
    Sentry.captureException(e, ctx);
    return;
  }

  if (e.message) {
    Sentry.captureException(new Error(e.message), ctx);
    return;
  }

  const message = Object.entries(e)
    .filter(([key]) => key !== 'isCaptured')
    .map(([key, value]) => `${key}:${value?.toString()}`)
    .join('\n');

  Sentry.captureException(new Error(message), ctx);
}
