import { PlusOutlined } from '@ant-design/icons';
import {
  Col, Form, Input, Row, Space, Typography,
} from 'antd';
import { fetchCustomers } from 'api/customers';
import { Colors } from 'assets/colors';
import DebounceSelect from 'components/Select/DebounceSelect';
import { CallInOrderContext } from 'containers/CreateOrder/context';
import React, { useContext, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { getData } from 'utils';
import { captureException } from 'utils/errors';
import CreateUserModal from './CreateUserModal';

function CustomerInfo() {
  const { setOrderDetails } = useContext(CallInOrderContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [newUserCreatedPhone, setNewUserCreatedPhone] = useState();

  const highlightText = (text) => {
    if (!text) return;
    const searchIndex = text.toLowerCase().indexOf(searchKey?.toLowerCase());
    if (searchIndex !== -1) {
      return (
        <span>
          {text.substring(0, searchIndex)}
          <strong style={{ color: '#101112' }}>
            {text.substr(searchIndex, searchKey?.length)}
          </strong>
          {text.substring(searchIndex + searchKey?.length)}
        </span>
      );
    }
    return text;
  };

  const getCustomers = (value) => {
    setSearchKey(value);
    return fetchCustomers({ searchKey: value })
      .then((res) => {
        const fetchedCustomers = getData(res)?.results?.map((c, idx) => ({
          customerId: c.id,
          name: c.name,
          email: c.email,
          value: c.phone,
          label: c.name,
        }));
        fetchedCustomers.push({ value: '', label: '' });
        return fetchedCustomers;
      })
      .catch((e) => {
        captureException(e);
      });
  };

  const onUserSelect = (user) => {
    setOrderDetails({ userId: user?.customerId });
    setSelectedCustomer(user);
  };
  const onSelect = (value, option) => {
    if (option?.value === '') {
      setShowCreateUserModal(true);
    } else {
      onUserSelect(option);
    }
  };

  const optionRenderer = (option) => (
    option.data.value ? (
      <Row
        gutter={5}
        align="middle"
        style={{
          padding: '8px 0 12px',
          borderBottom: '1px solid #A7A9A9',
        }}
      >
        <Col xs={24} lg={8}>
          <Typography.Text style={{ color: '#7d8284' }}>
            {highlightText(option.data.value)}
          </Typography.Text>
        </Col>
        {option.data.name && (
          <Col xs={24} lg={7}>
            <Typography.Text ellipsis style={{ color: '#7d8284' }}>
              {highlightText(option.data.name)}
            </Typography.Text>
          </Col>
        )}
        {option.data.email && (
          <Col xs={24} lg={8}>
            <Typography.Text ellipsis style={{ color: '#7d8284' }}>
              {highlightText(option.data.email)}
            </Typography.Text>
          </Col>
        )}
      </Row>
    ) : (
      <Row
        gutter={20}
        align="middle"
        style={{ height: 48 }}
      >
        <Col span={8}>
          <div>
            <span>
              <PlusOutlined style={{ color: Colors.primary1 }} />
            </span>
            <span style={{ color: Colors.primary1 }}> Create user</span>
          </div>
        </Col>
      </Row>
    ));

  return (
    <>
      <CreateUserModal
        open={showCreateUserModal}
        onClose={setShowCreateUserModal}
        onSuccess={(user) => {
          onUserSelect(user);
          setNewUserCreatedPhone(user.value);
        }}
      />
      {/* Customer info */}
      <div className="">
        <Typography.Text className="input-title">Select customer</Typography.Text>
        <Row style={{ width: '100%', marginBottom: newUserCreatedPhone ? 24 : 0 }}>
          <Col xs={24} md={12} lg={10} className="mr-2 lg:mr-6">
            {!newUserCreatedPhone ? (
              <Form.Item
                name="customer"
                rules={[
                  {
                    required: true,
                    message: 'Please select customer!',
                  },
                ]}
              >
                <DebounceSelect
                  id="customers"
                  allowClear
                  placeholder="Search Phone number, Name, or Email"
                  fetchOptions={getCustomers}
                  onChange={onSelect}
                  showSearch
                  value={selectedCustomer}
                  setSearchKey={setSearchKey}
                  style={{ width: '100%', height: 40, marginTop: 8 }}
                  optionRender={optionRenderer}
                />
              </Form.Item>
            ) : (
              <Input

                name="createdUserPhone"
                value={newUserCreatedPhone}
                enterButton
                allowClear
                onChange={() => {
                  setNewUserCreatedPhone();
                  setSelectedCustomer();
                }}
              />
            )}
          </Col>
          <Col style={{ display: 'flex' }}>
            <Space className="flex-col md:flex-row items-start md:items-center">
              <Typography.Text>{selectedCustomer?.name}</Typography.Text>
              <Typography.Text style={{ color: '#A7A9A9' }}>
                {selectedCustomer?.email}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CustomerInfo;
