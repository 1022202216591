import React from 'react';

import { Input, Select } from 'antd';

function SchedulingDaysInput({ isCatering, disabled, ...props }) {
  return (
    <>
      <Input type="number" disabled={disabled} style={{ display: isCatering ? undefined : 'none' }} {...props} />
      <Select
        defaultValue={1}
        options={
          [
            { value: 1, label: '1 day' },
            ...[2, 3, 4, 5, 6].map((days) => ({ value: days, label: `${days} days` })),
          ]
        }
        disabled={disabled}
        style={{ display: isCatering ? 'none' : undefined }}
        {...props}
      />
    </>
  );
}

export default SchedulingDaysInput;
