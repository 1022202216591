import styled from 'styled-components';
import { Navbar as RBNavbar } from 'react-bootstrap';
import { Colors } from 'assets/colors';

export const StyledNav = styled(RBNavbar)`
  color: ${Colors.brandColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  nav {
    display: flex;
    align-items: center;
    a {
      font-weight: bold;
      color: ${Colors.white} !important;
    }
    .getstarted {
      color: #f48530 !important;
      background: ${Colors.white};
      border-radius: 100px;
    }
  }
`;
