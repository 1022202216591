import React, { useRef, useState } from 'react';

import { ArrowDownIcon } from 'assets/icons';
import PropTypes from 'prop-types';
import Button from '../Button';
import { StyledDropdownWrapper } from './styles';
import { useOutsideClick } from '../../hooks/useClickOutside';

function Dropdown({
  classes,
  label,
  options,
  onChange,
  selected,
  btnType,
  optionClass,
  noArrow,
  btnClass,
  btnOpenClass,
  listClass,
  isSelectByData,
  disabled = false,
  doNotCloseOnClick = false,
  ...props
}) {
  const [showList, setShowList] = useState(false);
  const btnRef = useRef(null);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => handleCloseList());

  const handleCloseList = () => {
    setShowList(false);
  };

  return (
    <StyledDropdownWrapper>
      <Button
        disabled={disabled}
        ref={btnRef}
        label={label}
        type={btnType}
        onClick={() => (!showList ? setShowList(true) : null)}
        trailingIcon={
          noArrow ? null : showList ? (
            <ArrowDownIcon className="arrow-smooth arrow-up" />
          ) : (
            <ArrowDownIcon className="arrow-smooth" />
          )
        }
        classes={showList ? `btn-open ${btnOpenClass}` : `btn  ${btnClass}`}
      />
      {showList ? (
        <div className={`list ${listClass}`} ref={dropdownRef}>
          {options.map((option, index) => (
            <div
              className={`list-item ${optionClass}`}
              key={index}
              onClick={() => {
                if (isSelectByData) {
                  onChange(option?.data);
                } else {
                  onChange(option.title);
                }
                if (!doNotCloseOnClick) {
                  handleCloseList();
                }
              }}
            >
              {option.element}
            </div>
          ))}
        </div>
      ) : null}
    </StyledDropdownWrapper>
  );
}

Dropdown.propTypes = {
  classes: PropTypes.string,
  label: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.any,
  btnType: PropTypes.string,
  optionClass: PropTypes.string,
  noArrow: PropTypes.bool,
  btnClass: PropTypes.string,
  btnOpenClass: PropTypes.string,
  listClass: PropTypes.string,
};

export default Dropdown;
