import {
  Col, Form, Radio, Row, Space,
} from 'antd';
import React, { useContext } from 'react';
import { captureException } from 'utils/errors';
import OrderFormItem from '../OrderFormItem';
import AddressInput from './AddressInput';
import { StepsContext } from '../Steps/StepsProvider';
import { checkLocationIsSupported } from './requests';

function OrderType() {
  const has_delivery = Form.useWatch('has_delivery');
  const merchant = Form.useWatch('merchant');
  const { lock, unlock } = useContext(StepsContext);
  const checkAddressZone = (addressId) => {
    lock();
    return checkLocationIsSupported({ merchantId: merchant?.id, addressId })
      .then(({ data }) => data.is_location_supported)
      .catch(captureException)
      .finally(() => unlock());
  };

  return (
    <Row>
      <Col xs={24} lg={7}>
        <OrderFormItem name="has_delivery" label="Order type" fullWidth rules={[{ required: true }]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value>Delivery</Radio>
              <Radio value={false}>Pickup</Radio>
            </Space>
          </Radio.Group>
        </OrderFormItem>
      </Col>
      {has_delivery && (
        <Col xs={24} lg={17}>
          <OrderFormItem
            name="address"
            fullWidth
            validateTrigger={['onChange', 'onBlur']}
            rules={[{
              required: true,
            }, {
              validator: async (_, value) => {
                if (!value?.id) {
                  return Promise.resolve();
                }
                const isSupported = await checkAddressZone(value.id);
                if (!isSupported) {
                  return Promise.reject(new Error('Out of operation area. Please select another address.'));
                }
                return Promise.resolve();
              },
            }]}
          >
            <AddressInput />
          </OrderFormItem>
        </Col>
      )}
    </Row>
  );
}
export default OrderType;
