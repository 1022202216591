import styled from 'styled-components';
import ArrowDown from 'assets/icons/arrowDown.svg';
import { Colors } from 'assets/colors';

export const StyledWrapper = styled.div`
  position: relative;

  .btn {
    &-box {
      margin-top: 60.5px;
    }

    &-up {
      transform: rotate(180deg);
    }

    &-smooth {
      transition: 0.3s ease;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 0 40px;
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #1a1f36;
      height: 32px;
    }

    &-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &-style {
      font-family: 'Manrope';
      position: relative;
      margin: 0 8px 0 0;
      background: #ffffff;
      border: none;
      border-radius: 5px;
      color: ${Colors.textPrimary1};    
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      box-shadow: 0px 0px 0px 1px #D5D6D6, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05);
      padding: 6px 8px;
      display: flex;
      align-items: center;
      height: 32px;
      padding: 7px 12px;

      &:hover {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.16);
      }

      &--sun {
        margin-right: 8px;
      }

      &--cal {
        margin-right: 11px;
      }

      &--active {
         box-shadow: 0px 0px 0px 2px rgba(43, 100, 245, 0.4), 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .date-picker {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1000;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: initial;
    width: 257px;

    &-bottom {
      width: 100%;
      display: flex;
      height: 52px;
      border-top: 1px solid #eeedf0;
      padding: 4px 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;

      &-btn {
        position: relative;
        margin: 0 0 0 10px;
        background: #ffffff;
        border: none;
        border-radius: 4px;
        color: #4f566a;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
          0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
        padding: 6px 8px;
        height: 30px;
        color: #050505;

        &:hover {
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
            0px 0px 0px 1px rgba(60, 66, 87, 0.16),
            0px 1px 1px rgba(0, 0, 0, 0.16);
        }

        &--colored {
          background-color: #3765f0;
          color: white;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
            0px 0px 0px 1px #3765f0, 0px 1px 1px rgba(0, 0, 0, 0.12);

          &:hover {
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.18),
              0px 0px 0px 1px #2f5ce3, 0px 1px 1px rgba(0, 0, 0, 0.16);
            background: #2f5ce3;
          }

          &:disabled {
            background-color: #afc1f9;
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
              0px 0px 0px 1px #afc1f9, 0px 1px 1px rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  .date-picker-bottom--date {
    position: absolute;
    left: 99px;
    color: ${Colors.textPrimary1};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .react-datepicker {
    display: flex;
    border: none;
    margin: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
    border: 0.5px solid #d0d1d2;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #e1e1e1 !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 3%), 0px 0.5px 0.5px rgb(0 0 0 / 7%);
    overflow: hidden;
  }

  .react-datepicker__month-container {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    z-index: 1000;
    background-color: transparent;
    cursor: initial;
    padding-top: 17px;
    width: 285px;

    &-bottom {
      width: 100%;
      display: flex;
      height: 52px;
      border-top: 1px solid #eeedf0;
      padding: 4px 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;

      &-btn {
        position: relative;
        margin: 0 0 0 10px;
        background: #ffffff;
        border: none;
        border-radius: 4px;
        color: #4f566a;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
          0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
        padding: 6px 8px;
        height: 30px;
        color: #050505;

        &:hover {
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
            0px 0px 0px 1px rgba(60, 66, 87, 0.16),
            0px 1px 1px rgba(0, 0, 0, 0.16);
        }

        &--colored {
          background-color: #3765f0;
          color: white;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
            0px 0px 0px 1px #3765f0, 0px 1px 1px rgba(0, 0, 0, 0.12);

          &:hover {
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.18),
              0px 0px 0px 1px #2f5ce3, 0px 1px 1px rgba(0, 0, 0, 0.16);
            background: #2f5ce3;
          }

          &:disabled {
            background-color: #afc1f9;
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
              0px 0px 0px 1px #afc1f9, 0px 1px 1px rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  .date-picker-bottom--date {
    position: absolute;
    left: 99px;
    color: ${Colors.textPrimary1};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .react-datepicker {
    display: flex;
    border: none;
    margin: 14px;
    margin-top: 0;
    margin-bottom: 0;
    width: 284px;
    height: 287px;
  }

  .react-datepicker__month {
    margin-left: 14px;
  }

  .react-datepicker__header {
    background: white;
    border-bottom: none;
    margin: 0;
    padding-top: 0;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    z-index: 2000;
  }

  .react-datepicker__navigation--next {
    right: 11px !important;
    z-index: 2000;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .react-datepicker__year-dropdown-container {
    position: absolute;
    top: 267px;
    left: -9px;
    width: 72px;
    z-index: 2;
    position: relative;
    margin: 0 0 0 10px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    color: #4f566a;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
      0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 6px 8px;
    height: 30px;
    color: #050505;

    &:hover {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.16);
    }
  }

  .react-datepicker__header__dropdown {
    height: 0;
    width: 0;
  }

  .react-datepicker__year-read-view {
    text-align: left;
  }

  .react-datepicker__day--today {
    background-color: white;
    color: #19181a;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  .react-datepicker__day-names {
    margin: 0 16px;
  }

  .react-datepicker__day-name {
    width: 36px;
    height: 24px;
    color: #a1a4a5;
    margin: 12px 0 0 0;
  }

  .react-datepicker__day {
    width: 36px;
    height: 33px;
    border-radius: 5px !important;
    margin: 0;
    padding-top: 3px;
    border: 1px solid transparent;
    background-color: white;
    border-radius: 0;
    color: #19181a;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  .react-datepicker__day:hover {
    background: #f3f4f4;
    color: ${Colors.textPrimary1};
    border-radius: 5px;
    width: 36px;
    height: 33px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-end {
    background: ${Colors.primary1} !important;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: white !important;
    border-radius: 5px;
    width: 36px;
    height: 33px;
  }

  .react-datepicker__day--range-end {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -58%;
      width: 16px;
      height: 32px;
      background-color: transparent;
      box-shadow: 15px 0 0 #f0f0f0;
      z-index: -1;
      border-radius: 0;
    }
  }

  .react-datepicker__day--selected {
    position: relative;

    &:before {
      display: none;
      content: "";
      position: absolute;
      top: -1px;
      right: 46%;
      width: 16px;
      height: 32px;
      background-color: transparent;
      box-shadow: 15px 0 0 #f0f0f0;
      z-index: -1;
      border-radius: 0;
    }
  }

  .react-datepicker__day--outside-month {
    background-color: white !important;
    background-image: none !important;
    box-shadow: none !important;

    &:before {
      display: none;
    }
  }

  .react-datepicker__day--001.react-datepicker__day--in-range {
    background-image: linear-gradient(to right, white 11%, #f0f0f0);
    border: none;
  }

  /* .react-datepicker__day--030.react-datepicker__day--in-range {
  background-image: linear-gradient(to left, white 11%, #f0f0f0);
  border: none;
} */

  .react-datepicker__day--031.react-datepicker__day--in-range {
    background-image: linear-gradient(to left, white 11%, #f0f0f0);
    border: none;
  }

  .react-datepicker__day--in-range {
    background: #f0f0f0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
  }

  .react-datepicker__day--in-selecting-range {
    background: #f0f0f0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
    position: relative;

    &:hover {
      /* box-shadow: -10px 0 0 #f0f0f0; */
    }
  }

  .react-datepicker__week {
    display: flex;
    position: relative;
    z-index: 1;
  }

  .react-datepicker__year-read-view--down-arrow {
    position: relative;
    border-top: none !important;

    &::before {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: -5px;
      right: -6px;
      width: 12px;
      height: 12px;
      background-image: url(${ArrowDown});
    }
  }

  .react-datepicker__year-dropdown {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
    margin-top: 3px;
    overflow: auto;
  }

  .react-datepicker__year-option {
    padding: 7px 0;
  }

  .react-datepicker__year-option--selected {
    display: none;
  }

  .react-datepicker__navigation--next {
    border: none !important;
    height: 26px;
    width: 26px;
    margin-top: 10px;
    right: -8px;
    top: 2px;

    &::after {
      content: "";
      position: absolute;
      top: 13%;
      right: 19%;
      width: 17px;
      height: 15px;
      padding: 2px;
      transform: rotate(-90deg);
      background-image: url(${ArrowDown});
      background-repeat: no-repeat;
    }
  }

  .react-datepicker__year-read-view {
    color: #3c4257;
    visibility: visible !important;
  }

  .react-datepicker__year-option {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3c4257;
    margin-left: 6px;
    width: 50px;

    &:hover {
      background: #f3f3f3;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #3c4257;
      margin-left: 6px;
      width: 50px;
    }
  }

  .react-datepicker__navigation--previous {
    border: none !important;
    height: 26px;
    width: 26px;
    margin-top: 10px;
    left: 11px;
    top: 2px;

    &::after {
      content: "";
      position: absolute;
      top: 13%;
      right: 19%;
      width: 17px;
      height: 21px;
      padding: 2px;
      transform: rotate(90deg);
      background-image: url(${ArrowDown});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
